import React, { useEffect, useState } from "react";
import styled from "styled-components";

import beforebasic1 from "./../../@images/main-before-basic.svg";
import beforehover1 from "./../../@images/main-before-hover.svg";
import nextbasic1 from "./../../@images/main-next-basic.svg";
import nexthover1 from "./../../@images/main-next-hover.svg";
import status01 from "./../../@images/status-easy.svg";
import status02 from "./../../@images/status-normal.svg";
import status03 from "./../../@images/status-busy.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

let MainCBorder = styled.div`
    width: 100%;
    height: auto;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-top: 7.2rem;
    padding-left:2rem;
    padding-right:2rem;
    padding-bottom: 5rem;
    box-sizing: border-box;
`

const TitleBorder = styled.div`
    width: 100%;
    height: 17.2rem;
    padding: 0 2rem 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position:relative;
`

const TitleBordertotalshow = styled.div`
    font-size:1.6rem;
    color:#9e9e9e;
    position:absolute;
    text-align:right;
    right:2.4rem;
    bottom:1.6rem;
    cursor:pointer;
`

const Titlecontent = styled.div`
    text-align:left;
`
const Titlesmall = styled.span`
    font-size:3.2rem;
    color: #F32E13;
`
const Titlebig = styled.span`
    font-size:4rem;
    font-weight:600;
    color: white;
`
const BestPickArrowContainer = styled.div`
    display: flex;
    height: 5.6rem;
    width: 10rem;
    justify-content: space-between;
`

const ArrowButton = styled.div`
    width: 4.8rem;
    height: 4.8rem;
    background-image: url(${props => props.default});
    background-size: cover;
    cursor: pointer;
   
`

let ChatRoomStatusBorder = styled.div`
    width: auto;
    height: auto;
    display: flex;
    justify-content: right;
    align-items: flex-start;
    box-sizing: border-box;
    gap: 0.8rem;
    position:absolute;
    text-align:right;
    left:2.4rem;
    bottom:1.6rem;
`

let ChatStatus = styled.div`
    height:2.4rm;
    width: auto;
    display: flex;
    gap: 0.2rem;
    align-items: center;
`

let StatusImage = styled.img`
    width: 2.4rem;
    height: 2.4rem;
`

let StatusText = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 400;
    color: #6a6a6a;
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
`

let RoomListBorder = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
`

let RoomBorder = styled.div`
    width: 100%;
    height: 14.6rem;
    background-color: white;
    border-radius: 0.8rem;
    cursor: pointer;
    box-sizing: border-box;
    padding: 1.6rem 2rem;
    display: flex;
    align-items: center;
    position: relative;
`

let RoomProfileImg = styled.img`
    min-width: 9.8rem;
    min-height: 9.8rem;
    max-width: 9.8rem;
    max-height: 9.8rem;
    border-radius: 0.8rem;
    background-color: black;
    object-fit: cover;
`

let RoomInfoBorder = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: left;
`

let RoomTextBorder = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

let RoomTitleText = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 1.8rem;
    font-weight: 600;
    color: #171717;
    height: auto;
`

let RoomTitleInfoText = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 1.5rem;
    font-weight: 400;
    color: #444444;
    height: auto;
    margin-top: 1.6rem
`

let RoomSteady = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 400;
    color: #6A6A6A;
    margin-top: 0.4rem
`

let StatusBorder = styled.div`
    height: 2.4rem;
    display: flex;
    gap: 0.4rem;
    position: absolute;
    top: 2.3rem;
    right: 2.4rem;
`

let EventStatus = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    background-color: black;
    border-radius: 2rem;
    box-sizing: border-box;
    padding: 0.4rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

let UpStatus = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    background-color: #F32E13;
    border-radius: 2rem;
    box-sizing: border-box;
    padding: 0.4rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

let RoomTime = styled.div`
    position: absolute;
    bottom: 2.4rem;
    right: 2rem;
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 400;
    color: #6A6A6A;
`

const MainChoice = () => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [group, setGroup] = useState([]);
    let navigate = useNavigate();

    useEffect(()=>{
        const fetchData = async () => {
            try {
                let response = await axios.get(`https://api.midtrc.com/choice`,{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instData = response.data;
                let filetrData = instData.filter(obj=>obj.name !== "바로대기방")
                setGroup(filetrData);
            } catch (error) {
                
            }
        }

        const interval = setInterval(() => {
            const now = new Date();
            const currentHour = now.getHours();
            const currentMinutes = now.getMinutes();
            
            // 오후 7시부터 다음날 오전 11:30분 사이에만 fetchData 실행
            if ((currentHour >= 13 && currentHour < 24) || 
                (currentHour >= 0 && (currentHour < 11 || (currentHour === 11 && currentMinutes <= 30)))) {
                fetchData();
            }
        }, 5000);
    
        // 컴포넌트 언마운트 시 타이머 정리
        return () => clearInterval(interval);
        
    },[])

    useEffect(()=>{
        const fetchData = async () => {
            try {
                let response = await axios.get(`https://api.midtrc.com/choice`,{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instData = response.data;
                let filetrData = instData.filter(obj=>obj.name !== "바로대기방")
                setGroup(filetrData);
            } catch (error) {
                
            }
        }
        fetchData()
    },[])


    // let ChocieRoom = () => {

    //     return(
    //         <RoomBorder onClick={() => navigate('/choice-talk')}>
    //             <RoomProfileImg alt="profile-choice"/>
    //             <RoomInfoBorder>
    //                 <StatusImage style={{marginTop: "0.13rem"}} src={status01} alt="status01"/>
    //                 <RoomTextBorder>
    //                     <RoomTitleText>
    //                         강남구·달빛토끼
    //                     </RoomTitleText>
    //                     <RoomTitleInfoText>
    //                         오늘만 강남 특가 이벤트 진행 Rabbit
    //                     </RoomTitleInfoText>
    //                     <RoomSteady>
    //                         대기 2명
    //                     </RoomSteady>
    //                 </RoomTextBorder>
    //             </RoomInfoBorder>
    //             <StatusBorder>
    //                 <UpStatus>
    //                     UP
    //                 </UpStatus>
    //                 <EventStatus>
    //                     EVENT
    //                 </EventStatus>
    //             </StatusBorder>
    //             <RoomTime>
    //                 10분전
    //             </RoomTime>
    //         </RoomBorder>
    //     )
    // }


    let ChocieRoom = ({data}) => {
        const formatTime = (postTime) => {
            const now = new Date();
            
            // 서버 시간이 UTC라면 9시간을 더해 KST로 변환
            const postDate = new Date(new Date(postTime).getTime() + 9 * 60 * 60 * 1000);
        
            const diffInSeconds = Math.floor((now - postDate) / 1000);
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            const diffInHours = Math.floor(diffInMinutes / 60);
            const diffInDays = Math.floor(diffInHours / 24);
            
            if (diffInSeconds < 60) {
                return '지금';
            } else if (diffInMinutes < 60) {
                return `${diffInMinutes}분 전`;
            } else if (diffInHours < 24) {
                return `${diffInHours}시간 전`;
            } else if (diffInDays === 1) {
                return '어제';
            } else {
                const year = postDate.getFullYear().toString().slice(2); // 연도의 마지막 두 자리를 가져옵니다.
                const month = (postDate.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 +1
                const day = postDate.getDate().toString().padStart(2, '0');
                return `${year}.${month}.${day}`;
            }
        }
    
        const TimeDisplay = ({ postTime }) => {
            const formattedTime = formatTime(postTime);
            if(data?.talk_created_at){
                return <div>{formattedTime}</div>;
            }
            return ''
        };

        return(
            <RoomBorder onClick={()=>{navigate('/choice-talk')}}>
                <RoomProfileImg src={data?.profile_url} alt="profile-choice"/>
                <RoomInfoBorder>
                    <StatusImage style={{marginTop: "0.15rem"}} src={data?.status === 'active' || data?.status === 'plenty'? status01 : data?.status === 'neither'? status02 : status03} alt="status01"/>
                    <RoomTextBorder>
                        <RoomTitleText>
                            {data?.region} · {data?.name}
                        </RoomTitleText>
                        <RoomTitleInfoText>
                            {data?.choice_title}
                        </RoomTitleInfoText>
                        <RoomSteady>
                            {data?.waiting}
                        </RoomSteady>
                    </RoomTextBorder>
                </RoomInfoBorder>
                <StatusBorder>
                    {
                        data?.is_read?
                        null
                        :
                        <UpStatus>
                            UP
                        </UpStatus>
                    }
                    {
                        data?.is_event?
                        <EventStatus>
                            EVENT
                        </EventStatus>
                        :
                        null
                    }
                </StatusBorder>
                <RoomTime>
                    <TimeDisplay postTime={data?.talk_created_at}/>
                </RoomTime>
            </RoomBorder>
        )
    }

    return(
        <MainCBorder>
            {/* 타이틀 */}
            <TitleBorder>
                <Titlecontent>
                    <Titlesmall>CHOICE TALK</Titlesmall><br/>
                    <Titlebig>실시간 현황</Titlebig>
                </Titlecontent>
                <BestPickArrowContainer>
                    <ArrowButton default={beforebasic1} />
                    <ArrowButton default={nextbasic1} />
                </BestPickArrowContainer>
                <TitleBordertotalshow onClick={() => navigate('/choice-talk')}>전체보기</TitleBordertotalshow>
                
                <ChatRoomStatusBorder>
                    <ChatStatus>
                        <StatusImage src={status01} alt="status01"/>
                        <StatusText>
                            여유
                        </StatusText>
                    </ChatStatus>
                    <ChatStatus>
                        <StatusImage src={status02} alt="status01"/>
                        <StatusText>
                            중간
                        </StatusText>
                    </ChatStatus>
                    <ChatStatus>
                        <StatusImage src={status03} alt="status01"/>
                        <StatusText>
                            혼잡
                        </StatusText>
                    </ChatStatus>
                </ChatRoomStatusBorder>
            </TitleBorder>
            <RoomListBorder>
                {
                    group?.map((a, i)=>(
                        <ChocieRoom key={i} data={a}/>
                    ))
                }
            </RoomListBorder>
        </MainCBorder>
    )
}

export default MainChoice;