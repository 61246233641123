import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import more from "./../../@images/more-24.svg";
import like from "./../../@images/sns-like-black-40.svg";
import comment from "./../../@images/sns-comment-black-40.svg";
import arrow from "./../../@images/arrow-line-24.svg";
import emoji from "./../../@images/chat-emoji.svg";
import lock from "./../../@images/lock-40.svg";
import commentLike from "./../../@images/comment_like_24.svg";

let MypageBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    padding-left:2rem;
    padding-right:2rem;
`;

let MyNoticeBoardPostBorder = styled.div`
    margin-top: 0.8rem;
    width: 100%;
    gap: 4rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    background-color: #F7F7F7;
    padding-top: 3rem;
`;

let MyNoticeBoardPostSection = styled.div`
    width: 100%;
    padding-top: 11rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`;

let MypageNoticeBoardPostinfo = styled.div`
    padding-top: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 4rem;
    background-color: white;
    border-radius: 16px;
    border: 1px solid #D2D2D2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let MypageTitleBar = styled.div`
    color: #171717;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
`;

let MypageMyNoticeBoardPostwriter = styled.div`
    color: #171717;
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 600;
`;

let MypageMyNoticeBoardPostcategory = styled.div`
    color: #6a6a6a;
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 600;
`;

let MypageMyNoticeBoardPostdivider = styled.div`
    width: 100%;
    border-bottom: 1px solid #E8E8E8;
    margin-top:2rem;
    margin-bottom:2rem;
`;

let NoticeboardpostContainer = styled.div`
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

let NoticeboardpostTitle = styled.div`
    width: 100%;
    font-size:2rem;
    margin-bottom: 2rem;
    font-weight:600;
    font-family: 'Pretendard-Regular';
`;

let Noticeboardpostingcontent = styled.div`
    width: 100%;
    font-size:1.8rem;
    gap: 2rem;
    margin-bottom: 2rem;
    font-family: 'Pretendard-Regular';
`;

let ContentsBoarder = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 3.2rem 1rem 1.6rem 1rem;
    border-top: solid;
    border-width: 0.1rem;
    border-color: #d2d2d2;
    border-radius: 0.4rem;
    margin-bottom: 1.6rem;
`;

let StatusBorder = styled.div`
    width: 74.8rem;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

let SnsIconBorder = styled.div`
    display: flex;
    gap: 0.4rem;
    align-items: center;
`;

let SnsIcon = styled.img`
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    cursor: pointer;
`;

let SnsIconText = styled.div`
    height: auto;
    width: 3rem;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.8rem;
    color: #171717;
    text-align: left;
`;




// 코멘트
let CommentBorder = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: auto;
    border-radius: 0.8rem;
    border: none;
    background-color: #F7F7F7;
    padding: 1.6rem 1rem;
`;

let CommentsDropBorder = styled.div`
    display: flex;
    gap: 0.8rem;
`;

let CommentsDrop = styled.div`
    cursor: pointer;
    width: 14rem;
    height: 4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: white;
`;

let CommentsNick = styled.div`
    width: 14rem;
    height: 4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: #E8E8E8;
`;

let FilterIconArrow = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
`;

let CommentsInputBorder= styled.div`
    width: 100%;
    min-height: 5.6rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 1.6rem;
    box-sizing: border-box;
    background-color: white;
    position: relative;
`;

let CommentsIconBorder = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 1.6rem;
    top: 0.8rem;
`;

let CommentsIcon = styled.img`
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    cursor: pointer;
`;

let CommentsSubmitButton = styled.button`
    height: 3.6rem;
    background-color: #CD1B1B;
    box-sizing: border-box;
    padding: 0.8rem 1.2rem;
    border: none;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: white;
    cursor: pointer;
`;

let CommentsInput = styled.textarea`
    box-sizing: border-box;
    width: 42.9rem;
    min-height: 5.6rem;
    resize: none;
    border: none;
    outline: none;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    box-sizing: border-box;
    overflow: hidden;
    padding: 1.6rem 0;
    line-height: 1.5;
`;

// 댓글
let ReplyMainBorder = styled.div`
    width: 100%;
    min-height: 11.2rem;
`;

let ReplyBorder = styled.div`
    width: 100%;
    min-height: 11.2rem;
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 0.8rem;
    box-sizing: border-box;
`;

let ReplyProfileImage = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    background-color: black;
`;

let ReplyContentsBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-contetn: right;
    box-sizing: border-box;
`;

let ReplyNickText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: #171717;
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
`;

let ReplyTimeText = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9e9e9e;
    margin-left: 0.8rem;
`;

let ReplyContentsText = styled.textarea`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #444444;
    width: 100%;
    resize: none;
    outline: none;
    border: none;
    padding: 0;
`;

let ReplyShowMore = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9e9e9e;
    cursor: pointer;
`;

let ReplyToolBorder = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    gap: 0.8rem;
    justify-content: right;
    align-items: flex-start;
`;

let ReplyIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    cursor: pointer;
`;

let ReplyCount = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
    color: #9e9e9e;
    width: 100%;
    text-align: center;
`;

let ReReplyListBorder = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;
    padding-left: 3.2rem;
`;

const MyNoticeBoardPost = () => {
    const [commetnsValue, setCommetnsValue] = useState('');
    const textareaRef = useRef(null);

    const handleChange = (event) => {
        setCommetnsValue(event.target.value);
    };

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [commetnsValue]);

    const CommentSubmit = () => {
        return (
            <CommentBorder style={{ marginBottom: "1.6rem" }}>
                <CommentsDropBorder style={{ marginBottom: "0.8rem" }}>
                    <CommentsDrop>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span>랜덤 닉네임</span>
                        </div>
                        <FilterIconArrow src={arrow} alt="arrow" />
                    </CommentsDrop>
                    <CommentsNick>춘식이덧버선</CommentsNick>
                </CommentsDropBorder>
                <CommentsInputBorder>
                    <CommentsInput
                        ref={textareaRef}
                        value={commetnsValue}
                        onChange={handleChange}
                        rows="1"
                        placeholder="댓글을 입력해주세요"
                    />
                    <CommentsIconBorder>
                        <CommentsIcon src={emoji} alt="icon04" />
                        <CommentsIcon src={lock} alt="icon05" style={{ marginRight: "0.8rem" }} />
                        <CommentsSubmitButton>등록</CommentsSubmitButton>
                    </CommentsIconBorder>
                </CommentsInputBorder>
            </CommentBorder>
        );
    };

    const ReReply = () => {
        const [isClickedReply, setIsClickedReply] = useState(false);
        return (
            <ReplyMainBorder>
                <ReplyBorder>
                    <ReplyProfileImage alt="re-profile" />
                    <ReplyContentsBorder>
                        <ReplyNickText>
                            춘식이덧버선
                            <ReplyTimeText>30분전</ReplyTimeText>
                        </ReplyNickText>
                        <ReplyContentsText value={'논현역 근처도 추천해주세요'} readOnly onChange={() => { }} />
                        <ReplyShowMore>댓글</ReplyShowMore>
                    </ReplyContentsBorder>
                    <ReplyToolBorder>
                        <div>
                            <ReplyIcon src={commentLike} alt="icon08" />
                            <ReplyCount>124</ReplyCount>
                        </div>
                        <ReplyIcon src={more} alt="icon08" />
                    </ReplyToolBorder>
                </ReplyBorder>
                {isClickedReply ?
                    <CommentBorder style={{ marginBottom: "0.8rem" }}>
                        <CommentsDropBorder style={{ marginBottom: "0.8rem" }}>
                            <CommentsDrop>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span>랜덤 닉네임</span>
                                </div>
                                <FilterIconArrow src={arrow} alt="arrow" />
                            </CommentsDrop>
                            <CommentsNick>춘식이덧버선</CommentsNick>
                        </CommentsDropBorder>
                        <CommentsInputBorder>
                            <CommentsInput
                                onChange={() => { }}
                                placeholder="댓글을 입력해주세요"
                            />
                            <CommentsIconBorder>
                                <CommentsIcon src={emoji} alt="icon04" />
                                <CommentsIcon src={lock} alt="icon05" style={{ marginRight: "0.8rem" }} />
                                <CommentsSubmitButton>등록</CommentsSubmitButton>
                            </CommentsIconBorder>
                        </CommentsInputBorder>
                    </CommentBorder>
                    :
                    <div />
                }
            </ReplyMainBorder>
        );
    };

    const Reply = () => {
        const [isClickedReply, setIsClickedReply] = useState(false);
        return (
            <ReplyMainBorder>
                <ReplyBorder>
                    <ReplyProfileImage alt="re-profile" />
                    <ReplyContentsBorder>
                        <ReplyNickText>
                            춘식이덧버선
                            <ReplyTimeText>30분전</ReplyTimeText>
                        </ReplyNickText>
                        <ReplyContentsText value={'논현역 근처도 추천해주세요'} readOnly onChange={() => { }} />
                        <ReplyShowMore>댓글</ReplyShowMore>
                    </ReplyContentsBorder>
                    <ReplyToolBorder>
                        <div>
                            <ReplyIcon src={commentLike} alt="icon08" />
                            <ReplyCount>124</ReplyCount>
                        </div>
                        <ReplyIcon src={more} alt="icon08" />
                    </ReplyToolBorder>
                </ReplyBorder>
                {isClickedReply ?
                    <CommentBorder style={{ marginBottom: "0.8rem" }}>
                        <CommentsDropBorder style={{ marginBottom: "0.8rem" }}>
                            <CommentsDrop>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span>랜덤 닉네임</span>
                                </div>
                                <FilterIconArrow src={arrow} alt="arrow" />
                            </CommentsDrop>
                            <CommentsNick>춘식이덧버선</CommentsNick>
                        </CommentsDropBorder>
                        <CommentsInputBorder>
                            <CommentsInput
                                onChange={() => { }}
                                placeholder="댓글을 입력해주세요"
                            />
                            <CommentsIconBorder>
                                <CommentsIcon src={emoji} alt="icon04" />
                                <CommentsIcon src={lock} alt="icon05" style={{ marginRight: "0.8rem" }} />
                                <CommentsSubmitButton>등록</CommentsSubmitButton>
                            </CommentsIconBorder>
                        </CommentsInputBorder>
                    </CommentBorder>
                    :
                    <div />
                }
                <ReReplyListBorder style={{ marginTop: "0.8rem" }}>
                    <ReReply />
                </ReReplyListBorder>
            </ReplyMainBorder>
        );
    };

    return (
        <MypageBackground>
            <MyNoticeBoardPostBorder>
                <MyNoticeBoardPostSection>
                    <MypageNoticeBoardPostinfo>
                        <MypageTitleBar>
                            <MypageMyNoticeBoardPostwriter>미드나잇테라스 관리자</MypageMyNoticeBoardPostwriter>
                            <MypageMyNoticeBoardPostcategory>공지사항</MypageMyNoticeBoardPostcategory>
                        </MypageTitleBar>
                        <MypageMyNoticeBoardPostdivider />
                        <NoticeboardpostContainer>
                            <NoticeboardpostTitle>미드나잇테라스 오픈 관련 공지사항</NoticeboardpostTitle>
                            <Noticeboardpostingcontent>
                                1. 임시허가 사실 (허가서 사본 첨부·게시)<br></br>
                                - 사업명:공공·행정·민간기관의 CI 일괄변환 및 메신저 기반 모바일 전자고지 서비스<br></br>
                                - 유효기간 : 2024년 3월 27일 ~ 2025년 7월 24일<br></br>
                                - 해당 기술·서비스의 내용 : 공공⋅행정⋅민간기관 등이 기존 우편을 통한 각종 고지를 카카오톡으로 발송하고, 확인하는 서비스<br></br>
                                <br></br>
                                2. 이용자 유의사항<br></br>
                                - 임시허가의 기준, 규격, 범위, 규모, 조건 등<br></br>
                                ㅇ카카오와 연계하여 ‘모바일 전자고지’를 추진하는 행정⋅공공 ⋅민간기관(주민등록번호 수집 및 고지의 법적 근거 보유)의 요청에 한해, 본인확인기관*이 주민등록번호를 CI로 일괄 변환할 수 있도록 2년간 임시허가
                                *코리아크레딧뷰로, 에스씨아이평가정보, 나이스평가정보<br></br>
                                <br></br>
                                - 개인정보보호 및 활용의 방법<br></br>
                                ㅇ개인정보: 성명, 전화번호, 연계정보(CI)<br></br>
                                ㅇ개인정보 활용 내용: 모바일전자고지서비스 제공<br></br>
                                ㅇ개인정보보호 방법: 개인정보보호관리 체계 인증서 (ISMS-P, 23.02.01.) 취득<br></br>
                                <br></br>
                                3. 이용자 보호방안<br></br>
                                - 개인정보의 기술적・관리적 보호조치 기준 준수<br></br>
                                - 개인정보보호배상책임보험 가입<br></br>
                                <br></br>
                                4. 기타 장관이 임시허가와 관련하여 고지하도록 정한 사항<br></br>
                                - 해당 사항 없음<br></br>
                                <br></br>
                                감사합니다.
                            </Noticeboardpostingcontent>
                            <ContentsBoarder>
                                <StatusBorder style={{marginBottom: "1.6rem"}}>
                                    <SnsIconBorder>
                                        <SnsIcon src={like} alt="icon01"/>
                                        <SnsIconText style={{marginRight: "0.4rem"}}>
                                            132
                                        </SnsIconText>
                                        <SnsIcon src={comment} alt="icon01"/>
                                        <SnsIconText>
                                            32
                                        </SnsIconText>
                                    </SnsIconBorder>

                                </StatusBorder>
                                
                                {CommentSubmit()}
                                <Reply />
                            </ContentsBoarder>
                        </NoticeboardpostContainer>
                    </MypageNoticeBoardPostinfo>
                </MyNoticeBoardPostSection>
            </MyNoticeBoardPostBorder>
        </MypageBackground>
    );
};

export default MyNoticeBoardPost;
