/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import WarnningMark from './../../@images/warningmark-24.svg';
import Chat from "./chat";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

let ChoiceBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 17.8rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 7.8rem;
`
let MenuBorder = styled.div`
    width: 125.2rem;
    margin: 3.2rem 0 0 0;
    height: 6rem;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    gap: 3.2rem;
`

let MenuButton = styled.div`
    height: 100%;
    width: auto;
    border-style: solid;
    border-color: ${props => props.index? '#171717': 'rgba(0,0,0,0)'};
    border-width: 0 0 0.4rem 0;
    white-space: nowrap;
    box-sizing: border-box;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2.8rem;
    color: ${props => props.index? '#171717': '#9E9E9E'};
    cursor: pointer;
`

let SmallMenuBorder = styled.div`
    height: 4.8rem;
    width: 125.2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-style: solid;
    border-color: #E8E8E8;
    border-width: 0 0 0.1rem 0;
    box-sizing: border-box;
    margin: 2.4rem 0 1.6rem 0;
`

let SmallMenuButtonBorder = styled.div`
    display: flex;
    height: 2.4rem;
    gap: 3.2rem;
    font-family: 'Pretendard-Regular';
    font-size: 2.4rem;
    font-weight: 600;
`

let SmallButton = styled.div`
    width: auto;
    height: 100%;
    display: flex;
    align-items: flex-start;
    color: ${props=>props.smallIndex? '#171717': '#9E9E9E'};
    cursor: pointer;
`

let AlarmTextBorder = styled.div`
    height: 2.4rem;
    display: flex;
    justify-content: right:
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 500;
    color: #9e9e9e;
    gap: 0.4rem;
`

let AlarmImage = styled.img`
    width: 2.4rem;
    height: 2.4rm;
`

let AlarmText = styled.div`
    display: flex;
    align-items: center;

`



const Choice = () => {
    let [currentIndex, setCurrentIndex] = useState(true); // 실시간 현황보기 or 즐겨찾기 톡만 보기
    let [currentSmallIndex, setCurrentSmallIndex] = useState(0); // 지역
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [groupData, setGroupData] = useState([]);
    let [isChange, setIsChange] = useState(false);
    let [selectedRoom, setSelectedRoom] = useState(1);

    let navigate = useNavigate();

    useEffect(()=>{
        if(midMainager?.log_data?.client_type === null || midMainager?.log_data?.client_type === undefined){
            navigate('/login')
        }
    },[]);

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/choice',{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instList = response.data;
                let filetrData = instList.filter(obj=>obj.name !== "바로대기방")
                setGroupData(filetrData);
                
            } catch (error) {
                console.log(error)
            }
        }

        const interval = setInterval(() => {
            const now = new Date();
            const currentHour = now.getHours();
            const currentMinutes = now.getMinutes();
            fetchData();
            // 오후 7시부터 다음날 오전 11:30분 사이에만 fetchData 실행
            // if ((currentHour >= 13 && currentHour < 24) || 
            //     (currentHour >= 0 && (currentHour < 11 || (currentHour === 11 && currentMinutes <= 30)))) {
            //     fetchData();
            // }
           
        }, 5000);
    
        // 컴포넌트 언마운트 시 타이머 정리
        return () => clearInterval(interval);
        // fetchData();
    },[])

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/choice',{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instList = response.data;
                let filetrData = instList.filter(obj=>obj.name !== "바로대기방")
                setGroupData(filetrData);
            } catch (error) {
                console.log(error)
            }
        }

        fetchData();
    },[isChange, selectedRoom])

    const changeHandler = () => {
        setIsChange(!isChange);
    }

    const selectedHandler = (e) => {
        setSelectedRoom(e);
    }

    return(
        <ChoiceBorder>
            {/* 메인메뉴 */}
            <MenuBorder>
                <MenuButton index={currentIndex} onClick={()=>{setCurrentIndex(true)}}>
                    실시간 현황 보기
                </MenuButton>
                <MenuButton index={!currentIndex} onClick={()=>{setCurrentIndex(false)}}>
                    즐겨찾기 톡만 보기
                </MenuButton>
            </MenuBorder>
            {/* 스몰메뉴 */}
            <SmallMenuBorder>
                <SmallMenuButtonBorder>
                    <SmallButton smallIndex={currentSmallIndex  === 0} onClick={()=>{setCurrentSmallIndex(0)}}>
                        전체지역
                    </SmallButton>
                    <SmallButton smallIndex={currentSmallIndex  === 1} onClick={()=>{setCurrentSmallIndex(1)}}>
                        서울
                    </SmallButton>
                    <SmallButton smallIndex={currentSmallIndex  === 2} onClick={()=>{setCurrentSmallIndex(2)}}>
                        그 외 지역
                    </SmallButton>
                </SmallMenuButtonBorder>
                <AlarmTextBorder>
                    <AlarmImage src={WarnningMark} alt="warnig-mark"/>
                    <AlarmText>
                        실제 가게 상황은 내용과 다를 수 있으니 자세한 문의는 가게 관계자에게 해주시고 해당 내용은 참고만 하시기 바랍니다.
                    </AlarmText>
                </AlarmTextBorder>
            </SmallMenuBorder>
            <Chat isBook={currentIndex} myRigion={currentSmallIndex} groupData={groupData} changeHandler={changeHandler} selectedHandler={selectedHandler} selectedId={selectedRoom}/>
        </ChoiceBorder>
    )
}

export default Choice;