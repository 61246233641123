/*eslint-disable*/
import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import signuplogo from "./../../@images/signupbannerlogo.svg";
import { useNavigate } from "react-router-dom";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #F7F7F7;
  font-family: 'Pretendard-Regular';
`;

const Bottomspace = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #F7F7F7;
`;

const Banner = styled.div`
  width: 100%;
  height:9rem;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignupLogo = styled.img`
  width:19.4rem;
  height:2.6rem;
`;

const PageTitle = styled.h2`
  font-size: 3.2rem;
  color: #000;
  margin-top:8.3rem;
  margin-bottom: 8.3rem;
`;

const CardContainer = styled.div`
  width: 82.8rem;
  box-sizing: border-box;
  display:grid;
  grid-template-columns: 1fr 1fr;
  gap:2rem;
  align-items: start;
`;

const Findidcard = styled.div`
  width: 40.4rem;
  background-color: #fff;
  border-radius: 4px;
  text-align: center;
  padding-left:3.2rem;
  padding-right:3.2rem;
  padding-top:3.2rem;
  padding-bottom:3.2rem;
  box-sizing: border-box;
  position:relative;
`;

const FindPWcard = styled.div`
  width: 40.4rem;
  background-color: #fff;
  border-radius: 4px;
  text-align: center;
  padding-left:3.2rem;
  padding-right:3.2rem;
  padding-top:3.2rem;
  padding-bottom:3.2rem;
  box-sizing: border-box;
  position:relative;
`;

const SectionLabel = styled.div`
  position:relative;
  right:-13rem;
  align-self: flex-end;
  color: #171717;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;

const SectionRequired = styled.span`
  color: #E53935;
  margin-right: 0.1rem;
`;

const Label = styled.label`
  margin-top:2rem;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  margin-bottom: 1rem;
`;

const Required = styled.span`
  color: #E53935;
  margin-right: 0.6rem;
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 100%;
  gap: -3rem;
`;

const Inputbox = styled.input`
  padding: 1rem;
  width: 100%;
  height: 5.6rem;
  font-size: 1.6rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  box-sizing: border-box;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  height: 5.6rem;
  width: 7.6rem;
  font-size: 1.6rem;
  color: #fff;
  background-color: #222222;
  border: 1px solid #222222;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 1rem;
  margin-top: -1rem;
  
  &:hover {
    color: #222222;
    background-color: #fff;
    border: 1px solid #222222;
  }
`;

const Button2 = styled.button`
  padding: 0.5rem 1rem;
  height: 5.6rem;
  width: 9rem;
  font-size: 1.6rem;
  color: #fff;
  background-color: #222222;
  border: 1px solid #222222;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 1rem;
  margin-top: -1rem;
  
  &:hover {
    color: #222222;
    background-color: #fff;
    border: 1px solid #222222;
  }
`;

const RedButton = styled.button`
  background-color: #CD1B1B;
  border: 1px solid #CD1B1B;
  width: 100%;
  height: 5.6rem;
  color: white;
  font-size: 1.8rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 2rem;
  
  &:hover {
    color: #CD1B1B;
    background-color: #fff;
    border: 1px solid #CD1B1B;
  }
`;

const Findidhiddendiv = styled.div`
  width:100%;
`;

const Findidbg = styled.div`
  width: 100%;
  height: 5.6rem;
  padding-left:2rem;
  padding-right:2rem;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.8rem;
  background-color: #F7F7F7;
  display: flex;
  align-items: center;
  text-align:left;
`;

const Findpwhiddendiv = styled.div`
  width:100%;
`;

const Innumcontainer = styled.div`
  width:100%;
  margin-top:0.2rem;
  margin-bottom:2rem;
  display:flex;
  justify-content: space-between;
  font-size:1.4rem;
  color: #F32E13;
`;

const Pwcheckmessage = styled.div`
  width:100%;
  margin-top:0.2rem;
  margin-bottom:2rem;
  font-size:1.4rem;
  color: #F32E13;
  text-align:left;
`;

const Findidpw = () => {
  const [showFindId, setShowFindId] = useState(false);
  const [showFindPw, setShowFindPw] = useState(false);
  const [idResult, setIdResult] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [loginId, setLoginId] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  let navigate = useNavigate();

  const handleFindIdClick = async () => {
    try {
      const response = await axios.get('https://api.midtrc.com/auth/find', {
        params: { name: username, email: email }
      });

      console.log("API response:", response.data); // 응답 데이터 확인용 로그

      if (response.data && response.data.login_id) {
        const clientId = response.data.login_id;
        const maskedId = clientId.slice(0, 2) + '***' + clientId.slice(-2);
        setIdResult(maskedId);
        setShowFindId(true);
      } else {
        alert('회원가입 정보를 확인해주세요');
      }
    } catch (error) {
      console.error("Error finding ID:", error);
      alert('회원가입 정보를 확인해주세요');
    }
  };

  const handleFindPwClick = async () => {
    try {
      const response = await axios.get('https://api.midtrc.com/auth/find', {
        params: { login_id: loginId, phone_num: phoneNum }
      });

      console.log("API response:", response.data); // 응답 데이터 확인용 로그

      setShowFindPw(true);
    } catch (error) {
      console.error("Error finding password:", error);
      if (error.response && error.response.status === 404) {
        alert('가입된 핸드폰 번호를 확인해주세요');
      } else {
        alert('가입되지 않은 아이디입니다');
      }
    }
  };

  const handleResetPassword = async () => {
    if (password !== passwordConfirm) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }
    try {
      await axios.put('https://api.midtrc.com/auth/update-password', {
        login_id: loginId,
        phone_num: phoneNum,
        pwd: password
      });
      alert('비밀번호가 재설정되었습니다.'); 
      navigate('/login');
    } catch (error) {
      console.error("Error resetting password:", error);
    }
  };

  return (
    <PageContainer>
      <Banner>
        <SignupLogo src={signuplogo} alt="Signup Logo" />
      </Banner>
      <PageTitle>아이디/비밀번호 찾기</PageTitle>
      <CardContainer>
        <Findidcard>
          <SectionLabel><SectionRequired>*</SectionRequired> 필수 입력 사항</SectionLabel>
          <div style={{ marginBottom:"1rem", width:"100%" }}>
            <Label htmlFor="username"><Required>*</Required> 이름</Label>
            <InputGroup>
              <Inputbox 
                type="text" 
                id="username" 
                name="username" 
                placeholder="이름을 입력하세요." 
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </InputGroup>
          </div>
          <Label htmlFor="email"><Required>*</Required> 이메일</Label>
          <InputGroup>
            <Inputbox 
              type="text" 
              id="email" 
              name="email" 
              placeholder="이메일 주소를 입력하세요." 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
          {showFindId && (
            <Findidhiddendiv>
              <Label htmlFor="findid"> 찾으시는 아이디</Label>
              <Findidbg>
                {idResult}
              </Findidbg>
            </Findidhiddendiv>
          )}
          <RedButton onClick={handleFindIdClick}>아이디 찾기</RedButton>
        </Findidcard>

        <FindPWcard>
          <SectionLabel><SectionRequired>*</SectionRequired> 필수 입력 사항</SectionLabel>
          <div style={{ marginBottom:"1rem", width:"100%" }}>
            <Label htmlFor="loginId"><Required>*</Required> 아이디</Label>
            <InputGroup>
              <Inputbox 
                type="text" 
                id="loginId" 
                name="loginId" 
                placeholder="아이디를 입력하세요." 
                value={loginId}
                onChange={(e) => setLoginId(e.target.value)}
              />
            </InputGroup>
          </div>
          {!showFindPw && (
            <>
              <Label htmlFor="phone"><Required>*</Required> 핸드폰 번호</Label>
              <InputGroup>
                <Inputbox 
                  type="text" 
                  id="phone" 
                  name="phone" 
                  placeholder="핸드폰 번호를 입력하세요." 
                  value={phoneNum}
                  onChange={(e) => setPhoneNum(e.target.value)}
                />
                {/* <Button>인증</Button> */}
              </InputGroup>
              <RedButton onClick={handleFindPwClick}>비밀번호 찾기</RedButton>
            </>
          )}
          {showFindPw && (
            <Findpwhiddendiv>
              <Label htmlFor="phone"><Required>*</Required> 핸드폰 번호</Label>
              <InputGroup>
                <Inputbox 
                  type="text" 
                  id="phone" 
                  name="phone" 
                  placeholder="핸드폰 번호를 입력하세요." 
                  value={phoneNum}
                  onChange={(e) => setPhoneNum(e.target.value)}
                />
                {/* <Button2>재발송</Button2> */}
              </InputGroup>
              {/* <InputGroup>
                <Inputbox type="text" id="num" name="num" placeholder="인증번호를 입력하세요." />
                <Button>인증</Button>
              </InputGroup>
              <Innumcontainer>
                <div>입력하신 번호로 인증번호가 발송되었습니다.</div>
                <div>03:00</div>
              </Innumcontainer> */}
              <Label htmlFor="password"><Required>*</Required> 비밀번호 재설정</Label>
              <InputGroup>
                <Inputbox 
                  type="password" 
                  id="password" 
                  name="password" 
                  placeholder="비밀번호를 입력하세요." 
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </InputGroup>
              <InputGroup>
                <Inputbox 
                  type="password" 
                  id="passwordConfirm" 
                  name="passwordConfirm" 
                  placeholder="비밀번호 확인" 
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
                {/* <Button>확인</Button> */}
              </InputGroup>
              {password !== passwordConfirm && (
                <Pwcheckmessage>
                  비밀번호가 일치하지 않습니다.
                </Pwcheckmessage>
              )}
              <RedButton onClick={handleResetPassword}>재설정</RedButton>
            </Findpwhiddendiv>
          )}
        </FindPWcard>
      </CardContainer>
      <Bottomspace></Bottomspace>
    </PageContainer>
  );
};

export default Findidpw;
