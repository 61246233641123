/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import logo from "./../../@images/logo.svg";
import logo2 from "./../../@images/logo2.svg";
import security from "./../../@images/security.png";
import naverIcon from "./../../@images/naver.svg";
import kakao from "./../../@images/kakao.svg";
import google from "./../../@images/google.svg";
import warning from "./../../@images/signupwarning.svg";
import checkboxchecked from "./../../@images/checkbox_checked.svg";
import checkboxunchecked from "./../../@images/checkbox_unchecked.svg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { IsLogined, Logined, Logout } from "../../redux/slice/login-slice";

import { GoogleLogin } from "@react-oauth/google";




import MokReactIndex from "./../../mok_react_index.js"
import GoogleLoginButton from "../../@components/google.js";
import MoK_react_index_button_naver from "../../mok_react_index_button_naver.js";


const PageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  padding: 2rem;
  font-family: 'Pretendard-Regular';
  box-sizing: border-box;
`;

const CardContainer = styled.div`
  width: 82.8rem;
  background-color: #fff;
  text-align: center;
  position: relative;
`;

const WarningBanner = styled.div`
  width: 82.8rem;
  height: 9.8rem;
  background-color: #f4f4f4;
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  gap: 2.5rem;
`;

const WarningIcon = styled.img`
  display: inline-block;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

const WarningText = styled.div`
  align-items: center;
  text-align: left;
`;

const WarningHighlightedText = styled.span`
  font-weight: bold;
  color: #171717;
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 2.4rem;
`;

const WarningRegularText = styled.span`
  color: #6A6A6A;
  font-size: 1.2rem;
  line-height: 2.0rem;
`;

const Logo = styled.img`
  width: 12rem;
  height: 8.4rem;
  margin-bottom: 4rem;
  margin-top: 6.6rem;
`;

const Logo2 = styled.img`
  width: 7.8rem;
  position: absolute;
  top: 15rem;
  left: 4rem;
`;

const Divider = styled.div`
  height: auto;
  width: 0.1rem;
  background-color: #e0e0e0;
  margin: 0 2rem;
`;

const LoginContainer = styled.div`
  padding: 2rem;
  padding-bottom:3.5rem;
  box-sizing: border-box;
  border: 1px solid #D2D2D2;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SectionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 73.2rem;
`;

const Section = styled.div`
  width: 45%;
  text-align: left;
`;

const SectionTitle = styled.div`

`;

const SectionTitletop = styled.span`
  font-size:2.4rem;
  font-weight:600;
  color:#171717;
`;

const SectionTitlepoint = styled.span`
  font-size:2rem;
  font-weight:600;
  color:#F32E13;
`;

const SectionTitleprimary = styled.span`
  font-size:2rem;
  font-weight:600;
  color:#171717;
`;

const SectionTitle2 = styled.div`
  margin-bottom:1.5rem;
`;

const SectionText = styled.p`
  font-size: 1.6rem;
  color:#171717;
  margin-bottom: 1.5rem;
`;

const SectionTextpoint = styled.span`
  font-size: 1.6rem;
  color:#171717;
  margin-bottom: 1.5rem;
  font-weight:600;
`;

const Linkdivider = styled.span`
  margin-left:1rem;
  font-weight:100;
`;

const Button = styled.button`
  width: 32.4rem;
  height: 5.6rem;
  padding: 1rem;
  font-size: 1.8rem;
  color: #fff;
  background-color: #CD1B1B;
  border: 1px solid #CD1B1B;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-top: 1rem;
  
  &:hover {
    background-color: #fff;
    color: #CD1B1B;
    border-color: #CD1B1B;
  }
`;

const Button2 = styled.button`
  width: 32.4rem;
  height: 5.6rem;
  padding: 1rem;
  font-size: 1.8rem;
  color: #171717;
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #fff;
    color: #171717;
    border-color: #d9d9d9;
  }
`;

const Image = styled.img`
  width: 8.8rem;
  height: auto;
  margin-bottom: 1.5rem;
  margin-top:-1.5rem;
  margin-left:24.5rem;
`;

const Input = styled.input`
  width: 100%;
  height:5.6rem;
  padding: 0.8rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  box-sizing: border-box;
  font-family: 'Pretendard-Regular';
  outline: none;
`;

const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom:2rem;
  gap: 4rem;
  width:24.8rem;
  left:50%;
  position:relative;
  transform: translateX(-50%);
`;

const SocialButton = styled.img`
  width: 5.6rem;
  height: 5.6rem;
  cursor: pointer;
`;

const Footer = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #888;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap:0.5rem;
  font-size:1.4rem;
  color:#9E9E9E;
  margin-top:-1rem;
  margin-bottom:1.15rem;
`;

const Links = styled.div`
  margin-top:-2.3rem;
  display: flex;
  font-size:1.4rem;
  align-items: center;
  a {
    text-decoration: none;
    margin-left: 1rem;
    color:#9E9E9E;
  }
`;

const IntroText = styled.div`
  width: 73.2rem;
  height: 5.2rem;
  background-color: #f4f4f4;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  letter-spacing: -1.2px;
  margin-bottom: 4rem;
`;


const Login = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let midMainager = useSelector((state)=>state.midnightLoginManager)
  let [login_id, setLogin_id] = useState('');
  let [pwd, setPwd] = useState('');
  let [isChecked, setIsChecked] = useState(midMainager?.is_login);

  let [deviceType, setDeviceType] = useState('');
  let [browserType, setBrowserType] = useState('');

  useEffect(() => {
    handleGoogleCallback();
  }, []);

  const handleGoogleLogin = () => {
    // 구글 로그인 화면으로 이동시키기
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?
		client_id=${'1066421655241-cdgkma21142bv0pt3unhhdjuhncodcoq.apps.googleusercontent.com'}
		&redirect_uri=${'https://midtrc.com/login'}
		&response_type=code
		&scope=email profile`;
  };

  const handleGoogleCallback = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (!code) return;

    try {
        // 토큰 요청
        const response = await fetch('https://oauth2.googleapis.com/token', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
              code: code,
              client_id: '1066421655241-cdgkma21142bv0pt3unhhdjuhncodcoq.apps.googleusercontent.com',
              client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRETS,
              redirect_uri: 'https://midtrc.com/login',
              grant_type: 'authorization_code',
          })
        });

        const data = await response.json();

        if (data.access_token) {
          // 사용자 정보 요청
          const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
              headers: {
                  Authorization: `Bearer ${data.access_token}`,
              }
          });
      
          const userInfo = await userInfoResponse.json();
      
          // 아이디 체크 및 로그인/회원가입 로직
          try {
              const idCheckResponse = await axios.get(`https://api.midtrc.com/auth/id-check?login_id=${userInfo.email}`);
              
              // 아이디가 이미 존재하는 경우 로그인 로직으로 넘어갑니다.
              if (idCheckResponse.data.exists) {
                  throw new Error('User already registered');
              }
      
              // 회원가입 로직
              const requestBody = {
                  "signup_type": 'google',
                  "default_profile": userInfo.picture,
                  "client_type": 'user',
                  "sns_id": userInfo.email, 
                  "login_id": userInfo.email,
                  "nickname": userInfo.given_name, // 닉네임
                  "email": userInfo.email,
                  "pwd": null,
                  "name": userInfo.given_name,
                  "phone_num": null,
                  "birthday": null,
                  "gender": null,   // 성별
                  "invite_num": null,
                  "accepted_terms1": true,
                  "accepted_terms2": true,
                  "noti_sms": false,
              };
              const formData = new FormData();
              formData.append('req_body', JSON.stringify(requestBody));
      
              // 회원가입 요청
              await axios.post('https://api.midtrc.com/auth/signup', formData);
      
              console.log('Signup successful, proceeding to login.');
      
          } catch (error) {
              console.log('User is already registered or an error occurred during signup. Proceeding to login.');
          }
      
          // 로그인 로직
          try {
              const loginResponse = await axios.post('https://api.midtrc.com/auth/login', {
                  signup_type: "google",
                  login_id: userInfo.email,
                  pwd: null,
                  latest_device: deviceType,
                  latest_browser: browserType,
              });
      
              dispatch(Logined(loginResponse.data));
              navigate('/');
          } catch (loginError) {
              console.error('Login failed:', loginError);
          }
      } else {
          console.error('Failed to obtain access token:', data);
      }
      

    } catch (error) {
        console.error('Error during Google login:', error);
    }

    
};

  useEffect(()=>{
    const userAgent = navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setDeviceType(isMobile ? 'Mobile' : 'Desktop');

    let browserTypeInst = '';

    if (userAgent.indexOf("Firefox") > -1) {
        browserTypeInst = "Mozilla Firefox";
    } else if (userAgent.indexOf("Chrome") > -1) {
        browserTypeInst = "Google Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
        browserTypeInst = "Apple Safari";
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
        browserTypeInst = "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
        browserTypeInst = "Microsoft Internet Explorer";
    }

    setBrowserType(browserTypeInst);
  },[])

  // 아이디 작성
  const idHandler = (e) => {
    setLogin_id(e.target.value);
  }

  // 비밀번호 작성
  const pwdHandler = (e) => {
    setPwd(e.target.value);
  }

  // 로그인 저장
  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
  };

  // 이메일 로그인 버튼 클릭 시
  const emailLoginSubmit = () => {
    const requestBody = {
      "signup_type": "general",
      "login_id": login_id,
      "pwd": pwd,
      "latest_device": deviceType,
      "latest_browser": browserType
    }

    axios.post('https://api.midtrc.com/auth/login', requestBody)
    .then((res)=>{
      dispatch(Logined(res.data));
      isLoginedHandler();
      navigate('/');
      if(res.data.status === 'day_paused'){
        alert('1일간 사용 금지 된 계정입니다.');
        localStorage.removeItem("com.naver.nid.access_token"); 
        dispatch(Logout());
      }if(res.data.status === 'week_paused'){
        alert('1주일간 사용 금지 된 계정입니다.');
        localStorage.removeItem("com.naver.nid.access_token"); 
        dispatch(Logout());
      }if(res.data.status === 'month_paused'){
        alert('한달간 사용 금지 된 계정입니다.');
        localStorage.removeItem("com.naver.nid.access_token"); 
        dispatch(Logout());
      }
    }).catch((error)=>{alert(error)})
  }

  const isLoginedHandler = () => {
    if(isChecked){
      dispatch(IsLogined({id: login_id, pwd: pwd, is_login: true}));
    }else{
      dispatch(IsLogined({id: null, pwd: null, is_login: false}));
    }
  }

  // 기업회원 로그인 클릭 시 화면 전환
  const handleBusinessLoginClick = () => {
    navigate('/business-login');
  };

  // 네이버 sns 로그인
  const NaverLogin = () => {
    const { naver } = window;
    const naverRef = useRef()
    const clientId = process.env.REACT_APP_NAVER_CLIENT_ID;
    const callbackUrl = 'https://midtrc.com/login'
    
    const initializeNaverLogin = () => {
      const naverLogin = new naver.LoginWithNaverId({
          clientId,
          callbackUrl,
          isPopup: false,
          loginButton: { color: "green", type: 1, height: "56" },
          callbackHandle: true,
      });
      naverLogin.init();
      
      naverLogin.getLoginStatus(async function (status) {
          if (status) {
              const userid = naverLogin.user.getEmail();
              const username = naverLogin.user.getName();
              const token = naverLogin.accessToken.accessToken; // accessToken 가져오기
              const formattedBirthday = naverLogin.user.birthday.replace("-", ".");
              
            
              axios.get(`https://api.midtrc.com/auth/id-check?login_id=${userid}`).then((res)=>{
                // 회원 가입 후 로그인
                const requestBody = {
                  "signup_type": 'naver',
                  "default_profile": naverLogin.user.profile_image,
                  "client_type": 'user',
                  "sns_id": userid, 
                  "login_id": userid,
                  "nickname": naverLogin.user.nickname,// 닉네임
                  "email": naverLogin.user.email,
                  "pwd": null,
                  "name": naverLogin.user.name,
                  "phone_num": naverLogin.user.mobile,
                  "birthday": naverLogin.user.birthyear+'.'+formattedBirthday,
                  "gender": naverLogin.user.gender === 'M'? 'male':'female',   // 성별
                  "invite_num": null,
                  "accepted_terms1": true,
                  "accepted_terms2": true,
                  "noti_sms": false,
                };
  
                const jsonRequestData = JSON.stringify(requestBody);
                const formData = new FormData();
                formData.append('req_body',jsonRequestData);
                
                axios.post('https://api.midtrc.com/auth/signup',formData).then((res)=>{
                  // 로그인
                  axios.post('https://api.midtrc.com/auth/login',{
                      "signup_type": "naver",
                      "login_id": userid,
                      "pwd": null,
                      "latest_device": deviceType,
                      "latest_browser": browserType
                    }).then((res)=>{console.log(res.data); dispatch(Logined(res.data)); navigate('/')}).catch((error)=>{console.log(error)})


                }).catch((error)=>{console.log(error)})
              }).catch((error)=>{
                // 로그인
                axios.post('https://api.midtrc.com/auth/login',{
                  "signup_type": "naver",
                  "login_id": userid,
                  "pwd": null,
                  "latest_device": deviceType,
                  "latest_browser": browserType
                }).then((res)=>{console.log(res.data); dispatch(Logined(res.data)); navigate('/')}).catch((error)=>{console.log(error)})
              })
          }
      });
   };

    const userAccessToken = () => {
      window.location.href.includes('access_token') && getToken()
    }
      
    const getToken = () => {
      const token = window.location.href.split('=')[1].split('&')[0]
      localStorage.setItem('access_token', token)
      // setGetToken(token)
    }

    useEffect(() => {
      initializeNaverLogin()
      userAccessToken()
    }, [])
    
    return (
      <div>
        <div ref={naverRef} style={{display: "none"}} id="naverIdLogin"></div>
        <SocialButton  onClick={()=>{naverRef.current.children[0].click()}} src={naverIcon} alt="Naver" />
      </div>
    );
    
  };



  return (
    <PageContainer>
      <CardContainer>
        <WarningBanner>
          <WarningIcon src={warning} alt="Warning" />
          <WarningText>
            <WarningHighlightedText>미성년자 이용불가</WarningHighlightedText><br />
            <WarningRegularText>이 정보 내용은 청소년 유해 매체물로서 정보통신망 이용촉진 정보보호등에 관한 법률 및<br /> 청소년 보호법 규정에 의하여 15세 미만의 청소년은 이용할 수 없습니다.</WarningRegularText>
          </WarningText>
        </WarningBanner>
        <LoginContainer>
          <Logo2 src={logo2} alt="Midnight Terrace Logo" />
          <Logo src={logo} alt="M Logo" />
          <IntroText>미드나잇테라스는 밤에 빛나는 여성을 위한 통합 커뮤니티 서비스입니다.</IntroText>
          <SectionsContainer>
            <Section>
              <SectionTitle>
                <SectionTitletop>안심하고</SectionTitletop><br></br>
                <SectionTitlepoint>미드나잇 테라스</SectionTitlepoint>
                <SectionTitleprimary>에 접속하세요</SectionTitleprimary>
                </SectionTitle>

              <SectionText>인증 시 성인여부만 확인될 뿐<br></br>어떠한 형태로도<br></br>
              <SectionTextpoint>개인정보는 저장되지 않습니다.</SectionTextpoint></SectionText>
              <Image src={security} alt="Security" />
              {/* <Button primary onClick={()=>{navigate('/view')}} >비회원 인증 입장</Button> */}
              <MokReactIndex />
            </Section>
            <Divider />
            <Section>
              <SectionTitle2>
                <SectionTitletop>반가워요!</SectionTitletop><br></br>
                <SectionTitleprimary>미드나잇 테라스 회원님!</SectionTitleprimary>
              </SectionTitle2>
              <Input value={login_id} onChange={idHandler} type="text" placeholder="아이디를 입력하세요." />
              <Input value={pwd} onChange={pwdHandler} type="password" placeholder="비밀번호를 입력하세요." />
              <Footer>
                <CheckboxContainer onClick={handleCheckboxClick}>
                  <img
                    src={isChecked ? checkboxchecked : checkboxunchecked}
                    alt="Checkbox"
                  />
                  <label htmlFor="autoLogin">자동 로그인</label>
                </CheckboxContainer>
                <Links>
                  <a href="/findidpw">ID/비밀번호 찾기</a><Linkdivider>|</Linkdivider><a href="/signup">회원가입</a>
                </Links>
              </Footer>
              <Button onClick={()=>{emailLoginSubmit()}}>이메일 로그인</Button>
              <SocialLoginContainer>
                {/* <NaverLogin /> */}
                <MoK_react_index_button_naver />
                {/* <SocialButton src={kakao} alt="Kakao" /> */}
                <SocialButton src={google} alt="Google" onClick={handleGoogleLogin}/>
                {/* <GoogleLoginButton /> */}
              </SocialLoginContainer>
              <Button2 onClick={handleBusinessLoginClick}>기업회원 로그인</Button2>
            </Section>
          </SectionsContainer>
        </LoginContainer>
      </CardContainer>
    </PageContainer>
  );
};

export default Login;
