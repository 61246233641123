/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import arrow from "./../../@images/arrow-line-24.svg";
import AddPhoto from "./../../@images/add-photo.svg";
import unChecked from "./../../@images/check-circle-24.svg";
import checked from "./../../@images/check-circle-active-24.svg";
import close from "./../../@images/close-24.svg";
import { useNavigate } from "react-router-dom";
import korea from "../../@public-components/kroea";
import businessCategory from "../../@public-components/business-category";
import { useSelector } from "react-redux";
import axios from "axios";

let ColorBorder = styled.div`
    width: 100%;
    heigt: auto;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
    padding-top: 20rem;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`

let AddDetailBorder = styled.div`
    display: flex;
    justify-content: space-between;
    width: 104rem;
    box-sizing: border-box;
`

let AddDetailLeftBorder = styled.div`
    flex-direction: column;
    display: flex;
    gap: 0.8rem;
    width: 72.2rem;
    box-sizing: border-box;
`

let AddDetailTitleBorder = styled.div`
    height: 2.4rem;
    display: flex;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2.4rem;
    margin-bottom: 1.6rem;
`

let AddDetailSubmitBorder = styled.div`
    width: 72.2rem;
    background-color: white;
    box-sizing: border-box;
    padding: 3.2rem;
    text-align: left;
    border-color: #D2D2D2;
    border-radius: 0.4rem;
    border-width: 0.1rem;
    border-style: solid;
`

let Title = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
`

let SubmitInput = styled.input`
    height: 5.6rem;
    border-style: solid;
    border-color: #D2D2D2;
    border-width: 0.1rem;
    border-radius: 0.4rem;
    outline: none;
    box-sizing: border-box;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    padding: 1.6rem;
    &::placeholder{
        color: #d2d2d2;
    }
`

let AddFilterDropBorder = styled.div`
    display: flex;
    gap: 0.8rem;
`

let AddFilterDrop = styled.div`
    cursor: pointer;
    width: 16rem;
    height: 5.6rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #D2D2D2;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    display: flex;
    justify-content: space-between;;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    position: relative;
`

let FilterIconArrow = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
`

let TitleBorder = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

let TextButton = styled.button`
    padding: 0.4rem 0.8rem;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.2rem;
    color: #6A6A6A;
    border: none;
    background-color: #E8E8E8;
    border-radius: 0.4rem;
    cursor: pointer;
`

let CostBorder = styled.div`
    display: flex;
    align-items: center;
    gap: 0.8rem;
    justify-content: space-between;
    width: 100%;
    position: relative;
`

let CostIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
`

let UnitText = styled.div`
    position: absolute;
    z-index: 10;
    right: 1.6rem;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #9E9E9E;
`

let TextEditorBorder = styled.div`
    width: 100%;
    height: 28rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #D2d2d2;
    border-width: 0.1rem;
    background-color: #f5f5f5;
    margin-bottom: 3.2rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
`

let AddDetailRightBorder = styled.div`
    width: 29.8rem;
    box-sizing: border-box;
    padding-top: 4.8rem;
    position: relative;
`

let ContrllerStickyBorder = styled.div`
    position: sticky;
    top: 20rem;
`

let AddControllerBorder = styled.div`

    border-color: #D2D2D2;
    border-radius: 0.4rem;
    border-width: 0.1rem;
    border-style: solid;
    width: 100%;
    background-color: white;
    overflow: hidden;
    margin-bottom: 1.6rem;
`

let AddControllerProfileBorder = styled.div`
    background-color: #f7f7f7;
    box-sizing: border-box;
    padding: 2.4rem;
    display: flex;
    width: 100%;
    height: 9.6rem;
    align-items: center;
    gap: 1.2rem;
`

let ProfileImage = styled.img`
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 0.8rem;
    background-color: black;
`

let ProfileInfoCompany = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #171717;
`

let ProfileText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2rem;
    color: #171717;
`

let ControllerBorder = styled.div`
    box-sizing: border-box;
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
`

let ControllerInfoBorder = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`

let ControllerText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.6rem;
    color: #6A6A6A;
    height: 100%;
    width: 100%;
    display: flex;
    jusify-content: left;
    align-items: center;
`

let ControllerButton = styled.button`
    width: 100%;
    height: 5.6rem;
    border-radius: 0.4rem;
    border: none;
    background-color: #CD1B1B;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    color: white;
    cursor: pointer;
`

let KeyWordsTagsBorder = styled.div`
    display: flex;
    gap: 0.4rem;
`

let KeyWordsTag = styled.div`
    border-style: solid;
    border-color: #D2D2D2;
    border-width: 0.1rem;
    border-radius: 0.4rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 1.6rem 1.4rem 1.6rem 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.8rem;
    color: #444444;
    cursor: pointer;
`

let IntroTextArea =styled.textarea`
    width: 100%;
    height: 28rem;
    border-style: solid;
    border-color: #D2D2D2;
    border-width: 0.1rem;
    border-radius: 0.4rem;
    box-sizing: border-box;
    padding: 1.6rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.8rem;
    color: #171717;
    resize: none;
    outline:none;
    &::placeholder{
        color: #d2d2d2;
    }
`

let AreaTagsBorder = styled.div`
    width: 100%;
    height: 5.6rem;
    border-style: solid;
    border-color: #D2D2D2;
    border-width: 0.1rem;
    border-radius: 0.4rem;
    box-sizing: border-box;
    display: flex;
    jusify-content: left;
    align-items: center;
    padding: 0 1.6rem;
    gap: 0.4rem;
`

let AreaTags = styled.div`
    display: flex;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #171717;
    cursor:pointer;
`

let AreaButton = styled.button`
    width: 13.5rem;
    height: 5.6rem;
    border-radius: 0.4rem;
    border: none;
    background-color: #222222;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: white;
    cursor: pointer;
`

let CloseIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    cursor: pointer;
`

let DropBorder = styled.div`
    position: absolute;
    min-width: 16rem;
    height: auto;
    max-height: 30rem;
    left: 0px;
    top: 5.8rem;
    z-index: 30;
    background-color: white;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #E8E8E8;
    border-width: 0.1rem;
    overflow: auto;
    text-align: left;
    &::-webkit-scrollbar {
        width: 12px; /* 너비 설정 */
      }
    &::-webkit-scrollbar-thumb {
        background: #CBCED5;
        border-radius: 6px;
    }
`

let DropDownItems = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: grey;
    box-sizing: border-box;
    padding: 0 2rem;
    height: 3.4rem;
    &:hover{
        color: black;
    }
`

const ResumeAdd = ({clientType}) => {
    let navigate = useNavigate();
    let midMainager = useSelector((state)=>state.midnightLoginManager)
    let [resume_title, setresume_title] = useState('');
    let [keywordText, setKeywordText] = useState('');
    let [keyword, setkeyword] = useState([]);
    let [introduce, setintroduce] = useState();

    let [addressFirst, setaddressFirst] = useState('시/도 선택'); // 시/도
    let [isaddressFirst, setisaddressFirst] = useState(false);

    let [addressSecond, setaddressSecond] = useState('구 선택'); // 구
    let [addressSecondArray, setaddressSecondArray] = useState([]); // 선택 된 시/도에 해당하는 구
    let [isaddressSecond, setisaddressSecond] = useState(false);

    let [addressArray, setAddressArray] = useState([]); // 서버용 주소

    let [industry, setindustry] = useState('업종 선택');
    let [isindustry, setisindustry] = useState(false);

    let [tc_pay, settc_pay] = useState(0); // 서버용
    let [tc_format, settc_format] = useState(0);
    let [is_tc_pay, setis_tc_pay] = useState(false);

    let [hourly_pay, sethourly_pay] = useState(0); // 서버용
    let [hourly_format, sethourly_format] = useState(0);
    let [is_hourly_pay, setis_hourly_pay] = useState(false);

    let [daily_pay, setdaily_pay] = useState(0); // 서버용
    let [daily_format, setdaily_format] = useState(0);
    let [is_daily_pay, setis_daily_pay] = useState(false);

    let [weekly_pay, setweekly_pay] = useState(0); // 서버용
    let [weekly_format, setweekly_format] = useState(0);
    let [is_weekly_pay, setis_weekly_pay] = useState(false);

    let [monthly_pay, setmonthly_pay] = useState(0); // 서버용
    let [monthly_format, setmonthly_format] = useState(0);
    let [is_monthly_pay, setis_monthly_pay] = useState(false);

    let [per_pay, setper_pay] = useState(0); // 서버용
    let [per_format, setper_format] = useState(0);
    let [is_per_pay, setis_per_pay] = useState(0);

    let [career, setcareer] = useState('');

    // 컨트롤러
    let [age, setAge] = useState(0);

    // 권한에 따른 뷰
    useEffect(()=>{
        if(clientType !== 'user'){
            navigate('/job')
        }
        birthdayHandler(midMainager?.log_data?.birthday); // 만나이 계산
    },[])

    const birthdayHandler = (e) => {
        const birth = new Date(e);
        const today = new Date();
        let age = today.getFullYear() - birth.getFullYear();
        const monthDifference = today.getMonth() - birth.getMonth();
        const dayDifference = today.getDate() - birth.getDate();

        // 아직 생일을 넘지 않은 경우
        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
            age--;
        }

        setAge(age);
    }

    const resume_titleHandler = (e) => {
        setresume_title(e.target.value)
    }

    const keywordTextHandler = (e) => {
        setKeywordText(e.target.value)
    }

    const inputKeyword = (e) => {
        let instKeywordArray = [...keyword];
        if(e){
            if(instKeywordArray?.length < 5){
                if(keyword?.includes(e)){
                    
                }else{
                    instKeywordArray.push(e);
                }
            }
        }
        setkeyword(instKeywordArray);
        setKeywordText('');
    }

    const keywordDelHandler = (e) => {
        let instKeywordArray = [...keyword];
        let delArray = instKeywordArray?.filter(obj=>obj !== e);
        setkeyword(delArray)
    }

    const introduceHandler = (e) => {
        setintroduce(e.target.value)
    }

    const citisHandler = (e) => {
        let instprovince = korea.filter((obj)=>obj.province === e);
        setaddressSecondArray(instprovince[0]?.cities[0].districts)
    }

    const addressFirstHandler = (e) => {
        setaddressFirst(e);
        setisaddressFirst(false);
        setaddressSecond('구 선택') // 구선택 초기화
        citisHandler(e)
    }

    const addressSecondHandler = (e) => {
        setaddressSecond(e);
        setisaddressSecond(false)
    }

    const addAddressHandler = () => {
        let instArray = [...addressArray];
        if(addressFirst === '시/도 선택' || addressSecond === '구 선택'){
            alert('지역을 선택하세요')
        }else{
            let instAddress = addressFirst+' '+addressSecond;
            if(addressArray?.includes(instAddress)){
                // 존재하면 더 이상 추가 안됨
            }else{
                if(instArray?.length<5){
                    instArray.push(instAddress);
                }else{
                    alert('지역은 총 5개 까지 추가 가능합니다.')
                }
                
            }
        }
        setAddressArray(instArray);
    }

    const delAddressHandler = (e) => {
        let instAddressArray = [...addressArray];
        let delArray = instAddressArray?.filter(obj=>obj !== e);
        setAddressArray(delArray);
    }

    const industryHandler = (e) => {
        setindustry(e);
        setisindustry(false);
    }

    const tc_payHandler = (e) => {
        const input = e.target.value;
        const onlyNums = input.replace(/[^0-9]/g, '');
        settc_pay(onlyNums);

        const formattedPay = new Intl.NumberFormat().format(onlyNums);
        settc_format(formattedPay);
    }

    const hourly_payHandler = (e) => {
        const input = e.target.value;
        const onlyNums = input.replace(/[^0-9]/g, '');
        sethourly_pay(onlyNums);

        const formattedPay = new Intl.NumberFormat().format(onlyNums);
        sethourly_format(formattedPay);
    }
    
    const daily_payHandler = (e) => {
        const input = e.target.value;
        const onlyNums = input.replace(/[^0-9]/g, '');
        setdaily_pay(onlyNums);

        const formattedPay = new Intl.NumberFormat().format(onlyNums);
        setdaily_format(formattedPay)
    }

    const weekly_payHandler = (e) => {
        const input = e.target.value;
        const onlyNums = input.replace(/[^0-9]/g, '');
        setweekly_pay(onlyNums);

        const formattedPay = new Intl.NumberFormat().format(onlyNums);
        setweekly_format(formattedPay)
    }

    const monthly_payHandler = (e) => {
        const input = e.target.value;
        const onlyNums = input.replace(/[^0-9]/g, '');
        setmonthly_pay(onlyNums);

        const formattedPay = new Intl.NumberFormat().format(onlyNums);
        setmonthly_format(formattedPay)
    }

    const per_payHandler = (e) => {
        const input = e.target.value;
        const onlyNums = input.replace(/[^0-9]/g, '');
        setper_pay(onlyNums);

        const formattedPay = new Intl.NumberFormat().format(onlyNums);
        setper_format(formattedPay)
    }

    const careerHandler = (e) => {
        setcareer(e.target.value);
    }

    let dropRef01 = useRef();
    const dropRef01Handler = (event) => {
        if (dropRef01.current && !dropRef01.current.contains(event.target)) {
            setisaddressFirst(false);
        }else{
            setisaddressFirst(true);
        }
    }
    // 도 선택 드랍다운 박스 useEffect
    useEffect(() => {
        document.addEventListener('mousedown', dropRef01Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef01Handler);
        };
    }, []);

    let dropRef02 = useRef();
    const dropRef02Handler = (event) => {
        if (dropRef02.current && !dropRef02.current.contains(event.target)) {
            setisaddressSecond(false);
        }else{
            setisaddressSecond(true);
        }
    }
    // 구 선택 드랍다운 박스 useEffect
    useEffect(() => {
        document.addEventListener('mousedown', dropRef02Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef02Handler);
        };
    }, []);

    let dropRef03 = useRef();
    const dropRef03Handler = (event) => {
        if (dropRef03.current && !dropRef03.current.contains(event.target)) {
            setisindustry(false)
        }else{
            setisindustry(true)
        }
    }

    // 업종 드랍다운 박스 useEffect
    useEffect(() => {
        document.addEventListener('mousedown', dropRef03Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef03Handler);
        };
    }, []);


    const submitHandler = () => {
        if(resume_title === '' ||
            introduce === '' ||
            industry === '업종 선택' ||
            addressArray?.length < 1 ||
            career === '' ||
            Boolean(is_hourly_pay || is_daily_pay || is_weekly_pay || is_monthly_pay || is_tc_pay || is_per_pay) === false
            
        ){
            alert('정보를 확인하세요');
        }else{
            const requestBody = {
                "client_id":  midMainager?.log_data?.id,
                "status": "active",
                "popular_yn": false,
                "age": age, // 계산해서 나이 나옴
                "resume_title": resume_title,
                "keyword": keyword?.length>0?keyword:[],
                "introduce": introduce,
                "address": addressArray?.length>0?addressArray:[],
                "industry": [
                    industry
                ],
                "hourly_pay": is_hourly_pay?hourly_pay:0,
                "daily_pay": is_daily_pay?daily_pay:0,
                "weekly_pay": is_weekly_pay?weekly_pay:0,
                "monthly_pay": is_monthly_pay?monthly_pay:0,
                "pension": is_tc_pay?tc_pay:0, //연급
                "per_pay": is_per_pay?per_pay:0,
                "career": career
            }

        axios.post('https://api.midtrc.com/resume',requestBody,{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{
            // console.log(res.data);
            navigate('/job')
        }).catch((error)=>{console.log(error)});
        }
    }

    return(
        <ColorBorder>
            <AddDetailBorder>
                <AddDetailLeftBorder>
                    <AddDetailTitleBorder>
                        이력서 등록
                    </AddDetailTitleBorder>
                    {/* 이력서 제목, 나의 강점 키워드, 자기소개 */}
                    <AddDetailSubmitBorder>
                        <Title>
                            이력서 제목
                        </Title>
                        <SubmitInput value={resume_title} onChange={resume_titleHandler} style={{width: "100%", marginBottom: "3.2rem"}} placeholder="제목을 입력하세요"/>
                        <Title>
                            나의 강점 키워드
                        </Title>
                        <div style={{display: "flex", gap: "0.8rem", marginBottom: "1rem"}}>
                            <SubmitInput value={keywordText} onChange={keywordTextHandler} style={{width: "50%"}}/>
                            <AreaButton onClick={()=>{inputKeyword(keywordText)}}>
                                키워드 추가
                            </AreaButton>
                        </div>
                        <KeyWordsTagsBorder style={{marginBottom: "3.2rem"}}>
                            {
                                keyword?.map((a, i)=>(
                                    <KeyWordsTag key={i} onClick={()=>{keywordDelHandler(a)}}>
                                        <CostIcon src={checked} alt="active-option"/>
                                        <div>
                                            {a}
                                        </div>
                                    </KeyWordsTag>
                                ))
                            }
                        </KeyWordsTagsBorder>
                        <Title>
                            자기소개
                        </Title>
                        <IntroTextArea value={introduce} onChange={introduceHandler} placeholder="자기소개를 입력해주세요"/>
                    </AddDetailSubmitBorder>
                    {/* 근무지역 */}
                    <AddDetailSubmitBorder>
                        <Title>
                            희망 지역
                        </Title>
                        {/* 지역 선택 드랍다운 박스 */}
                        <AddFilterDropBorder style={{marginBottom: "0.8rem"}}>
                            <AddFilterDrop ref={dropRef01} onChange={dropRef01Handler}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <span>{addressFirst}</span>
                                </div>
                                <FilterIconArrow src={arrow} style={{transform: isaddressFirst?'rotate(180deg)':'rotate(0deg)'}} alt="arrow"/>
                                {
                                    isaddressFirst?
                                    <DropBorder>
                                        {
                                            korea.map((a, i)=>(
                                                <DropDownItems onClick={()=> {addressFirstHandler(a.province);}} key={i}>
                                                    {a.province}
                                                </DropDownItems>
                                            ))
                                        }
                                        
                                    </DropBorder>
                                    :
                                    <div style={{display: "none"}} />
                                }
                            </AddFilterDrop>
                            <AddFilterDrop ref={dropRef02} onChange={dropRef02Handler}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <span>{addressSecond}</span>
                                </div>
                                <FilterIconArrow src={arrow} style={{transform: isaddressSecond?'rotate(180deg)':'rotate(0deg)'}} alt="arrow"/>
                                {
                                    isaddressSecond?
                                    <DropBorder>
                                    {
                                        addressSecondArray?.map((a, i)=>(
                                            <DropDownItems onClick={()=> {addressSecondHandler(a);}} key={i}>
                                                {a}
                                            </DropDownItems>
                                        ))
                                    }
                                    </DropBorder>
                                    :
                                    <div style={{display: "none"}} />
                                }
                            </AddFilterDrop>
                            <AreaButton onClick={()=>{addAddressHandler()}}>
                                지역 추가
                            </AreaButton>
                        </AddFilterDropBorder>
                        {/* 선택한 지역 */}
                        <AddFilterDropBorder style={{marginBottom: "3.2rem"}}>
                            <AreaTagsBorder>
                            {
                                addressArray?.map((a, i)=>(
                                    <AreaTags key={i} onClick={()=>{delAddressHandler(a)}}>
                                        <div style={{height: "100%", display: "flex", alignItems: "center", marginTop: "0.1rem"}}>
                                            {a}
                                        </div>
                                        <CloseIcon src={close} alt="close"/>
                                    </AreaTags>
                                ))
                            }
                            </AreaTagsBorder>
                        </AddFilterDropBorder>
                        <Title>
                            희망 업종
                        </Title>
                        <AddFilterDropBorder style={{marginBottom: "3.2rem"}}>
                            <AddFilterDrop ref={dropRef03} onChange={dropRef03Handler}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <span>업종 선택</span>
                                </div>
                                <FilterIconArrow src={arrow} style={{transform: isindustry?'rotate(180deg)':'rotate(0deg)'}} alt="arrow"/>
                                {
                                    isindustry?
                                    <DropBorder>
                                        {
                                            businessCategory.map((a, i)=>(
                                                <DropDownItems onClick={()=> {industryHandler(a)}} key={i}>
                                                    {a}
                                                </DropDownItems>
                                            ))
                                        }
                                    </DropBorder>
                                    :
                                    <div style={{display: "none"}} />
                                }
                            </AddFilterDrop>
                            <SubmitInput readOnly value={industry} onChange={()=>{}} style={{width: "49rem"}}/>
                        </AddFilterDropBorder>
                        <Title>
                            희망 급여
                        </Title>
                        
                        <CostBorder style={{marginBottom: "0.8rem"}}>
                            <CostIcon onClick={()=>{setis_hourly_pay(!is_hourly_pay)}} src={is_hourly_pay?checked:unChecked} alt="active-option"/>
                            <SubmitInput readOnly value={'시급'} onChange={()=>{}} style={{width: "13.2rem"}}/>
                            <SubmitInput readOnly={!is_hourly_pay} value={hourly_format} onChange={hourly_payHandler} style={{width: "49rem"}}/>
                            <UnitText>
                                원
                            </UnitText>
                        </CostBorder>
                        <CostBorder style={{marginBottom: "0.8rem"}}>
                            <CostIcon onClick={()=>{setis_daily_pay(!is_daily_pay);}} src={is_daily_pay?checked:unChecked} alt="active-option"/>
                            <SubmitInput readOnly value={'일급'} onChange={()=>{}} style={{width: "13.2rem"}}/>
                            <SubmitInput readOnly={!is_daily_pay} value={daily_format} onChange={daily_payHandler} style={{width: "49rem"}}/>
                            <UnitText>
                                원
                            </UnitText>
                        </CostBorder>
                        <CostBorder style={{marginBottom: "0.8rem"}}>
                            <CostIcon onClick={()=>{setis_weekly_pay(!is_weekly_pay)}} src={is_weekly_pay?checked:unChecked} alt="active-option"/>
                            <SubmitInput readOnly value={'주급'} onChange={()=>{}} style={{width: "13.2rem"}}/>
                            <SubmitInput readOnly={!is_weekly_pay} value={weekly_format} onChange={weekly_payHandler} style={{width: "49rem"}}/>
                            <UnitText>
                                원
                            </UnitText>
                        </CostBorder>
                        <CostBorder style={{marginBottom: "0.8rem"}}>
                            <CostIcon onClick={()=>{setis_monthly_pay(!is_monthly_pay)}} src={is_monthly_pay?checked:unChecked} alt="active-option"/>
                            <SubmitInput readOnly value={'월급'} onChange={()=>{}} style={{width: "13.2rem"}}/>
                            <SubmitInput readOnly={!is_monthly_pay} value={monthly_format} onChange={monthly_payHandler} style={{width: "49rem"}}/>
                            <UnitText>
                                원
                            </UnitText>
                        </CostBorder>
                        <CostBorder style={{marginBottom: "0.8rem"}}>
                            <CostIcon onClick={()=>{setis_tc_pay(!is_tc_pay)}} src={is_tc_pay?checked:unChecked} alt="active-option"/>
                            <SubmitInput readOnly value={'연급'} onChange={()=>{}} style={{width: "13.2rem"}}/>
                            <SubmitInput readOnly={!is_tc_pay} value={tc_format} onChange={tc_payHandler} style={{width: "49rem"}}/>
                            <UnitText>
                                원
                            </UnitText>
                        </CostBorder>
                        <CostBorder style={{marginBottom: "3.2rem"}}>
                            <CostIcon onClick={()=>{setis_per_pay(!is_per_pay)}} src={is_per_pay?checked:unChecked} alt="active-option"/>
                            <SubmitInput readOnly value={'건당'} onChange={()=>{}} style={{width: "13.2rem"}}/>
                            <SubmitInput readOnly={!is_per_pay} value={per_format} onChange={per_payHandler} style={{width: "49rem"}}/>
                            <UnitText>
                                원
                            </UnitText>
                        </CostBorder>
                        <Title>
                            경력 한줄소개
                        </Title>
                        <SubmitInput value={career} onChange={careerHandler} style={{width: "100%"}} placeholder="경력을 입력해주세요"/>
                    </AddDetailSubmitBorder>
                </AddDetailLeftBorder>
                {/* 오른쪽 컨트롤러 */}
                <AddDetailRightBorder>
                    <ContrllerStickyBorder>
                        <AddControllerBorder>
                            {/* 프로필 정보 */}
                            <AddControllerProfileBorder>
                                <div>
                                    <ProfileInfoCompany>
                                        회원
                                    </ProfileInfoCompany>
                                    <ProfileText>
                                        {midMainager?.log_data?.name}
                                    </ProfileText>
                                </div>
                            </AddControllerProfileBorder>
                            {/* sns 정보 */}
                            <ControllerBorder>
                                <ControllerInfoBorder>
                                    <ControllerText>
                                        성별
                                    </ControllerText>
                                    <ControllerText style={{justifyContent: "right", color: "#171717"}}>
                                        {midMainager?.log_data?.gender === 'male'? '남':'여'}
                                    </ControllerText>
                                </ControllerInfoBorder>
                                <ControllerInfoBorder>
                                    <ControllerText>
                                        나이
                                    </ControllerText>
                                    <ControllerText style={{justifyContent: "right", color: "#171717"}}>
                                        {age}
                                    </ControllerText>
                                </ControllerInfoBorder>
                                
                            </ControllerBorder>
                        </AddControllerBorder>
                        <ControllerButton onClick={()=>{submitHandler()}}>
                            이력서 등록하기
                        </ControllerButton>
                    </ContrllerStickyBorder>
                </AddDetailRightBorder>
            </AddDetailBorder>
        </ColorBorder>
    )
}


export default ResumeAdd;