/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import arrow from "./../../@images/arrow-line-24.svg";
import AddPhoto from "./../../@images/add-photo.svg";
import CommunityEditor from "./community-editor-mob";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

let CommuBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 11rem;
    color: black;
    box-sizign: border-box;
    padding-bottom: 7.2rem;
    background-color: #F7F7F7;
    padding-left:2rem;
    padding-right:2rem;
`

let CommuContentBorder = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-top: 2rem;
`

let CommuTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2.4rem;
    color: #171717;
    margin-top: 2rem;
    width: 100%;
`

let EditorBorder = styled.div`
    width: 100%;
    box-sizing: border-box;
`

let ContentBorder = styled.div`
    width: 100%;
    background-color: white;
    border-radius: 0.4rem;
    border-color: #E8E8E8;
    border-width: 0.1rem;
    border-style: solid;
    box-sizing: border-box;
    padding: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

let ContentTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    color: #171717;
    margin-bottom: 0.8rem;
`

let NickDrop = styled.div`
    width: 19.2rem;
    height: 5.6rem;
    border-style: solid;
    border-color: #D2D2D2;
    border-width: 0.1rem;
    border-radius: 0.4rem;
    padding: 1.6rem;
    box-sizing: border-box;
    cursor: pointer;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: #6A6A6A;
    position: relative;
`

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  gap: 0.8rem;
`;

const InputNickname = styled.input`
  padding: 1rem;
  width: 100%;
  height: 5.6rem;
  font-size: 1.6rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Pretendard-Regular';
  outline: none;
  background-color: #F7F7F7;
`;

let FilterIconArrow = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
    position: absolute;
    top: 50%;
    right: 0.6rem;
    transform: translateY(-50%) rotate(${props=>props.rotate}deg);
`

let DropBorder = styled.div`
    position: absolute;
    width: 100%;
    height: auto;
    max-height: 30rem;
    left: 0px;
    top: 5.8rem;
    z-index: 30;
    background-color: white;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #E8E8E8;
    border-width: 0.1rem;
    overflow: auto;
    text-align: left;
    &::-webkit-scrollbar {
        width: 12px; /* 너비 설정 */
      }
    &::-webkit-scrollbar-thumb {
        background: #CBCED5;
        border-radius: 6px;
    }
`

let DropDownItems = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: grey;
    box-sizing: border-box;
    padding: 0 2rem;
    height: 3.4rem;
    &:hover{
        color: black;
    }
`

let ImageGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 1.6rem;
    width: 100%;
    height: 12rem;
`

let ImageBorder= styled.div`
    width: 200%;
    height: 100%;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 0.1rem;
    border-radius: 0.4rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`

let ContentInput = styled.input`
    width: 100%;
    height: 5.6rem;
    box-sizing: border-box;
    border-style: solid;
    border-color: #D2D2D2;
    border-width: 0.1rem;
    border-radius: 0.4rem;
    outline: none;
    padding: 0 1.6rem;
    font-size: 1.6rem;
`

let AddDetailSubmitBorder = styled.div`
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    padding: 3.2rem;
    text-align: left;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    border-width: 0.1rem;
    border-style: solid;
`

let ButtonBorder = styled.div`
    width: 100%;
    box-sizing: border-box;
    position: relative;
`

let SubmitButton = styled.button`
    position: sticky;
    top: 20rem;
    width: 100%;
    height: 5.6rem;
    background-color: #CD1B1B;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    color: white;
    font-size: 1.8rem;
    font-weight: 500;
`

const CommunityAdd = () => {
    // 랜덤 닉네임 변수
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let randomFirstNick = ['파랑', '빨강', '초록', '검정', '노랑', '분홍']
    let randomSecondNick = ['곰', '토끼', '여우', '팬더', '강아지', '고양이']
    let [contentType, setContentType] = useState('sns'); //sns, 자유게시판 선택
    let [isRandom, setIsRandom] = useState(); // 랜덤 닉네임
    

    let [pwd, setPwd] = useState('');
    let [clickedDrop, setClickedDrop] = useState(false);
    let [nickName, setNickName] = useState('');
    let [contentsImage, setContentsImage] = useState([]); // 이미지 (서버용: props.server)
    let [title, setTitle] = useState(''); 
    let [content, setContent] = useState('');

    let imageRef = useRef(null);
    let params = useParams();
    let navigate = useNavigate();

    useEffect(()=>{
        randomHandler(false)
    },[])

    const contentHandler = (e) => {
        setContent(e);
    }

    const nicknameHandler = (e) => {
        setNickName(e)
    }

    const randomHandler = (e) => {
        const firstPart = randomFirstNick[Math.floor(Math.random() * randomFirstNick.length)];
        const secondPart = randomSecondNick[Math.floor(Math.random() * randomSecondNick.length)];
        setIsRandom(e);
        setClickedDrop(false);
        if(e){
            nicknameHandler(firstPart+secondPart)
        }else{
            nicknameHandler(midMainager?.log_data?.nickname?midMainager?.log_data?.nickname:'') // 고정 닉네임
        }
    }

    let dropRef01 = useRef();
    const dropRef01Handler = (event) => {
        if (dropRef01.current && !dropRef01.current.contains(event.target)) {
            setClickedDrop(false)
        }else{
            setClickedDrop(true)
        }
    }

    // 업종 드랍다운 박스 useEffect
    useEffect(() => {
        document.addEventListener('mousedown', dropRef01Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef01Handler);
        };
    }, []);

    const delImageHandler = (e) => {
        if(e){
            let instArray = contentsImage?.filter(obj=>obj !== e);
            setContentsImage(instArray);
        }
    }

    //이미지 컴포넌트
    const ImageHandler = ({data}) => {
        return(
            <ImageBorder onClick={()=>{delImageHandler(data)}}>
                <img src={data.inst} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt="profile"/>
            </ImageBorder>
        )
    }

    let contentsImageHandler = (e) => {
        let instArray = [...contentsImage];
        instArray.push(e);
        setContentsImage(instArray);
    }

    const imageAddHandler = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                contentsImageHandler({server: file, inst: reader.result})
            };
            reader.readAsDataURL(file);
        }
        imageRef.current.value = null
    }

    const titleHandler = (e) => {
        setTitle(e.target.value);
    };
    
    const submitHandler = () => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        let requestBody = {
            "client_id": midMainager?.log_data?.id,
            "pwd": pwd,
            "status": "active",
            "hot_issue": false,
            "is_mobile": isMobile ? true : false,
            "lounge_type": params.id === 'sns'? 'sns':'general',
            "nickname": nickName,
            "lounge_title": title,
            "data": [
                {content}
            ]
        }

        const jsonRequestData = JSON.stringify(requestBody);
        const formData = new FormData();
        formData.append('req_body',jsonRequestData);
        if(contentsImage?.length === 0){

        }else{
           for(let i = 0; i<=contentsImage?.length-1; i++){
            formData.append('images',contentsImage[i]);
           }
        }
        axios.post('https://api.midtrc.com/lounge',formData,{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{console.log(res.data); navigate('/community')}).catch((error)=>{})
    }

    return(
        <CommuBorder>
            <input type="file" onChange={(e)=>{imageAddHandler(e);}} style={{display: "none"}} ref={imageRef}/>
            <CommuTitle>
                게시글 작성
            </CommuTitle>
            <CommuContentBorder>
                <EditorBorder>
                    {/* 닉네임 */}
                    <ContentBorder style={{marginBottom: '0.6rem'}}>
                        <ContentTitle>
                            닉네임 설정
                        </ContentTitle>
                        <InputGroup>
                            <NickDrop ref={dropRef01}>
                                {
                                    isRandom?
                                    <div>랜덤 닉네임</div>
                                    :
                                    <div>고정 닉네임</div>
                                }
                                <FilterIconArrow src={arrow} rotate={clickedDrop? 180: 0} alt="arrow"/>
                                {
                                    clickedDrop?
                                    <DropBorder>
                                        <DropDownItems onClick={()=>{randomHandler(true)}}>
                                            랜덤 닉네임
                                        </DropDownItems>
                                        <DropDownItems onClick={()=>{randomHandler(false)}}>
                                            고정 닉네임
                                        </DropDownItems>
                                    </DropBorder>
                                    :
                                    <div/>
                                }
                            </NickDrop>
                            <InputNickname readOnly value={nickName} onChange={()=>{}} placeholder="닉네임을 입력하세요." />
                        </InputGroup>
                    </ContentBorder>
                    {/* 이미지 */}
                    <ContentBorder>
                        <ContentTitle>
                            이미지
                            <span style={{marginLeft: '0.5rem', color: '#9E9E9E'}}>
                                (최대 5장)
                            </span>
                        </ContentTitle>
                        <ImageGroup>
                            {
                                contentsImage?.map((a, i)=> (
                                    <ImageHandler key={i} data={a}/>
                                ))
                            }
                            {
                                contentsImage?.length < 5?
                                <ImageBorder onClick={()=>{imageRef.current.click()}} >
                                    <img src={AddPhoto} style={{width: '50%', height: '50%', objectFit: 'cover'}} alt="profile"/>
                                </ImageBorder>
                                :
                                <div style={{display: "none"}}/>
                            }
                            
                        </ImageGroup>
                    </ContentBorder>
                    {/* 제목, 내용 */}
                    <AddDetailSubmitBorder style={{marginTop: "0.8rem"}}>
                        <ContentTitle>
                            제목
                        </ContentTitle>
                        <ContentInput onChange={titleHandler}/>
                        <ContentTitle style={{marginTop: "3.2rem"}}>
                            내용
                        </ContentTitle>
                        <CommunityEditor data={contentHandler} />
                    </AddDetailSubmitBorder>
                </EditorBorder>
                <ButtonBorder>
                    <SubmitButton onClick={()=>{submitHandler()}}>
                        게시글 등록
                    </SubmitButton>
                </ButtonBorder>
            </CommuContentBorder>
        </CommuBorder>
    )
}

export default CommunityAdd;
