import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import more from "./../../@images/more-24.svg";
import like from "./../../@images/sns-like-black-40.svg";
import likeActive from "./../../@images/like-active.svg";
import comment from "./../../@images/sns-comment-black-40.svg";
import link from "./../../@images/sns-link-black-40.svg";
import bookMark from "./../../@images/sns-bookmark-black-40.svg";
import bookMarkActive from "./../../@images/sns-bookmark-active.svg";
import arrow from "./../../@images/arrow-line-24.svg";
import emoji from "./../../@images/chat-emoji.svg";
import lock from "./../../@images/lock-40.svg";
import lockActive from "./../../@images/lock-active-24.svg";
import commentLike from "./../../@images/comment_like_24.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";
import profileIcon1 from "./../../@images/profile_icon1.svg";
import profileIcon2 from "./../../@images/profile_icon2.svg";
import profileIcon3 from "./../../@images/profile_icon3.svg";
import profileIcon4 from "./../../@images/profile_icon4.svg";
import profileIcon5 from "./../../@images/profile_icon5.svg";


let ContentsBoarder = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 3.2rem 1rem 1.6rem 1rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #d2d2d2;
    border-radius: 0.4rem;
    margin-bottom: 1.6rem;
`

let TopProfileBorder = styled.div`
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: left;
    align-itme: center;
    position: relative;
`

let TopProfileImageBorder = styled.div`
    border-radius: 3rem;
    width: 5rem;
    height: 5rem;
    overflow: hidden;
    margin-right: 1.6rem;
`

let TopProfileImage = styled.img`
    min-width: 5rem;
    min-height: 5rem;
    background-color: black;
`

let TopNickBorder = styled.div`
    position:relative;
    margin-top:-1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.4rem;
`

let TopNickText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2rem;
    color: #171717;
`

let TopTimeText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #9e9e9e;
`

let TopDotHamburgerButton = styled.img`
    widht: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    position: absolute;
    top: 0rem;
    right: 0rem;
    cursor: pointer;
`

let PhotoContentsBorder = styled.div`
    width: 100%;
    height: 30rem;
    border-radius: 0.4rem;
    overflow: hidden;
    position: relative;
`

let PhotoImage = styled.img`
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    background-color: #f5f5f5;
    cursor: pointer;
`

let PhotoLeftProgressBorder = styled.div`
    position: absolute;
    display: flex;
    column-gap: 0.8rem;
    bottom: 0.8rem;
    left: 50%;
    height: 0.8rem;
    width: auto;
    transform: translateX(-50%);
`

let PhotoLeftProgressButton = styled.svg`
    width: 0.8rem;
    height: 0.8rem;
    opacity: ${props => props.isSelected? 1: 0.6};
    border-radius: 1rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #D2D2D2;
    background-color: white;
    cursor: pointer;
`

let StatusBorder = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

let SnsIconBorder = styled.div`
    display: flex;
    gap: 0.4rem;
    align-items: center;
`

let SnsIcon = styled.img`
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    cursor: pointer;
`

let SnsIconText = styled.div`
    height: auto;
    width: 3rem;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.8rem;
    color: #171717;
    text-align: left;
`

let SnsTextBorder = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;
    padding-bottom: 1.6rem;
    border-color: #d2d2d2;
    border-style: solid;
    border-width: 0 0 0.1rem 0;
`

let SnsTitleText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.8rem;
    color: #171717;
`

let SnsContentsText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.8rem;
    color: #171717;
    line-height: 1.5;
`

let SnsContentsMore = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.8rem;
    color: #9E9E9E;
    margin-left: 0.4rem;
    cursor: pointer;
`
// 코멘트
let CommentBorder = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: auto;
    border-radius: 0.8rem;
    border: none;
    background-color: #F7F7F7;
    padding: 1.6rem 2.4rem;
`

let CommentsDropBorder = styled.div`
    display: flex;
    gap: 0.8rem;
`

let CommentsDrop = styled.div`
    cursor: pointer;
    width: 16rem;
    height: 4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: white;
`

let CommentsNick = styled.div`
    width: 14rem;
    height: 4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: #E8E8E8;
`

let FilterIconArrow = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
`

let CommentsInputBorder= styled.div`
    width: 100%;
    min-height: 5.6rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 1.6rem;
    box-sizing: border-box;
    background-color: white;
    position: relative;
`

let CommentsIconBorder = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 1.6rem;
    top: 0.8rem;
`

let CommentsIcon = styled.img`
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    cursor: pointer;
`

let CommentsSubmitButton = styled.button`
    height: 3.6rem;
    background-color: #CD1B1B;
    box-sizing: border-box;
    padding: 0.8rem 1.2rem;
    border: none;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: white;
    cursor: pointer;
`

let CommentsInput = styled.textarea`
    box-sizing: border-box;
    width: 100%;
    min-height: 4.6rem;
    resize: none;
    border: none;
    outline: none;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.3rem;
    box-sizing: border-box;
    overflow: hidden;
    padding: 1.6rem 0;
    line-height: 1.5;
`

// 댓글
let ReplyMainBorder = styled.div`
    width: 100%;
    min-height: 11.2rem;
`

let ReplyBorder = styled.div`
    width: 100%;
    min-height: 11.2rem;
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 0.8rem;
    box-sizing: border-box;
`

let ReplyProfileImage = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    background-color: black;
`

let ReplyContentsBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-contetn: right;
    box-sizing: border-box;
`

let ReplyNickText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: #171717;
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
`

let ReplyTimeText = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9e9e9e;
    margin-left: 0.8rem;
`

let ReplyContentsText = styled.textarea`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #444444;
    width: 100%;
    resize: none;
    outline: none;
    border: none;
    padding: 0;
`

let ReplyShowMore = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9e9e9e;
    cursor: pointer;
`

let ReplyToolBorder = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    gap: 0.8rem;
    justify-content: right;
    align-items: flex-start;
`

let ReplyIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    cursor: pointer;
`

let ReplyCount = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
    color: #9e9e9e;
    width: 100%;
    text-align: center;
`

let ReReplyListBorder = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;
    padding-left: 3.2rem;

`

let DropBorder = styled.div`
    width: 14rem;
    box-sizing: border-box;
    padding: 0 2rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #dedede;
    border-width: 0.1rem;
    background-color: white;
    position: absolute;
    top: 4.5rem;
    left: 0;
    z-index: 30;
`

let DropBorderItem = styled.div`
    font-size: 1.4rem;
    text-align: left;
    padding: 1rem 0;
    &:hover{
        color: red;
    }
`

let EmojisBorder = styled.div`
    
    height: 24rem;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #dedede;
    border-width: 0.1rem;
    background-color: white;
    position: absolute;
    top: 4.5rem;
    right: 0;
    z-index: 30;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    overflow-y: auto;
    overflow-x: hidden;
`

let EmojisItems = styled.div`
    box-sizing: border-box;
    padding: 1rem;
    font-size: 1.8rem;
    cursor: pointer;
    &:hover{
        background-color: #f2f2f2;
    }
`

let DropBorder01 = styled.div`
    position: absolute;
    width: 10rem;
    height: auto;
    max-height: 30rem;
    right: 0px;
    top: 2em;
    z-index: 30;
    background-color: white;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #E8E8E8;
    border-width: 0.1rem;
    text-align: left;
`;

let DropDownItems01 = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: grey;
    box-sizing: border-box;
    padding: 0 2rem;
    height: 3.4rem;
    &:hover {
        color: black;
    }
`;

const SnsContents =  ({data, isChange}) => {
    const [commetnsValue, setCommetnsValue] = useState('');
    const textareaRef = useRef(null);
    let [currentImage, setCurrentImage] = useState(0);
    let [contentsImage, setContentsImage] = useState([...data?.image_urls]);
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [contentsId, setContentsId] = useState(data?.id);
    let [replyData, setReplyData] = useState([]);
    let [lounge_id, setLounge_id] = useState(data?.id);

    // 코멘트 작성
    let [isRandom, setIsRandom] = useState('랜덤 닉네임');
    let [isDrop, setIsDrop] = useState(false);
    let [isSecret, setIsSecret] = useState(false);
    let [commentsNick, setCommentsNick] = useState(''); // 닉네임 서버용
    let randomFirstNick = ['파랑', '빨강', '초록', '검정', '노랑', '분홍']
    let randomSecondNick = ['곰', '토끼', '여우', '팬더', '강아지', '고양이']
    let [comments, setComments] = useState(''); // 코멘트 서버용
    let [isCommentsDrop, setIsCommentsDrop] = useState(false);
    let emojis= [
        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
        '✅', '❎', '💯',
    ]

    // 콘텐츠 data?.data[0]?.content
    const [commentsFrom, setCommentsFrom] = useState(data?.data[0]?.content);
    const [isTruncated, setIsTruncated] = useState(true);


       //수정 페이지로 이동
       const navigate = useNavigate();

       const handleEdit = () => {
           navigate(`/community-edit/${data.id}`);
       };

    useEffect(()=>{nicknameHandler({type: '랜덤 닉네임', text: ''})},[])

    let commentsHandler = (e) => {
        setComments(e.target.value);
    }

    let commentsEmojisHandler = (e) => {
        setComments(prevComments => prevComments + e);
    }
    
    let dropRef01 = useRef();
    
    const dropRef01Handler = (event) => {
        if (dropRef01.current && !dropRef01.current.contains(event.target)) {
            setIsDrop(false);
        }else{
            setIsDrop(true);
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', dropRef01Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef01Handler);
        };
    }, []);

    let dropRef02 = useRef();
    const dropRef02Handler = (event) => {
        if (dropRef02.current && !dropRef02.current.contains(event.target)) {
            setIsCommentsDrop(false);
        }else{
            setIsCommentsDrop(true);
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', dropRef02Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef02Handler);
        };
    }, []);

    let [complainOpen, setComplainOpen] = useState(false);
    let [drop, setDrop] = useState(false);

    let dropRef03 = useRef();


    const dropRef03Handler = (event) => {
        if (dropRef03.current && !dropRef03.current.contains(event.target)) {
            setDrop(false);
        }else{
            setDrop(true);
        }
    }


    useEffect(() => {
        document.addEventListener('mousedown', dropRef03Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef03Handler);
        };
    }, []);


    const complainHandler = () => {
        if(complainOpen){
            setComplainOpen(false)
            document.body.style.overflow = 'auto';
        }else{
            setComplainOpen(true)
            document.body.style.overflow = 'hidden';
        }
    }




    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [comments]);


    const nicknameHandler = (e) => {
        if(e.type === '랜덤 닉네임'){
            const firstPart = randomFirstNick[Math.floor(Math.random() * randomFirstNick.length)];
            const secondPart = randomSecondNick[Math.floor(Math.random() * randomSecondNick.length)];
            setCommentsNick(firstPart+secondPart);
        }else if(e.type === '고정 닉네임'){
            setCommentsNick(midMainager?.log_data?.nickname?midMainager?.log_data?.nickname:'')
        }else{
            setCommentsNick(e.text)
        }
    }

    const CommentSubmitHandler = () => {
        let reqeustBody = {
            "client_id": midMainager?.log_data?.id,
            "lounge_id": data?.id,
            "nickname": commentsNick,
            "is_private": isSecret,
            "content": comments
          }
          console.log(reqeustBody);

        axios.post('https://api.midtrc.com/comment',reqeustBody,{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{
            // isChange(); 
            nicknameHandler({type: '랜덤 닉네임', text: ''});
            setComments('');
            setIsSecret(false);
            isChange();
        }).catch((error)=>{console.log(error)});
    }



    const CommentSubmit = () => {
        return(
            <CommentBorder style={{marginBottom: "1.6rem"}}>
                {/* 코멘트 */}
                <CommentsDropBorder style={{marginBottom: "0.8rem"}}>
                        {/* 코멘트 드랍다운 */}
                        <div style={{display: "flex", gap: '0.8rem'}}>
                            <CommentsDrop ref={dropRef01} onClick={dropRef01Handler}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    {
                                        isRandom === '랜덤 닉네임'?
                                        <span>랜덤 닉네임</span>
                                        :
                                        isRandom === '고정 닉네임'?
                                        <span>고정 닉네임</span>
                                        :
                                        <span>닉네임</span>
                                    }
                                </div>
                                <FilterIconArrow style={{transform: isDrop? 'rotate(180deg)' : 'rotate(0deg)'}} src={arrow} alt="arrow"/>
                                {
                                    isDrop?
                                    <DropBorder>
                                        <DropBorderItem onClick={(e)=>{e.stopPropagation(); setIsRandom('랜덤 닉네임'); setIsDrop(false); nicknameHandler({type: '랜덤 닉네임', text: ''})}}>랜덤 닉네임</DropBorderItem>
                                        <DropBorderItem onClick={(e)=>{e.stopPropagation(); setIsRandom('고정 닉네임'); setIsDrop(false); nicknameHandler({type: '고정 닉네임', text: ''})}}>고정 닉네임</DropBorderItem>
                                        <DropBorderItem onClick={(e)=>{e.stopPropagation(); setIsRandom('닉네임'); setIsDrop(false); nicknameHandler({type: '닉네임', text: ''})}}>닉네임</DropBorderItem>
                                    </DropBorder>
                                    :
                                    <div style={{display: "none"}}/>
                                }
                            </CommentsDrop>
                            {/* 닉네인 뷰 */}
                            <CommentsNick value={commentsNick} onChange={(e)=>{nicknameHandler({type: '닉네임', text: e.target.value})}} readOnly={isRandom !== '닉네임'} placeholder="닉네임 작성" />
                        </div>
                    {/* {
                        isSecret?
                        <CommentsPwd type="password"/>
                        :
                        <div />
                    } */}
                </CommentsDropBorder>
                {/* 코멘트 인풋 */}
                <CommentsInputBorder>
                    <CommentsInput 
                    ref={textareaRef} 
                    value={comments} 
                    onChange={commentsHandler} 
                    rows="1"
                    placeholder="댓글을 입력해주세요"
                    />
                    <CommentsIconBorder>
                        <div style={{display: "flex", alignItems: "center", position: "relative"}} ref={dropRef02} onClick={dropRef02Handler}>
                            <CommentsIcon src={emoji} alt="icon04"/>
                            {
                                isCommentsDrop?
                                <EmojisBorder >
                                    {
                                        emojis.map((a, i)=>(
                                            <EmojisItems onClick={(e)=>{e.stopPropagation(); commentsEmojisHandler(a); setIsCommentsDrop(false)}} key={i}>
                                                {a}
                                            </EmojisItems>
                                        ))
                                    }
                                </EmojisBorder>
                                :
                                <div style={{display: "none"}}/>
                            }
                            
                        </div>
                            <CommentsIcon onClick={()=>{setIsSecret(!isSecret)}} src={isSecret? lockActive : lock} alt="icon05" style={{marginRight: "0.8rem"}}/>
                            <CommentsSubmitButton onClick={()=>{CommentSubmitHandler()}}>
                                등록
                            </CommentsSubmitButton>
                            
                    </CommentsIconBorder>
                </CommentsInputBorder>
            </CommentBorder>
        )
    }

    const ReReply = () => {
        let [isClickedReply, setIsClickedReply] = useState(false);
        return(
            <ReplyMainBorder>
                <ReplyBorder>
                    {/* 프로필 이미지 */}
                    <ReplyProfileImage alt="re-profile"/>
                    {/* 닉네임, 시간, 내용, 대댓글 */}
                    <ReplyContentsBorder>
                        <ReplyNickText>
                            춘식이덧버선
                            <ReplyTimeText>
                                30분전
                            </ReplyTimeText>
                        </ReplyNickText>
                        <ReplyContentsText value={'논현역 근처도 추천해주세요'} readOnly onChange={()=>{}} />
                        <ReplyShowMore>
                            댓글
                        </ReplyShowMore>
                    </ReplyContentsBorder>
                    <ReplyToolBorder>
                        <div>
                            <ReplyIcon src={commentLike} alt="icon08"/>
                            <ReplyCount>
                                124
                            </ReplyCount>
                        </div>
                        
                        <ReplyIcon src={more} alt="icon08"/>
                    </ReplyToolBorder>
                </ReplyBorder>
                {
                    isClickedReply?
                    <CommentBorder style={{marginBottom: "0.8rem"}}>
                        {/* 코멘트 */}
                        <CommentsDropBorder style={{marginBottom: "0.8rem"}}>
                            {/* 코멘트 드랍다운 */}
                            <CommentsDrop>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <span>랜덤 닉네임</span>
                                </div>
                                <FilterIconArrow src={arrow} alt="arrow"/>
                            </CommentsDrop>
                            {/* 닉네인 뷰 */}
                            <CommentsNick>
                                춘식이덧버선
                            </CommentsNick>
                        </CommentsDropBorder>
                        {/* 코멘트 인풋 */}
                        <CommentsInputBorder>
                            <CommentsInput 
                            // ref={textareaRef} 
                            // value={value} 
                            // onChange={handleChange} 
                            // rows="1"
                            onChange={()=>{}}
                            placeholder="댓글을 입력해주세요"
                            />
                            <CommentsIconBorder>
                                <CommentsIcon src={emoji} alt="icon04"/>
                                <CommentsIcon src={lock} alt="icon05" style={{marginRight: "0.8rem"}}/>
                                <CommentsSubmitButton>
                                    등록
                                </CommentsSubmitButton>
                            </CommentsIconBorder>
                        </CommentsInputBorder>
                    </CommentBorder>
                    :
                    <div/>
                }
            </ReplyMainBorder>
        )
    }

    const Reply = ({data}) => {
        let [isClickedReply, setIsClickedReply] = useState(false);
        const [contentHeight, setContentHeight] = useState('auto');
        const clickedReplyHandler = () => {
            setIsClickedReply(!isClickedReply)
        }
        const replyContentsRef = useRef();

        useEffect(() => {
            if (replyContentsRef.current) {
                setContentHeight(replyContentsRef.current.scrollHeight + 10 +'px');
            }
        }, [data?.content]);

        const replyCount = () => {
            axios.put(`https://api.midtrc.com/comment/${data?.id}/like?lounge_id=${lounge_id}`,{},{
                headers: {
                    'access-token': midMainager?.log_data?.access_token
                }
            }).then((res)=>{ isChange(); console.log(res.data)}).catch((error)=>{console.log(error)});
        }

        const formatTime = (postTime) => {
            const now = new Date();
            const postDate = new Date(postTime);
        
            const diffInSeconds = Math.floor((now - postDate) / 1000);
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            const diffInHours = Math.floor(diffInMinutes / 60);
            const diffInDays = Math.floor(diffInHours / 24);
        
            if (diffInHours < 1) {
                return `${diffInMinutes}분`;
            } else if (diffInHours < 3) {
                return `${diffInHours}시간`;
            } else if (diffInDays < 1) {
                return '오늘';
            } else if (diffInDays < 2) {
                return '어제';
            } else {
                const year = postDate.getFullYear().toString().slice(2); // Get last two digits of the year
                const month = (postDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
                const day = postDate.getDate().toString().padStart(2, '0');
                return `${year}.${month}.${day}`;
            }
        }
    
        const TimeDisplay = ({ postTime }) => {
            const formattedTime = formatTime(postTime);
            return <div>{formattedTime}</div>;
        };

        return(
            <ReplyMainBorder>
                <ReplyBorder>
                    {/* 프로필 이미지 */}
                    <ReplyProfileImage alt="re-profile"/>
                    {/* 닉네임, 시간, 내용, 대댓글 */}
                    <ReplyContentsBorder>
                        <ReplyNickText>
                                {data?.nickname}
                            <ReplyTimeText>
                                <TimeDisplay postTime={data?.updated_at}/>
                            </ReplyTimeText>
                        </ReplyNickText>
                        <ReplyContentsText ref={replyContentsRef} value={data?.content?data?.content:''} readOnly onChange={()=>{}} style={{ height: contentHeight }}/>
                        <ReplyShowMore style={{marginBottom: "1rem"}} onClick={(e)=>{e.stopPropagation(); clickedReplyHandler()}}>
                            {
                                isClickedReply?
                                <div>닫기</div>:<div>댓글</div>
                            }
                        </ReplyShowMore>
                    </ReplyContentsBorder>
                    <ReplyToolBorder>
                        <div>
                            <ReplyIcon src={commentLike} alt="icon08" onClick={()=>{replyCount()}}/>
                            <ReplyCount>
                                {data?.like_count}
                            </ReplyCount>
                        </div>
                        <ReplyIcon src={more} alt="icon08"/>
                    </ReplyToolBorder>
                </ReplyBorder>
                {
                    isClickedReply?
                    <CommentBorder style={{marginBottom: "0.8rem"}}>
                        {/* 코멘트 */}
                        <CommentsDropBorder style={{marginBottom: "0.8rem", justifyContent: "left", gap: "0.8rem"}}>
                            {/* 코멘트 드랍다운 */}
                            <CommentsDrop>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <span>랜덤 닉네임</span>
                                </div>
                                <FilterIconArrow src={arrow} alt="arrow"/>
                            </CommentsDrop>
                            {/* 닉네인 뷰 */}
                            <CommentsNick />
                        </CommentsDropBorder>
                        {/* 코멘트 인풋 */}
                        <CommentsInputBorder>
                            <CommentsInput 
                            // ref={textareaRef} 
                            value={'asdfasdfasdf'} 
                            // rows="1"
                            onChange={()=>{}}
                            placeholder="댓글을 입력해주세요"
                            />
                            <CommentsIconBorder>
                                <CommentsIcon src={emoji} alt="icon04"/>
                                <CommentsIcon src={lock} alt="icon05" style={{marginRight: "0.8rem"}}/>
                                <CommentsSubmitButton>
                                    등록
                                </CommentsSubmitButton>
                            </CommentsIconBorder>
                        </CommentsInputBorder>
                    </CommentBorder>
                    :
                    <div/>
                }
                {/* <ReReplyListBorder style={{marginTop: "0.8rem"}}>
                    <ReReply />
                </ReReplyListBorder> */}
                
            </ReplyMainBorder>
        )
    }
    
    const formatTime = (postTime) => {
        const now = new Date();
        const postDate = new Date(postTime);
    
        const diffInSeconds = Math.floor((now - postDate) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
    
        if (diffInHours < 1) {
            return `${diffInMinutes}분`;
        } else if (diffInHours < 3) {
            return `${diffInHours}시간`;
        } else if (diffInDays < 1) {
            return '오늘';
        } else if (diffInDays < 2) {
            return '어제';
        } else {
            const year = postDate.getFullYear().toString().slice(2); // Get last two digits of the year
            const month = (postDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
            const day = postDate.getDate().toString().padStart(2, '0');
            return `${year}.${month}.${day}`;
        }
    }

    const TimeDisplay = ({ postTime }) => {
        const formattedTime = formatTime(postTime);
        return <div>{formattedTime}</div>;
    };

    const imageHandler = () => {
        let instCount = currentImage + 1;
        if(instCount > contentsImage?.length-1){
            setCurrentImage(0);
        }else{
            setCurrentImage(instCount);
        }
    }

    const likeHandler = () => {
        axios.put(`https://api.midtrc.com/lounge/${data?.id}/like`,{},{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{isChange(); console.log(res.data)}).catch((error)=>{console.log(error)});
    }

    // const handleCopyUrl = (id) => {
    //     const url = `${window.location.origin}/community/${id}`;
    //     navigator.clipboard.writeText(url).then(() => {
    //       alert('URL copied to clipboard');
    //     });
    // };

    const favoriteHandler = () => {
        axios.put(`https://api.midtrc.com/lounge/${data?.id}/favorite`,{},{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{isChange(); console.log(res.data)}).catch((error)=>{console.log(error)});
    }

    const ProfileImageHandler = () => {
        let instProfile = midMainager?.log_data?.profile_url;
        if(instProfile === '01'){
            return profileIcon1;
        }else if(instProfile === '02'){
            return profileIcon2;
        }else if(instProfile === '03'){
            return profileIcon3;
        }else if(instProfile === '04'){
            return profileIcon4;
        }else if(instProfile === '05'){
            return profileIcon5;
        }else{
            return instProfile
        }
    }

    const toggleTruncate = () => {
        setIsTruncated(!isTruncated);
    };

    const getDisplayedText = () => {
        let instCount = 50;
        if (isTruncated && commentsFrom.length > instCount) {
          return commentsFrom.slice(0, instCount) + '...';
        }
        return commentsFrom;
    };

    

    return(
        <ContentsBoarder>
            {/* 닉네임, 시간 */}
            <TopProfileBorder style={{marginBottom: "1.6rem"}}>
                <TopProfileImageBorder>
                    <TopProfileImage src={ProfileImageHandler()} alt="profile"/>
                </TopProfileImageBorder>
                <TopNickBorder>
                    <TopNickText>
                        {data?.nickname}
                    </TopNickText>
                    <TopTimeText>
                        <TimeDisplay postTime={data?.created_at}/>
                    </TopTimeText>
                </TopNickBorder>

               {/* 게시글 햄버거 버튼 */}   
               <TopDotHamburgerButton>
                <div ref={dropRef03} style={{position: "relative"}}>
                    <img src={more} alt="dot-burger"/>
                    {
                        drop?
                        <DropBorder01>
                               {data?.client_id === midMainager?.log_data?.id ? (
                                <>
                            <DropDownItems01 onClick={(e)=>{e.stopPropagation();setDrop(false); handleEdit(); }}>
                                수정
                            </DropDownItems01>
                            <DropDownItems01 onClick={(e)=>{e.stopPropagation(); setDrop(false)}}>
                                삭제
                            </DropDownItems01>
                                </>
                           ) : null}
                            <DropDownItems01 onClick={(e)=>{e.stopPropagation();complainHandler(); setDrop(false)}}>
                                신고하기
                            </DropDownItems01>
                        </DropBorder01>
                        :
                        null
                    }
                    
                </div>
                </TopDotHamburgerButton>


            </TopProfileBorder>
            {/* 이미지 롤링 */}
            <PhotoContentsBorder style={{marginBottom: "1.6rem"}}>
                {
                    contentsImage?.map((a, i)=>(
                        <PhotoImage src={a} isSelected={currentImage === i} key={i} alt={a} onClick={()=>{imageHandler()}}/>
                    ))
                }
                <PhotoLeftProgressBorder>
                    {
                        contentsImage?.map((a, i)=>(
                            <PhotoLeftProgressButton key={i} isSelected={currentImage === i} onClick={()=>{setCurrentImage(i)}}/>
                        ))
                    }
                </PhotoLeftProgressBorder>
            </PhotoContentsBorder>
            {/* 좋아요, 코멘트, 링크, 책깔피 */}
            <StatusBorder style={{marginBottom: "1.6rem"}}>
                <SnsIconBorder>
                    <SnsIcon onClick={()=>{likeHandler()}} src={data?.is_like?likeActive:like} alt="icon01"/>
                    <SnsIconText style={{marginRight: "0.4rem"}}>
                        {data?.like_count}
                    </SnsIconText>
                    <SnsIcon src={comment} alt="icon01"/>
                    <SnsIconText>
                        {data?.comment_count}
                    </SnsIconText>
                </SnsIconBorder>
                <SnsIconBorder>
                    {/* <SnsIcon onClick={()=>{handleCopyUrl(data?.id)}} src={link} alt="icon01"/> */}
                    <SnsIcon onClick={()=>{favoriteHandler()}} src={data?.is_favorite?bookMarkActive:bookMark} alt="icon01"/>
                </SnsIconBorder>
            </StatusBorder>
            {/* 게시글 제목, 게시글 내용 요약 */}
            <SnsTextBorder style={{marginBottom: "1.6rem"}}>
                <SnsTitleText style={{marginBottom: "0.8rem"}}>
                    {data?.lounge_title}
                </SnsTitleText>
                <SnsContentsText>
                    {/* <div dangerouslySetInnerHTML={{ __html: getDisplayedText()}}/>  */}
                    {parse(getDisplayedText())}
                    {
                        isTruncated && commentsFrom.length > 50?
                        <SnsContentsMore onClick={()=>{toggleTruncate()}}>
                            더 보기
                        </SnsContentsMore>
                        :
                        null
                    }
                </SnsContentsText>
            </SnsTextBorder>
            {/* 댓글 작성 */}
            {CommentSubmit()}
            {/* 댓글 대댓글 리스트 최초 1개~ 더보기 버튼 클릭 */}
            {
                data?.lounge_comments?.map((a, i)=>(
                    <Reply key={i} data={a}/>
                ))
            }
            {/* {
                data?.lounge_comments?.length === 0 || data?.lounge_comments?.length === 1?
                <div />
                :
                <ShowmoreButton>
                    더 보기
                </ShowmoreButton>
            } */}
        </ContentsBoarder>
    )
}

export default SnsContents;