/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { Route, Routes } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';


// 컴포넌트
import NavigationBar from './@public-components/navigation-bar';
import Main from './pages-pc/1_main/@main';
import Login from './pages-pc/2_login/@Login';
import BusinessLogin from './pages-pc/2_login/BusinessLogin';
import Signup from './pages-pc/3_signup/@Signup';
import BusinessSignup from './pages-pc/4_businesssignup/@BusinessSignup';
import Signup3Step from './pages-pc/3_signup/Signup3Step'; 
import Choice from "./pages-pc/5_choice/@choice";
import Job from "./pages-pc/7_job/@job";
import JobDetail from "./pages-pc/8_job_detail/@job-detail";
import JobAdd from "./pages-pc/8_job_detail/@job-add";
import ResumeDetail from "./pages-pc/9_resume_detail/@resume-detail";
import ResumeAdd from "./pages-pc/9_resume_detail/@resume-add";
import Community from "./pages-pc/6_community/@community";
import BasicMyPage from "./pages-pc/11_mypage/@basic-mypage";
import MyResume from "./pages-pc/11_mypage/mypage-myresume";
import MyJobList from "./pages-pc/11_mypage/mypage-myjoblist";
import MyComuPost from "./pages-pc/11_mypage/mypage-mycomupost";
import MyComuFavoritePost from "./pages-pc/11_mypage/mypage-myfavoritecomu";
import NoticeBoard from "./pages-pc/11_mypage/mypage-noticeboard";
import NoticeBoardPost from "./pages-pc/11_mypage/mypage-noticeboard-post";
import EventBoard from "./pages-pc/11_mypage/mypage-eventboard";
import EventBoardPost from "./pages-pc/11_mypage/mypage-eventboard-post";
import Question1on1 from "./pages-pc/11_mypage/mypage-1on1questionboard";
import BusinessMyPage from "./pages-pc/12_business_mypage/@business-mypage";
import BusinessMyJob from "./pages-pc/12_business_mypage/business-mypage-myjoblist";
import BusinessMyResume from "./pages-pc/12_business_mypage/business-mypage-myresmuelist";
import FAQ from "./pages-pc/11_mypage/mypage-faqquestionboard";
import MessageBox from "./pages-pc/10_message/@message";
import Footer from "./@public-components/footer";
import FindIDPW from './pages-pc/2_login/find-idpw';



//모바일 컴포넌트
import NavigationBarMob from './@public-components/navigation-bar-mob';
import MainMob from './pages-mob/1_main_mob/@main-mob';
import LoginMob from './pages-mob/2_login_mob/@Login-mob';
import BusinessLoginMob from './pages-mob/2_login_mob/BusinessLogin-mob';
import SignupMob from './pages-mob/3_signup_mob/@Signup-mob';
import BusinessSignupMob from './pages-mob/4_businesssignup_mob/@BusinessSignup-mob';
import Signup3StepMob from './pages-mob/3_signup_mob/Signup3Step-mob'; 
import FooterMob from "./@public-components/footer-mob";
import ChoiceMob from "./pages-mob/5_choice_mob/@choice-mob";
import CommunityMob from "./pages-mob/6_community_mob/@community-mob";
import JobMob from "./pages-mob/7_job_mob/@job-mob";
import JobDetailMob from "./pages-mob/8_job_detail_mob/@job-detail-mob";
import JobAddMob from "./pages-mob/8_job_detail_mob/@job-add-mob";
import ResumeDetailMob from "./pages-mob/9_resume_detail_mob/@resume-detail-mob";
import ResumeAddMob from "./pages-mob/9_resume_detail_mob/@resume-add-mob";
import BasicMypageMob from "./pages-mob/11_mypage_mob/@basic-mypage-mob";
import MyResumeMob from "./pages-mob/11_mypage_mob/mypage-myresume-mob";
import MyJobListMob from "./pages-mob/11_mypage_mob/mypage-myjoblist-mob";
import MyComuPostMob from "./pages-mob/11_mypage_mob/mypage-mycomupost-mob";
import MyComuFavoritePostMob from "./pages-mob/11_mypage_mob/mypage-myfavoritecomu-mob";
import NoticeBoardMob from "./pages-mob/11_mypage_mob/mypage-noticeboard-mob";
import NoticeBoardPostMob from "./pages-mob/11_mypage_mob/mypage-noticeboard-post-mob";
import EventBoardMob from "./pages-mob/11_mypage_mob/mypage-eventboard-mob";
import EventBoardPostMob from "./pages-mob/11_mypage_mob/mypage-eventboard-post-mob";
import Question1on1Mob from "./pages-mob/11_mypage_mob/mypage-1on1questionboard-mob";
import FAQMob from "./pages-mob/11_mypage_mob/mypage-faqquestionboard-mob";
import BusinessMyPageMob from "./pages-mob/12_business_mypage_mob/@business-mypage-mob";
import BusinessMyJobMob from "./pages-mob/12_business_mypage_mob/business-mypage-myjoblist-mob";
import BusinessMyResumeMob from "./pages-mob/12_business_mypage_mob/business-mypage-myresmuelist-mob";
import MessageBoxMob from "./pages-mob/10_message_mob/@message-mob";
import CommunityPost from "./pages-pc/6_community/community-post";
import CommunityPostMob from "./pages-mob/6_community_mob/community-post-mob";
import FindIDPWMob from './pages-mob/2_login_mob/find-idpw-mob';
import { useDispatch, useSelector } from "react-redux";
import CommunityAdd from "./pages-pc/6_community/community-add";
import CommunityEdit from "./pages-pc/6_community/community-edit";

import CommunityAddMob from "./pages-mob/6_community_mob/community-add-mob";


import MycomupostComment from "./pages-pc/11_mypage/mypage-mycomupost-comment";
import MycomupostCommentMob from "./pages-mob/11_mypage_mob/mypage-mycomupost-comment-mob";
import MycomupostFavorite from "./pages-pc/11_mypage/mypage-mycomupost-favorite";
import MycomupostFavoriteMob from "./pages-mob/11_mypage_mob/mypage-myfavoritecomu-mob";
import ResumeEdit from "./pages-pc/9_resume_detail/@resume-edit";
import JobEdit from "./pages-pc/8_job_detail/@job-edit";

import JobEditMob from "./pages-mob/8_job_detail_mob/@job-edit-mob";


import axios from "axios";
import { Logined, Logout } from "./redux/slice/login-slice";
import MypageEditUserInfo from "./pages-pc/11_mypage/mypage-edit-user-info";
import MypageEditBusinessInfo from "./pages-pc/11_mypage/mypage-edit-business-info";

import MypageEditUserInfoMob from "./pages-mob/11_mypage_mob/mypage-edit-user-info-mob";
import MypageEditBusinessInfoMob from "./pages-mob/11_mypage_mob/mypage-edit-business-info-mob";

import Naver from "./@components/naver";
import Policy02 from "./@public-components/policy02";


const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  html {
    font-size: 62.5%;
    // font-size: 60%;
    line-height: 1.285;
    font-family: 'Pretendard-Regular';
  }
  body {
    font-size: 1.6rem;
    line-height: 1.285;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Pretendard-Regular';
  }
  p {
    margin: 0;
  }
`;

const ContentsBorder = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

function App() {
  const location = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  let midMainager = useSelector((state)=>state.midnightLoginManager);
  let [clientType, setClientType] = useState('');
  let navigate = useNavigate();
  let [deviceType, setDeviceType] = useState('');
  let [browserType, setBrowserType] = useState('');
  let dispatch = useDispatch();

  useEffect(() => {
    // 현재 날짜와 시간을 가져오기
    const today = new Date();
    const visitDate = today.toISOString().split('T')[0];

    // IP 주소를 가져오기 (여기서는 외부 API를 사용해야 하므로, 예시로 fetch 사용)
    const fetchIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        const ip = response.data.ip;

        // API 요청 보내기
        axios.post(`https://api.midtrc.com/visit?visit_date=${visitDate}&ip=${ip}`,{})
          .then((res) => {
            // console.log(res.data);
          })
          .catch((error) => {
            
          });
      } catch (error) {
        console.log('Failed to fetch IP:', error);
      }
    };

    fetchIP();
  }, []);


  useEffect(()=>{
    setClientType(midMainager?.log_data?.client_type === null || midMainager?.log_data?.client_type === undefined? null:midMainager?.log_data?.client_type) // 권한에 따른 view 때문에 클라이언트 타입을 최초에 가져옵니다
    
  },[midMainager?.log_data, location]);


  useEffect(()=>{
    if(midMainager?.is_login){
      axios.get('https://api.midtrc.com/auth/token',{
          headers: {
            'access-token': midMainager?.log_data?.access_token
        }
      }).then((res)=>{
      }).catch((error)=>{
        isLoginedHandler()
      })
    }
  },[])

  const isLoginedHandler = () => {
    // 토큰이 유효아지 않은데 로그인 기억하기가 true인경우 재 로그인
    const userAgent = navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setDeviceType(isMobile ? 'Mobile' : 'Desktop');

    let browserTypeInst = '';

    if (userAgent.indexOf("Firefox") > -1) {
        browserTypeInst = "Mozilla Firefox";
    } else if (userAgent.indexOf("Chrome") > -1) {
        browserTypeInst = "Google Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
        browserTypeInst = "Apple Safari";
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
        browserTypeInst = "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
        browserTypeInst = "Microsoft Internet Explorer";
    }
    setBrowserType(browserTypeInst);

    if(midMainager?.is_login){
      const requestBody = {
        "signup_type": "general",
        "login_id": midMainager?.id,
        "pwd": midMainager?.pw,
        "latest_device": deviceType,
        "latest_browser": browserType
      }

      axios.post('https://api.midtrc.com/auth/login', requestBody)
      .then((res)=>{
        dispatch(Logined(res.data));
        navigate('/');
        if(res.data.status === 'day_paused'){
          alert('1일간 사용 금지 된 계정입니다.');
          localStorage.removeItem("com.naver.nid.access_token"); 
          dispatch(Logout());
        }if(res.data.status === 'week_paused'){
          alert('1주일간 사용 금지 된 계정입니다.');
          localStorage.removeItem("com.naver.nid.access_token"); 
          dispatch(Logout());
        }if(res.data.status === 'month_paused'){
          alert('한달간 사용 금지 된 계정입니다.');
          localStorage.removeItem("com.naver.nid.access_token"); 
          dispatch(Logout());
        }
      }).catch((error)=>{alert(error)})
    }else{ // 로그인 저장하기가 아닌 경우 캐쉬 데이터 모두 지운다
      dispatch(Logout());
    }
  }

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[location])

  return (
    <div>
      <GlobalStyle resize={1920}/>
      {
        location.pathname !== '/signup' &&
        location.pathname !== '/login' && 
        location.pathname !== '/findidpw' && 
        location.pathname !== '/business-signup' && 
        location.pathname !== '/business-login' && 
        location.pathname !== '/welcome' &&
        (isMobile ? <NavigationBarMob /> : <NavigationBar clientType={clientType}/>)
      }
      <ContentsBorder>
        <Routes>
          {/* sns로그인 */}
          <Route path='/naver' element={<Naver/>} />
          <Route path='/confirm' element={isMobile ? <MainMob /> : <Main />} />
          <Route path='/policy' element={<Policy02 />} />

          <Route path='/' element={isMobile ? <MainMob /> : <Main />} />
          <Route path='/login' element={isMobile ? <LoginMob /> : <Login />} />
          <Route path='/findidpw' element={isMobile ? <FindIDPWMob /> :  <FindIDPW />} />
          <Route path='/business-login' element={isMobile ? <BusinessLoginMob /> :<BusinessLogin />} />
          <Route path='/signup' element={isMobile ? <SignupMob /> : <Signup />} />
          <Route path='/welcome' element={isMobile ? <Signup3StepMob /> : <Signup3Step />} /> 
          <Route path='/business-signup' element={isMobile ? <BusinessSignupMob /> : <BusinessSignup />} /> 

          {/* 초이스톡 */}
          <Route path='/choice-talk' element={isMobile ? <ChoiceMob /> :  <Choice />} /> 
          
          {/* 구인구직 */}
          <Route path='/job' element={isMobile ? <JobMob clientType={clientType}/> :  <Job clientType={clientType}/>} /> 
          <Route path='/job-detail/:id' element={isMobile ? <JobDetailMob /> : <JobDetail />} /> 
          <Route path='/job-add' element={isMobile ? <JobAddMob clientType={clientType}/> : <JobAdd clientType={clientType}/>} /> 
          <Route path='/resume-detail/:id' element={isMobile ? <ResumeDetailMob /> :<ResumeDetail />} /> 
          <Route path='/resume-add' element={isMobile ? <ResumeAddMob clientType={clientType}/> :<ResumeAdd clientType={clientType}/>} /> 
          
          {/* 커뮤니티 */}
          <Route path='/community' element={isMobile ? <CommunityMob /> :  <Community />} /> 
          <Route path='/community/:id' element={isMobile ? <CommunityMob /> :  <Community />} /> {/* sns게시글 위치가 저장됨 */}
          <Route path='/community-add/:id' element={isMobile ? <CommunityAddMob /> :  <CommunityAdd />} /> 
          <Route path='/community-post/:id' element={isMobile ? <CommunityPostMob /> :  <CommunityPost />} /> 
          <Route path='/community-edit/:id' element={<CommunityEdit />} /> 

          {/* 공통회원 마이페이지 */}
          <Route path='/mycomupost' element={isMobile ?<MyComuPostMob />:<MyComuPost />} /> 
          <Route path='/mycomupost-comment' element={isMobile ?<MycomupostCommentMob />:<MycomupostComment />} /> {/* 내가 작성한 댓글이 있는 콘텐츠 보여지기 */}
          <Route path='/mycomupost-favorite' element={isMobile ?<MycomupostFavoriteMob />:<MycomupostFavorite />} /> 
          {/* <Route path='/myfavoritecomupost' element={isMobile ?<MyComuFavoritePostMob />:<MyComuFavoritePost />} />  */}
          <Route path='/message/:id' element={isMobile ?<MessageBoxMob />:<MessageBox />} /> {/* 즐겨찾기 한 채팅방 */}

          <Route path='/notice-board' element={isMobile ?<NoticeBoardMob />:<NoticeBoard />} /> 
          <Route path='/noticeboard-post/:id' element={isMobile ?<NoticeBoardPostMob />:<NoticeBoardPost />} /> 
          <Route path='/event-board' element={isMobile ?<EventBoardMob />:<EventBoard />} /> 
          <Route path='/eventboard-post/:id' element={isMobile ?<EventBoardPostMob />:<EventBoardPost />} /> 
          <Route path='/my-question' element={isMobile ?<Question1on1Mob />:<Question1on1 isRequest={false} />} /> 
          <Route path='/request' element={isMobile ?<Question1on1Mob />:<Question1on1 isRequest={true}/>} /> 
          <Route path='/faq' element={isMobile ?<FAQMob />:<FAQ />} /> 

          {/* 일반회원 마이페이지 */}
          <Route path='/mypage' element={isMobile ?<BasicMypageMob />:<BasicMyPage />} /> 
          <Route path='/myresume' element={isMobile ?<MyResumeMob />: <MyResume />} />
          <Route path='/myresume-edit/:id' element={<ResumeEdit clientType={clientType}/>} /> {/* 이력서 편집/수정 */}
          <Route path='/myjoblist' element={isMobile ?<MyJobListMob />:<MyJobList />} />
          <Route path='/my-user-info' element={isMobile ?<MypageEditUserInfoMob />:<MypageEditUserInfo />} />

          {/* 사업자회원 마이페이지 */}
          <Route path='/businessmypage' element={isMobile ?<BusinessMyPageMob />: <BusinessMyPage />} /> 
          <Route path='/business-myjob' element={isMobile ?<BusinessMyJobMob />:<BusinessMyJob />} />
          <Route path='/job-edit/:id' element={isMobile ?<JobEditMob clientType={clientType}/>:<JobEdit clientType={clientType}/>} />
          <Route path='/business-myresume' element={isMobile ?<BusinessMyResumeMob />:<BusinessMyResume />} />
          <Route path='/my-business-info' element={isMobile ?<MypageEditBusinessInfoMob />:<MypageEditBusinessInfo />} />

          {/* 메시지 함 */}
          <Route path='/message' element={isMobile ?<MessageBoxMob />:<MessageBox />} />

        </Routes>
      </ContentsBorder>
      {
        location.pathname !== '/signup' &&
        // location.pathname !== '/login' && 
        location.pathname !== '/findidpw' && 
        location.pathname !== '/business-signup' && 
        location.pathname !== '/business-login' && 
        location.pathname !== '/welcome' &&
        (isMobile ? <FooterMob /> : <Footer />)
      }
    </div>
  );
}

export default App;
