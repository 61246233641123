/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MessageRoom from "./message-room";
import MessageWindow from "./message-window";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

// font-family: 'Pretendard-Regular';
// font-weight: 600;
// font-size: 2.8rem;

let ColorBorder = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
    box-sizing: border-box;
    padding-top: 18rem;
    padding-bottom: 6.4rem;
`

let MessageBorder = styled.div`
    width: 104rem;
    background-color: white;
    box-sizing: border-box;
    padding: 3.2rem;
`
    
let TextButtonBorder = styled.div`
    display: flex;
    column-gap: 2.4rem;
    height: 2.4rem;
    align-itemc: center;
    margin-bottom: 3.2rem;
`

let TextButton = styled.button`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2rem;
    color: ${props=>props.isSelected? "#171717":"#9E9E9E"};
    box-sizing: border-box;
    padding: 0px;
    background: none;
    border: none;
    cursor: pointer;
`

let ContentsBorder = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

const MessageBox = () => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [isSelected, setIsSelected] = useState(0);
    let [clickedRoom, setClickedRoom] = useState('');
    let [clickedProfile, setClickedProfile] = useState();
    let [messageData, setMessageData] = useState([]);
    let [isChange, setIsChange] = useState(false);
    let [roomIndex, setRoomIndex] = useState();

    let params = useParams();

    let navigate = useNavigate();
    
    useEffect(()=>{
        if(midMainager?.log_data?.client_type === null || midMainager?.log_data?.client_type === undefined){
            navigate('/login')
        }
    },[]);

    // 메시지 그룹 클릭하면 메시지 데이터를 가져오는 함수
    useEffect(()=>{
        if(clickedRoom === ''){
            // 선택된 메시지 그룹이 없는 경우
            setMessageData([]);
        }else{
            const fetchData = async () => {
                try {
                    const response = await axios.get(`https://api.midtrc.com/message/${clickedRoom}`,{
                        headers: {
                            'access-token': midMainager?.log_data?.access_token
                        }
                    })
                    let instList = response.data
                    setMessageData(instList);

                    if(params.id === 'favorite'){
                        setIsSelected(2)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            fetchData();
        }

    },[clickedRoom, isChange])

    // 메시지 클릭 시 메시지 콘텍트 아이디 가져오는 함수
    const clickedRoomHandler = (e) => {
        setClickedRoom(e);
    }

    const profile = (e) => {
        setClickedProfile(e)
    }

    const changeData = () => {
        setIsChange(!isChange)
    }

    const roomIndexHandler = (e) => {
        setRoomIndex(e)
    }


    return(
        <ColorBorder>
            <MessageBorder>
                    {
                        params.id === 'favorite'?
                        <TextButtonBorder>
                            <TextButton isSelected={isSelected === 2} onClick={()=>{setIsSelected(2)}}>
                                즐겨찾는 메세지
                            </TextButton>
                        </TextButtonBorder>
                        :
                        <TextButtonBorder>
                            <TextButton isSelected={isSelected === 0} onClick={()=>{setIsSelected(0)}}>
                                전체 메세지
                            </TextButton>
                            <TextButton isSelected={isSelected === 1} onClick={()=>{setIsSelected(1)}}>
                                안 읽은 메세지
                            </TextButton>
                            <TextButton isSelected={isSelected === 2} onClick={()=>{setIsSelected(2)}}>
                                즐겨찾는 메세지
                            </TextButton>
                            <TextButton isSelected={isSelected === 3} onClick={()=>{setIsSelected(3)}}>
                                관리자 메세지
                            </TextButton>
                            <TextButton isSelected={isSelected === 4} onClick={()=>{setIsSelected(4)}}>
                                이벤트 메세지
                            </TextButton>
                        </TextButtonBorder>
                    }
                {/* 나중에 스위치가 들어가던, 데이터 테이블만 변경을 하던, 작동시킬 예정 */}
                <ContentsBorder>
                    <MessageRoom roomIndex={roomIndexHandler} selectedFilter={isSelected} clickedRoom={clickedRoomHandler} profile={profile} clickedRoomId={clickedRoom}/>
                    <MessageWindow roomIndex={roomIndex} changeData={changeData} data={messageData} profile={clickedProfile} />
                </ContentsBorder>
            </MessageBorder>
        </ColorBorder>
    )
}

export default MessageBox;