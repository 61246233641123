/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MyResumeCard from "./mypage-myresume-card";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

let MypageBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
`;

let MyResumeBorder = styled.div`
    margin-top: 0.8rem;
    width: 82.8rem;
    gap: 4rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    background-color: #F7F7F7;
    padding-top: 3rem;
`;

let MyResumeSection = styled.div`
    width: 82.8rem;
    padding-top: 17.8rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`;

let MypageResumeinfo = styled.div`
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 4rem;
    background-color: white;
    border-radius: 16px;
    border: 1px solid #D2D2D2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let MypageTitleBar = styled.div`
    color: #171717;
    width: 74.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center; 
`;

let MypageMyResumeTitle = styled.div`
    color: #171717;
    font-family: 'Pretendard-Regular';
    font-size: 2rem;
    font-weight: 600;
`;

let MypageMyResumeAddResume = styled.div`
    color: white;
    background-color: #CD1B1B;
    font-size: 1.6rem;
    width: 12rem;
    height: 4rem;
    border: 1px solid #CD1B1B;
    border-radius: 4px;
    font-family: 'Pretendard-Regular';
    display: flex; 
    justify-content: center; 
    align-items: center; 
    cursor: pointer;

    &:hover {
        color: #CD1B1B; 
        background-color: white; 
        border-color: #CD1B1B; 
    }
`;

let MypageMyResumedivider = styled.div`
    width: 74.8rem;
    border-bottom: 1px solid #E8E8E8;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

let MypageMyCoreResumeContainer = styled.div`
    width: 74.8rem;
    margin-top: 1rem;
`;

let MypageMyCoreResumSubtitle = styled.div`
    color: #444444;
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
`;

let MypageMyCoreResumCardContainer = styled.div`
    width: 74.8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

let MypageMyResumeTabContainer = styled.div`
    width: 74.8rem;
    margin-top: 1rem;
    display: flex;
    gap: 3rem;
`;

let MypageMyResumeTabtitle = styled.div`
    color: ${({ active }) => (active ? '#444444' : '#9E9E9E')};
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    cursor: pointer;
`;

let MypageMyResumeContainerWriting = styled.div`
    width: 74.8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

let MypageMyResumeContainerGetWork = styled.div`
    width: 74.8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

let MypageMyResumeContainerGetWorked = styled.div`
    width: 74.8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

const MyResume = () => {
    const [activeTab, setActiveTab] = useState("writing");
    let navigate = useNavigate();
    let [isChange, setIsChange] = useState(false);
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [showData, setShowData] = useState([]) // 대표이력서
    let [activeData, setActiveData] = useState([]);
    let [pauseData, setPauseData] = useState([]);
    let [writingData, setWritingData] = useState([]);

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/mypage/resume',{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instList = response.data;
                let filterRepresent = instList.filter(obj=>obj.status === 'represent');
                 // 호출 된 이력서 리스트 데이터
                
                let filterwriting = instList.filter(obj=>obj.status === 'writing'); // 작성 중
                let filterpaused = instList.filter(obj=>obj.status === 'paused'); // 마감
                let filteractive = instList.filter(obj=>obj.status === 'active'); // 구직중

                setShowData(filterRepresent);
                setActiveData(filteractive)
                setPauseData(filterpaused)
                setWritingData(filterwriting)
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    },[isChange])


    const changeData = () => {
        setIsChange(!isChange)
    }

    return (
        <MypageBackground>
            <MyResumeBorder>
                <MyResumeSection>
                    <MypageResumeinfo>
                        <MypageTitleBar>
                            <MypageMyResumeTitle>내가 작성한 이력서</MypageMyResumeTitle>
                            <MypageMyResumeAddResume onClick={()=>{navigate('/resume-add')}}>+ 이력서 작성</MypageMyResumeAddResume>
                        </MypageTitleBar>
                        <MypageMyResumedivider />
                        <MypageMyCoreResumeContainer>
                            <MypageMyCoreResumSubtitle>
                                대표 이력서
                            </MypageMyCoreResumSubtitle>
                        </MypageMyCoreResumeContainer>
                        <MypageMyCoreResumCardContainer>
                        {
                            showData?.map((a, i)=>(
                                <MyResumeCard key={i} data={a} isChange={changeData}/>
                            ))
                        }
                        </MypageMyCoreResumCardContainer>

                        <MypageMyResumeTabContainer>
                            <MypageMyResumeTabtitle
                                active={activeTab === "writing"}
                                onClick={() => setActiveTab("writing")}
                            >
                                작성 중
                            </MypageMyResumeTabtitle>
                            <MypageMyResumeTabtitle
                                active={activeTab === "gettingWork"}
                                onClick={() => setActiveTab("gettingWork")}
                            >
                                구직 중
                            </MypageMyResumeTabtitle>
                            <MypageMyResumeTabtitle
                                active={activeTab === "gotWork"}
                                onClick={() => setActiveTab("gotWork")}
                            >
                                구직 마감
                            </MypageMyResumeTabtitle>
                        </MypageMyResumeTabContainer>

                        {activeTab === "writing" && (
                            <MypageMyResumeContainerWriting>
                                {
                                    writingData?.map((a, i)=>(
                                        <MyResumeCard key={i} data={a} isChange={changeData}/>
                                    ))
                                }
                            </MypageMyResumeContainerWriting>
                        )}
                        {activeTab === "gettingWork" && (
                            <MypageMyResumeContainerGetWork>
                                {
                                    activeData?.map((a, i)=>(
                                        <MyResumeCard key={i} data={a} isChange={changeData}/>
                                    ))
                                }
                            </MypageMyResumeContainerGetWork>
                        )}
                        {activeTab === "gotWork" && (
                            <MypageMyResumeContainerGetWorked>
                                {
                                    pauseData?.map((a, i)=>(
                                        <MyResumeCard key={i} data={a} isChange={changeData}/>
                                    ))
                                }
                            </MypageMyResumeContainerGetWorked>
                        )}
                    </MypageResumeinfo>
                </MyResumeSection>
            </MyResumeBorder>
        </MypageBackground>
    );
};

export default MyResume;
