
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import more from "./../../@images/more-24.svg";
import like from "./../../@images/sns-like-black-40.svg";
import likeActive from './../../@images/like-active.svg';
import comment from "./../../@images/sns-comment-black-40.svg";
import arrow from "./../../@images/arrow-line-24.svg";
import emoji from "./../../@images/chat-emoji.svg";
import lock from "./../../@images/lock-40.svg";
import lockActive from "./../../@images/lock-active-24.svg";
import commentLike from "./../../@images/comment_like_24.svg";
import axios from "axios";
import { useSelector } from "react-redux";

// 코멘트
let CommentBorder = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: auto;
    border-radius: 0.8rem;
    border: none;
    background-color: #F7F7F7;
    padding: 1.6rem 2.4rem;
`;

let CommentsDropBorder = styled.div`
    display: flex;
    gap: 0.8rem;
`;

let CommentsDrop = styled.div`
    cursor: pointer;
    width: 14rem;
    height: 4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: white;
    position: relative;
`;

let CommentsNick = styled.input`
    width: 14rem;
    height: 4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: #E8E8E8;
    outline: none;
`

let FilterIconArrow = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
`;

let CommentsInputBorder= styled.div`
    width: 100%;
    min-height: 5.6rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 1.6rem;
    box-sizing: border-box;
    background-color: white;
    position: relative;
`;

let CommentsIconBorder = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 1.6rem;
    top: 0.8rem;
`;

let CommentsIcon = styled.img`
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    cursor: pointer;
`;

let CommentsSubmitButton = styled.button`
    height: 3.6rem;
    background-color: #CD1B1B;
    box-sizing: border-box;
    padding: 0.8rem 1.2rem;
    border: none;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: white;
    cursor: pointer;
`;

let CommentsInput = styled.textarea`
    box-sizing: border-box;
    width: 42.9rem;
    min-height: 5.6rem;
    resize: none;
    border: none;
    outline: none;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    box-sizing: border-box;
    overflow: hidden;
    padding: 1.6rem 0;
    line-height: 1.5;
`;

// 댓글
let ReplyMainBorder = styled.div`
    width: 100%;
    min-height: 11.2rem;
`;

let ReplyBorder = styled.div`
    width: 100%;
    min-height: 11.2rem;
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 0.8rem;
    box-sizing: border-box;
`;

let ReplyProfileImage = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    background-color: black;
`;

let ReplyContentsBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-contetn: right;
    box-sizing: border-box;
`;

let ReplyNickText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: #171717;
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
`;

let ReplyTimeText = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9e9e9e;
    margin-left: 0.8rem;
`;

let ReplyContentsText = styled.textarea`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #444444;
    width: 100%;
    resize: none;
    outline: none;
    border: none;
    padding: 0;
`;

let ReplyShowMore = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9e9e9e;
    cursor: pointer;
`;

let ReplyToolBorder = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    gap: 0.8rem;
    justify-content: right;
    align-items: flex-start;
`;

let ReplyIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    cursor: pointer;
`;

let ReplyCount = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
    color: #9e9e9e;
    width: 100%;
    text-align: center;
`;

let ReReplyListBorder = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;
    padding-left: 3.2rem;
`;

let DropBorder = styled.div`
    width: 14rem;
    box-sizing: border-box;
    padding: 0 2rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #dedede;
    border-width: 0.1rem;
    background-color: white;
    position: absolute;
    top: 4.5rem;
    left: 0;
    z-index: 30;
`

let DropBorderItem = styled.div`
    font-size: 1.4rem;
    text-align: left;
    padding: 1rem 0;
    &:hover{
        color: red;
    }
`

let EmojisBorder = styled.div`
    
    height: 24rem;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #dedede;
    border-width: 0.1rem;
    background-color: white;
    position: absolute;
    top: 4.5rem;
    right: 0;
    z-index: 30;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    overflow-y: auto;
    overflow-x: hidden;
`

let EmojisItems = styled.div`
    box-sizing: border-box;
    padding: 1rem;
    font-size: 1.8rem;
    cursor: pointer;
    &:hover{
        background-color: #f2f2f2;
    }
`

let ShowmoreButton = styled.button`
    width: 100%;
    height: 3rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #a9a9a9;
    background: none;
    border: none;
    cursor: pointer;
`

let PhotoImage = styled.img`
    width: 100%;
    object-fit: cover;
    background-color: #f5f5f5;
    cursor: pointer;
`

const CommentsInputSubBoxMob = ({data, lounge_id, isChange}) => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    // 코멘트 작성
    let [isRandom, setIsRandom] = useState('랜덤 닉네임');
    let [isDrop, setIsDrop] = useState(false);
    let [isSecret, setIsSecret] = useState(false);
    let [commentsNick, setCommentsNick] = useState(''); // 닉네임 서버용
    let randomFirstNick = ['파랑', '빨강', '초록', '검정', '노랑', '분홍']
    let randomSecondNick = ['곰', '토끼', '여우', '팬더', '강아지', '고양이']
    let [comments, setComments] = useState(''); // 코멘트 서버용
    let [isCommentsDrop, setIsCommentsDrop] = useState(false);
    let emojis= [
        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
        '✅', '❎', '💯',
    ]
    

    useEffect(()=>{nicknameHandler({type: '랜덤 닉네임', text: ''})},[])

    let commentsHandler = (e) => {
        setComments(e.target.value);
    }

    let commentsEmojisHandler = (e) => {
        setComments(prevComments => prevComments + e);
    }
    
    let dropRef01 = useRef();
    
    const dropRef01Handler = (event) => {
        if (dropRef01.current && !dropRef01.current.contains(event.target)) {
            setIsDrop(false);
        }else{
            setIsDrop(true);
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', dropRef01Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef01Handler);
        };
    }, []);

    let dropRef02 = useRef();
    const dropRef02Handler = (event) => {
        if (dropRef02.current && !dropRef02.current.contains(event.target)) {
            setIsCommentsDrop(false);
        }else{
            setIsCommentsDrop(true);
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', dropRef02Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef02Handler);
        };
    }, []);

    const textareaRef = useRef(null);
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [comments]);




    const nicknameHandler = (e) => {
        if(e.type === '랜덤 닉네임'){
            const firstPart = randomFirstNick[Math.floor(Math.random() * randomFirstNick.length)];
            const secondPart = randomSecondNick[Math.floor(Math.random() * randomSecondNick.length)];
            setCommentsNick(firstPart+secondPart);
        }else if(e.type === '고정 닉네임'){
            setCommentsNick(midMainager?.log_data?.nickname?midMainager?.log_data?.nickname:'')
        }else{
            setCommentsNick(e.text)
        }
    }

    const CommentSubmitHandler = () => {
        let reqeustBody = {
            "client_id": midMainager?.log_data?.id,
            "lounge_id": lounge_id,
            "nickname": commentsNick,
            "is_private": isSecret,
            "is_sub": true,
            "sub_comment_id": data.id, // 상위 댓글 아이디 값
            "tag_client_id": data.client_id, 
            "content": '@'+`${data.nickname}`+' '+comments
          }

        axios.post('https://api.midtrc.com/comment',reqeustBody,{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{
            nicknameHandler({type: '랜덤 닉네임', text: ''});
            setComments('');
            setIsSecret(false);
            isChange();
        }).catch((error)=>{console.log(error)});
    }


    return(
        <CommentBorder style={{marginBottom: "1.6rem"}}>
            {/* 코멘트 */}
            <CommentsDropBorder style={{marginBottom: "0.8rem"}}>
                    {/* 코멘트 드랍다운 */}
                    <div style={{display: "flex", gap: '0.8rem'}}>
                    <CommentsDrop ref={dropRef01} onClick={dropRef01Handler}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            {
                                isRandom === '랜덤 닉네임'?
                                <span>랜덤 닉네임</span>
                                :
                                isRandom === '고정 닉네임'?
                                <span>고정 닉네임</span>
                                :
                                <span>닉네임</span>
                            }
                        </div>
                        <FilterIconArrow style={{transform: isDrop? 'rotate(180deg)' : 'rotate(0deg)'}} src={arrow} alt="arrow"/>
                        {
                            isDrop?
                            <DropBorder>
                                <DropBorderItem onClick={(e)=>{e.stopPropagation(); setIsRandom('랜덤 닉네임'); setIsDrop(false); nicknameHandler({type: '랜덤 닉네임', text: ''})}}>랜덤 닉네임</DropBorderItem>
                                <DropBorderItem onClick={(e)=>{e.stopPropagation(); setIsRandom('고정 닉네임'); setIsDrop(false); nicknameHandler({type: '고정 닉네임', text: ''})}}>고정 닉네임</DropBorderItem>
                                <DropBorderItem onClick={(e)=>{e.stopPropagation(); setIsRandom('닉네임'); setIsDrop(false); nicknameHandler({type: '닉네임', text: ''})}}>닉네임</DropBorderItem>
                            </DropBorder>
                            :
                            <div style={{display: "none"}}/>
                        }
                    </CommentsDrop>
                    {/* 닉네인 뷰 */}
                    <CommentsNick value={commentsNick} onChange={(e)=>{nicknameHandler({type: '닉네임', text: e.target.value})}} readOnly={isRandom !== '닉네임'} placeholder="닉네임 작성" />
                </div>
                {/* {
                    isSecret?
                    <CommentsPwd type="password"/>
                    :
                    <div />
                } */}
            </CommentsDropBorder>
            {/* 코멘트 인풋 */}
            <CommentsInputBorder>
                <CommentsInput 
                ref={textareaRef} 
                value={comments} 
                onChange={commentsHandler} 
                rows="1"
                placeholder="댓글을 입력해주세요"
                />
                <CommentsIconBorder>
                    <div style={{display: "flex", alignItems: "center", position: "relative"}} ref={dropRef02} onClick={dropRef02Handler}>
                        <CommentsIcon src={emoji} alt="icon04"/>
                        {
                            isCommentsDrop?
                            <EmojisBorder >
                                {
                                    emojis.map((a, i)=>(
                                        <EmojisItems onClick={(e)=>{e.stopPropagation(); commentsEmojisHandler(a); setIsCommentsDrop(false)}} key={i}>
                                            {a}
                                        </EmojisItems>
                                    ))
                                }
                            </EmojisBorder>
                            :
                            <div style={{display: "none"}}/>
                        }
                        
                    </div>
                        <CommentsIcon onClick={()=>{setIsSecret(!isSecret)}} src={isSecret? lockActive : lock} alt="icon05" style={{marginRight: "0.8rem"}}/>
                        <CommentsSubmitButton onClick={()=>{CommentSubmitHandler()}}>
                            등록
                        </CommentsSubmitButton>
                        
                </CommentsIconBorder>
            </CommentsInputBorder>
        </CommentBorder>
    )
}

export default CommentsInputSubBoxMob;