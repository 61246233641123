import React, { useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import signuplogo from "./../@images/signupbannerlogo.svg";
import pagebackground from "./../@images/signupwelcomebackgroundimg.svg";
import signupWhiteLogo from "./../@images/signupwhitelogo.svg";
import appStoreLogo from "./../@images/appstorelogo.svg";
import googlePlayLogo from "./../@images/googleplaylogo.svg";
import { useNavigate } from "react-router-dom";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #000;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: white;
  }
`;

const PageWrapper = styled.div`
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: -1;
    z-index: 100;
    width: 102%;
    height: 100vh;
    background: url(${pagebackground}) no-repeat center center;
    background-size: cover;
    font-family: 'Pretendard-Regular';
`;

const Banner = styled.div`
  width: 100%;
  height: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignupLogo = styled.img`
  width: 19.4rem;
  height: 2.6rem;
`;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 9rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Logo = styled.img`
  width: 15rem;
  margin-bottom: 2rem;
`;

const WhiteLogo = styled.img`
  width: 6rem;
  margin: 2rem 0;
`;

const Title = styled.p`
  font-size: 3.2rem;
  margin-bottom: 1rem;
  line-height:4.5rem;
`;

const Subtitle = styled.p`
  font-size: 2.4rem;
  margin-bottom: 4rem;
`;

const Button = styled.button`
  background-color: white;
  color: #F32E13;
  border: none;
  padding: 1rem 2rem;
  font-size: 2rem;
  font-weight:600;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-bottom: 6rem;
  width:40.4rem;
  height:6.4rem;
`;

const DownloadSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;

const DownloadText = styled.p`
  margin-bottom: 2rem;
  font-size:1.6rem;
`;

const DownloadButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const DownloadButton = styled.div`
  display: flex;
  align-items: center;
  background-color: #27252A;
  border-radius: 4px;
  cursor: pointer;
  width: 40.4rem;
  height:6.4rem;
  margin: 0.5rem 0;
  font-size:1.8rem;

  img {
    width: 3.2rem;
    margin-right: 1rem;
    margin-left:9.5rem;
  }

  p {
    flex: 1;
    margin: 0;
    text-align: left;
  }
`;


const Naver = () => {
    let navigate = useNavigate();
    

    return (
        <>
          <GlobalStyle />
          <PageWrapper>
            <Banner>
              <SignupLogo src={signuplogo} alt="Signup Logo" />
            </Banner>
            <Container>
              <WhiteLogo src={signupWhiteLogo} alt="White Logo" />
              <Title>미드나잇 테라스<br></br>회원가입을 환영합니다!</Title>
              <Subtitle>언제나 회원님의 즐겁고 편안한 쉼터가 되길 바랍니다.</Subtitle>
              <Button onClick={() => navigate('/')}>미드나잇 테라스 바로 입장하기</Button>
              <DownloadSection>
                <DownloadText>일부 특별한 기능은 앱에서만 이용이 가능해요!</DownloadText>
                <DownloadButtons>
                  <DownloadButton>
                    <img src={appStoreLogo} alt="App Store Logo" />
                    <p>앱스토어 앱 다운로드</p>
                  </DownloadButton>
                  <DownloadButton>
                    <img src={googlePlayLogo} alt="Google Play Logo" />
                    <p>플레이스토어 앱 다운로드</p>
                  </DownloadButton>
                </DownloadButtons>
              </DownloadSection>
            </Container>
          </PageWrapper>
        </>
      );
    };

export default Naver;