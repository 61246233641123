/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import logo from "./../@images/nv_large_logo.svg";
import nvMenu from "./../@images/nv-menu-32.svg";
import nvSmallLogo from "./../@images/nv_small_logo.svg";

import nvSearch from "./../@images/nv-search-32.svg";
import nvAdd from "./../@images/nv-add-32.svg";
import nvMessageDefault from "./../@images/nv-message-default.svg";
import nvBellDefault from "./../@images/nv-bell-default.svg";
import nvProfile from "./../@images/nv-profile.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import profileIcon1 from "./../@images/profile_icon1.svg";
import profileIcon2 from "./../@images/profile_icon2.svg";
import profileIcon3 from "./../@images/profile_icon3.svg";
import profileIcon4 from "./../@images/profile_icon4.svg";
import profileIcon5 from "./../@images/profile_icon5.svg";
import { Logout } from "../redux/slice/login-slice";

let NavBarBorder = styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    background-color: white;
`

let NavBar = styled.div`
    width: 100%;
    height: 9.8rem; /* 뷰포트의 너비에 따라 높이를 계산 */
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
`

let NavBarLogo = styled.img`
    height: 3.4rem;
`

let NavBarMenu = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: 0px 2px 2px rgba(0,0,0,0.1)
`

let NavBarMenuBorder = styled.div`
    width: 130rem;
    height: 8rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0rem 2.4rem 0rem 2.4rem;
`

let NavBarLeft = styled.div`
    width: 24.2rem;
    height: 3.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

let NavBarMenuIcon = styled.img`
    height: 3.2rem;
    width: 3.2rem;
    object-fit: cover;
    cursor: pointer;
    overflow: hidden;
    border-radius: 1.6rem;
`

let NavBarSmallLogo = styled.img`
    height: 2.6rem;
    cursor: pointer;
`

let NavBarCenter = styled.div`
    width: 23.4rem;
    height: 3.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

let NavMenuButton = styled.button`
    font-family: 'Pretendard-Regular';
    font-weight: 700;
    font-size: 1.6rem;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    color: #171717;
    cursor: pointer;

    &:hover{
        font-weight: 800;
        color: #CD1B1B;
    }
`

let NavBarRight = styled.div`
    width: 22.4rem;
    height: 3.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`

let NavBorder = styled.div`
    height: auto;
    width: 60%;
    background-color: white;
    position: absolute;
    top: 4rem;
    right: 0;
    box-sizing: border-box;
    border-radius: 0.4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #dedede;
    padding: 1rem 0;
`

let NavBorderItems = styled.div`
    width: 100%;
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 500;
    box-sizing: border-box;
    display: flex;
    justify-content: left;
    padding: 1rem 2rem;
    cursor: pointer;
    &:hover{
        color: red;
    }
`

const NavigationBar = () => {
    let navigate = useNavigate();
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let dispatch = useDispatch();
    let [drop, setDrop] = useState(false);

    const ProfileImageHandler = () => {
        let instProfile = midMainager?.log_data?.profile_url;
        if(instProfile === '01'){
            return profileIcon1;
        }else if(instProfile === '02'){
            return profileIcon2;
        }else if(instProfile === '03'){
            return profileIcon3;
        }else if(instProfile === '04'){
            return profileIcon4;
        }else if(instProfile === '05'){
            return profileIcon5;
        }else{
            return instProfile
        }
    }
    
    let dropRef01 = useRef();
    const dropRef01Handler = (event) => {
        if (dropRef01.current && !dropRef01.current.contains(event.target)) {
            setDrop(false)
        }else{
            setDrop(true)
        }
    }

    // 지역 드랍다운 박스 useEffect
    useEffect(() => {
        document.addEventListener('mousedown', dropRef01Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef01Handler);
        };
    }, []);


    const handleCopyUrl = () => {
        const currentUrl = window.location.href;
        
        navigator.clipboard.writeText(currentUrl).then(() => {
          alert('URL로 친구를 초대 해보세요');
        }).catch(err => {
          console.error('URL 복사에 실패했습니다: ', err);
        });
    };


    return(
            <NavBarBorder>
                <NavBar >
                    <NavBarLogo alt="logo" src={logo} />
                </NavBar>
                <NavBarMenu>
                    <NavBarMenuBorder>
                        {/* 좌측 항목 묶음 */}
                        <NavBarLeft>
                            <NavBarMenuIcon alt="menu-icon" src={nvMenu}/>
                            <NavBarSmallLogo alt="menu-logo" src={nvSmallLogo} onClick={()=>{navigate('/')}}/>
                        </NavBarLeft>
                        {/* 중앙 메뉴 묵음 */}
                        <NavBarCenter>
                            <NavMenuButton onClick={()=>{navigate('/choice-talk')}}>
                                초이스톡
                            </NavMenuButton>
                            <NavMenuButton onClick={()=>{navigate('/community')}}>
                                라운지
                            </NavMenuButton>
                            <NavMenuButton onClick={()=>{navigate('/job')}}>
                                구인구직
                            </NavMenuButton>
                        </NavBarCenter>
                        {
                            !midMainager?.log_data?.access_token?
                            <NavBarRight style={{justifyContent: "right"}}>
                                <NavMenuButton onClick={()=>{navigate('/login')}}>
                                    로그인
                                </NavMenuButton>
                            </NavBarRight>
                            :
                            <NavBarRight>
                                <NavBarMenuIcon alt="menu-search" src={nvSearch}/>
                                <NavBarMenuIcon alt="menu-add" onClick={()=>{handleCopyUrl()}} src={nvAdd}/>
                                <NavBarMenuIcon alt="menu-message-default" src={nvMessageDefault} onClick={()=>{navigate('/message')}}/>
                                <NavBarMenuIcon alt="menu-bell-default" src={nvBellDefault}/>
                                <div ref={dropRef01} onClick={dropRef01Handler} style={{marginTop: '0.4rem'}}>
                                    <NavBarMenuIcon alt="menu-profile" src={ProfileImageHandler()} />
                                    {
                                        drop?
                                        <NavBorder>
                                            <NavBorderItems onClick={(e)=>{e.stopPropagation(); navigate(midMainager?.log_data?.client_type === 'user'?'/mypage':midMainager?.log_data?.client_type === 'business'?'/businessmypage':'/'); setDrop(false)}}>
                                                마이페이지
                                            </NavBorderItems>
                                            <NavBorderItems onClick={(e)=>{
                                                    e.stopPropagation(); 
                                                    localStorage.removeItem("com.naver.nid.access_token"); 
                                                    // window.location.reload(); 
                                                    dispatch(Logout());
                                                    navigate('/login')
                                                }}>
                                                로그아웃
                                            </NavBorderItems>
                                        </NavBorder>
                                        :
                                        <div style={{display: "none"}}/>
                                    }
                                </div>
                            </NavBarRight>
                        }
                        
                    </NavBarMenuBorder>
                </NavBarMenu>
                {/* <NavBorder>
                    
                </NavBorder> */}
            </NavBarBorder>
            
    )
}

export default NavigationBar;