/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import kebabmenubtn from "./../../@images/kebabmenuicon.svg";
import mypagecheckbox from "./../../@images/mypage-checkedbox.svg";
import mypageuncheckbox from "./../../@images/mypage-uncheckedbox.svg";
import mypagenewmessageicon from "./../../@images/mypage-newmessageicon.svg";
import mypagemenuarrow from "./../../@images/arrow.svg";
import mypageterracenoticeicon from "./../../@images/mypage-noticetitleicon.svg";
import mypagegotolinkicon from "./../../@images/mypage-gotoicon.svg";
import mypagecsicon from "./../../@images/mypage-cscentericon.svg";

import profileIcon1 from "./../../@images/profile_icon1.svg";
import profileIcon2 from "./../../@images/profile_icon2.svg";
import profileIcon3 from "./../../@images/profile_icon3.svg";
import profileIcon4 from "./../../@images/profile_icon4.svg";
import profileIcon5 from "./../../@images/profile_icon5.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

let MypageBackground = styled.div`
    width:100%;
    height:100%;
    background-color:#F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
`;

let MypageBorder = styled.div`
    margin-top: 0.8rem;
    width: 104rem;
    display: flex;
    gap: 4rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    background-color:#F7F7F7;
    padding-top:3rem;
`;

let MypageCusborder = styled.div`
    width: 38.4rem;
    padding-top: 17.8rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`;



let MypageCusinfo = styled.div`
    padding-top: 4rem;
    padding-left:2rem;
    padding-right:2rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 4rem;
    background-color:white;
    border-radius: 16px;
    border: 1px solid #D2D2D2;
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;


let KebabMenuButton = styled.div`
    position:absolute;
    right:1.5rem;
    top:1.5rem;
    cursor:pointer;
`;

let MypageProfileImg = styled.img`
    width:14rem;
    height:14rem;
    background-color: #f2f2f2;
    margin-top:1.5rem;
    border-radius: 32px;
`;

let MypageMyID = styled.div`
    color: black;
    font-size:2.4rem;
    font-weight:600;
    box-sizing: border-box;
    text-align:center;
    font-family: 'Pretendard-Regular';
    margin-top: 2rem;
`;

let MypageMyemail = styled.div`
    color: black;
    font-size:1.6rem;
    box-sizing: border-box;
    text-align:center;
    font-family: 'Pretendard-Regular';
    margin-top:1rem;
    margin-bottom:1rem;
    background-color:#F7F7F7;
    width: 20.9rem;
    height:4rem;
    border-radius: 24px;
    display:flex;
    justify-content:center;
    align-items:center;
`;

let MypageRandomCheckContainer = styled.div`
    display:flex;
    gap:0.8rem;
    font-family: 'Pretendard-Regular';
    margin-top: 1.5rem;
`;

let MypageCheckBtn = styled.img`
    margin-top: 0.1rem;
    width:1.2rem;
    height:1.2rem;
    cursor:pointer;
`;

let MypageRandomChecklabel = styled.div`
    font-size:1.2rem;
    color:#6A6A6A;
    font-family: 'Pretendard-Regular';
`;

let MypageMyinfodivider = styled.div`
    width:32rem;
    border-bottom: 1px solid #E8E8E8;
    margin-top:2rem;
    margin-bottom:2rem;
`;

let MypageMessageTitle = styled.div`
    display:flex;
    gap:0.8rem;
    font-family: 'Pretendard-Regular';
    margin-top: 1.5rem;
    margin-bottom:1.5rem;
`;

let MypageMenuBigTitle = styled.div`
    font-size:1.8rem;
    font-weight:600;
    color:#171717;
    font-family: 'Pretendard-Regular';
    cursor:pointer;
`;

let MypageNewMessageTag = styled.img`
    width:2.2rem;
    height:2.2rem;
`;

let MypageMyInfoMenuList = styled.div`
    box-sizing: border-box;
    padding-left:2rem;
    width:32rem;
`;

let MypageFoldingMenuBar = styled.div`
    display:flex;
    justify-content: space-between;
    width:32rem;
    position:relative;
    cursor: pointer;
`;

let MypageArrow = styled.img`
    position:absolute;
    right:2rem;
    width:2.4rem;
    height:2.4rem;
    transition: transform 0.3s ease;
    transform: ${props => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

let MypageFoldedMenuList = styled.div`
    box-sizing: border-box;
    padding-left:2rem;
    width:32rem;
    font-size:1.6rem;
    color:#6a6a6a;
    font-family: 'Pretendard-Regular';
    max-height: ${props => (props.expanded ? '1000px' : '0')};
    overflow: hidden;
    transition: max-height 0.5s ease;
`;

let MypageFoldedMenu = styled.div`
    width:32rem;
    font-size:1.6rem;
    color:#6a6a6a;
    margin-top:2.5rem;
    cursor: pointer;
`;

let MypageMenulist = styled.div`
    padding-top: 3rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 2rem;
    background-color:white;
    border-radius: 16px;
  
    border: 1px solid #D2D2D2;
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let MypageMenuborder = styled.div`
    width: 61.6rem;
    padding-top: 17.8rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`;

let MypageMenusectiontitlebar = styled.div`
    width: 53.6rem;
    color: #171717;
    display:flex;
    gap:0.8rem;
    margin-bottom:2.5rem;
`;

let Mypagenoticeicon = styled.img`
    width:4rem;
    height:4rem;
    margin-top:-0.8rem;
`;

let MypageMenusectiontitle = styled.div`
    color:#171717;
    font-size:2rem;
    font-family: 'Pretendard-Regular';
    font-weight:600;
`;

let MypageMenudivider = styled.div`
    width:53.6rem;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom:2rem;
`;

let MypageMenusectionsubmenucontainer = styled.div`
    width: 53.6rem;
`;
let MypageMenusectionsubmenu = styled.div`
    display:flex;
    position:relative;
    justify-content:space-between;
    margin-top:1rem;
    margin-bottom:2rem;
    cursor: pointer;
`;
let MypageMenusectionsubmenutitle = styled.div`
    font-size:1.6rem;
    font-family: 'Pretendard-Regular';
    color:#444444;
    cursor: pointer;
`;

let Mypagegotoicon = styled.img`
    width:2.4rem;
    height:2.4rem;
    cursor:pointer;
`;

let MypageMenulist2 = styled.div`
    margin-top:4rem;
    padding-top: 3rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 2rem;
    background-color:white;
    border-radius: 16px;
  
    border: 1px solid #D2D2D2;
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let DropBorder = styled.div`
    position: absolute;
    min-width: 14rem;
    height: auto;
    max-height: 30rem;
    right: 0px;
    top: 4rem;
    z-index: 30;
    background-color: white;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #E8E8E8;
    border-width: 0.1rem;
    overflow: auto;
    text-align: left;
    &::-webkit-scrollbar {
        width: 12px; /* 너비 설정 */
      }
    &::-webkit-scrollbar-thumb {
        background: #CBCED5;
        border-radius: 6px;
    }
`

let DropDownItems = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: grey;
    box-sizing: border-box;
    padding: 0 2rem;
    height: 3.4rem;
    &:hover{
        color: black;
    }
`



const Mypage = () => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(true);
    const [isActivityMenuOpen, setIsActivityMenuOpen] = useState(false);
    const [isJobMenuOpen, setIsJobMenuOpen] = useState(false);
    const [randomNick, setRandomNick] = useState('');
    let randomFirstNick = ['파랑', '빨강', '초록', '검정', '노랑', '분홍']
    let randomSecondNick = ['곰', '토끼', '여우', '팬더', '강아지', '고양이']
    let [drop, setDrop] = useState(false);
    
    useEffect(()=>{
        if(midMainager?.log_data?.client_type === null || midMainager?.log_data?.client_type === undefined){
            navigate('/login')
        }
    },[]);

    useEffect(()=>{
        const firstPart = randomFirstNick[Math.floor(Math.random() * randomFirstNick.length)];
        const secondPart = randomSecondNick[Math.floor(Math.random() * randomSecondNick.length)];
        setRandomNick(firstPart+secondPart);
    },[])


    const ProfileImageHandler = () => {
        let instProfile = midMainager?.log_data?.profile_url;
        if(instProfile === '01'){
            return profileIcon1;
        }else if(instProfile === '02'){
            return profileIcon2;
        }else if(instProfile === '03'){
            return profileIcon3;
        }else if(instProfile === '04'){
            return profileIcon4;
        }else if(instProfile === '05'){
            return profileIcon5;
        }else{
            return instProfile
        }
    }

    const handleCheckboxClick = () => {
        setIsChecked(!isChecked);
    };

    const toggleActivityMenu = () => {
        setIsActivityMenuOpen(!isActivityMenuOpen);
    };

    const toggleJobMenu = () => {
        setIsJobMenuOpen(!isJobMenuOpen);
    };

    let dropRef01 = useRef();
    const dropRef01Handler = (event) => {
        if (dropRef01.current && !dropRef01.current.contains(event.target)) {
            setDrop(false);
        }else{
            setDrop(true);
        }
    }
    // 도 선택 드랍다운 박스 useEffect
    useEffect(() => {
        document.addEventListener('mousedown', dropRef01Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef01Handler);
        };
    }, []);
    

    return (
        <MypageBackground>
            <MypageBorder>
                <MypageCusborder>
                    <MypageCusinfo>
                        <KebabMenuButton>
                            <div ref={dropRef01} style={{position: "relative"}}>
                                <img src={kebabmenubtn} alt="Kebab Menu Button" />
                                {
                                    drop?
                                    <DropBorder>
                                        <DropDownItems onClick={(e)=>{e.stopPropagation(); navigate('/my-user-info'); setDrop(false);}}>
                                            개인정보 변경
                                        </DropDownItems>
                                    </DropBorder>
                                    :
                                    null
                                }
                            </div>
                        </KebabMenuButton>
                        <MypageProfileImg src={ProfileImageHandler()} alt="profileimg" />
                        <MypageMyID>{!isChecked?midMainager?.log_data?.nickname:randomNick}</MypageMyID>
                        <MypageMyemail>{midMainager?.log_data?.email}</MypageMyemail>
                        <MypageRandomCheckContainer>
                            <MypageCheckBtn 
                                src={isChecked ? mypagecheckbox : mypageuncheckbox} 
                                alt="checkbox" 
                                onClick={handleCheckboxClick} 
                            />
                            <MypageRandomChecklabel>랜덤 닉네임</MypageRandomChecklabel>
                        </MypageRandomCheckContainer>
                        <MypageMyinfodivider />
                        <MypageMyInfoMenuList>
                            <MypageMessageTitle>
                                <MypageMenuBigTitle onClick={()=>{navigate('/message')}}>메세지함</MypageMenuBigTitle>
                                <MypageNewMessageTag src={mypagenewmessageicon} alt="New Message" />
                            </MypageMessageTitle>
                        </MypageMyInfoMenuList>
                        <MypageMyinfodivider />
                        <MypageMyInfoMenuList>
                            <MypageFoldingMenuBar onClick={toggleActivityMenu}>
                                <MypageMenuBigTitle>내 활동 관리</MypageMenuBigTitle>
                                <MypageArrow src={mypagemenuarrow} expanded={isActivityMenuOpen} />
                            </MypageFoldingMenuBar>
                        </MypageMyInfoMenuList>
                        <MypageFoldedMenuList expanded={isActivityMenuOpen}>
                            <MypageFoldedMenu onClick={()=>{navigate('/mycomupost')}}>내가 작성한 게시글</MypageFoldedMenu> 
                            <MypageFoldedMenu onClick={()=>{navigate('/mycomupost-comment')}}>내가 작성한 댓글</MypageFoldedMenu>
                            <MypageFoldedMenu onClick={()=>{navigate('/message/favorite')}}>즐겨찾는 톡</MypageFoldedMenu>
                            <MypageFoldedMenu onClick={()=>{navigate('/mycomupost-favorite')}}>내가 찜한 게시글</MypageFoldedMenu>
                        </MypageFoldedMenuList>

                        <MypageMyinfodivider />
                        <MypageMyInfoMenuList>
                            <MypageFoldingMenuBar onClick={toggleJobMenu}>
                                <MypageMenuBigTitle>구직 관리</MypageMenuBigTitle>
                                <MypageArrow src={mypagemenuarrow} expanded={isJobMenuOpen} />
                            </MypageFoldingMenuBar>
                        </MypageMyInfoMenuList>
                        <MypageFoldedMenuList expanded={isJobMenuOpen}>
                            <MypageFoldedMenu onClick={()=>{navigate('/myjoblist')}}>찜한 채용 공고</MypageFoldedMenu>
                            <MypageFoldedMenu onClick={()=>{navigate('/resume-add')}}>이력서 작성</MypageFoldedMenu>
                            <MypageFoldedMenu onClick={()=>{navigate('/myresume')}}>이력서 관리</MypageFoldedMenu>
                        </MypageFoldedMenuList>
                    </MypageCusinfo>
                </MypageCusborder>

                <MypageMenuborder>
                    <MypageMenulist>
                        <MypageMenusectiontitlebar>
                            <Mypagenoticeicon src={mypageterracenoticeicon} alt="noticeicon"/>
                            <MypageMenusectiontitle>미드나잇 테라스 소식</MypageMenusectiontitle>
                        </MypageMenusectiontitlebar>
                        <MypageMenudivider/>
                        <MypageMenusectionsubmenucontainer>
                            <MypageMenusectionsubmenu onClick={()=>{navigate('/notice-board')}}>
                                <MypageMenusectionsubmenutitle>공지사항</MypageMenusectionsubmenutitle>
                                <Mypagegotoicon src={mypagegotolinkicon} alt="gotoicon"/>
                            </MypageMenusectionsubmenu>
                            <MypageMenusectionsubmenu onClick={()=>{navigate('/event-board')}}> 
                                <MypageMenusectionsubmenutitle>이벤트</MypageMenusectionsubmenutitle>
                                <Mypagegotoicon src={mypagegotolinkicon} alt="gotoicon"/>
                            </MypageMenusectionsubmenu>
                            <MypageMenusectionsubmenu>
                                <MypageMenusectionsubmenutitle>친구 초대하기</MypageMenusectionsubmenutitle>
                                <Mypagegotoicon src={mypagegotolinkicon} alt="gotoicon"/>
                            </MypageMenusectionsubmenu>
                            <MypageMenusectionsubmenu onClick={()=>{alert('서비스 준비 중입니다.')}}>
                                <MypageMenusectionsubmenutitle>APP 다운로드</MypageMenusectionsubmenutitle>
                                <Mypagegotoicon src={mypagegotolinkicon} alt="gotoicon"/>
                            </MypageMenusectionsubmenu>
                        </MypageMenusectionsubmenucontainer>
                    </MypageMenulist>


                    <MypageMenulist2>
                        <MypageMenusectiontitlebar>
                            <Mypagenoticeicon src={mypagecsicon} alt="noticeicon"/>
                            <MypageMenusectiontitle>고객센터</MypageMenusectiontitle>
                        </MypageMenusectiontitlebar>
                        <MypageMenudivider/>
                        <MypageMenusectionsubmenucontainer>
                            <MypageMenusectionsubmenu onClick={()=>{alert('서비스 준비 중입니다.')}}>
                                <MypageMenusectionsubmenutitle >스마트 고객센터</MypageMenusectionsubmenutitle>
                                <Mypagegotoicon src={mypagegotolinkicon} alt="gotoicon"/>
                            </MypageMenusectionsubmenu>
                            <MypageMenusectionsubmenu onClick={()=>{navigate('/my-question')}}>
                                <MypageMenusectionsubmenutitle>1:1 문의게시판</MypageMenusectionsubmenutitle>
                                <Mypagegotoicon src={mypagegotolinkicon} alt="gotoicon"/>
                            </MypageMenusectionsubmenu>
                            <MypageMenusectionsubmenu onClick={()=>{navigate('/faq')}}>
                                <MypageMenusectionsubmenutitle>자주 묻는 질문</MypageMenusectionsubmenutitle>
                                <Mypagegotoicon src={mypagegotolinkicon} alt="gotoicon"/>
                            </MypageMenusectionsubmenu>
                        </MypageMenusectionsubmenucontainer>
                    </MypageMenulist2>


                </MypageMenuborder>
            </MypageBorder>
        </MypageBackground>
    );
};

export default Mypage;
