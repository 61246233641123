/*eslint-disable*/
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import questionspreadarrow from "./../../@images/arrow.svg";
import { useSelector } from "react-redux";
import axios from "axios";

let ContentsBorder = styled.div`
    width: 74.8rem; 
    height: 4rem;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

let NormalContentsList = styled.div`
    width: 74.8rem;
    height: auto;
    align-items: center;
    box-sizing: border-box;
`;

let ContentTitleBorder = styled.div`
    width: 74.8rem;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 9fr;
    column-gap: 1.6rem;
    box-sizing: border-box;
    font-size: 1.4rem;
`;

let NoticeNum = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #171717;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

let NoticeTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #171717;
    width: 100%;
    display: flex;
    justify-content: space-between;
`;


let Questionarrow = styled.img`
    margin-top: -0.5rem;
    margin-right:3rem;
    width: 2.4rem;
    height: 2.4rem;
    transition: transform 0.3s ease;
    transform: ${props => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

let ContentQNABorder = styled.div`
    width: 57rem;
    margin-left: 8.5rem;
    margin-right: 5rem;
    max-height: ${props => (props.expanded ? `${props.contentHeight}px` : '0')};
    overflow: hidden;
    box-sizing: border-box;
    font-size: 1.4rem;
    transition: max-height 0.3s ease;
`;


let ContentAnswer = styled.div`
    width: 57rem;
    height: 100%;
    background-color: #E8E8E8;
    padding-left: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    padding-right: 1rem;
    box-sizing: border-box;
    font-size: 1.4rem;
    color: #171717;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: 'Pretendard-Regular';
`;

const MypageMyquestionContents = ({ content }) => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    const [expanded, setExpanded] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);

    const [data, setData] = useState();

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.midtrc.com/notice/${content.id}`,{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                setData(response.data);
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    },[])

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, []);

    return (
        <div>
            <ContentsBorder onClick={toggleExpand}>
                <NormalContentsList>
                    <ContentTitleBorder>
                        <NoticeNum>
                            {content.num}
                        </NoticeNum>
                        <NoticeTitle>
                            <div>{content.notice_title}</div>
                            <Questionarrow src={questionspreadarrow} alt="question fold icon" expanded={expanded} />
                        </NoticeTitle>
                        
                    </ContentTitleBorder>
                </NormalContentsList>
            </ContentsBorder>

            <ContentQNABorder ref={contentRef} expanded={expanded} contentHeight={contentHeight}>
                <div >
                    <ContentAnswer>
                        {data?.data[0]?.content}
                    </ContentAnswer>
                </div>
            </ContentQNABorder>
        </div>
    );
};

export default MypageMyquestionContents;
