/*eslint-disable*/
import React from "react";
import styled from "styled-components";
import newPage from "./../../@images/newpage-24.svg";
import bookMarkActive from "./../../@images/bookmark-active.svg";

let ResumeCardBorder = styled.div`
    height: 25.6rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 1px;
    border-radius: 0.8rem;
    cursor: pointer;
`

let ResumeImages = styled.img`
    min-width: 2.4rem;
    min-height: 2.4rem;
    max-width: 2.4rem;
    max-height: 2.4rem;
    background-color: grey;
    object-fit: cover;
    border-radius: 2rem;
`

let ResumeInfoBorder = styled.div`
    box-sizing: border-box;
    padding: 2.4rem;
    background-color: white;
    height: 15.2rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 1px;
    border-radius: 0.8rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`

let ResumeCompanyNameBorder = styled.div`
    width: 100%;
    font-family: 'Pretendard-Regular';
    font-weight: 800;
    font-size: 1.4rem;
    color: #171717;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

let ResumeCompanyName = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 800;
    font-size: 1.4rem;
    color: #171717;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 0.4rem;
`

let ResumeContents = styled.div`
    box-sizing: border-box;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    text-align: left;
    color: #171717;
`

let ResumeSubInfoBorder= styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    aling-items: center;
    height: 2.4rem;
`

let ResumeSubArea = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6A6A6A;
    display: flex;
    align-items: center;
`

let ResumeSubMoneyType = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
`

let ResumeSubMoney = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.4rem;
    color: #444444;
`

let ResumeSubIconBorder = styled.div`
    display: flex;
    gap: 0.2rem;
    align-items: center;
`

let ResumeSubIcon = styled.img`
    height: 2.4rem;
    width: 2.4rem;
    cursor: pointer;
    object-fit: cover;
`

let ResumeGender = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6A6A6A;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`

const BusinessResumeCard = () => {
    return(
        <ResumeInfoBorder  style={{marginBottom: "1.5rem"}}>
            <ResumeCompanyNameBorder>
                <ResumeCompanyName>
                    <ResumeImages alt="profile"/>
                    <div>강남달빛토끼</div>
                    <ResumeGender>(여/24세)</ResumeGender>
                </ResumeCompanyName>
                
                <ResumeSubIconBorder>
                    <ResumeSubIcon src={bookMarkActive} alt="book-mark-active"/>
                    <ResumeSubIcon src={newPage} alt="new-page"/>
                </ResumeSubIconBorder>
            </ResumeCompanyNameBorder>
            <ResumeContents>
                단기로 일할 곳 찾습니다.
            </ResumeContents>
            <ResumeSubInfoBorder>
                <ResumeSubArea>
                    <ResumeSubMoneyType>
                        서울 강남구 · 
                    </ResumeSubMoneyType>
                    &nbsp;
                    <ResumeSubMoneyType style={{color: "#F32E13"}}>
                        시
                    </ResumeSubMoneyType>
                    &nbsp;
                    <ResumeSubMoney>
                        30,000
                    </ResumeSubMoney>
                    &nbsp;
                    <ResumeSubMoneyType>
                        원
                    </ResumeSubMoneyType>
                </ResumeSubArea>
                <ResumeSubArea style={{color: "#9E9E9E"}}>
                    2024.4.6
                </ResumeSubArea>
                
            </ResumeSubInfoBorder>
        </ResumeInfoBorder>
    )
}

export default BusinessResumeCard;