/*eslint-disable*/
import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import signuplogo from "./../../@images/signupbannerlogo.svg";
import step1page1stepimg from "./../../@images/step1page1stepimg.svg";
import step1page2stepimg from "./../../@images/step1page2stepimg.svg";
import step1page3stepimg from "./../../@images/step1page3stepimg.svg";
import step2page1stepimg from "./../../@images/step2page1stepimg.svg";
import step2page2stepimg from "./../../@images/step2page2stepimg.svg";
import BusinessSignup1Step from "./BusinessSignup1Step";
import BusinessSignup2Step from "./BusinessSignup2Step";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #F7F7F7;
  font-family: 'Pretendard-Regular';
`;

const Bottomspace = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #F7F7F7;
`;

const Banner = styled.div`
  width: 100%;
  height:9rem;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignupLogo = styled.img`
  width:19.4rem;
  height:2.6rem;
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:-1rem;
  margin-bottom:2rem;
`;

const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StepImage = styled.img`
  width: 7.6rem;
  height: 7.6rem;
`;

const StepLabel = styled.div`
  margin-top: 2.5rem;
  margin-bottom:1rem;
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.color};
  text-align: center;
`;

const StepDivider = styled.div`
  width: 4rem;
  height: 0;
  border: 0.1rem dashed #9E9E9E;
  margin: 0 1rem;
  transform: translateY(-3rem);
`;

const PageTitle = styled.h2`
  font-size: 3.2rem;
  color: #000;
  margin-top:8.3rem;
  margin-bottom: 8.3rem;
`;

const CardContainer = styled.div`
  width: 61.6rem;
  background-color: #fff;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;

`;

const Signup = () => {
  const [step, setStep] = useState(1);
  let [termsChecked, setTermsChecked] = useState(); // sns 약관동의, 나머지는 필수값
  let navigate = useNavigate();
  
  const renderStepContent = () => {
    switch (step) {
      case 1:
        return <BusinessSignup1Step goToNextStep={() => setStep(2)} saveCheckedStatus={(e)=>{setTermsChecked(e);}}/>;
      case 2:
        return <BusinessSignup2Step goSubmit={(e)=>signSubmitHandler(e)}/>;
      default:
        return <BusinessSignup1Step goToNextStep={() => setStep(2)} />;
    }
  };

  const stepImages = step === 2 ? [step2page1stepimg, step2page2stepimg, step1page3stepimg] : [step1page1stepimg, step1page2stepimg, step1page3stepimg];
  const stepLabels = ["약관동의", "정보입력", "가입완료"];
  const stepColors = step === 2 ? ["#9e9e9e", "#171717", "#9e9e9e"] : ["#171717", "#9e9e9e", "#9e9e9e"];


  const signSubmitHandler = (e) => {
    // 데이터가 다 넘어오지는 않기 때문에 terms 빼고 다 집어넣어야함
    const requestBody = {
        "signup_type": e.data.signup_type,
        "default_profile": e.data.default_profile,
        "client_type": e.data.client_type,
        "sns_id": e.data.sns_id, 
        "login_id": e.data.login_id,
        "nickname": e.data.nickname,// 닉네임
        "email": e.data.email,
        "pwd": e.data.pwd,
        "name": e.data.name,
        "phone_num": e.data.phone_num,
        "birthday": e.data.birthday,
        "gender": e.data.gender,   // 성별
        "invite_num": e.data.invite_num,
        "accepted_terms1": true,
        "accepted_terms2": true,
        "noti_sms": termsChecked,
        "business_industry": e.data.business_industry,
    };

    const jsonRequestData = JSON.stringify(requestBody);
    const formData = new FormData();
    formData.append('req_body',jsonRequestData);
    if(e.profileImage){
      formData.append('profile_image',e.profileImage);
    }
    if(e.businessImage){
      formData.append('business_image',e.businessImage);
    }
    axios.post('https://api.midtrc.com/auth/signup',formData).then((res)=>{
      navigate('/login')
    }).catch((error)=>{console.log(error)})
  }


  return (
    <PageContainer>
      <Banner>
        <SignupLogo src={signuplogo} alt="Signup Logo" />
      </Banner>
      <PageTitle>미드나잇 테라스 회원가입</PageTitle>
      <StepsContainer>
        {stepImages.map((src, index) => (
          <React.Fragment key={index}>
            <StepItem>
              <StepImage src={src} alt={`Step ${index + 1}`} />
              <StepLabel color={stepColors[index]}>{stepLabels[index]}</StepLabel>
            </StepItem>
            {index < stepImages.length - 1 && <StepDivider />}
          </React.Fragment>
        ))}
      </StepsContainer>
      <CardContainer>
        {renderStepContent()}
      </CardContainer>
      <Bottomspace></Bottomspace>
    </PageContainer>
  );
};

export default Signup;
