import React, { useState } from "react";
import styled from "styled-components";
import newPage from "./../../@images/newpage-24.svg";
import bookMarkActive from "./../../@images/bookmark-active.svg";
import kebabmenubtn from "./../../@images/kebabmenuicon.svg";

let MyJobCardBorder = styled.div`
    height: 25.6rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 1px;
    border-radius: 0.8rem;
    cursor: pointer;
`

let MyJobImages = styled.img`
    min-width: 100%;
    min-height: 11.6rem;
    max-width: 100%;
    max-height: 11.6rem;
    background-color: grey;
    object-fit: cover;
    border-radius: 0.8rem 0.8rem 0 0 ;
`

let MyJobInfoBorder = styled.div`
    box-sizing: border-box;
    padding: 1.5rem 0.5rem;
    background-color: white;
    height: 14rem;
    border-radius: 0 0 0.8rem 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position:relative;
`

let MyJobCompanyName = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #171717;
    box-sizing: border-box;
`

let MyJobContents = styled.div`
    box-sizing: border-box;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.5rem;
    text-align: left;
    color: #171717;
`

let MyJobSubInfoBorder= styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    aling-items: center;
    height: 2.4rem;
    position:relative;
`

let MyJobSubArea = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6a6a6a;
    display: flex;
    align-items: center;
`

let MyJobSubMoneyType = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1rem;
    letter-spacing:-1px;
`

let MyJobSubMoney = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.2rem;
`

let MyJobSubIconBorder = styled.div`
    
    display: flex;
    gap: 0.2rem;
    align-items: center;
`

let MyJobSubIcon = styled.img`
    height: 2.2rem;
    width: 2.2rem;
    cursor: pointer;
    object-fit: cover;
`

let MyResumeKekbabMenuList = styled.div`
    position: absolute;
    box-sizing: border-box;
    z-index: 3;
    border: 1px solid #D2D2D2;
    background-color: white;
    width: 10.8rem;
    height: 15.6rem;
    right: 2rem;
    border-radius: 2px;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    display: ${props => (props.show ? "flex" : "none")};
    flex-direction: column;
`;

let MyResumeKekbabMenu = styled.div`
    font-family: 'Pretendard-Regular';
    margin-top: 1.2rem;
    font-weight: 400;
    font-size: 1.2rem;
    color: #444444;
    cursor: pointer;
`;

let Myjobkebabamenubar = styled.div`

    width:100%;
    display:flex;
    justify-content:space-between;
    position:relative;
`;

let MyResumeSubIcon = styled.img`
    height: 2.4rem;
    width: 2.4rem;
    cursor: pointer;
    object-fit: cover;
    position:absolute;
    top:-0.4rem;
    right:-1rem;
`;

const MyJobCard = () => {

    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(prevShowMenu => !prevShowMenu);
    };


    return(
        <MyJobCardBorder>
            <MyJobImages alt="MyJob-banner"/>
            <MyJobInfoBorder>
                <Myjobkebabamenubar>
                <MyJobCompanyName>
                    강남달빛토끼
                </MyJobCompanyName>
                <MyResumeSubIcon src={kebabmenubtn} alt="kebabmenu" onClick={toggleMenu}/>
                </Myjobkebabamenubar>
                <MyJobContents>
                    달빛토끼에서 함께 즐겁게 일하실 여성분(20/30대)모집
                </MyJobContents>
                <MyJobSubInfoBorder>
                    <MyJobSubArea>
                        
                        <MyJobSubMoneyType>
                            서울 강남구 · 
                        </MyJobSubMoneyType>
                        &nbsp;
                        <MyJobSubMoneyType style={{color: "#F32E13"}}>
                            시
                        </MyJobSubMoneyType>
                        &nbsp;
                        <MyJobSubMoney>
                            30,000
                        </MyJobSubMoney>
                        &nbsp;
                        <MyJobSubMoneyType>
                            원
                        </MyJobSubMoneyType>
                    </MyJobSubArea>
                    <MyJobSubIconBorder>
                        <MyJobSubIcon src={bookMarkActive} alt="book-mark-active"/>
                        <MyJobSubIcon src={newPage} alt="new-page"/>
                    </MyJobSubIconBorder>
                </MyJobSubInfoBorder>

            <MyResumeKekbabMenuList show={showMenu}>
                <MyResumeKekbabMenu>수정/삭제</MyResumeKekbabMenu>
                <MyResumeKekbabMenu>대표공고 등록</MyResumeKekbabMenu>
                <MyResumeKekbabMenu>작성 중</MyResumeKekbabMenu>
                <MyResumeKekbabMenu>구인 중</MyResumeKekbabMenu>
                <MyResumeKekbabMenu>구인 마감</MyResumeKekbabMenu>
            </MyResumeKekbabMenuList>

            </MyJobInfoBorder>
        </MyJobCardBorder>
    )
}

export default MyJobCard;