/*eslint-disable*/
import React from "react";
import styled from "styled-components";
import borad from "./../../@images/board-img-24.svg";
import files from "./../../@images/file-24.svg";
import book from "./../../@images/like-24.svg";
import bookActive from './../../@images/like-active.svg';
import comment from "./../../@images/comment-24.svg";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import { useSelector } from "react-redux";
import axios from "axios";

let ContentsBorder = styled.div`
    widht: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    cursor: pointer;
`

let NormalContentsList = styled.div`
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 8fr 1fr 1fr;
    align-items: center;
    box-sizing: border-box;
`

let ContentTitleBorder = styled.div`
    height: 100%;
    display: flex;
    // grid-template-columns: 5fr 5fr;
    column-gap: 1.6rem;
    box-sizing: border-box;
`

let ContentMasterIcon = styled.div`
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 2rem;
    background-color: #10a64c;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.2rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-top: 0.2rem;
`

let TitleText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    color: #171717;
    white-space: nowrap; /* 줄바꿈 방지 */
    width: auto;
    // word-wrap: break-word;
    // word-break: break-word;
`

let ListIconBorder = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.4rem;

`

let ListIcon = styled.img`
    width: 2.4rem;
    height: 2.4rme;
`

let IconText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9E9E9E;
`

let EtcText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9E9E9E;
    width: 100%;
    display: flex;
    justify-content: center;
    aling-items: center;
`

const NormalContents = ({data, isChange}) => {
    let navigate = useNavigate();
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    
    const getDisplayedText = () => {
        let instCount = 40;
        let content = data?.lounge_title || '';

        // Remove line breaks from the HTML content
        content = content.replace(/<p>|<\/p>/g, '');

        if (content.length > instCount) {
            return content.slice(0, instCount) + '...';
        }
        return content;
    };

    const likeHandler = () => {
        axios.put(`https://api.midtrc.com/lounge/${data?.id}/like`,{},{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{isChange();}).catch((error)=>{console.log(error)});
    }

    const formatTime = (postTime) => {
        const now = new Date();
        const postDate = new Date(postTime);
    
        const diffInSeconds = Math.floor((now - postDate) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
    
        if (diffInHours < 1) {
            return `${diffInMinutes}분`;
        } else if (diffInHours < 3) {
            return `${diffInHours}시간`;
        } else if (diffInDays < 1) {
            return '오늘';
        } else if (diffInDays < 2) {
            return '어제';
        } else {
            const year = postDate.getFullYear().toString().slice(2); // Get last two digits of the year
            const month = (postDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
            const day = postDate.getDate().toString().padStart(2, '0');
            return `${year}.${month}.${day}`;
        }
    }

    const TimeDisplay = ({ postTime }) => {
        const formattedTime = formatTime(postTime);
        return <div>{formattedTime}</div>;
    };

    return(
        <ContentsBorder onClick={()=>{navigate(`/community-post/${data?.id}`)}}>
            <NormalContentsList>
                <ContentTitleBorder>
                    <div style={{display: "flex", gap: "0.8rem", width: "auto"}}>
                        {
                            data?.is_mobile?
                            <ContentMasterIcon>
                                M
                            </ContentMasterIcon>
                            :
                            null
                        }
                        <TitleText>
                            {parse(getDisplayedText())}
                        </TitleText>
                        <ListIconBorder>
                            {
                                data?.image_urls?.length !== 0?
                                <ListIcon src={borad} alt="icon02"/>
                                :
                                null
                            }
                            {/* <ListIcon src={files} alt="icon03"/> */}
                        </ListIconBorder>
                    </div>
                    <div style={{display: "flex", gap: "0.8rem"}}>
                        <ListIconBorder style={{maxWidth: "6rem"}}>
                            <ListIcon onClick={(e)=>{e.stopPropagation(); likeHandler()}} src={data?.is_like? bookActive : book} alt="icon03"/>
                            <IconText>
                                {data?.like_count}
                            </IconText>
                        </ListIconBorder>
                        <ListIconBorder style={{maxWidth: "6rem"}}>
                            <ListIcon src={comment} alt="icon04"/>
                            <IconText>
                                {data?.comment_count}
                            </IconText>
                        </ListIconBorder>
                    </div>
                </ContentTitleBorder>
                <EtcText>
                    {data?.nickname}
                </EtcText>
                <EtcText>
                    <TimeDisplay postTime={data?.created_at}/>
                </EtcText>
            </NormalContentsList>
        </ContentsBorder>
    )
}

export default NormalContents;