/*eslint-disable*/
import React from "react";
import styled from "styled-components";
import EventBoardContents from "./mypage-eventboardcontainer";

let MypageBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
`;

let MyNoticeBoardBorder = styled.div`
    margin-top: 0.8rem;
    width: 82.8rem;
    gap: 4rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    background-color: #F7F7F7;
    padding-top: 3rem;
`;

let MyNoticeBoardSection = styled.div`
    width: 82.8rem;
    padding-top: 17.8rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`;

let MypageNoticeBoardinfo = styled.div`
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 4rem;
    background-color: white;
    border-radius: 16px;
    border: 1px solid #D2D2D2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let MypageTitleBar = styled.div`
    color: #171717;
    width: 74.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center; 
`;

let MypageMyNoticeBoardTitle = styled.div`
    color: #171717;
    font-family: 'Pretendard-Regular';
    font-size: 2rem;
    font-weight: 600;
`;


let MypageMyNoticeBoarddivider = styled.div`
    width:74.8rem;
    border-bottom: 1px solid #E8E8E8;
    margin-top:2rem;
    margin-bottom:2rem;
`;

let MypageNoticeBoardContainer = styled.div`
    width: 74.8rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

const MyNoticeBoard = () => {
    return (
        <MypageBackground>
            <MyNoticeBoardBorder>
                <MyNoticeBoardSection>
                    <MypageNoticeBoardinfo>
                        <MypageTitleBar>
                            <MypageMyNoticeBoardTitle>이벤트</MypageMyNoticeBoardTitle>

                        </MypageTitleBar>
                        <MypageMyNoticeBoarddivider/>

                        <MypageNoticeBoardContainer>

                            <EventBoardContents/>
                        </MypageNoticeBoardContainer>
                    </MypageNoticeBoardinfo>
                </MyNoticeBoardSection>
            </MyNoticeBoardBorder>
        </MypageBackground>
    );
};

export default MyNoticeBoard;
