/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import status01 from "./../../@images/status-easy.svg";
import status02 from "./../../@images/status-normal.svg";
import status03 from "./../../@images/status-busy.svg";
import ChatRoom from "./chat-room";
import ChatWindow from "./chat-window";

let ChatMainBorder = styled.div`
    width: 125.2rem;
    display: flex;
    justify-content: space-between;
`

let ChatRoomBorder = styled.div`
    width: 51.0rem;
    height: 106.0rem;
    margin-bottom: 0.8rem;
`

let ChatRoomStatusBorder = styled.div`
    width: 100%;
    height: 3.6rem;
    display: flex;
    justify-content: right;
    align-items: flex-start;
    box-sizing: border-box;
    border-width: 0 0 0.1rem 0;
    border-style: solid;
    border-color: #E8E8E8;
    gap: 0.8rem;
`

let ChatRoomScroll = styled.div`
    height: 98rem;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 0px; /* 너비 설정 */
    }
`

let ChatStatus = styled.div`
    height:2.4rm;
    width: auto;
    display: flex;
    gap: 0.2rem;
    align-items: center;
`

let StatusImage = styled.img`
    width: 2.4rem;
    height: 2.4rem;
`

let StatusText = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 400;
    color: #6a6a6a;
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
`

let ChatShowMoreBorder = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 400;
    color: #9e9e9e;
    cursor: pointer;
`

let ChatWindowBorder = styled.div`
    width: 70.2rem;
    height: 106.0rem;
    margin-bottom: 0.8rem;
`

let ChatWindowButtonBorder = styled.div`
    width: 100%;
    height: 3.6rem;
    display: flex;
    justify-content: right;
    align-items: flex-start;
    box-sizing: border-box;
    gap: 0.8rem;
`

let ChatWindowButton = styled.button`
    background-color: black;
    border-style: solid;
    border-width: 0px;
    border-radius: 0.4rem;
    color: white;
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 400;
    height: 2.4rem;
    padding: 0.4rem 1.6rem;
    cursor: pointer;
`

const Chat = ({groupData, changeHandler, selectedHandler, selectedId, isBook, myRigion}) => {
    let [filterPin, setFilterPin] = useState([]);
    let [filterPinNone, setfilterPinNone] = useState([]);

    
    useEffect(()=>{
        let filterBookMark = isBook? groupData : groupData?.filter(obj => obj.is_favorite === true) // 즐겨찾기 데이터 확인
        let filterRigion = myRigion === 0? filterBookMark: myRigion === 1? filterBookMark?.filter(obj => obj.region && obj.region.includes('서울')): filterBookMark?.filter(obj => obj.region && !obj.region.includes('서울')) // 지역데이터 확인
        let filterPininst = filterRigion?.filter(obj => obj.pin_yn === true);
        let filterPinNoneinst = filterRigion?.filter(obj => obj.pin_yn === false);
        setFilterPin(filterPininst);
        setfilterPinNone(filterPinNoneinst);
    },[groupData, myRigion, isBook])
    

    return(
        <ChatMainBorder>
            <ChatRoomBorder>
                {/* 상단 스테이터스 보더 */}
                <ChatRoomStatusBorder>
                    <ChatStatus>
                        <StatusImage src={status01} alt="status01"/>
                        <StatusText>
                            여유
                        </StatusText>
                    </ChatStatus>
                    <ChatStatus>
                        <StatusImage src={status02} alt="status01"/>
                        <StatusText>
                            중간
                        </StatusText>
                    </ChatStatus>
                    <ChatStatus>
                        <StatusImage src={status03} alt="status01"/>
                        <StatusText>
                            혼잡
                        </StatusText>
                    </ChatStatus>
                </ChatRoomStatusBorder>
                {/* 채팅 방 */}
                <ChatRoomScroll>
                    {
                        filterPin?.map((a, i)=>(
                            <ChatRoom key={i} data={a} changeHandler={changeHandler} isSelected={selectedHandler} selectedId={selectedId}/>
                        ))
                    }
                    {
                        filterPinNone?.map((a, i)=>(
                            <ChatRoom key={i} data={a} changeHandler={changeHandler} isSelected={selectedHandler} selectedId={selectedId}/>
                        ))
                    }
                </ChatRoomScroll>
            </ChatRoomBorder>
            <ChatWindowBorder>
                {/* 첫번째 줄 */}
                <ChatWindowButtonBorder>
                    <ChatWindowButton onClick={()=>{alert('서비스 준비중 입니다')}}>
                        요청·제안
                    </ChatWindowButton>
                </ChatWindowButtonBorder>
                {/* 채팅 창 */}
                <ChatWindow selectedId={selectedId} changeHandler={changeHandler} data={groupData}/>
            </ChatWindowBorder>
        </ChatMainBorder>
    )
}


export default Chat;