import React, { useEffect, useState } from "react";
import styled from "styled-components";
import warnning from "./../../@images/warningmark-32.svg";
import category01 from "./../../@images/category-01.svg";
import category02 from "./../../@images/category-02.svg";
import category03 from "./../../@images/category-03.svg";
import category04 from "./../../@images/category-04.svg";
import JobCard from "../7_job_mob/job-card-mob";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

let MainJobBorder = styled.div`
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left:2rem;
    padding-right:2rem;
    box-sizing: border-box;
`

let MainJobTitleBorder = styled.div`
    align-items: center;
    width: 100%;
`

let MainJobTitleText = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 4rem;
    font-weight: 600;
    color: #171717;
    display: flex;
    align-items: center;
`

let MainJobTitleButtonBorder = styled.div`
    margin-top:3rem;
    margin-bottom:1rem;
    display: flex;
    gap: 0.8rem;
`

let MainJobTitleButton = styled.button`
    width: 12rem;
    height: 4rem;
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 500;
    color: white;
    background-color: black;
    box-sizing: border-box;
    padding: 0.8rem 1.6rem;
    border-radius: 0.4rem;
    border: none;
    cursor: pointer;
`

let MainJobTitleRedButton = styled.button`
    width: 12rem;
    height: 4rem;
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 500;
    color: white;
    background-color: #CD1B1B;
    box-sizing: border-box;
    padding: 0.8rem 1.6rem;
    border-radius: 0.4rem;
    border: none;
    cursor: pointer;
`

let MainJobTitleAlarm = styled.span`
    box-sizing: border-box;
    margin-left: 0.5rem;
`

let Alarm = styled.img`
    width: 3.2rem
    height: 3.2rem;
    object-fit: cover;
    display: flex;
    align-items: center;
`

let SubMenuBorder = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    height: 10.4rem;
    width: 100%;
    gap: 1.6rem;
`

let SubMenuButton = styled.button`
    display: flex;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1rem;
    color: ${props=>props.clickedSub? 'white':'black'};;
    background-color: ${props=>props.clickedSub? 'black':'white'};
    border-color:  ${props=>props.clickedSub? 'rgba(0,0,0,0)':'#D2D2D2'};
    border-style: solid;
    border-radius: 4rem;
    border-width: 0.1rem;
    height: 3rem;
    box-sizing: border-box;
    padding: 0.5rem 1rem 0.5rem 1rem;
    cursor: pointer;
`

let CategoryIcon = styled.img`
    width: 1.5rem;
    height: 1.5rme;
`

let JobListBorder = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
`

let JobShowMoreBorder = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-size: 1.5rem;
    font-weight: 400;
    color: #171717;
    cursor: pointer;
    margin-top: 2rem;
`
const MainJob = () => {
    let navigate = useNavigate();
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [category, setCategory] = useState(3);
    let [data, setData] = useState([]);
    let [isChange, setIsChange] = useState(false);

    const categroyHandler = () => {
        switch(category){
            case 0:
                return 'always';
            case 1:
                return 'short_term';
            case 2: 
                return 'always';
            case 3:
                return '';
        }
    }

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/recruit',{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instList = response.data;
                
                let categoryFilter = instList.filter(item=>item.recruit_type === categroyHandler() || categroyHandler() === '')

                setData(categoryFilter);

            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    },[category, isChange])

    const changeData = () => {
        setIsChange(!isChange)
    }

    return(
        <MainJobBorder>
            <MainJobTitleBorder>
                <MainJobTitleText>
                    채용 공고<MainJobTitleAlarm><Alarm src={warnning} alt="warinning"/></MainJobTitleAlarm>
                </MainJobTitleText>
                <MainJobTitleButtonBorder>
                    <MainJobTitleRedButton  onClick={() => navigate('resume-add/:id')}>
                        + 이력서 등록
                    </MainJobTitleRedButton>
                    <MainJobTitleButton  onClick={() => navigate('/job-add/:id')}>
                        + 채용공고
                    </MainJobTitleButton>
                </MainJobTitleButtonBorder>
            </MainJobTitleBorder>
            {/* 서브 메뉴 */}
            <SubMenuBorder>
                <SubMenuButton clickedSub={category === 0} onClick={()=>{setCategory(0)}}>
                    <CategoryIcon src={category01} alt="category01"/>
                    미드나잇 알바
                </SubMenuButton>
                <SubMenuButton clickedSub={category === 1} onClick={()=>{setCategory(1)}}>
                    <CategoryIcon src={category02} alt="category02" />
                    단기 알바
                </SubMenuButton>
                <SubMenuButton clickedSub={category === 2} onClick={()=>{setCategory(2)}}>
                    <CategoryIcon src={category03} alt="category03"/>
                    상시 채용
                </SubMenuButton>
                <SubMenuButton clickedSub={category === 3} onClick={()=>{setCategory(3)}}>
                    <CategoryIcon src={category04} alt="category04"/>
                    기타/전체
                </SubMenuButton>
            </SubMenuBorder>
            
            <JobListBorder>
                {
                    data?.map((a, i)=>(
                        <JobCard data={a} key={i} isChange={changeData}/>
                    ))
                }
            </JobListBorder>
            <JobShowMoreBorder onClick={()=>{navigate('/job');}}>
                + 채용 공고 전체 보기
            </JobShowMoreBorder>
        </MainJobBorder>
    )
}

export default MainJob;