/*eslint-disable*/
import React from "react";
import styled from "styled-components";

import messageLeft from "./../../@images/message-left.svg";

import ProfileTest from "./../../@images/profile-test.png";

let MessageBorder = styled.div`
    box-sizing: border-box;
    display: flex;
    width: 100%;
`

let MessageProfileBorder = styled.div`
    width: 6.4rem;
    height: 6.4rem;
    padding: 0.4rem;
    box-sizing: border-box;
`

let MessageProfile = styled.img`
    border-radius: 1.2rem;
    min-width: 5.6rem;
    min-height: 5.6rem;
    max-width: 5.6rem;
    max-height: 5.6rem;
    background-color: white;
`

let MessageLeft = styled.img`
    min-widht: 1.6rem;
    max-widht: 1.6rem;
    min-height: 3.6rem;
    max-height: 3.6rem;
`

let MessageContentsBorder = styled.div`
    min-height: 6.4rem;
    max-width: 100%;
    min-width: 9rem;
    border-radius: 0.8rem;
    background-color: white;
    margin-left: -0.1rem;
    box-sizing: border-box;
    padding: 1.6rem;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    line-height: 1.5;
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 400;
    text-align: left;
`
let MessageTimeText = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: left;
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 400;
    text-align: left;
    color: #171717;
    margin-left: 10px;
    white-space: nowrap;
`

const Message = ({choiceMessage, profile}) => {
    
    const formatTime = (postTime) => {
        const now = new Date();
        const postDate = new Date(postTime);
    
        const diffInSeconds = Math.floor((now - postDate) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
    
        const getFormattedTime = (date) => {
            const hours = date.getHours();
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const period = hours < 12 ? '오전' : '오후';
            const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
            return `${period} ${formattedHours}:${minutes}`;
        };
    
        if (diffInDays < 1) {
            return getFormattedTime(postDate);
        } else if (diffInDays < 2) {
            return `어제 ${getFormattedTime(postDate)}`;
        } else {
            const year = postDate.getFullYear().toString().slice(2); // Get last two digits of the year
            const month = (postDate.getMonth() + 1).toString(); // Months are zero-indexed
            const day = postDate.getDate().toString();
            return `${year}.${month}.${day} ${getFormattedTime(postDate)}`;
        }
    };
    
    const TimeDisplay = ({ postTime }) => {
        const formattedTime = formatTime(postTime);
        if (postTime) {
            return <div>{formattedTime}</div>;
        }
        return '';
    };

    return(
        <MessageBorder>
            <MessageProfileBorder>
                <MessageProfile src={profile} alt="message-profile"/>
            </MessageProfileBorder>
            <MessageLeft src={messageLeft} alt="message-left"/>
            <MessageContentsBorder>
                {choiceMessage?.content}
            </MessageContentsBorder>
            <MessageTimeText>
                <TimeDisplay postTime={choiceMessage?.created_at} />
            </MessageTimeText>
        </MessageBorder>
    )
}

export default Message;