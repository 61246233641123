import React from "react";
import p0 from "./../@images/policy_iamge/p0.jpg"
import p1 from "./../@images/policy_iamge/p1.jpg"
import p2 from "./../@images/policy_iamge/p2.jpg"
import p3 from "./../@images/policy_iamge/p3.jpg"
import p4 from "./../@images/policy_iamge/p4.jpg"
import p5 from "./../@images/policy_iamge/p5.jpg"
import p6 from "./../@images/policy_iamge/p6.jpg"

const Policy02 = () => {
    return(
        <div>
            <img src={p0} style={{width: "100%", objectFit: "contain"}} alt="p0"/>
            <img src={p1} style={{width: "100%", objectFit: "contain"}} alt="p1"/>
            <img src={p2} style={{width: "100%", objectFit: "contain"}} alt="p2"/>
            <img src={p3} style={{width: "100%", objectFit: "contain"}} alt="p3"/>
            <img src={p4} style={{width: "100%", objectFit: "contain"}} alt="p4"/>
            <img src={p5} style={{width: "100%", objectFit: "contain"}} alt="p5"/>
            <img src={p6} style={{width: "100%", objectFit: "contain"}} alt="p6"/>
        </div>
    )   
}

export default Policy02;