/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NormalContents from "./mypage-faqquestion-contents";
import next from "./../../@images/circle-next-24.svg";
import axios from "axios";
import { useSelector } from "react-redux";

let NormalBorder = styled.div`
    width: 74.8rem;
    margin-top: -2rem;
`;

let NormalTopBorder = styled.div`
    width: 100%;
    height: 6.4rem;
    background-color: #222222;
    border-radius: 0.8rem 0.8rem 0 0;
    display: grid;
    grid-template-columns: 1fr 9fr;
    margin-bottom: 1.6rem;
`;

let NormalTopBorderTitleText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
`;

let NormalListBorder = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 1.6rem;
    margin-bottom: 1.6rem;
    border-width: 0 0 0.1rem 0;
    border-style: solid;
    border-color: #E8E8E8;
    
`;

let PageNaitionBorder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1.6rem;
    margin-top: 1.6rem;
`;

let PageIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
    transform: rotate(${props => props.degree}deg);
`;

let Pages = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.8rem;
    color: #9E9E9E;
    cursor: pointer;
`;

const MypageMyquestionBoardContainer = () => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    const [currentPage, setCurrentPage] = useState(1);
    const contentsPerPage = 10;
    // const contents = Array.from({ length: 15 }, (_, index) => ({
    //     title: `미드나잇 테라스 운영 관련 공지`,
    //     time: `24분 전`
    // }));

    let [contents, setContents] = useState([]);

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.midtrc.com/notice?notice_type=question`,{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                setContents(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    },[])




    const totalPages = Math.ceil(contents.length / contentsPerPage);

    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderContents = () => {
        const startIndex = (currentPage - 1) * contentsPerPage;
        const selectedContents = contents.slice(startIndex, startIndex + contentsPerPage);
        return selectedContents.map((content, index) => (
            <NormalContents key={startIndex + index + 1} content={{ num: startIndex + index + 1, ...content }} />
        ));
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <Pages key={i} onClick={() => handleClick(i)}>
                    {i}
                </Pages>
            );
        }
        return pageNumbers;
    };

    const PageNation = () => (
        <PageNaitionBorder>
            {currentPage > 1 && <PageIcon src={next} degree={0} onClick={() => handleClick(currentPage - 1)} alt="previous" />}
            {renderPageNumbers()}
            {currentPage < totalPages && <PageIcon src={next} degree={180} onClick={() => handleClick(currentPage + 1)} alt="next" />}
        </PageNaitionBorder>
    );

    return (
        <NormalBorder>
            {/* 게시글 리스트 */}
            <NormalTopBorder>
                <NormalTopBorderTitleText>
                    번호
                </NormalTopBorderTitleText>
                <NormalTopBorderTitleText>
                    제목
                </NormalTopBorderTitleText>
            </NormalTopBorder>
            <NormalListBorder>
                {renderContents()}
            </NormalListBorder>

            {/* 페이지 네이션 */}
            <PageNation />
        </NormalBorder>
    );
}

export default MypageMyquestionBoardContainer;
