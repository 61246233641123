/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import location from "./../../@images/location-24.svg";
import Resume from "./../../@images/job-24.svg";
import arrow from "./../../@images/arrow-line-24.svg";
import search from "./../../@images/search.svg";
import ResumeCard from "./resume-card";
import { useSelector } from "react-redux";
import axios from "axios";
import korea from "../../@public-components/kroea";


let ResumeMainBorder = styled.div`
    width: 125.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
`

let ResumeFilterBorder = styled.div`
    width: 100%;
    height: 12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.2rem;
    border-style: solid;
    border-width: 0 0 0.1rem 0;
    border-color: #D2D2D2;
`

let ResumeFilterDropBorder = styled.div`
    display: flex;
    gap: 2rem;
`

let ResumeFilterDrop = styled.div`
    cursor: pointer;
    width: 19.2rem;
    height: 5.6rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #D2D2D2;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    display: flex;
    justify-content: space-between;;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    position: relative;
`

let FilterIcon = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
    margin-right: 0.4rem;
`

let FilterIconArrow = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
`

let ResumeFilterSearchBorder = styled.div`
    width: 40.4rem;
    height: 5.6rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #D2D2D2;
    border-radius: 0.4rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`

let SearchIcon = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    margin: 0 0.4rem 0 1.6rem;
`

let SearchInput = styled.input`
    width: 100%;
    height: 100%;
    outline: none;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #6A6A6A;
    box-sizing: border-box;
    border: none;
`

let ResumeTitleBorder = styled.div`
    width: 125.2rem;
    height: 3.2rem;
    margin-bottom: 1.6rem;
    box-sizing: border-box;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #171717;
`

let ResumeRequest = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #6A6A6A;
    cursor: pointer;
`
let ResumeListBorder = styled.div`
    width: 125.2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
`

let ResumeShowMoreBorder = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 400;
    color: #9e9e9e;
    cursor: pointer;
`

let DropBorder = styled.div`
    position: absolute;
    min-width: 16rem;
    height: auto;
    max-height: 30rem;
    left: 0px;
    top: 5.8rem;
    z-index: 30;
    background-color: white;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #E8E8E8;
    border-width: 0.1rem;
    overflow: auto;
    text-align: left;
    &::-webkit-scrollbar {
        width: 12px; /* 너비 설정 */
      }
    &::-webkit-scrollbar-thumb {
        background: #CBCED5;
        border-radius: 6px;
    }
`

let DropDownItems = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: grey;
    box-sizing: border-box;
    padding: 0 2rem;
    height: 3.4rem;
    &:hover{
        color: black;
    }
`


const ResumeList = () => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [ResumeData, setResumeData] = useState([]);
    let [showData, setShowData] = useState([])
    let [showMore, setShowMore] = useState(1);

    // 필터 값
    let [areaFilter, setAreaFilter] = useState('지역');
    let [isArea, setIsArea] = useState(false);
    let [jobTypeFilter, setJobtypeFilter] = useState('업종');
    let [isJob, setIsJob] = useState(false);
    let [searchFilter, setSearchFilter] = useState('');

    let [isChange, setIsChange] = useState(false);
    
    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/resume',{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instList = response.data;
                 // 호출 된 이력서 리스트 데이터
                if(areaFilter === '지역' && searchFilter === ''){
                    setResumeData(instList);
                }else{
                    let filterData = instList.filter(item => 
                        areaFilter === '지역' &&
                        item.resume_title.toLowerCase().includes(searchFilter.toLowerCase()) ||
                        item.address.some(addr => addr.includes(areaFilter)) &&
                        item.resume_title.toLowerCase().includes(searchFilter.toLowerCase())
                    );
                    setResumeData(filterData);
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
        setIsArea(false);
        setIsJob(false);
    },[midMainager?.log_data, isChange, areaFilter, searchFilter])

    useEffect(()=>{
        let listArray = []
        for(let i = 1; i < 16*showMore; i++){
            if(ResumeData[i-1]){
                listArray.push(ResumeData[i-1]);
            }
        }
        setShowData(listArray);
    },[showMore, ResumeData])

    const showmoreHandler = () => {
        let instShowMore = showMore+1;
        setShowMore(instShowMore)
    }

    const areaHandler = (e) => {
        setAreaFilter(e);
       
    }

    const jobTypeHandler = (e) => {
        setJobtypeFilter(e);
    }

    const searchHandler = (e) => {
        setSearchFilter(e.target.value);
    }

    const changeData = () => {
        setIsChange(!isChange)
    }

    let dropRef01 = useRef();
    const dropRef01Handler = (event) => {
        if (dropRef01.current && !dropRef01.current.contains(event.target)) {
            setIsArea(false)
        }else{
            setIsArea(true)
        }
    }

    // 지역 드랍다운 박스 useEffect
    useEffect(() => {
        document.addEventListener('mousedown', dropRef01Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef01Handler);
        };
    }, []);

    return(
        <ResumeMainBorder>
            <ResumeFilterBorder>
                {/* 드랍다운 */}
                <ResumeFilterDropBorder>
                <ResumeFilterDrop ref={dropRef01} onClick={dropRef01Handler}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FilterIcon src={location} alt="location"/>
                            <span>{areaFilter}</span>
                        </div>
                        <FilterIconArrow src={arrow} style={{transform: isArea?'rotate(180deg)':'rotate(0deg)'}} alt="arrow"/>
                        {
                            isArea?
                            <DropBorder>
                                <DropDownItems onClick={()=>{areaHandler('지역')}}>
                                    전체보기
                                </DropDownItems>
                                {
                                    korea.map((a, i)=>(
                                        <DropDownItems key={i} onClick={()=>{areaHandler(a.province)}}>
                                            {a.province}
                                        </DropDownItems>
                                    ))
                                }
                            </DropBorder>
                            :
                            <div style={{display: "none"}} />
                        }
                    </ResumeFilterDrop>
                    {/* <ResumeFilterDrop>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FilterIcon src={Resume} alt="location"/>
                            <span>직종</span>
                        </div>
                        <FilterIconArrow src={arrow} alt="arrow"/>
                    </ResumeFilterDrop> */}
                </ResumeFilterDropBorder>
                {/* 키워드 서치 */}
                <ResumeFilterSearchBorder>
                    <SearchIcon src={search} alt="search-icon"/>
                    <SearchInput value={searchFilter} onChange={searchHandler} placeholder="키워드를 입력하세요"/>
                </ResumeFilterSearchBorder>
            </ResumeFilterBorder>
            <ResumeTitleBorder>
                <div>
                    최신 인재 정보
                </div>
                    <ResumeRequest>
                        
                    </ResumeRequest>
            </ResumeTitleBorder>
            <ResumeListBorder>
                {
                    showData?.map((a, i)=>(
                        <ResumeCard key={i} data={a} isChange={changeData}/>
                    ))
                }
            </ResumeListBorder>
            <ResumeShowMoreBorder onClick={()=>{showmoreHandler()}}>
                +  더 보기
            </ResumeShowMoreBorder>
        </ResumeMainBorder>
    )
}

export default ResumeList;