/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MyResumeCard from "./business-mypage-resume-card";
import { useSelector } from "react-redux";
import axios from "axios";

let MypageBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
`;

let MyJobListBorder = styled.div`
    margin-top: 0.8rem;
    width: 82.8rem;
    gap: 4rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    background-color: #F7F7F7;
    padding-top: 3rem;
`;

let MyJobListSection = styled.div`
    width: 82.8rem;
    padding-top: 17.8rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`;

let MypageJobListinfo = styled.div`
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 4rem;
    background-color: white;
    border-radius: 16px;
    border: 1px solid #D2D2D2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let MypageTitleBar = styled.div`
    color: #171717;
    width: 74.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center; 
`;

let MypageJobListTitle = styled.div`
    color: #171717;
    font-family: 'Pretendard-Regular';
    font-size: 2rem;
    font-weight: 600;
`;



let MypageJobListdivider = styled.div`
    width:74.8rem;
    border-bottom: 1px solid #E8E8E8;
    margin-top:2rem;
    margin-bottom:2rem;
`;

let MypageMyJobListContainer = styled.div`
    width: 74.8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const Businessmyresume = () => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [showData, setShowData] = useState([]);
    let [isChange, setIsChange] = useState(false);

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/resume',{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instList = response.data;
                let filterList = instList.filter(obj=>obj.is_favorite === true);
                setShowData(filterList);
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    },[isChange])

    const changeData = () => {
        setIsChange(!isChange)
    }




    return (
        <MypageBackground>
            <MyJobListBorder>
                <MyJobListSection>
                    <MypageJobListinfo>
                        <MypageTitleBar>
                            <MypageJobListTitle>찜한 이력서</MypageJobListTitle>
                        </MypageTitleBar>
                        <MypageJobListdivider/>
                        <MypageMyJobListContainer>
                        {
                            showData?.map((a, i)=>(
                                <MyResumeCard key={i} data={a} isChange={changeData}/>
                            ))
                        }
                        </MypageMyJobListContainer>

                    </MypageJobListinfo>
                </MyJobListSection>
            </MyJobListBorder>
        </MypageBackground>
    );
};

export default Businessmyresume;
