/*eslint-disable*/
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import profileIcon1 from "./../../@images/profile_icon1.svg";
import profileIcon2 from "./../../@images/profile_icon2.svg";
import profileIcon3 from "./../../@images/profile_icon3.svg";
import profileIcon4 from "./../../@images/profile_icon4.svg";
import profileIcon5 from "./../../@images/profile_icon5.svg";
import addIcon from "./../../@images/add_icon.png";
import checkboxchecked from "./../../@images/checkbox_checked.svg";
import checkboxunchecked from "./../../@images/checkbox_unchecked.svg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../redux/slice/login-slice";

const CardContainer2 = styled.div`
  width: 61.6rem;
  text-align: left;
  box-sizing: border-box;
  margin: 0 auto;
`;

const CardContainer = styled.div`
  padding: 20rem 0;
  width: 100%;
  background-color: #F7F7F7;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  
`;

const ProfileSection = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  padding-top: 3.4rem;
  padding-left: 3.1rem;
  padding-right: 3.1rem;
  padding-bottom: 3.2rem;
  box-sizing: border-box;
  margin-bottom: 2rem;
  border: 1px solid #ccc;
`;

const ProfileLabel = styled.label`
  margin-bottom: 3.4rem;
  font-size: 1.8rem;
  text-align: center;
  color: #171717;
`;

const ProfileImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  gap: 3.2rem;
`;

const ImageWrapper = styled.div`
  width: 6.4rem;
  height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  cursor: pointer;
`;

const ProfileImage = styled.img`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
`;

const AddImageButton = styled.img`
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const EnlargedImage = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  img {
    width: 12rem;
    height: 12rem;
    border-radius: 8px;
    background-color: #f7f7f7;
  }
`;

const Section = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 4px;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 3.4rem;
  padding-left: 3.1rem;
  padding-right: 3.1rem;
  padding-bottom: 3.2rem;
  box-sizing: border-box;
  margin-bottom: 2rem;
  width: 100%;
  border: 1px solid #ccc;
  
`;

const LastSection = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 4px;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 3.4rem;
  padding-left: 3.1rem;
  padding-right: 3.1rem;
  padding-bottom: 3.2rem;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #ccc;
  
`;

const SectionLabel = styled.div`
  align-self: flex-end;
  color: #171717;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  margin-bottom: 0.7rem;
`;

const SectionRequired = styled.span`
  color: #E53935;
  margin-right: 0.1rem;
`;

const Required = styled.span`
  color: #E53935;
  margin-right: 0.6rem;
`;

const Inputbox = styled.input`
  padding: 1rem;
  width: 100%;
  height: 5.6rem;
  font-size: 1.6rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  box-sizing: border-box;
  outline: none;
`;

const InputNickname = styled.input`
  padding: 1rem;
  width: 35.2rem;
  height: 5.6rem;
  font-size: 1.6rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  box-sizing: border-box;
  font-family: 'Pretendard-Regular';
  outline: none;
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  gap: -3rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  height: 5.6rem;
  width: 7.6rem;
  font-size: 1.6rem;
  color: #fff;
  background-color: ${props => props.isRead? '#dedede' : '#222222'} ;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 1rem;
  margin-top: -1rem;
`;


const Select = styled.select`
  padding: 0.5rem;
  height:5.6rem;
  font-size: 1.6rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  flex: 1;
  margin-right:1rem;
  width:19.2rem;
  outline: none;
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  gap:2rem;
  margin-bottom: 1rem;
  margin-left:0.1rem;
`;

const RadioLabel = styled.label`
  margin-right: 1rem;
  font-size:1.6rem;
`;

const Labelspace = styled.div`
  height: 1rem;
  width:100%;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;
  margin-top: 1.5rem;
`;

const RadioImage = styled.img`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  margin-top:-0.3rem;
`;

const RedButton = styled.button`
  background-color: #CD1B1B;
  border: 1px solid #CD1B1B;
  width: 100%;
  height: 5.6rem;
  color: white;
  font-size: 1.8rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-top: -1rem;
  
  &:hover {
    color: #CD1B1B;
    background-color: #fff;
    border: 1px solid #CD1B1B;
  }
`;

const Inputdate = styled.input`
  padding: 1rem;
  width: 10rem;
  height: 5.6rem;
  font-size: 1.6rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  box-sizing: border-box;
  font-family: 'Pretendard-Regular';
`;
const SelectYearMonth = styled.select`
  padding: 0.5rem;
  height:5.6rem;
  font-size: 1.6rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  flex: 1;
  margin-right:1rem;
  width:16rem;
`;
const InputGroupBirth = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 41.2rem;
  gap: -3rem;
`;




const MypageEditUserInfo = () => {
  let midMainager = useSelector((state)=>state.midnightLoginManager);
  let dispatch = useDispatch(null);
  const [selectedProfileImage, setSelectedProfileImage] = useState(profileIcon1);
  const [customImage, setCustomImage] = useState(null);
  const [nicknameType, setNicknameType] = useState("default");
  
  const [birthYears, setBirthYears] = useState([]);
  const [birthMonths, setBirthMonths] = useState([]);
  const fileInputRef = useRef(null);
  let navigate = useNavigate();

  // 회원 분류
  let [signup_type, setsignup_type] = useState('general'); // 로그인 방식
  // 프로필 이미지 타입
  let [default_iamge, setDefault_image] = useState('01');
  // 커스텀 프로필 이미지
  let [custom_profile, setCustom_profile] = useState(null);
  let [sns_id, setsns_id] = useState();
  let [login_id, setlogin_id] = useState('');// 아이디
  let [loginChekced, setLoginChecked] = useState(false);

  let [nickname, setNickName] = useState('');// 닉네임
  let [email, setemail] = useState('');// 이메일
  let [emailChecked, setEmailChecked] = useState(false);
  let [pwd, setpwd] = useState(''); // 비밀번호(확인)
  let [rePwd, setRePwd] = useState('');
  let [pwdChecked, setPwdChecked] = useState();
  let [name, setname] = useState(''); // 사용자 이름
  let [phone_num, setphone_num] = useState(''); // 핸드폰 번호
  let [phone_num_checked, setPhone_num_checked] = useState(false);
  let [birthYear, setBirthYear] = useState('');
  let [birthMonth, setBirthMonth] = useState('');
  let [birthDay, setBirthDay] = useState('');
  let [gender, setGender] = useState('');// 성별
  let [invite_num, setinvite_num] = useState(''); // 추천인 핸드폰 번호
  
  let [sms, setSms] =useState(false);
    // 사업자 관련 정보
  let [business_industry, setbusiness_industry] = useState();

  // 랜덤 닉네임 변수
  let randomFirstNick = ['파랑', '빨강', '초록', '검정', '노랑', '분홍']
  let randomSecondNick = ['곰', '토끼', '여우', '팬더', '강아지', '고양이']
  

  useEffect(()=>{
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.midtrc.com/client/${midMainager?.log_data?.id}`,{
            headers: {
              'access-token': midMainager?.log_data?.access_token
          }
        })
        const instData = response.data;

        if(instData.profile_url === '01'){
          setDefault_image('01');
          setSelectedProfileImage(profileIcon1)
        }else if(instData.profile_url === '02'){
          setDefault_image('02');
          setSelectedProfileImage(profileIcon2)
        }else if(instData.profile_url === '03'){
          setDefault_image('03');
          setSelectedProfileImage(profileIcon3)
        }else if(instData.profile_url === '04'){
          setDefault_image('04');
          setSelectedProfileImage(profileIcon4)
        }else if(instData.profile_url === '05'){
          setDefault_image('05');
          setSelectedProfileImage(profileIcon5)
        }else{
          setCustom_profile(instData.profile_url)
        }

        setlogin_id(instData.login_id);
        setLoginChecked(true)
        setphone_num(instData.phone_num);
        setPhone_num_checked(true);
        setemail(instData.email);
        setEmailChecked(true);
        setNickName(instData.nickname);
        setname(instData.name);

        const [year, month, day] = instData.birthday.split('.').map(part => part.padStart(2, '0'));
        setBirthYear(parseInt(year, 10));
        setBirthMonth(parseInt(month, 10));
        setBirthDay(parseInt(day, 10));

        setGender(instData.gender);
        setinvite_num(instData.invite_num);
        setSms(instData.noti_sms);
      } catch (error) {
        console.log(error)
      }
    }

    fetchData();
  },[])
  


  useEffect(() => {
    // 출생년도 설정 (1900년부터 현재년도까지)
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 1899 }, (v, k) => 1900 + k);
    setBirthYears(years);

    // 출생월 설정 (1월부터 12월까지)
    const months = Array.from({ length: 12 }, (v, k) => k + 1);
    setBirthMonths(months);
  }, []);

  const handleProfileImageClick = (image) => {
    setSelectedProfileImage(image);
    setCustomImage(null);
  };

  const handleAddImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCustomImage(reader.result);
        setSelectedProfileImage(null);
      };
      reader.readAsDataURL(file);
      setCustom_profile(file)
    }
    
  };

  const handleGenderChange = (value) => {
    setGender(value);
  };

  const idHandler = (e) => {
    setlogin_id(e.target.value);
    // 고치는 순간 false로 변경
    setLoginChecked(false);
    
  }

  const idCheckedHandler = () => {
    // 아이디 체크 api
    if(login_id === ''){
      alert('아이디를 입력하세요')
    }else{
      axios.get('https://api.midtrc.com/auth/id-check?login_id='+login_id).then((res)=>{
        alert(res.data); 
        setLoginChecked(true);
      }).catch((error)=>{alert(error.response.data.detail);setLoginChecked(false)})
    }
  }

  const pwdHandler = (e) => {
    setpwd(e.target.value)
  }

  const rePwdHandler = (e) => {
    if(pwd === e.target.value){
      setPwdChecked(true);
    }else{
      setPwdChecked(false);
    }
    setRePwd(e.target.value);
  }

  const nicknameHandler = (e) => {
    setNickName(e);
  }

  const nickNameTypeHandler = (e) => {
    const firstPart = randomFirstNick[Math.floor(Math.random() * randomFirstNick.length)];
    const secondPart = randomSecondNick[Math.floor(Math.random() * randomSecondNick.length)];
   
    if(e.target.value === 'default'){
      nicknameHandler('')
      setNicknameType('default')
    }else if(e.target.value === 'random'){
      nicknameHandler(firstPart + secondPart)
      setNicknameType('random')
    }
  }

  const phoneHandler = (e) => {
    const input = e.target.value;
    // 정규 표현식을 사용하여 숫자만 남기기
    const onlyNums = input.replace(/[^0-9]/g, '');
    setphone_num(onlyNums);

    // 작성하면 false로 변경
    setPhone_num_checked(false)
  }

  const phoneCheckedHandler = () => {
    setPhone_num_checked(true)
  }

  const emailHandler = (e) => {
    setemail(e.target.value)
    setEmailChecked(false)
  }

  const emailCheckedHandler = () => {
    setEmailChecked(true)
  }

  const nameHandler = (e) => {
    setname(e.target.value)
  }

  const yearHandler = (e) => {
    setBirthYear(parseInt(e.target.value));
    // setBirthDay('')
  }

  const monthHandler = (e) => {
    setBirthMonth(parseInt(e.target.value));
    // setBirthDay('')
  }

  const dayMaxHandler = (e) => {
    if(birthYear && birthMonth){
      let instDay = new Date(birthYear, birthMonth, 0).getDate();
      return instDay
    }
    return 31
  }

  const dayHandler = (e) => {
    if(e.target.value !== ''){
      if(dayMaxHandler()<parseInt(e.target.value)){
        setBirthDay(1)
      }else{
        setBirthDay(parseInt(e.target.value))
      }
    }else{
      setBirthDay()
    }
  }

  const invitePhoneHandler = (e) => {
    const input = e.target.value;
    // 정규 표현식을 사용하여 숫자만 남기기
    const onlyNums = input.replace(/[^0-9]/g, '');
    setinvite_num(onlyNums)
  }

  const submitHandler = () => {
    const reqeustBody = {"req_user":{
        "signup_type": signup_type,
        "client_type": "user",
        "sns_id": sns_id, 
        // "login_id": login_id,
        // "email": email,
        // "pwd": pwd,
        "name": name,
        "nickname": nickname,// 닉네임
        // "phone_num": phone_num,
        "birthday": birthYear+'.'+birthMonth+'.'+birthDay,
        // "gender": gender,   // 성별
        // "invite_num": invite_num,
        "default_profile": custom_profile?null:default_iamge,
        "accepted_terms1": true, // 필수 값이라 무조건 true
        "accepted_terms2": true, // 필수 값이라 무조건 true
        // 사업자 관련 정보
        "business_industry": business_industry, //업종
        "noti_sms": sms
        // 사업자 등록증
    }}
    // 서밋 데이터 프롭스 전송
    const jsonRequestData = JSON.stringify(reqeustBody);
    const formData = new FormData();
    formData.append('req_body',jsonRequestData);
    if(custom_profile){
      formData.append('profile_image',custom_profile);
    }
    // if(e.businessImage){
    //   formData.append('business_image',e.businessImage);
    // }
    axios.put(`https://api.midtrc.com/client/${midMainager?.log_data?.id}?client_type=${midMainager?.log_data?.client_type}`,formData,{
      headers: {
        'access-token': midMainager?.log_data?.access_token
      }
    }).then((res)=>{
      navigate('/');
      dispatch(Logout())
    }).catch((error)=>{console.log(error);})
    
  }

  return (
    <CardContainer>
      <CardContainer2 style={{ border: 'none' }}>
        {/* 프로필 이미지 */}
        <ProfileSection>
          <ProfileLabel>프로필 사진 설정</ProfileLabel>
          <EnlargedImage>
            {customImage ? (
              <img src={customImage} alt="Selected profile" />
            ) : (
              <img src={selectedProfileImage} alt="Selected profile" />
            )}
          </EnlargedImage>
          <ProfileImagesContainer>
            <ImageWrapper onClick={() => {handleProfileImageClick(profileIcon1); setDefault_image('01')}}>
              <ProfileImage
                src={profileIcon1}
                selected={selectedProfileImage === profileIcon1}
              />
            </ImageWrapper>
            <ImageWrapper onClick={() => {handleProfileImageClick(profileIcon2); setDefault_image('02')}}>
              <ProfileImage
                src={profileIcon2}
                selected={selectedProfileImage === profileIcon2}
              />
            </ImageWrapper>
            <ImageWrapper onClick={() => {handleProfileImageClick(profileIcon3); setDefault_image('03')}}>
              <ProfileImage
                src={profileIcon3}
                selected={selectedProfileImage === profileIcon3}
              />
            </ImageWrapper>
            <ImageWrapper onClick={() => {handleProfileImageClick(profileIcon4); setDefault_image('04')}}>
              <ProfileImage
                src={profileIcon4}
                selected={selectedProfileImage === profileIcon4}
              />
            </ImageWrapper>
            <ImageWrapper onClick={() => {handleProfileImageClick(profileIcon5); setDefault_image('05')}}>
              <ProfileImage
                src={profileIcon5}
                selected={selectedProfileImage === profileIcon5}
              />
            </ImageWrapper>
            <ImageWrapper onClick={handleAddImageClick}>
              <AddImageButton
                src={addIcon}
              />
            </ImageWrapper>
          </ProfileImagesContainer>
          <HiddenFileInput
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </ProfileSection>
        {/* 필수 입력 사항 */}
        <Section>
          <SectionLabel><SectionRequired>*</SectionRequired> 필수 입력 사항</SectionLabel>
          <div  style={{ marginBottom:"1rem", width:"100%"}}>
            <Label htmlFor="username"><Required>*</Required> 아이디</Label>
            <InputGroup>
              <Inputbox readOnly onChange={idHandler} value={login_id} type="text" id="username" name="username" placeholder="아이디를 입력하세요."  />
              <Button disabled onClick={idCheckedHandler} isRead={loginChekced}>확인</Button>
            </InputGroup>
          </div>
          {/* <div  style={{ marginBottom:"2rem", width:"100%"}}>
            <Label htmlFor="password"><Required>*</Required> 비밀번호</Label>
            <Inputbox readOnly value={pwd} onChange={pwdHandler} type="password" placeholder="비밀번호를 입력하세요." style={{ height: '5.6rem' }} />
            <Inputbox value={rePwd} onChange={rePwdHandler} type="password" placeholder="비밀번호를 확인하세요." style={{ height: '5.6rem' }} />
          </div> */}
        
          <Label htmlFor="nickname"><Required>*</Required> 닉네임</Label>
          <InputGroup>
            <Select onChange={nickNameTypeHandler}>
              <option value="default">기본 닉네임</option>
              <option value="random">랜덤 닉네임</option>
            </Select>
            <InputNickname value={nickname} onChange={(e)=>nicknameHandler(e.target.value)} type="text" id="nickname" name="nickname" placeholder="닉네임을 입력하세요." readOnly={nicknameType === "random"} />
          </InputGroup>
        </Section>

        <Section>
          <Label htmlFor="phone"><Required>*</Required> 핸드폰 번호</Label>
          <InputGroup>
            <Inputbox readOnly value={phone_num} onChange={phoneHandler} type="text" id="phone" name="phone" placeholder="'-'없이 번호만 입력하세요." />
          <Button disabled onClick={phoneCheckedHandler} isRead={phone_num_checked}>인증</Button>         
          </InputGroup>
          <Labelspace></Labelspace>
          <Label htmlFor="email"><Required>*</Required> 이메일</Label>
          <InputGroup>
            <Inputbox readOnly value={email} onChange={emailHandler} type="email" id="email" name="email" placeholder="이메일 주소를 입력하세요." />
            <Button disabled onClick={emailCheckedHandler} isRead={emailChecked}>확인</Button>
          </InputGroup>
        </Section>

        <LastSection>
          <Label htmlFor="name"><Required>*</Required> 이름</Label>
          <Inputbox value={name} onChange={nameHandler} type="text" id="name" name="name" placeholder="이름을 입력하세요." />
          <Labelspace></Labelspace>
          <Labelspace></Labelspace>
          <Label htmlFor="birthYear"><Required>*</Required> 생년월일</Label>
          <InputGroupBirth>
            <SelectYearMonth value={birthYear} id="birthYear" name="birthYear" onChange={yearHandler}>
              <option value="">출생년도</option>
              {birthYears.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </SelectYearMonth>
            <SelectYearMonth value={birthMonth} id="birthMonth" name="birthMonth" onChange={monthHandler}>
              <option value="">출생월</option>
              {birthMonths.map(month => (
                <option key={month} value={month}>{month}</option>
              ))}
            </SelectYearMonth>
            <Inputdate 
              type="number" 
              placeholder="출생일" 
              min="1" 
              max={dayMaxHandler()}
              onChange={dayHandler}
              value={typeof birthDay === "number"? birthDay: ''}
            />
          </InputGroupBirth>
          <Labelspace></Labelspace>
          
          <Label><Required>*</Required> 성별</Label>
          <RadioGroup>
            <RadioContainer>
              <RadioImage
                src={gender === "male" ? checkboxchecked : checkboxunchecked}
                alt="Radio"
              />
              <RadioLabel htmlFor="male">남성</RadioLabel>
            </RadioContainer>
            <RadioContainer>
              <RadioImage
                src={gender === "female" ? checkboxchecked : checkboxunchecked}
                alt="Radio"
              />
              <RadioLabel htmlFor="female">여성</RadioLabel>
            </RadioContainer>
          </RadioGroup>
            <Labelspace></Labelspace>
            <Labelspace></Labelspace>
            <Labelspace></Labelspace>
          {/* <Label htmlFor="referrer">추천인 핸드폰 번호</Label>
          <Inputbox value={invite_num} onChange={invitePhoneHandler} type="text" id="referrer" name="referrer" placeholder="추천인 핸드폰 번호를 입력하세요." /> */}
            <Labelspace></Labelspace>
            <Labelspace></Labelspace>
            <Labelspace></Labelspace>
            <Labelspace></Labelspace>
            <Labelspace></Labelspace>
          <RedButton onClick={()=>{submitHandler()}}>회원정보 변경</RedButton>
        </LastSection>
      </CardContainer2>
    </CardContainer>
  );
};

export default MypageEditUserInfo;
