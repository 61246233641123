import React, { useState } from "react";
import styled from "styled-components";
import logo from "./../@images/nv_large_logo.svg";
import logo2 from "./../@images/logo2.svg";
import nvMenu from "./../@images/nv-menu-32.svg";
import sidemenuclose from "./../@images/closebtn.svg";
import nvSmallLogo from "./../@images/nv_small_logo.svg";
import nvProfile from "./../@images/nv-profile.svg";
import nvSearch from "./../@images/nv-search-32.svg";
import nvAdd from "./../@images/nv-add-32.svg";
import nvBellDefault from "./../@images/nv-bell-default.svg";
import arrow from "./../@images/arrow.svg";
import sidemenunewmessageicon from "./../@images/mypage-newmessageicon.svg";
import { useNavigate } from "react-router-dom";
import profileIcon1 from "./../@images/profile_icon1.svg";
import profileIcon2 from "./../@images/profile_icon2.svg";
import profileIcon3 from "./../@images/profile_icon3.svg";
import profileIcon4 from "./../@images/profile_icon4.svg";
import profileIcon5 from "./../@images/profile_icon5.svg";
import { Logout } from "../redux/slice/login-slice";
import { useDispatch, useSelector } from "react-redux";

let NavBarBorder = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: white;
`;

let NavBar = styled.div`
  width: 100%;
  height: 4rem;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

let NavBarLogo = styled.img`
  height: 2rem;
`;

let NavBarMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
`;

let NavBarMenuBorder = styled.div`
  width: 100%;
  height: 8rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0rem 2rem;
`;

let NavBarLeft = styled.div`
  width: 10rem;
  height: 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

let NavBarMenuIcon = styled.img`
  height: 3.5rem;
  cursor: pointer;
`;

let NavBarSmallLogo = styled.img`
  height: 3rem;
  cursor: pointer;
`;

let NavBarRight = styled.div`
  width: 12rem;
  height: 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

let SideMenu = styled.div`
  width: 80%;
  position:relative;
  height: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? "0" : "-80%")};
  transition: left 0.3s ease-in-out;
  z-index: 100;
  display: flex;
  flex-direction: column;
  font-family: 'Pretendard-Regular';
`;

let Sidemenuclose = styled.img`
  width: 5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer; /* 클릭 가능하도록 커서 변경 */
`;

let Overlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 90;
`;

let SidemenuProfileContainer = styled.div`
  margin-top:6rem;
  width:100%;
  padding-left:2rem;
  padding-right:2rem;
  box-sizing: border-box;
  display:flex;
  justify-content: space-between;
  align-items: center;
`;

let SidemenuProfileInfo = styled.div`
  position:relative;
`;

let SidemenuProfileNick = styled.div`
  position:absolute;
  font-size:2.2rem;
  width:14rem;
`;

let SidemenuProfileSetting = styled.div`
  margin-top:4rem;
  color:#a6a6a6;
  display:flex;
  gap:0.5rem;
`;

let SidemenuProfileSettingText = styled.div`
  font-size:1.8rem;
  color:#a6a6a6;
`;

let SidemenuProfileImg = styled.img`
  width: 5rem;
`;

let GotoSetProfileArrow = styled.img`
  width: 3rem;
  transform: rotate(-90deg);
  margin-top:-0.35rem;
  cursor:pointer;
`;

let SidemenuLists = styled.div`
  padding-left:2rem;
  padding-right:2rem;
  padding-top:2rem;
  box-sizing: border-box;
`;

const Button2 = styled.button`
  width: 100%;
  height: 5rem;
  padding: 1rem;
  font-size: 1.8rem;
  color: #171717;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-top: 1rem;
`;

let SideMenudivider = styled.div`
    width:100%;
    border-bottom: 1px solid #E8E8E8;
    margin-top:2rem;
    margin-bottom:2rem;
`;

let SidemenuCategory = styled.div`
    color:#171717;
    font-size:2rem;
    font-family: 'Pretendard-Regular';
    font-weight:600;
    cursor:pointer;
`;

let SidemenuMessageCategory = styled.div`
    display:flex;
    gap:1rem;
`;

let SidemenuNewIcon = styled.img`
    margin-top:0.3rem;
    width:2rem;
    height:2rem;
`;

let SidemenuCategory2 = styled.div`
    margin-top:2rem;
    margin-bottom:2rem;
    color:#171717;
    font-size:2rem;
    font-family: 'Pretendard-Regular';
    font-weight:600;
    cursor:pointer;
`;

const NavigationBarMob = () => {
  let midMainager = useSelector((state)=>state.midnightLoginManager);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const ProfileImageHandler = () => {
    let instProfile = midMainager?.log_data?.profile_url;
    if(instProfile === '01'){
        return profileIcon1;
    }else if(instProfile === '02'){
        return profileIcon2;
    }else if(instProfile === '03'){
        return profileIcon3;
    }else if(instProfile === '04'){
        return profileIcon4;
    }else if(instProfile === '05'){
        return profileIcon5;
    }else{
        return instProfile
    }
  };

  const handleLogout = (e) => {
    e.stopPropagation();
    dispatch(Logout());
    navigate('/login');
  };

  return (
    <>
      <NavBarBorder>
        <NavBar>
          <NavBarLogo alt="logo" src={logo} />
        </NavBar>
        <NavBarMenu>
          <NavBarMenuBorder>
            <NavBarLeft>
              <NavBarMenuIcon alt="menu-icon" src={nvMenu} onClick={toggleMenu} />
              <NavBarSmallLogo alt="menu-logo" src={logo2} onClick={()=>{navigate('/'); setMenuOpen(false)}} />
            </NavBarLeft>
            <NavBarRight>
              <NavBarMenuIcon alt="menu-search" src={nvSearch} />
              <NavBarMenuIcon alt="menu-add" src={nvAdd} />
              <NavBarMenuIcon alt="menu-bell-default" src={nvBellDefault} />
            </NavBarRight>
          </NavBarMenuBorder>
        </NavBarMenu>
      </NavBarBorder>
      <Overlay isOpen={isMenuOpen} onClick={toggleMenu} />
      <SideMenu isOpen={isMenuOpen}>
        <Sidemenuclose alt="sideclose-icon" src={sidemenuclose} onClick={toggleMenu} />
        {midMainager?.log_data?.access_token && (
          <SidemenuProfileContainer>
            <SidemenuProfileInfo>
              <SidemenuProfileNick>{midMainager?.log_data?.nickname}</SidemenuProfileNick>
              <SidemenuProfileSetting>
                <SidemenuProfileSettingText onClick={(e)=>{e.stopPropagation(); navigate(midMainager?.log_data?.client_type === 'user'?'/mypage':midMainager?.log_data?.client_type === 'business'?'/businessmypage':'/');setMenuOpen(false)}}>프로필 관리</SidemenuProfileSettingText>
                <GotoSetProfileArrow alt="menu-bell-default" src={arrow} />
              </SidemenuProfileSetting>
            </SidemenuProfileInfo>
            <SidemenuProfileImg alt="sidemenu-profileimg" src={ProfileImageHandler()} />
          </SidemenuProfileContainer>
        )}
        <SidemenuLists>
          {midMainager?.log_data?.access_token ? (
            <Button2 onClick={handleLogout}>로그아웃</Button2>
          ) : (
            <Button2 onClick={() => {navigate('/login'); setMenuOpen(false);}}>로그인</Button2>
          )}
          <SideMenudivider></SideMenudivider>
          <SidemenuMessageCategory>
            <SidemenuCategory onClick={()=>{navigate('/message');setMenuOpen(false)}}>메시지함</SidemenuCategory>
            <SidemenuNewIcon src={sidemenunewmessageicon} alt="New Message" />
          </SidemenuMessageCategory>
          <SideMenudivider></SideMenudivider>
          <SidemenuCategory2 onClick={()=>{navigate('/choice-talk'); setMenuOpen(false)}}>초이스톡</SidemenuCategory2>
          <SidemenuCategory2 onClick={()=>{navigate('/community'); setMenuOpen(false)}}>라운지</SidemenuCategory2>
          <SidemenuCategory2 onClick={()=>{navigate('/job'); setMenuOpen(false)}}>구인구직</SidemenuCategory2>
          <SideMenudivider></SideMenudivider>
          <SidemenuCategory onClick={(e)=>{e.stopPropagation(); navigate(midMainager?.log_data?.client_type === 'user'?'/mypage':midMainager?.log_data?.client_type === 'business'?'/businessmypage':'/');setMenuOpen(false)}}>마이페이지</SidemenuCategory>
        </SidemenuLists>
      </SideMenu>
    </>
  );
};

export default NavigationBarMob;
