import { configureStore} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer} from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';
import loginSlice from './slice/login-slice';
import searchSlice from './slice/search-slice';


const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: [ 'midnightLoginManager', 'searchManager' ],
    blacklist: [  ],
};

const reducer = combineReducers({
    midnightLoginManager : loginSlice,
    searchManager: searchSlice
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    })
});

export default store;