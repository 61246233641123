/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import bookMarkActive from  "./../../@images/bookmark-active.svg";
import category01 from "./../../@images/category-01.svg";
import category02 from "./../../@images/category-02.svg";
import category03 from "./../../@images/category-03.svg";
import category04 from "./../../@images/category-04.svg";
import JobFavorite from "./job-favorite";
import JobList from "./job-list";
import ResumeFavorite from "./resume-favorite";
import ResumeList from "./resume-list";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

let JobBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 17.8rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`

let SectionBorder =styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
`

let JobMenuBorder = styled.div`
    width: 125.2rem;
    margin: 3.2rem 0 0 0;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
`

let MenuButtonBorder = styled.div`
    display: flex;
    gap: 3.2rem;
    height: 100%;
`

let MenuButton = styled.div`
    height: 100%;
    width: auto;
    border-style: solid;
    border-color: ${props => props.index? '#171717': 'rgba(0,0,0,0)'};
    border-width: 0 0 0.4rem 0;
    white-space: nowrap;
    box-sizing: border-box;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2.8rem;
    color: ${props => props.index? '#171717': '#9E9E9E'};
    cursor: pointer;
    padding: 0 1.2rem;
`

let LinkButtonBorder = styled.div`
    display: flex;
`

let LinkButton = styled.button`
    border-radius: 0.4rem;
    height: 4rem;
    padding: 0.8rem 1.6rem;
    background-color: ${props=>props.isRed? "#cd1b1b": "#222222"};
    border: none;
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 500;
    color: white;
    white-space: nowrap;
    cursor: pointer;
`

let BookMarkButton =  styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 0.4rem;
    border-width: 0.1rem;
    border-style: solid;
    border-color: #D2D2D2;
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

let BookMarkIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
`

let SubMenuBorder = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    height: 10.4rem;
    width: 125.2rem;
    gap: 1.6rem;
`

let SubMenuButton = styled.button`
    display: flex;
    gap: 0.4rem;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: ${props=>props.clickedSub? 'white':'black'};;
    background-color: ${props=>props.clickedSub? 'black':'white'};
    border-color:  ${props=>props.clickedSub? 'rgba(0,0,0,0)':'#D2D2D2'};
    border-style: solid;
    border-radius: 4rem;
    border-width: 0.1rem;
    height: 5.6rem;
    box-sizing: border-box;
    padding: 1.6rem 2.8rem 1.6rem 2rem;
    cursor: pointer;
`

let CategoryIcon = styled.img`
    width: 2.4rem;
    height: 2.4rme;
`

const Job = ({clientType}) => {
    let navigate = useNavigate();
    
    let [currentIndex, setCurrentIndex] = useState(true); // 채용정보 or 인재정보
    let [currentSmallIndex, setCurrentSmallIndex] = useState('미드나잇 알바');

    let midMainager = useSelector((state)=>state.midnightLoginManager);
    
    useEffect(()=>{
        if(midMainager?.log_data?.client_type === null || midMainager?.log_data?.client_type === undefined){
            navigate('/login')
        }
    },[]);

    const currentIndexHandler = (e) => {
        setCurrentIndex(e);
    }
    

    const switchIndex = () => {
        switch(currentIndex){
            case true:
                return(
                    <SectionBorder>
                        {/* 채용정보 서브 메뉴 */}
                        <SubMenuBorder>
                            <SubMenuButton clickedSub={currentSmallIndex === '미드나잇 알바'} onClick={()=>{setCurrentSmallIndex('미드나잇 알바')}}>
                                <CategoryIcon src={category01} alt="category01"/>
                                미드나잇 알바
                            </SubMenuButton>
                            <SubMenuButton clickedSub={currentSmallIndex === '단기 알바'} onClick={()=>{setCurrentSmallIndex('단기 알바')}}>
                                <CategoryIcon src={category02} alt="category02"/>
                                단기 알바
                            </SubMenuButton>
                            <SubMenuButton clickedSub={currentSmallIndex === '상시 채용'} onClick={()=>{setCurrentSmallIndex('상시 채용')}}>
                                <CategoryIcon src={category03} alt="category03"/>
                                상시 채용
                            </SubMenuButton>
                            <SubMenuButton clickedSub={currentSmallIndex === '기타/전체'} onClick={()=>{setCurrentSmallIndex('기타/전체')}}>
                                <CategoryIcon src={category04} alt="category04"/>
                                기타/전체
                            </SubMenuButton>
                        </SubMenuBorder>
                        {/* 인기있는 알바 */}
                        <JobFavorite />
                        {/* 알바 정보 리스트 */}
                        <JobList listCateogry={currentSmallIndex}/>
                    </SectionBorder>
                )
            case false:
                return(
                    <SectionBorder>
                        <ResumeFavorite />
                        <ResumeList />
                    </SectionBorder>
                )

            default:
                return <div/>
        }
    }
    return(
        <JobBorder>
            {/* 상단 메인 메뉴 */}
            <JobMenuBorder>
                <MenuButtonBorder>
                    <MenuButton onClick={()=>{currentIndexHandler(true)}} index={currentIndex}>
                        채용정보
                    </MenuButton>
                    <MenuButton onClick={()=>{currentIndexHandler(false)}} index={!currentIndex}>
                        인재정보
                    </MenuButton>
                </MenuButtonBorder>
                <LinkButtonBorder>
                    <LinkButton onClick={()=>{if(clientType==='user'){navigate('/resume-add')}else{alert('로그인 후 이용할 수 있습니다.')}}} isRed={true} style={{marginRight: "0.8rem"}}>
                        + 이력서 등록
                    </LinkButton>
                    <LinkButton onClick={()=>{if(clientType==='business'){navigate('/job-add')}else{alert('사업자 회원으로 로그인 후 이용할 수 있습니다.')}}}isRed={false} style={{marginRight: "1.5rem"}}>
                        + 채용공고 등록
                    </LinkButton>
                    <BookMarkButton>
                        <BookMarkIcon src={bookMarkActive} alt="bookmark-icon"/>
                    </BookMarkButton>
                </LinkButtonBorder>
            </JobMenuBorder>
            {switchIndex()}
        </JobBorder>
    )
}

export default Job;