import React from "react";
import styled from "styled-components";
import borad from "./../../@images/board-img-24.svg";
import files from "./../../@images/file-24.svg";
import book from "./../../@images/like-24.svg";
import bookActive from './../../@images/like-active.svg';
import comment from "./../../@images/comment-24.svg";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import { useSelector } from "react-redux";
import axios from "axios";

let ContentsMobBorder = styled.div`
    width: 100%;
    cursor: pointer;
    border-bottom: solid;
    border-color: #d2d2d2;
    border-width: 0.1rem;
    height: auto;
`

let ContentsBorder = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    // flex-direction: column;
    align-items: center;
    cursor: pointer;
`

let NormalContentsList = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: #f9f9f9;
    padding: 0 1rem;
`

let ContentTitleBorder = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    // row-gap: 0.8rem;
    box-sizing: border-box;
`

let ContentMasterIcon = styled.div`
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 2rem;
    background-color: #10a64c;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-top: 0.2rem;
`

let TitleText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.4rem;
    color: #171717;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
`

let ListIconBorder = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
`

let ListIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
`

let IconText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9E9E9E;
`

let EtcText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.1rem;
    color: #9E9E9E;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const NormalContentsMob = ({ data, isChange }) => {
    let navigate = useNavigate();
    let midMainager = useSelector((state) => state.midnightLoginManager);

    const getDisplayedText = () => {
        let instCount = 40;
        let content = data?.lounge_title || '';

        content = content.replace(/<p>|<\/p>/g, '');

        if (content.length > instCount) {
            return content.slice(0, instCount) + '...';
        }
        return content;
    };

    const likeHandler = () => {
        axios.put(`https://api.midtrc.com/lounge/${data?.id}/like`, {}, {
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res) => { isChange(); }).catch((error) => { console.log(error) });
    }

    const formatTime = (postTime) => {
        const now = new Date();
        const postDate = new Date(postTime);

        const diffInSeconds = Math.floor((now - postDate) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);

        if (diffInHours < 1) {
            return `${diffInMinutes}분`;
        } else if (diffInHours < 3) {
            return `${diffInHours}시간`;
        } else if (diffInDays < 1) {
            return '오늘';
        } else if (diffInDays < 2) {
            return '어제';
        } else {
            const year = postDate.getFullYear().toString().slice(2);
            const month = (postDate.getMonth() + 1).toString().padStart(2, '0');
            const day = postDate.getDate().toString().padStart(2, '0');
            return `${year}.${month}.${day}`;
        }
    }

    const TimeDisplay = ({ postTime }) => {
        const formattedTime = formatTime(postTime);
        return <div>{formattedTime}</div>;
    };

    return (
        <ContentsMobBorder onClick={() => { navigate(`/community-post/${data?.id}`) }}>
            <ContentsBorder>
                <NormalContentsList>
                    <ContentTitleBorder>
                        <div style={{ display: "flex", width: "100%"}}>
                            {
                                data?.is_mobile ?
                                    <ContentMasterIcon>
                                        M
                                    </ContentMasterIcon>
                                    :
                                    null
                            }
                            <TitleText>
                                {parse(getDisplayedText())}
                            </TitleText>
                        </div>
                    </ContentTitleBorder>
                    <div style={{ display: "flex", gap: "0.8rem", marginLeft: "2rem" }}>
                        <ListIconBorder>
                            {
                                data?.image_urls?.length !== 0 ?
                                    <ListIcon src={borad} alt="icon02" />
                                    :
                                    null
                            }
                        </ListIconBorder>
                        <div style={{ display: "flex", gap: "0.8rem" }}>
                            <ListIconBorder style={{ width: "6rem" }}>
                                <ListIcon onClick={(e) => { e.stopPropagation(); likeHandler() }} src={data?.is_like ? bookActive : book} alt="icon03" />
                                <IconText>
                                    {data?.like_count}
                                </IconText>
                            </ListIconBorder>
                            <ListIconBorder style={{ width: "6rem" }}>
                                <ListIcon src={comment} alt="icon04" />
                                <IconText>
                                    {data?.comment_count}
                                </IconText>
                            </ListIconBorder>
                        </div>
                    </div>
                </NormalContentsList>
                
            </ContentsBorder>
        </ContentsMobBorder>
    )
}

export default NormalContentsMob;
