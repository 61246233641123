/*eslint-disable*/
import React, { useState } from "react";
import styled from "styled-components";
import newPage from "./../../@images/newpage-24.svg";
import bookMarkActive from "./../../@images/bookmark-active.svg";
import bookMarkDefault from "./../../@images/bookmark-default.svg"
import kebabmenubtn from "./../../@images/kebabmenuicon.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

let MyJobCardBorder = styled.div`
    height: 25.6rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 1px;
    border-radius: 0.8rem;
    cursor: pointer;

`

let MyJobImages = styled.img`
    min-width: 100%;
    min-height: 11.6rem;
    max-width: 100%;
    max-height: 11.6rem;
    background-color: grey;
    object-fit: cover;
    border-radius: 0.8rem 0.8rem 0 0 ;
`

let MyJobInfoBorder = styled.div`
    box-sizing: border-box;
    padding: 1.5rem 2.4rem;
    background-color: white;
    height: 14rem;
    border-radius: 0 0 0.8rem 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position:relative;
`

let MyJobCompanyName = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #171717;
    box-sizing: border-box;
`

let MyJobContents = styled.div`
    box-sizing: border-box;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    text-align: left;
    color: #171717;
`

let MyJobSubInfoBorder= styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    aling-items: center;
    height: 2.4rem;
`

let MyJobSubArea = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6a6a6a;
    display: flex;
    align-items: center;
`

let MyJobSubMoneyType = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
`

let MyJobSubMoney = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.4rem;
`

let MyJobSubIconBorder = styled.div`
    display: flex;
    gap: 0.2rem;
    align-items: center;
`

let MyJobSubIcon = styled.img`
    height: 2.4rem;
    width: 2.4rem;
    cursor: pointer;
    object-fit: cover;
`

let MyResumeKekbabMenuList = styled.div`
    position: absolute;
    box-sizing: border-box;
    z-index: 3;
    border: 1px solid #D2D2D2;
    background-color: white;
    width: 10.8rem;
    height: 15.6rem;
    right: -9rem;
    border-radius: 2px;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    display: ${props => (props.show ? "flex" : "none")};
    flex-direction: column;
`;

let MyResumeKekbabMenu = styled.div`
    font-family: 'Pretendard-Regular';
    margin-top: 1.2rem;
    font-weight: 400;
    font-size: 1.2rem;
    color: #444444;
    cursor: pointer;
`;

let Myjobkebabamenubar = styled.div`
    display:flex;
    justify-content:space-between;
    position:relative;
    width:30rem;
`;

let MyResumeSubIcon = styled.img`
    height: 2.4rem;
    width: 2.4rem;
    cursor: pointer;
    object-fit: cover;
    position:absolute;
    top:-0.3rem;
    right:-3rem;
`;

const MyJobCard = ({data, isChange}) => {
    let navigate = useNavigate();
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(prevShowMenu => !prevShowMenu);
    };

    

    const areaHandler = () => {
        let parts = data?.address?.split(' ');
        let result = parts?.length >= 2? parts[0] + ' ' + parts[1] : data?.address;
        return result;
    }

    const tcHandler = () => {
        if(data?.tc_pay !== 0 && data?.tc_pay !== null){
            return 'T.C'
        }else if(data?.hourly_pay !== 0 && data?.hourly_pay !== null){
            return '시'
        }else if(data?.daily_pay !== 0 && data?.daily_pay !== null){
            return '일'
        }else if(data?.weekly_pay !== 0 && data?.weekly_pay !== null){
            return '주'
        }else if(data?.monthly_pay !== 0 && data?.monthly_pay !== null){
            return '월'
        }else if(data?.per_pay !== 0 && data?.per_pay !== null){
            return '건'
        }
    };

    const payHandler = () => {
        if(data?.tc_pay !== 0 && data?.tc_pay !== null){
            return data?.tc_pay?.toLocaleString()
        }else if(data?.hourly_pay !== 0 && data?.hourly_pay !== null){
            return data?.hourly_pay?.toLocaleString()
        }else if(data?.daily_pay !== 0 && data?.daily_pay !== null){
            return data?.daily_pay?.toLocaleString()
        }else if(data?.weekly_pay !== 0 && data?.weekly_pay !== null){
            return data?.weekly_pay?.toLocaleString()
        }else if(data?.monthly_pay !== 0 && data?.monthly_pay !== null){
            return data?.monthly_pay?.toLocaleString()
        }else if(data?.per_pay !== 0 && data?.per_pay !== null){
            return data?.per_pay?.toLocaleString()
        }
    };

    const favoritHandler = () => {
        if(midMainager?.log_data?.client_type !== 'user'){
            alert('일반 회원으로 로그인하세요')
        }else{
            axios.put(`https://api.midtrc.com/recruit/${data?.id}/favorite`,{},{
                headers: {
                    'access-token': midMainager?.log_data?.access_token
                }
            }).then((res)=>{isChange(!isChange)}).catch((error)=>{console.log(error.response.data.detail)})
        }
        
    }

    const newWindow = () => {
        const newWindow = window.open('', '_blank');
        newWindow.location.href = `/job-detail/${data?.id}`; 
    }

    const statusHandler = (e) => {
        let reqeustBody = {
            "before": data.status,
            "after": e
            }

        axios.put(`https://api.midtrc.com/recruit/${data.id}/status`,reqeustBody,{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{console.log(res.data); isChange(); setShowMenu(false)}).catch((error)=>{console.log(error)})

    }

    const delHandler = () => {
        axios.delete(`https://api.midtrc.com/recruit/${data.id}`,{},{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{console.log(res.data); isChange(); setShowMenu(false)}).catch((error)=>{console.log(error)})

    }


    return(
        <MyJobCardBorder onClick={()=>{navigate(`/job-edit/${data.id}`)}}>
            <MyJobImages src={data?.main_image_url} alt="MyJob-banner"/>
            <MyJobInfoBorder>
                <Myjobkebabamenubar>
                <MyJobCompanyName>
                    {data?.nickname}
                </MyJobCompanyName>
                <MyResumeSubIcon src={kebabmenubtn} alt="kebabmenu" onClick={(e)=>{e.stopPropagation(); toggleMenu()}}/>
                </Myjobkebabamenubar>
                <MyJobContents>
                    {data?.recruit_title}
                </MyJobContents>
                <MyJobSubInfoBorder>
                    <MyJobSubArea>
                        
                        <MyJobSubMoneyType>
                            {areaHandler()} · 
                        </MyJobSubMoneyType>
                        &nbsp;
                        <MyJobSubMoneyType style={{color: "#F32E13"}}>
                            {tcHandler()}
                        </MyJobSubMoneyType>
                        &nbsp;
                        <MyJobSubMoney>
                            {payHandler()}
                        </MyJobSubMoney>
                        &nbsp;
                        <MyJobSubMoneyType>
                            원
                        </MyJobSubMoneyType>
                    </MyJobSubArea>
                    <MyJobSubIconBorder>
                        <MyJobSubIcon onClick={(e)=>{e.stopPropagation(); favoritHandler()}} src={data?.is_favorite? bookMarkActive : bookMarkDefault} alt="book-mark-active"/>
                        <MyJobSubIcon onClick={(e)=>{e.stopPropagation(); newWindow()}} src={newPage} alt="new-page"/>
                    </MyJobSubIconBorder>
                </MyJobSubInfoBorder>

            <MyResumeKekbabMenuList show={showMenu}>
                <MyResumeKekbabMenu onClick={(e)=>{e.stopPropagation(); delHandler()}}>수정/삭제</MyResumeKekbabMenu>
                <MyResumeKekbabMenu onClick={(e)=>{e.stopPropagation(); statusHandler('represent')}}>대표공고 등록</MyResumeKekbabMenu>
                <MyResumeKekbabMenu onClick={(e)=>{e.stopPropagation(); statusHandler('writing')}}>작성 중</MyResumeKekbabMenu>
                <MyResumeKekbabMenu onClick={(e)=>{e.stopPropagation(); statusHandler('active')}}>구직 중</MyResumeKekbabMenu>
                <MyResumeKekbabMenu onClick={(e)=>{e.stopPropagation(); statusHandler('paused')}}>구직 마감</MyResumeKekbabMenu>
            </MyResumeKekbabMenuList>

            </MyJobInfoBorder>
        </MyJobCardBorder>
    )
}

export default MyJobCard;