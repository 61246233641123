/*eslint-disable*/
import React, { useEffect } from "react";
import styled from "styled-components";
import MainBanner from "./main-banner";
import MainRounge from "./main-rounge";
import MainChoice from "./main-choice";
import MainJob from "./main-job";

import MiddleBanner from "./../../@images/Main-banner-M.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

let MainBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 17.8rem;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`

let MiddleImage = styled.img`
    width: 125.2rem;
    height: 33.6rem;
    object-fit: cover;
`

const Main = () => {
    let navigate = useNavigate();
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    
    useEffect(()=>{
        if(midMainager?.log_data?.client_type === null || midMainager?.log_data?.client_type === undefined){
            navigate('/login')
        }
    },[]);

    return(
        <MainBorder>
            {/* 메인 배너 */}
            <MainBanner />
            {/* 테라스 라운지 */}
            <MainRounge />
            {/* 실시간 현황 */}
            <MainChoice />
            {/* 구인구직 */}
            <MainJob />
            {/* 미들 배너 */}
            <MiddleImage src={MiddleBanner} alt="banner"/>
        </MainBorder>
    )
}

export default Main;