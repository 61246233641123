// 한국 지역 데이터 배열 정의
const korea = [
    {
      province: '서울시',
      cities: [
        {
          city: '서울시',
          districts: [
            '종로구', '중구', '용산구', '성동구', '광진구', '동대문구', '중랑구', '성북구', '강북구', '도봉구',
            '노원구', '은평구', '서대문구', '마포구', '양천구', '강서구', '구로구', '금천구', '영등포구', '동작구',
            '관악구', '서초구', '강남구', '송파구', '강동구'
          ]
        }
      ]
    },

    {
      province: '부산시',
      cities: [
        {
          city: '부산시',
          districts: [
            '중구', '서구', '동구', '영도구', '부산진구', '동래구', '남구', '북구', '해운대구', '사하구',
            '금정구', '강서구', '연제구', '수영구', '사상구', '기장군'
          ]
        }
      ]
    },

    {
      province: '대구시',
      cities: [
        {
          city: '대구시',
          districts: [
            '중구', '동구', '서구', '남구', '북구', '수성구', '달서구', '달성군'
          ]
        }
      ]
    },

    {
      province: '인천시',
      cities: [
        {
          city: '인천시',
          districts: [
            '중구', '동구', '미추홀구', '연수구', '남동구', '부평구', '계양구', '서구', '강화군', '옹진군'
          ]
        }
      ]
    },

    {
      province: '광주시',
      cities: [
        {
          city: '광주시',
          districts: [
            '동구', '서구', '남구', '북구', '광산구'
          ]
        }
      ]
    },

    {
      province: '대전시',
      cities: [
        {
          city: '대전시',
          districts: [
            '동구', '중구', '서구', '유성구', '대덕구'
          ]
        }
      ]
    },

    {
      province: '울산시',
      cities: [
        {
          city: '울산시',
          districts: [
            '중구', '남구', '동구', '북구', '울주군'
          ]
        }
      ]
    },

    {
      province: '세종시',
      cities: [
        {
          city: '세종시',
          districts: []
        }
      ]
    },

    {
      province: '경기도',
      cities: [
        {
          city: '수원시',
          districts: [
            '장안구', '권선구', '팔달구', '영통구'
          ]
        },
        {
          city: '성남시',
          districts: [
            '수정구', '중원구', '분당구'
          ]
        },
        {
          city: '안양시',
          districts: [
            '만안구', '동안구'
          ]
        },
        {
          city: '안산시',
          districts: [
            '상록구', '단원구'
          ]
        },
        {
          city: '고양시',
          districts: [
            '덕양구', '일산동구', '일산서구'
          ]
        },
        {
          city: '용인시',
          districts: [
            '처인구', '기흥구', '수지구'
          ]
        },
        {
          city: '청주시',
          districts: [
            '상당구', '서원구', '흥덕구', '청원구'
          ]
        },
        {
          city: '천안시',
          districts: [
            '동남구', '서북구'
          ]
        },
        {
          city: '평택시',
          districts: []
        },
        {
          city: '용인시',
          districts: [
            '처인구', '기흥구', '수지구'
          ]
        },
        {
          city: '안성시',
          districts: []
        },
        {
          city: '화성시',
          districts: [
            '동탄구', '병점구', '장안구'
          ]
        },
        {
          city: '남양주시',
          districts: []
        },
        {
          city: '이천시',
          districts: []
        },
        {
          city: '안산시',
          districts: [
            '상록구', '단원구'
          ]
        },
        {
          city: '파주시',
          districts: []
        },
        {
          city: '오산시',
          districts: []
        },
        {
          city: '평택시',
          districts: []
        },
        {
          city: '양주시',
          districts: []
        },
        {
          city: '의정부시',
          districts: []
        },
        {
          city: '군포시',
          districts: []
        },
        {
          city: '구리시',
          districts: []
        },
        {
          city: '남양주시',
          districts: []
        },
        {
          city: '김포시',
          districts: []
        },
        {
          city: '용인시',
          districts: [
            '처인구', '기흥구', '수지구'
          ]
        },
        {
          city: '광명시',
          districts: []
        },
        {
          city: '포천시',
          districts: []
        },
        {
          city: '양평군',
          districts: []
        },
        {
          city: '여주시',
          districts: []
        },
        {
          city: '동두천시',
          districts: []
        },
        {
          city: '가평군',
          districts: []
        },
        {
          city: '연천군',
          districts: []
        }
      ]
    },

    {
      province: '강원도',
      cities: [
        {
          city: '춘천시',
          districts: [
            '춘천시'
          ]
        },
        {
          city: '원주시',
          districts: [
            '원주시'
          ]
        },
        {
          city: '강릉시',
          districts: [
            '강릉시'
          ]
        },
        {
          city: '동해시',
          districts: [
            '동해시'
          ]
        },
        {
          city: '태백시',
          districts: [
            '태백시'
          ]
        },
        {
          city: '속초시',
          districts: [
            '속초시'
          ]
        },
        {
          city: '삼척시',
          districts: [
            '삼척시'
          ]
        },
        {
          city: '홍천군',
          districts: [
            '홍천군'
          ]
        },
        {
          city: '횡성군',
          districts: [
            '횡성군'
          ]
        },
        {
          city: '영월군',
          districts: [
            '영월군'
          ]
        },
        {
            city: '평창군',
            districts: [
              '평창군'
            ]
          },
          {
            city: '정선군',
            districts: [
              '정선군'
            ]
          },
          {
            city: '철원군',
            districts: [
              '철원군'
            ]
          },
          {
            city: '화천군',
            districts: [
              '화천군'
            ]
          },
          {
            city: '양구군',
            districts: [
              '양구군'
            ]
          },
          {
            city: '인제군',
            districts: [
              '인제군'
            ]
          },
          {
            city: '고성군',
            districts: [
              '고성군'
            ]
          },
          {
            city: '양양군',
            districts: [
              '양양군'
            ]
          }
        ]
    },

    {
    province: '충청북도',
    cities: [
        {
        city: '청주시',
        districts: [
            '상당구', '서원구', '흥덕구', '청원구'
        ]
        },
        {
        city: '충주시',
        districts: []
        },
        {
        city: '제천시',
        districts: []
        },
        {
        city: '보은군',
        districts: []
        },
        {
        city: '옥천군',
        districts: []
        },
        {
        city: '영동군',
        districts: []
        },
        {
        city: '증평군',
        districts: []
        },
        {
        city: '진천군',
        districts: []
        },
        {
        city: '괴산군',
        districts: []
        },
        {
        city: '음성군',
        districts: []
        },
        {
        city: '단양군',
        districts: []
        }
    ]
    },

    {
    province: '충청남도',
    cities: [
        {
        city: '천안시',
        districts: [
            '동남구', '서북구'
        ]
        },
        {
        city: '공주시',
        districts: []
        },
        {
        city: '보령시',
        districts: []
        },
        {
        city: '아산시',
        districts: []
        },
        {
        city: '서산시',
        districts: []
        },
        {
        city: '논산시',
        districts: []
        },
        {
        city: '계룡시',
        districts: []
        },
        {
        city: '당진시',
        districts: []
        },
        {
        city: '금산군',
        districts: []
        },
        {
        city: '부여군',
        districts: []
        },
        {
        city: '서천군',
        districts: []
        },
        {
        city: '청양군',
        districts: []
        },
        {
        city: '홍성군',
        districts: []
        },
        {
        city: '예산군',
        districts: []
        },
        {
        city: '태안군',
        districts: []
        }
    ]
    },

    {
    province: '전라북도',
    cities: [
        {
        city: '전주시',
        districts: [
            '완산구', '덕진구'
        ]
        },
        {
        city: '군산시',
        districts: []
        },
        {
        city: '익산시',
        districts: []
        },
        {
        city: '정읍시',
        districts: []
        },
        {
        city: '남원시',
        districts: []
        },
        {
        city: '김제시',
        districts: []
        },
        {
        city: '완주군',
        districts: []
        },
        {
        city: '진안군',
        districts: []
        },
        {
        city: '무주군',
        districts: []
        },
        {
        city: '장수군',
        districts: []
        },
        {
        city: '임실군',
        districts: []
        },
        {
        city: '순창군',
        districts: []
        },
        {
        city: '고창군',
        districts: []
        },
        {
        city: '부안군',
        districts: []
        }
    ]
    },

    {
    province: '전라남도',
    cities: [
        {
        city: '목포시',
        districts: []
        },
        {
        city: '여수시',
        districts: []
        },
        {
        city: '순천시',
        districts: []
        },
        {
        city: '나주시',
        districts: []
        },
        {
        city: '광양시',
        districts: []
        },
        {
        city: '담양군',
        districts: []
        },
        {
        city: '곡성군',
        districts: []
        },
        {
        city: '구례군',
        districts: []
        },
        {
        city: '고흥군',
        districts: []
        },
        {
        city: '보성군',
        districts: []
        },
        {
        city: '화순군',
        districts: []
        },
        {
        city: '장흥군',
        districts: []
        },
        {
        city: '강진군',
        districts: []
        },
        {
        city: '해남군',
        districts: []
        },
        {
        city: '영암군',
        districts: []
        },
        {
        city: '무안군',
        districts: []
        },
        {
        city: '함평군',
        districts: []
        },
        {
        city: '영광군',
        districts: []
        },
        {
        city: '장성군',
        districts: []
        },
        {
        city: '완도군',
        districts: []
        },
        {
        city: '진도군',
        districts: []
        },
        {
        city: '신안군',
        districts: []
        }
    ]
    },

    {
    province: '경상북도',
    cities: [
        {
        city: '포항시',
        districts: [
            '남구', '북구'
        ]
        },
        {
        city: '경주시',
        districts: []
        },
        {
        city: '김천시',
        districts: []
        },
        {
        city: '안동시',
        districts: []
        },
        {
        city: '구미시',
        districts: []
        },
        {
        city: '영주시',
        districts: []
        },
        {
        city: '영천시',
        districts: []
        },
        {
        city: '상주시',
        districts: []
        },
        {
        city: '문경시',
        districts: []
        },
        {
        city: '경산시',
        districts: []
        },
        {
        city: '군위군',
        districts: []
        },
        {
        city: '의성군',
        districts: []
        },
        {
        city: '청송군',
        districts: []
        },
        {
        city: '영양군',
        districts: []
        },
        {
        city: '영덕군',
        districts: []
        },
        {
        city: '청도군',
        districts: []
        },
        {
        city: '고령군',
        districts: []
        },
        {
        city: '성주군',
        districts: []
        },
        {
        city: '칠곡군',
        districts: []
        },
        {
        city: '예천군',
        districts: []
        },
        {
        city: '봉화군',
        districts: []
        },
        {
        city: '울진군',
        districts: []
        },
        {
        city: '울릉군',
        districts: []
        }
    ]
    },

    {
    province: '경상남도',
    cities: [
        {
        city: '창원시',
        districts: [
            '의창구', '성산구', '마산합포구', '마산회원구', '진해구'
        ]
        },
        {
        city: '진주시',
        districts: []
        },
        {
        city: '통영시',
        districts: []
        },
        {
        city: '사천시',
        districts: []
        },
        {
        city: '김해시',
        districts: []
        },
        {
        city: '밀양시',
        districts: []
        },
        {
        city: '거제시',
        districts: []
        },
        {
        city: '양산시',
        districts: []
        },
        {
        city: '의령군',
        districts: []
        },
        {
        city: '함안군',
        districts: []
        },
        {
        city: '창녕군',
        districts: []
        },
        {
        city: '고성군',
        districts: []
        },
        {
        city: '남해군',
        districts: []
        },
        {
        city: '하동군',
        districts: []
        },
        {
        city: '산청군',
        districts: []
        },
        {
        city: '함양군',
        districts: []
        },
        {
        city: '거창군',
        districts: []
        },
        {
        city: '합천군',
        districts: []
        }
    ]
    },

    {
    province: '제주도',
    cities: [
        {
        city: '제주시',
        districts: []
        },
        {
        city: '서귀포시',
        districts: []
        }
    ]
    }
];
    
  
export default korea;