import React from "react";
import styled from "styled-components";
import MainBannerMob from "./main-banner-mob";
import MainRoungeMob from "./main-rounge-mob";
import MainChoiceMob from "./main-choice-mob";
import MainJobMob from "./main-job-mob";

import MiddleBanner from "./../../@images/main-banner-mobile.png";

let MainBorderMob = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 12.2rem;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`

let MiddleImageMob = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
`

let MiddleImageContainerMob = styled.div`
    width: 100%;
    height: 23rem;
    padding-left:2rem;
    padding-right:2rem;
    box-sizing: border-box;
    position:relative;
    
`


let MiddleImageContainerTxtsmall = styled.div`
    position:absolute;
    font-size:2.4rem;
    font-family: 'Pretendard-Regular';
    color:white;
    top:30%;
    padding-left:2rem;
    box-sizing: border-box;
`

let MiddleImageContainerTxtbig = styled.div`
    position:absolute;
    font-size:3.2rem;
    font-weight:600;
    font-family: 'Pretendard-Regular';
    color:white;
    top:47%;
    padding-left:2rem;
    box-sizing: border-box;
`

const MainMob = () => {

    return(
        <MainBorderMob>
            {/* 메인 배너 */}
            <MainBannerMob />
            {/* 테라스 라운지 */}
            <MainRoungeMob />
            {/* 실시간 현황 */}
            <MainChoiceMob />
            {/* 구인구직 */}
            <MainJobMob />
            {/* 미들 배너 */}
            <MiddleImageContainerMob>
            <MiddleImageMob src={MiddleBanner} alt="banner"/>
            <MiddleImageContainerTxtsmall>밤에 빛나는, 너를 빛내는</MiddleImageContainerTxtsmall>
            <MiddleImageContainerTxtbig>미드나잇테라스</MiddleImageContainerTxtbig>
            </MiddleImageContainerMob>
        </MainBorderMob>
    )
}

export default MainMob;