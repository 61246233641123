import { ContentState, EditorState } from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import React, { useEffect, useState } from "react";
import './../../App.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateFromHTML } from "draft-js-import-html";
import htmlToDraft from "html-to-draftjs";


const JobEditorReader = ({data}) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    // useEffect(() => {
    //     // Convert HTML string to ContentState and then to EditorState
        

    //     const contentState = stateFromHTML(data);
    //     const newEditorState = EditorState.createWithContent(contentState);
    //     setEditorState(newEditorState);
    // }, [data]);

    useEffect(() => {
        if (data) {  // data가 존재하는지 확인
            const contentBlock = htmlToDraft(data);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const newEditorState = EditorState.createWithContent(contentState);
                setEditorState(newEditorState);
            }
        }
    }, [data]);


    return(
        <div>
            <Editor 
                editorState={editorState}
                wrapperClassName="job-wrapper"
                editorClassName="job-editor"
                toolbarClassName="toolbar-class"
                toolbarHidden={true}
                readOnly={true}
            />
        </div>
    )
}



export default JobEditorReader;