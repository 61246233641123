/*eslint-disable*/
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import React, { useState } from "react";
import './../../App.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import Re from "./../../@images/re-24.svg";
import Do from  "./../../@images/do.svg";
import bold from "./../../@images/tool-bold-24.svg";
import italic from "./../../@images/tool-italic-24.svg";
import emoji from './../../@images/chat-emoji.svg';

const CommunityEditor = ({data}) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    let [draftToServer, setDraftToServer] = useState(''); // html로 변환한 서버용 데이터

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
        setDraftToServer(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        data(draftToServer);
    };

    const uploadImageCallBack = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({ data: { link: reader.result } });
            // insertImage(reader.result);
          };
          reader.readAsDataURL(file);
        });
      };


    return(
        <div>
            <Editor 
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
                toolbarClassName="toolbar-class"
                toolbar={{
                    options: ['history', 'fontFamily', 'fontSize', 'inline', 'image', 'emoji'],
                    inline: {
                        inDropdown: false,
                        className: 'toolbar-button',
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic'],
                        bold: { icon: bold, className: undefined },
                        italic: { icon: italic, className: undefined },
                    },
                    history: {
                        inDropdown: false,
                        className: 'toolbar-button',
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['undo', 'redo'],
                        undo: { icon: Re, className: undefined },
                        redo: { icon: Do, className: undefined },
                      },
                    fontSize: {
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
                        className: 'toolbar-button',
                        component: undefined,
                        dropdownClassName: 'drop-down-draft',
                    },
                    image: {
                        uploadCallback: uploadImageCallBack,
                            previewImage: true,
                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                            defaultSize: {
                            height: 'auto',
                            width: '100%',
                        },
                    },
                    emoji: {
                        icon: emoji,
                        className: 'toolbar-button',
                        emojis: [
                          '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                          '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                          '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                          '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                          '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                          '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                          '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                          '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                          '✅', '❎', '💯',
                        ],
                      },
                }}
            />
        </div>
    )
}



export default CommunityEditor;