import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

let ComplainBorder = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

let ComplainContent = styled.div`
    width: 60rem;
    height: auto;
    border-radius: 1.6rem;
    box-sizing: border-box;
    padding: 3.2rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

let ComplainTitle = styled.div`
    font-size: 2rem;
    font-weight: 600;
`

let ComplainLine = styled.div`
    border-width: 0.1rem 0 0 0;
    border-style: solid;
    border-color: #E8E8E8;
    width: 100%;
    margin-top: 3.2rem;
    margin-bottom: 1.6rem;
`

let CompainInput = styled.input`
    width: 100%;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 2rem;
    font-size: 1.8rem;
    font-weight: 500;
    font-family: 'Pretendard-Regular';
    &::placeholder{
        color: #a9a9a9;
    }
`

let ComplainText = styled.textarea`
    width: 100%;
    min-height: 30rem;
    resize: none;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 2rem;
    font-size: 1.8rem;
    font-weight: 500;
    font-family: 'Pretendard-Regular';
    background-color: #F7F7F7;
    border-radius: 1.6rem;
    &::placeholder{
        color: #a9a9a9;
    }
`

let CancelButton = styled.button`
    min-width: 10.4rem;
    max-width: 10.4rem;
    height: 5.6rem;
    background-color: #B8B8B8;
    border-radius: 0.4rem;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 500;
    font-family: 'Pretendard-Regular';
`

let SubmitButton = styled.button`
    min-width: 10.4rem;
    max-width: 10.4rem;
    height: 5.6rem;
    background-color: #CD1B1B;
    border-radius: 0.4rem;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 500;
    font-family: 'Pretendard-Regular';
`


const Complain = ({handler, complain_type, service_id}) => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [title, setTitle] = useState('');
    let [content, setContent] = useState('');

    const complainHandler = () => {
        let reqeustBody = {
            "complain_type": complain_type,
            "complain_title": title,
            "content": content,
            "service_id": service_id
          }

        axios.post('https://api.midtrc.com/complain',reqeustBody,{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{alert('신고가 완료되었습니다.'); handler()}).catch((error)=>{console.log(error)})
    }

    const titleHandler = (e) => {
        setTitle(e.target.value)
    }

    const contentHandler = (e) => {
        setContent(e.target.value)
    }


    return(
        <ComplainBorder onClick={()=>{handler()}}>
            <ComplainContent onClick={(e)=>{e.stopPropagation();}}>
                <ComplainTitle>
                    신고하기
                </ComplainTitle>
                <ComplainLine />
                <CompainInput value={title} onChange={titleHandler} placeholder="제목을 입력하세요"/>
                <ComplainText value={content} onChange={contentHandler} placeholder="내용을 입력하세요"/>
                <div style={{display: "flex", justifyContent: "right", gap: "1.6rem", width: "100%", marginTop: "3.2rem"}}>
                    <CancelButton onClick={(e)=>{e.stopPropagation(); handler()}}>
                        취소하기
                    </CancelButton>
                    <SubmitButton onClick={()=>{complainHandler()}}>
                        작성하기
                    </SubmitButton>
                </div>
            </ComplainContent>
        </ComplainBorder>
    )
}


export default Complain;