/*eslint-disable*/
import React from "react";
import styled from "styled-components";
import MyComuPostSNSContent from "./mypage-mycomupost-sns-contents";

let MypageBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
`;

let MyfavoritecomupostpostBorder = styled.div`
    margin-top: 0.8rem;
    width: 82.8rem;
    gap: 4rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    background-color: #F7F7F7;
    padding-top: 3rem;
`;

let MyfavoritecomupostpostSection = styled.div`
    width: 82.8rem;
    padding-top: 17.8rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`;

let MypageResumeinfo = styled.div`
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 4rem;
    background-color: white;
    border-radius: 16px;
    border: 1px solid #D2D2D2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let MypageTitleBar = styled.div`
    color: #171717;
    width: 74.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center; 
`;

let MypageMyfavoritecomupostpostTitle = styled.div`
    color: #171717;
    font-family: 'Pretendard-Regular';
    font-size: 2rem;
    font-weight: 600;
`;


let MypageMyfavoritecomupostpostdivider = styled.div`
    width:74.8rem;
    border-bottom: 1px solid #E8E8E8;
    margin-top:2rem;
    margin-bottom:2rem;
`;

let MypageMyfavoritecomupostpostListContainer = styled.div`
    width: 74.8rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;



const Myfavoritecomupostpost = () => {
    return (
        <MypageBackground>
            <MyfavoritecomupostpostBorder>
                <MyfavoritecomupostpostSection>
                    <MypageResumeinfo>
                        <MypageTitleBar>
                            <MypageMyfavoritecomupostpostTitle>내가 찜한 게시글</MypageMyfavoritecomupostpostTitle>
                        </MypageTitleBar>
                        <MypageMyfavoritecomupostpostdivider/>
                        <MypageMyfavoritecomupostpostListContainer>
                            <MyComuPostSNSContent />
                            <MyComuPostSNSContent />
                            <MyComuPostSNSContent />


                        </MypageMyfavoritecomupostpostListContainer>
                    </MypageResumeinfo>
                </MyfavoritecomupostpostSection>
            </MyfavoritecomupostpostBorder>
        </MypageBackground>
    );
};

export default Myfavoritecomupostpost;
