import { createSlice } from "@reduxjs/toolkit";


const searchSlice = createSlice({
    name: 'midnightLoginManager',
    initialState: {
        searchData: []
    },
    reducers : {
        AddTag(state, info){
            state.searchData = info.payload
        },
    }
})


export let { AddTag } = searchSlice.actions;

export default searchSlice.reducer;