import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import linkWhite from "./../../@images/link-white-24.svg";
import bookMarkwhite from "./../../@images/bookmark-white-24.svg";
import bookMark from "./../../@images/bookmark-24.svg";
import bookMarkActive from "./../../@images/bookmark-active.svg";
import arrow from "./../../@images/arrow-24.svg";
import Message from "./message-mob";
import waterMark from "./../../@images/wordmark.svg";
import book from "./../../@images/book.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import useWebSocket from "react-use-websocket";

let ChatWindowBorder = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

`

let ChatWindowclickedBorder = styled.div`
    width: 100%;
    min-height: 21.2rem;
    max-height: 21.2rem;
    box-sizing: border-box;
    border-radius: 1.6rem;
    over-flow: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
`

let ChatWindowclickedImageBorder = styled.div`
    position: relative;
    width: 100%;
    min-height: 13.2rem;
    max-height: 13.2rem;
    padding: 1.6rem 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    over-flow: hidden;
`

let ChatWindowClickedImage = styled.img`
    position: absolute;
    top: 0;
    left: 0%;
    transform: traslateX(-50%);
    object-fit: cover;
    min-width: 100%;
    min-height: 13.2rem;
    max-width: 100%;
    max-height: 13.2rem;
    background-color: white;
    border-radius: 1.6rem 1.6rem 0 0;
    z-index: 0;
`
let ChatWindowClickedCover = styled.div`
    position: absolute;
    top: 0;
    left: 0%;
    transform: traslateX(-50%);
    object-fit: cover;
    min-width: 100%;
    min-height: 13.2rem;
    max-width: 100%;
    max-height: 13.2rem;
    background-color: rgba(0,0,0,0.4);
    border-radius: 1.6rem 1.6rem 0 0;
`

let ChatWindowClickedFirst = styled.div`
    display: flex;
    width: 100%;
    justify-content: right;
    align-items: center;
    z-index: 20;
`

let ChatWindowClickedIconBorder = styled.div`
    display: flex;
    gap: 0.2rem;
`

let ChatWindowClickedIcon = styled.img`
    width: 2.4rem;
    height: 2.4rme;
    object-fit: cover;
    cursor: pointer;
`

let ChatWindowClickedTitleBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: right;
    z-index: 10;
    gap: 0.6rem;
`

let ChatWindowClickedTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 2rem;
    font-weight: 600;
    color: white;
`

let ChatWindowClickedSubTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 400;
    color: white;
`

let ChatWindowClickedThird = styled.div`
    display: flex;
    width: 100%;
    justify-content: right;
    align-items: center;
    z-index: 20;
`

let ChatWindowLinkButton = styled.button`
    background-color: #CD1B1B;
    border-style: solid;
    border-width: 0px;
    border-radius: 0.4rem;
    color: white;
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 400;
    height: 2.4rem;
    padding: 0.2rem 0.8rem;
    cursor: pointer;
`

let ChatWindowContentsBorder = styled.div`
    width: 100%;
    height: 8rem;
    padding: 1.6rem 2.4rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-style: solid;
    border-color: #D2D2D2;
    border-width: 0 0.1rem 0.1rem 0.1rem;
    border-radius:  0 0 1.6rem 1.6rem;
`

let ChatWindowContents = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;
    color: #171717;
`

let ChatWindowMainBorder = styled.div`
    width: 100%;
    height: 76rem;
    background-color: #E6E8EC;
    padding: 11.2rem 1.6rem 7rem 1.6rem;
    box-sizing: border-box;
    border-radius: 1.6rem;
    position: relative;
`

let ChatwindowNoticeBorder = styled.div`
    position: absolute;
    box-sizing: border-box;
    top: 1.6rem;
    width: 90%;
    height: 7.2rem;
    background-color: #F7F7F7;
    padding: 0.8rem 1.6rem;
    z-index: 20;
    border-radius: 0.8rem;
    cursor: pointer;
`

let ChatwindowNotice = styled.div`
    position: relative;
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 400;
    text-align: left;
    color: #171717;
    line-height: 1.8;
`

let ChatWindowArrowBorder = styled.img`
    position: absolute;
    bottom: 0.4rem;
    right: 0rem;
    width: 2.4rem;
    height: 2.4rme;
    transform: rotate(${props=>props.isExpend?'180':'0'}deg);
`

let ChatWindowScrollBorder= styled.div`
    overflow: auto;
    height: 100%;
    width: 100%;
    &::-webkit-scrollbar {
        width: 12px; /* 너비 설정 */
      }
    &::-webkit-scrollbar-thumb {
        background: #CBCED5;
        border-radius: 6px;
    }
`

let ChatWindowScrollInnBorder = styled.div`
    widht: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: right;
    box-sizing: border-box;
    padding: 0rem 2.4rem;
    gap: 2.4rem;
`

let ChatwindowWaterMark = styled.img`
    position: absolute;
    width: 100%;
    top: 11.2rem;
    pointer-events: none;
    z-index: 20;
`

let ChatWindowBook = styled.div`
    position: absolute;
    width: 4.8rem;
    height: 4.8rem;
    bottom: 1.6rem;
    right: 1.6rem;
    cursor: pointer;
`

let PayTableBorder = styled.div`
    position: absolute;
    min-width: 35rem;
    height: 38rem;
    right: 0px;
    bottom: 7rem;
    z-index: 30;
    background-color: white;
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #E8E8E8;
    border-width: 0.1rem;
    overflow: auto;
    text-align: left;
    &::-webkit-scrollbar {
        width: 12px; /* 너비 설정 */
      }
    &::-webkit-scrollbar-thumb {
        background: #CBCED5;
        border-radius: 6px;
    }
`

let PayTableImg = styled.img`
    min-width: 100%;
    min-height: 45rem;
    object-fit: cover;
`

let Title = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
`

const ChatWindowMob = ({data, selectedId}) => {
    let midManager = useSelector((state)=>state.midnightLoginManager);
    let [choiceInfo, setChoiceInfo] = useState();
    let [expend, setExpend] = useState(false);
    let [messageData, setMessageData] = useState([]);
    let [explain, setExplain] = useState(false);
    let limitCount = 10;
    let [offsetCount, setOffsetCount] = useState(0);
    const [initialScroll, setInitialScroll] = useState(true);

    useEffect(()=>{
        const fetchData = async () =>{
            try {
                let response = await axios.get(`https://api.midtrc.com/choice/${selectedId}`,{
                    headers: {
                        'access-token': midManager?.log_data?.access_token
                    }
                })
                let instData = response.data;
                setChoiceInfo(instData);
            } catch (error) {
                console.log(error)
            }
        }
        if(selectedId === 0){

        }else{
            fetchData();
        }
    },[selectedId]);

    useEffect(()=>{
        setOffsetCount(0)
        setInitialScroll(true);
    },[selectedId])

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                let response = await axios.get(`https://api.midtrc.com/talk?group_id=${selectedId}&limit=${limitCount}&offset=${offsetCount}`, {
                    headers: {
                        'access-token': midManager?.log_data?.access_token
                    }
                });
                let newMessages = response.data;
                newMessages.reverse();
                newMessages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

                if (offsetCount === 0) {
                    // `selectedId`가 변경된 경우, 메시지를 초기화하고 새로 추가
                    setMessageData(newMessages);
                } else {
                    // 스크롤 상단에 도달한 경우, 기존 메시지에 새로운 메시지를 앞에 추가
                    setMessageData((prevData) => {
                        const existingIds = new Set(prevData.map(message => message.id));
                        const filteredMessages = newMessages.filter(message => !existingIds.has(message.id));
                        return [...filteredMessages,...prevData];
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };

        if (selectedId !== 0) {
            fetchMessages();
        }
        
    }, [selectedId, offsetCount]);

    const accessToken = midManager?.log_data?.access_token;

    // WebSocket URL에 access_token을 쿼리 파라미터로 포함시킵니다.
    const socketUrl = `wss://api.midtrc.com/ws/${selectedId}/client/${midManager?.log_data?.id}/talk?access_token=${accessToken}`;

    // useWebSocket 훅을 사용하여 WebSocket을 설정합니다.
    const {
        sendMessage,
        lastMessage,
        readyState,
    } = useWebSocket(socketUrl, {
        onOpen: () => {},
        onMessage: (message) => {
            const data = message.data;

            const group_name = data.split('group_name: ')[1]?.split(',')[0].trim();
            const user_name = data.split('user_name: ')[1]?.split(',')[0].trim();
            const content = data.split('content: ')[1]?.split(',')[0].trim();
            const original_created_at = data.split('created_at: ')[1]?.trim();

            if (content && user_name && group_name && original_created_at) {
                // const createdAtDate = new Date(original_created_at);
                // createdAtDate.setHours(createdAtDate.getHours() + 9);

                // const year = createdAtDate.getFullYear().toString().slice(2);
                // const month = (createdAtDate.getMonth() + 1).toString().padStart(2, '0');
                // const day = createdAtDate.getDate().toString().padStart(2, '0');
                // const hours = createdAtDate.getHours().toString().padStart(2, '0');
                // const minutes = createdAtDate.getMinutes().toString().padStart(2, '0');
                // const seconds = createdAtDate.getSeconds().toString().padStart(2, '0');

                // const formatted_created_at = `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
                // original_created_at을 Date 객체로 변환
                const createdAtDate = new Date(original_created_at);

                // 9시간 추가 (UTC+9)
                createdAtDate.setHours(createdAtDate.getHours() + 9);
                
                // 원하는 형식 (2024-08-24T11:35:44+00:00)으로 변환
                const formatted_created_at = createdAtDate.toISOString().replace('.000Z', '+00:00');
                

                const messageForm = {
                    content: content,
                    created_at: formatted_created_at,
                    group_name: group_name,
                    id: 'websocket',
                    status: 'active',
                    updated_at: formatted_created_at,
                    user_name: user_name
                };

                setMessageData(prev => [...prev, messageForm]);
            }
          },
        onError: (error) => console.error('WebSocket error:', error),
        onClose: () => {},
    });

    // 연결 상태에 따라 처리할 수 있습니다.
    useEffect(() => {
        if (readyState === WebSocket.OPEN) {
        console.log('WebSocket is open');
        // 필요한 경우 메시지를 보낼 수 있습니다.
        // sendMessage('Your message here');
        }
    }, [readyState, selectedId]);
    
    const favoriteHandler = () => {
        axios.put(`https://api.midtrc.com/choice/${selectedId}/favorite`,{},{
            headers: {
                'access-token': midManager?.log_data?.access_token
            }
        }).then((res)=>{console.log(res.data);}).catch((error)=>{console.log(error)})
    }

    const noticeHandler = () => {
        setExpend(!expend);
    }

    const truncateHandler = () => {
        let instCount = 45;
        let instText = choiceInfo?.notice;
        if (instText?.length > instCount) {
            return instText.slice(0, instCount) + '...';
          }
        return instText
    }

    const chatScrollRef = useRef();
    useEffect(() => {
        if (chatScrollRef.current && initialScroll) {
            chatScrollRef.current.scrollTop = chatScrollRef.current.scrollHeight;
        }
    }, [selectedId, messageData, initialScroll]);

    // 스크롤 최상단 감지 및 offsetCount 증가
    const handleScroll = () => {
        if (chatScrollRef.current.scrollTop === 0) {
            setOffsetCount((prevCount) => prevCount + limitCount);
            setInitialScroll(false);
        }
    };

    let dropRef01 = useRef();
    const dropRef01Handler = (event) => {
        if (dropRef01.current && !dropRef01.current.contains(event.target)) {
            setExplain(false);
        }else{
            setExplain(true);
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', dropRef01Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef01Handler);
        };
    }, []);

    return(

        <ChatWindowBorder >
            {/* 채팅 창 상단 업체소개 배너 */}
            <ChatWindowclickedBorder>
                <ChatWindowclickedImageBorder>
                    <ChatWindowClickedImage src={choiceInfo?.profile_url} alt="chat-banner"/>
                    <ChatWindowClickedCover />
                    {/* 첫째 줄 */}
                    <ChatWindowClickedFirst>
                        <ChatWindowClickedIconBorder>
                            <ChatWindowClickedIcon src={linkWhite} alt="line-icon02"/>
                            <ChatWindowClickedIcon onClick={()=>{favoriteHandler()}} src={choiceInfo?.is_favorite?bookMarkActive:bookMark} alt="mark-icon02"/>
                        </ChatWindowClickedIconBorder>
                    </ChatWindowClickedFirst>
                    {/* 둘째 줄 */}
                    <ChatWindowClickedTitleBorder>
                        <ChatWindowClickedTitle>
                            {choiceInfo?.name}
                        </ChatWindowClickedTitle>
                        <ChatWindowClickedSubTitle>
                            {choiceInfo?.region}
                        </ChatWindowClickedSubTitle>
                    </ChatWindowClickedTitleBorder>
                    {/* 셋째 줄 */}
                    <ChatWindowClickedThird>
                        <ChatWindowLinkButton onClick={()=>{alert('서비스 준비중 입니다')}}>
                            공고보기
                        </ChatWindowLinkButton>
                    </ChatWindowClickedThird>
                </ChatWindowclickedImageBorder>
                <ChatWindowContentsBorder>
                    <ChatWindowContents>
                        {choiceInfo?.choice_title}
                    </ChatWindowContents>
                </ChatWindowContentsBorder>
            </ChatWindowclickedBorder>
            {/* 채팅 창 */}
            <ChatWindowMainBorder>
                <ChatwindowNoticeBorder isExpend={expend}>
                    <ChatwindowNotice>
                        {
                            expend?
                            choiceInfo?.notice
                            :
                            truncateHandler()
                        }
                        <ChatWindowArrowBorder onClick={noticeHandler} src={arrow} isExpend={expend} alt="arrow-fold"/>
                    </ChatwindowNotice>
                </ChatwindowNoticeBorder>
                <ChatWindowScrollBorder ref={chatScrollRef}>
                    <ChatwindowWaterMark src={waterMark} alt="water-mark"/>
                    <ChatWindowBook ref={dropRef01}>
                        <div style={{position: "relative"}}>
                            <img src={book} alt="fix-book"/>
                            {
                                explain?
                                <PayTableBorder>
                                    <Title style={{fontSize: "1.6rem", fontWeight: "600"}}>
                                        {choiceInfo?.word_title}
                                    </Title>
                                    <PayTableImg src={choiceInfo?.word_img_url} alt="table"/>
                                </PayTableBorder>
                                :
                                null
                            }
                        </div>
                    </ChatWindowBook>
                    <ChatWindowScrollInnBorder>
                        {/* {
                            data?.map((a, i)=>(
                                <Message key={i} data={a} />
                            ))
                        } */}
                        {
                            messageData?.map((a, i)=>(
                                <Message key={i} choiceMessage={a} profile={choiceInfo?.profile_url}/>
                            ))
                        }
                    </ChatWindowScrollInnBorder>
                </ChatWindowScrollBorder>
            </ChatWindowMainBorder>
        </ChatWindowBorder>
    )
}


export default ChatWindowMob;