import React from "react";
import styled from "styled-components";
import newPage from "./../../@images/newpage-24.svg";
import bookMarkActive from "./../../@images/bookmark-active.svg";
import bookMarkDefault from "./../../@images/bookmark-default.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

let JobCardBorder = styled.div`
    height: 25.6rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 1px;
    border-radius: 0.8rem;
    cursor: pointer;
    box-sizing: border-box;
`;

let JobImages = styled.img`
    min-width: 100%;
    min-height: 11.6rem;
    max-width: 100%;
    max-height: 11.6rem;
    background-color: grey;
    object-fit: cover;
    border-radius: 0.8rem 0.8rem 0 0;
`;

let JobInfoBorder = styled.div`
    box-sizing: border-box;
    padding: 1.5rem 2rem;
    background-color: white;
    height: 13rem;
    border-radius: 0 0 0.8rem 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

let JobCompanyName = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #171717;
    box-sizing: border-box;
`;

let JobContents = styled.div`
    box-sizing: border-box;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.4rem;
    text-align: left;
    color: #171717;
    display: flex;
    align-items: flex-start;
`;

let JobSubInfoBorder = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    height: 2.4rem;
    position: relative;
`;

let JobSubArea = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6a6a6a;
    display: flex;
    align-items: center;
`;

let JobSubMoneyType = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
`;

let JobSubMoney = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.2rem;
`;

let JobSubIconBorder = styled.div`
    position: absolute;
    right: 0;
    top: -8rem;
    display: flex;
    gap: 0.1rem;
    align-items: center;
`;

let JobSubIcon = styled.img`
    height: 2.4rem;
    width: 2.4rem;
    cursor: pointer;
    object-fit: cover;
`;

const JobCard = ({data, isChange}) => {
    const navigate = useNavigate();
    const midMainager = useSelector((state) => state.midnightLoginManager);
    const truncatedContent = data?.recruit_title?.length > 20 ? data?.recruit_title?.substring(0, 20) + '...' : data?.recruit_title;
    const areaHandler = () => {
        let parts = data?.address?.split(' ');
        let result = parts?.length >= 2 ? parts[0] + ' ' + parts[1] : data?.address;
        return result;
    };

    const tcHandler = () => {
        if (data?.tc_pay !== 0 && data?.tc_pay !== null) {
            return 'T.C';
        } else if (data?.hourly_pay !== 0 && data?.hourly_pay !== null) {
            return '시';
        } else if (data?.daily_pay !== 0 && data?.daily_pay !== null) {
            return '일';
        } else if (data?.weekly_pay !== 0 && data?.weekly_pay !== null) {
            return '주';
        } else if (data?.monthly_pay !== 0 && data?.monthly_pay !== null) {
            return '월';
        } else if (data?.per_pay !== 0 && data?.per_pay !== null) {
            return '건';
        }
    };

    const payHandler = () => {
        if (data?.tc_pay !== 0 && data?.tc_pay !== null) {
            return data?.tc_pay?.toLocaleString();
        } else if (data?.hourly_pay !== 0 && data?.hourly_pay !== null) {
            return data?.hourly_pay?.toLocaleString();
        } else if (data?.daily_pay !== 0 && data?.daily_pay !== null) {
            return data?.daily_pay?.toLocaleString();
        } else if (data?.weekly_pay !== 0 && data?.weekly_pay !== null) {
            return data?.weekly_pay?.toLocaleString();
        } else if (data?.monthly_pay !== 0 && data?.monthly_pay !== null) {
            return data?.monthly_pay?.toLocaleString();
        } else if (data?.per_pay !== 0 && data?.per_pay !== null) {
            return data?.per_pay?.toLocaleString();
        }
    };

    const favoritHandler = () => {
        if (midMainager?.log_data?.client_type !== 'user') {
            alert('일반 회원으로 로그인하세요');
        } else {
            axios.put(`https://api.midtrc.com/recruit/${data?.id}/favorite`, {}, {
                headers: {
                    'access-token': midMainager?.log_data?.access_token
                }
            }).then((res) => { isChange(!isChange); }).catch((error) => { console.log(error.response.data.detail); });
        }
    };

    const newWindow = () => {
        const newWindow = window.open('', '_blank');
        newWindow.location.href = `/job-detail/${data?.id}`;
    };

    return (
        <JobCardBorder onClick={() => navigate(`/job-detail/${data?.id}`)}>
            <JobImages src={data?.main_image_url} alt="job-banner" />
            <JobInfoBorder>
                <JobCompanyName>
                    {data?.nickname}
                </JobCompanyName>
                <JobContents>
                    {truncatedContent}
                </JobContents>
                <JobSubInfoBorder>
                    <JobSubArea>
                        <JobSubMoneyType>
                            {areaHandler()} · 
                        </JobSubMoneyType>
                        &nbsp;
                        <JobSubMoneyType style={{ color: "#F32E13" }}>
                            {tcHandler()}
                        </JobSubMoneyType>
                        &nbsp;
                        <JobSubMoney>
                            {payHandler()}
                        </JobSubMoney>
                        &nbsp;
                        <JobSubMoneyType>
                            원
                        </JobSubMoneyType>
                    </JobSubArea>
                    <JobSubIconBorder>
                        <JobSubIcon onClick={(e) => { e.stopPropagation(); favoritHandler(); }} src={data?.is_favorite ? bookMarkActive : bookMarkDefault} alt="book-mark-active" />
                        <JobSubIcon onClick={(e) => { e.stopPropagation(); newWindow(); }} src={newPage} alt="new-page" />
                    </JobSubIconBorder>
                </JobSubInfoBorder>
            </JobInfoBorder>
        </JobCardBorder>
    );
};

export default JobCard;
