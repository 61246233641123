import React, { useEffect, useState } from "react";
import styled from "styled-components";
import WarnningMark from './../../@images/warningmark-24.svg';
import ChatMob from "./chat-mob";
import ChatWindowMob from "./chat-window-mob";
import axios from "axios";
import { useSelector } from "react-redux";

let ChoiceBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 17.8rem;
    color: black;
    padding-bottom: 0.2rem;
    padding-left:2rem;
    padding-right:2rem;
    box-sizing: border-box;
`
let MenuBorder = styled.div`
    width: 100%;
    margin-top:-3rem;
    height: 4rem;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    gap: 2rem;
`

let MenuButton = styled.div`
    height: 100%;
    width: auto;
    border-style: solid;
    border-color: ${props => props.index ? '#171717' : 'rgba(0,0,0,0)'};
    border-width: 0 0 0.4rem 0;
    white-space: nowrap;
    box-sizing: border-box;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2rem;
    color: ${props => props.index ? '#171717' : '#9E9E9E'};
    cursor: pointer;
`

let SmallMenuBorder = styled.div`
    height: 4.8rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-style: solid;
    border-color: #E8E8E8;
    border:none;
    box-sizing: border-box;
    margin: 1.5rem 0 1.6rem 0;
`

let SmallMenuButtonBorder = styled.div`
    display: flex;
    height: 2.4rem;
    gap: 1.5rem;
    font-family: 'Pretendard-Regular';
    font-size: 1.8rem;
    font-weight: 600;
`

let SmallButton = styled.div`
    width: auto;
    height: 100%;
    display: flex;
    align-items: flex-start;
    color: ${props=>props.smallIndex? '#171717': '#9E9E9E'};
    cursor: pointer;
`

let AlarmTextBorder = styled.div`
    height: 2rem;
    display: flex;
    justify-content: right:
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 500;
    color: #9e9e9e;
    gap: 0.4rem;
    margin-top:-2rem;
    margin-bottom:2rem;
    
`

let AlarmImage = styled.img`
    width: 2.4rem;
    height: 2.4rm;
`

let AlarmText = styled.div`
    display: flex;
    align-items: center;

`

const Choice = () => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    const [currentIndex, setCurrentIndex] = useState(true); // 실시간 현황보기 or 즐겨찾기 톡만 보기
    const [currentSmallIndex, setCurrentSmallIndex] = useState(0); // 지역
    let [selectedId, setSelectedId] = useState(0);
    let [change, setChange] = useState(false);
    let [groupData, setGroupData] = useState([]);

    let [filterPin, setFilterPin] = useState([]);
    let [filterPinNone, setfilterPinNone] = useState([]);
    

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/choice',{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instList = response.data;
                let filterData = instList.filter(obj=>obj.name !== "바로대기방")
                
                let filterBookMark = filterData.filter(obj => currentIndex === true || obj.is_favorite !== currentIndex );
                
                let filterRigion = currentSmallIndex === 0? filterBookMark: currentSmallIndex === 1? filterBookMark?.filter(obj => obj.region && obj.region.includes('서울')): filterBookMark?.filter(obj => obj.region && !obj.region.includes('서울'))
                let filterPininst = filterRigion?.filter(obj => obj.pin_yn === true);
                let filterPinNoneinst = filterRigion?.filter(obj => obj.pin_yn === false);
                setFilterPin(filterPininst);
                setfilterPinNone(filterPinNoneinst);
                setSelectedId(0);
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    },[change, currentIndex, currentSmallIndex])

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/choice',{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instList = response.data;
                let filterData = instList.filter(obj=>obj.name !== "바로대기방")
                
                let filterBookMark = filterData.filter(obj => currentIndex === true || obj.is_favorite !== currentIndex );
                
                let filterRigion = currentSmallIndex === 0? filterBookMark: currentSmallIndex === 1? filterBookMark?.filter(obj => obj.region && obj.region.includes('서울')): filterBookMark?.filter(obj => obj.region && !obj.region.includes('서울'))
                let filterPininst = filterRigion?.filter(obj => obj.pin_yn === true);
                let filterPinNoneinst = filterRigion?.filter(obj => obj.pin_yn === false);
                setFilterPin(filterPininst);
                setfilterPinNone(filterPinNoneinst);
                setSelectedId(0);
            } catch (error) {
                console.log(error)
            }
        }

        const interval = setInterval(() => {
            const now = new Date();
            const currentHour = now.getHours();
            const currentMinutes = now.getMinutes();
    
            // 오후 7시부터 다음날 오전 11:30분 사이에만 fetchData 실행
            if ((currentHour >= 13 && currentHour < 24) || 
                (currentHour >= 0 && (currentHour < 11 || (currentHour === 11 && currentMinutes <= 30)))) {
                fetchData();
            }
        }, 5000);
    
        // 컴포넌트 언마운트 시 타이머 정리
        return () => clearInterval(interval);
        // fetchData();
    },[])

    const selectedIdHandler = (e) => {
        setSelectedId(e)
    }

    const changeHandler = () => {
        setChange(!change)
    }

    return (
        <ChoiceBorder>
            {/* 메인메뉴 */}
            <MenuBorder>
                <MenuButton index={currentIndex} onClick={() => {setCurrentIndex(true); setSelectedId(0)}}>
                    실시간 현황 보기
                </MenuButton>
                <MenuButton index={!currentIndex} onClick={() => {setCurrentIndex(false); setSelectedId(0)}}>
                    즐겨찾기 톡만 보기
                </MenuButton>
            </MenuBorder>
            {/* 스몰메뉴 */}
            <SmallMenuBorder>
                <SmallMenuButtonBorder>
                    <SmallButton smallIndex={currentSmallIndex  === 0} onClick={() => {setCurrentSmallIndex(0); setSelectedId(0)}}>
                        전체지역
                    </SmallButton>
                    <SmallButton smallIndex={currentSmallIndex  === 1} onClick={() => {setCurrentSmallIndex(1); setSelectedId(0)}}>
                        서울
                    </SmallButton>
                    <SmallButton smallIndex={currentSmallIndex  === 2} onClick={() => {setCurrentSmallIndex(2); setSelectedId(0)}}>
                        그 외 지역
                    </SmallButton>
                </SmallMenuButtonBorder>
            </SmallMenuBorder>
            <AlarmTextBorder>
                <AlarmImage src={WarnningMark} alt="warnig-mark"/>
                <AlarmText>
                    실제 가게 상황은 내용과 다를 수 있으니 자세한 문의는 가게 관계자에게 해주시고 해당 내용은 참고만 하시기 바랍니다.
                </AlarmText>
            </AlarmTextBorder>
            {
                selectedId === 0? <ChatMob changeHandler={changeHandler} data={groupData} filterPin={filterPin} filterPinNone={filterPinNone} selectedId={selectedIdHandler}/> : <ChatWindowMob selectedId={selectedId} data={groupData}/>
            }
        </ChoiceBorder>
    )
}

export default Choice;
