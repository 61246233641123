import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NormalContents from "./normal-contents-mob";
import arrow from "./../../@images/arrow-line-24.svg";
import search from "./../../@images/search.svg";
import next from "./../../@images/circle-next-24.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

let NormalBorder = styled.div`
    width: 100%;
    margin-top: 3.2rem;
`

let NormalFilterBorder = styled.div`
    width: 100%;
    height: 5.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;
`

let NormalFilterLeft = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 1.6rem;
`

let NoramlFilterDropBox = styled.div`
    width: 11rem;
    height: 4rem;
    padding: 1rem 0.8rem 0.8rem 1.6rem;
    box-sizing: border-box;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 0.1rem;
    display: flex;
    justify-content: space-between;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: #6a6a6a;
    cursor: pointer;
    position: relative;
`

let NormalFilterIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    cursor: pointer;
`

let NormalFilterList = styled.div`
    display: flex;
    padding: 1.6rem 0.8rem;
    gap: 0.8rem; 
    height: 5.6rem;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
`

let NormalFilterText = styled.div`
    color: ${props => props.isSelected? '#171717':'#9e9e9e'};
    cursor: pointer;
`

let NormalTopBorder = styled.div`
    width: 100%;
    height: 5rem;
    background-color: #222222;
    border-radius: 0.8rem 0.8rem 0 0;
    display: grid;
    grid-template-columns: 8fr 1fr 1fr;
    margin-bottom: 1.6rem;
`

let NormalFilterRight = styled.div`
    width: 100%;
    display: flex;
    gap: 1.6rem;
    align-items: center;
`

let NormalFilterSearchBorder = styled.div`
    width: 100%;
    height: 5.6rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #D2D2D2;
    border-radius: 0.4rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`

let SearchIcon = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    margin: 0 0.4rem 0 1.6rem;
`

let SearchInput = styled.input`
    width: 100%;
    height: 100%;
    outline: none;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #6A6A6A;
    box-sizing: border-box;
    border: none;
    background: none;
`

let FilterSubmitButton = styled.button`
    margin-top: -2rem;
    margin-bottom: 2rem;
    width: 100%;
    height: 5.6rem;
    background-color: #CD1B1B;
    border-radius: 0.4rem;
    color: white;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    border: none;
    cursor: pointer;
`

let NormalTopBorderTitleText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
`

let NormalListBorder = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 1.6rem;
    margin-bottom: 1.6rem;
    border-width: 0 0 0.1rem 0;
    border-style: solid;
    border-color: #E8E8E8;
`

let PageNaitionBorder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1.6rem;
    margin-top: 1.6rem;
`

let PageIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
    transform: rotate(${props => props.degree}deg);
`

let Pages = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.8rem;
    color: #9E9E9E;
    cursor: pointer;
`

let SearchButton = styled.button`
    width: 30%;
    height: 5.6rem;
    background-color: #CD1B1B;
    border-radius: 0.4rem;
    color: white;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    border: none;
    cursor: pointer;
`

const NormalContentsBoard = () => {
    let navigate = useNavigate();
    let midMainager = useSelector((state) => state.midnightLoginManager);
    let [changeData, setChangeData] = useState(false);
    let [data, setData] = useState([]);
    let [currentPage, setCurrentPage] = useState(1);
    let [itemsPerPage, setItemsPerPage] = useState(10);
    let [searchKeyword, setSearchKeyword] = useState('');
    let [sortOption, setSortOption] = useState('latest');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/lounge', {
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instList = response.data;
                let filterData = instList.filter(obj => obj.lounge_type === 'general');
                setData(filterData);
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [changeData, midMainager?.log_data?.access_token]);

    const isChangeData = () => {
        setChangeData(!changeData);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const handleSearchChange = (event) => {
        setSearchKeyword(event.target.value);
        setCurrentPage(1);
    };

    const handleSortChange = (sortOption) => {
        setSortOption(sortOption);
        setCurrentPage(1);
    };

    const filterAndSortData = () => {
        let filteredData = data.filter(item =>
            item.lounge_title.toLowerCase().includes(searchKeyword.toLowerCase())
        );

        switch (sortOption) {
            case 'latest':
                filteredData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
                break;
            case 'recommendation':
                filteredData.sort((a, b) => b.like_count - a.like_count);
                break;
            case 'comments':
                filteredData.sort((a, b) => b.comment_count - a.comment_count);
                break;
            default:
                break;
        }

        return filteredData;
    };

    const PageNation = () => {
        const pageCount = Math.ceil(filterAndSortData().length / itemsPerPage);
        const pages = [];
        for (let i = 1; i <= pageCount; i++) {
            pages.push(
                <Pages key={i} onClick={() => handlePageChange(i)} isSelected={currentPage === i}>
                    {i}
                </Pages>
            );
        }
        return (
            <PageNaitionBorder>
                <PageIcon src={next} degree={0} alt="icon06" onClick={() => handlePageChange(Math.max(1, currentPage - 1))} />
                {pages}
                <PageIcon src={next} degree={180} alt="icon05" onClick={() => handlePageChange(Math.min(pageCount, currentPage + 1))} />
            </PageNaitionBorder>
        );
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filterAndSortData().slice(indexOfFirstItem, indexOfLastItem);

    return (
        <NormalBorder>
            <FilterSubmitButton onClick={() => { navigate('/community-add/list') }}>
                게시글 작성
            </FilterSubmitButton>
            <NormalFilterLeft>
                <NoramlFilterDropBox>
                    <select style={{ width: '100%', height: '100%', border: 'none', outline: 'none', paddingLeft: "1rem" }} value={itemsPerPage} onChange={handleItemsPerPageChange}>
                        <option value={5}>5 개씩</option>
                        <option value={10}>10 개씩</option>
                        <option value={15}>15 개씩</option>
                        <option value={20}>20 개씩</option>
                    </select>
                    <NormalFilterIcon src={arrow} alt="icon02" />
                </NoramlFilterDropBox>
                <NormalFilterList>
                    <NormalFilterText
                        isSelected={sortOption === 'latest'}
                        onClick={() => handleSortChange('latest')}
                    >
                        최신순
                    </NormalFilterText>
                    <NormalFilterText style={{ fontSize: "1.2rem" }} isSelected={false}>
                        |
                    </NormalFilterText>
                    <NormalFilterText
                        isSelected={sortOption === 'recommendation'}
                        onClick={() => handleSortChange('recommendation')}
                    >
                        추천순
                    </NormalFilterText>
                    <NormalFilterText style={{ fontSize: "1.2rem" }} isSelected={false}>
                        |
                    </NormalFilterText>
                    <NormalFilterText
                        isSelected={sortOption === 'comments'}
                        onClick={() => handleSortChange('comments')}
                    >
                        댓글 많은 순
                    </NormalFilterText>
                </NormalFilterList>
            </NormalFilterLeft>
            <NormalFilterBorder>
                <NormalFilterRight>
                    <NormalFilterSearchBorder>
                        <SearchIcon src={search} alt="search-icon" />
                        <SearchInput
                            placeholder="키워드를 입력하세요"
                            value={searchKeyword}
                            onChange={handleSearchChange}
                        />
                    </NormalFilterSearchBorder>
                    <SearchButton>
                        검색
                    </SearchButton>
                </NormalFilterRight>
            </NormalFilterBorder>
            <NormalTopBorder>
                <NormalTopBorderTitleText>
                    제목
                </NormalTopBorderTitleText>
                {/* <NormalTopBorderTitleText>
                    글쓴이
                </NormalTopBorderTitleText>
                <NormalTopBorderTitleText>
                    작성일
                </NormalTopBorderTitleText> */}
            </NormalTopBorder>
            <NormalListBorder>
                {
                    currentItems?.map((a, i) => (
                        <NormalContents data={a} key={i} isChange={isChangeData} />
                    ))
                }
            </NormalListBorder>
            <PageNation />
        </NormalBorder>
    )
}

export default NormalContentsBoard;
