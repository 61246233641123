/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import snsF from "./../@images/facebook-white-32.svg";
import snsI from "./../@images/insta-white-32.svg";
import snsB from "./../@images/blog-white-32.svg";
import { useNavigate } from "react-router-dom";
import logo from "./../@images/nv_large_logo.svg"
import Policy01 from "./policy01";
import Policy02 from "./policy02";

let FooterBorder = styled.div`
    width: 100%;
    background-color: black;
    box-sizing: border-box;
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-top: 2rem;
`

let TopMenuBorder = styled.div`
    width: 125.2rem;
    display: flex;
    justify-content: left;
`

let TopMenuGridMenu = styled.div`
    width: 56rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: start;
    row-gap: 1.6rem;
`

let TopMenuText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: #9e9e9e;
`

let SubMenuText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: white;
    cursor: pointer;
    height: 2.1rem;
`

let SubMenuGridMenu = styled.div`
    width: 36.4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
`

let SubMenuFlexBorder = styled.div`
    display: flex;
    justify-content: left;
    gap: 0.8rem;
    height: 2.1rem;
`

let SnsIcon = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
    cursor: pointer;
`

let Number = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2rem;
    color: white;
    height: 2.1rem;
    cursor: pointer;
`

let BottomMenuBorder = styled.div`
    width: 125.2rem;
    height: 12rem;
    border-style: solid;
    border-width: 0.1rem 0 0 0;
    border-color: #D2D2D2;
    box-sizing: border-box;
    padding-top: 3.2rem;
    margin-top: 6rem;
    position: relative;
`

let BottomTopMenuBorder = styled.div`
    display: flex;
    gap: 4.6rem;
    margin-bottom: 1.6rem;
`

let BottomText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #6A6A6A;
    line-height: 1.5;
`

let BottomText02 = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #6A6A6A;
    line-height: 1.5;
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
`

let ModalBackBorder = styled.div`
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

let ModalContents = styled.textarea`
    resize: none;
    width: 50%;
    height: 80%;
    background-color: white;
    border-radius: 0.4rem;
    outline: none;
    box-sizing: border-box;
    padding: 3rem;
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-wight: 400;
`

let ModalContentsdiv = styled.div`
    resize: none;
    width: 50%;
    height: 80%;
    background-color: white;
    border-radius: 0.4rem;
    outline: none;
    box-sizing: border-box;
    padding: 3rem;
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-wight: 400;
    overflow: auto;
`

const Footer = () => {
    let navigate = useNavigate();
    let [open, setOpen] = useState(false)
    let [open1, setOpen1] = useState(false)
    let [open2, setOpen2] = useState(false)

    useEffect(()=>{
        if(open){
            document.body.style.overflow = 'hidden';
        }else if(open1){
            document.body.style.overflow = 'hidden';
        }else if(open2){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'auto';
        }
    },[open, open1, open2])

    let ModalPolicy = () => {
        return(
            <ModalBackBorder onClick={()=>{setOpen(false)}}>
                <ModalContents onClick={(e)=>{e.stopPropagation()}} readOnly value={Policy01} onChange={()=>{}} placeholder="policy01"/>
            </ModalBackBorder>
        )
    }

    let ModalPolicy02 = () => {
        return(
            <ModalBackBorder onClick={()=>{setOpen1(false)}}>
                <ModalContentsdiv onClick={(e)=>{e.stopPropagation()}}>
                    <Policy02 />
                </ModalContentsdiv>
            </ModalBackBorder>
        )
    }

    let ModalPolicy03 = () => {
        return(
            <ModalBackBorder onClick={()=>{setOpen2(false)}}>
                <ModalContentsdiv style={{width: 'auto', height: 'auto', lineHeight: "1.8"}} onClick={(e)=>{e.stopPropagation()}}>
                    상호명: 주식회사 블랙스케일<br/>
                    사업자등록번호: 186-87-02958<br/>
                    대표자: 나창주<br/>
                    사업장 소재지: 서울특별시 강남구 테헤란로 311, 2016(역삼동, 아남타워빌딩)<br/>
                    TEL: 010-7333-4520
                </ModalContentsdiv>
            </ModalBackBorder>
        )
    }

    return(
        <FooterBorder>
            {
                open?
                <ModalPolicy />
                :
                null
            }
            {
                open1?
                <ModalPolicy02 />
                :
                null
            }
            {
                open2?
                <ModalPolicy03/>
                :
                null
            }
            <TopMenuBorder>
                <div>
                    <img src={logo} alt="logo" style={{marginLeft: "-0.5rem", marginBottom: "2rem"}}/>
                    <TopMenuText style={{marginBottom: "1rem"}}>
                        주소: 서울특별시 강남구 테헤란로 311, 2016호(역삼동, 아남타워빌딩)
                    </TopMenuText>
                    <TopMenuText style={{marginBottom: "1rem"}}>
                        대표: 나창주
                    </TopMenuText>
                    <TopMenuText style={{marginBottom: "1rem"}}>
                        상호: 주식회사 블랙스케일
                    </TopMenuText>
                    <TopMenuText>
                        TEL: 010-7333-4520
                    </TopMenuText>
                </div>
            </TopMenuBorder>
            <BottomMenuBorder>
                <BottomTopMenuBorder>
                    <TopMenuText onClick={()=>{setOpen(true);setOpen1(false);setOpen2(false)}} style={{cursor: "pointer"}}>
                        이용약관
                    </TopMenuText>
                    <TopMenuText onClick={()=>{setOpen(false);setOpen1(true);setOpen2(false)}} style={{cursor: "pointer"}}>
                        개인정보처리방침
                    </TopMenuText>
                    <TopMenuText onClick={()=>{setOpen(false);setOpen1(false);setOpen2(true)}} style={{cursor: "pointer"}}>
                        사업자정보
                    </TopMenuText>
                </BottomTopMenuBorder>
                <BottomText>
                    미드나잇 테라스는 통신판매 중개자로서 통신판매의 당사자가 아니며,<br/>
                    상품, 상품정보, 거래에 대한 법적인 책임은 판매회원에게 있습니다.
                </BottomText>
                <BottomText02>
                    Copyrightⓒ black scale All Rights Reserved.
                </BottomText02>
            </BottomMenuBorder>
        </FooterBorder>
    )
}

export default Footer;

{/* <FooterBorder>
    <TopMenuBorder>
        <TopMenuGridMenu>
            <TopMenuText>서비스 소개</TopMenuText>
            <TopMenuText>일반안내</TopMenuText>
            <TopMenuText>제휴안내</TopMenuText>
            <TopMenuText>고객안내</TopMenuText>

            <SubMenuText>회사소개</SubMenuText>
            <SubMenuText>이용안내</SubMenuText>
            <SubMenuText>이용안내</SubMenuText>
            <SubMenuText onClick={()=>{navigate('')}}>공지사항</SubMenuText>

            <SubMenuText>서비스 소개</SubMenuText>
            <SubMenuText>안전정책</SubMenuText>
            <SubMenuText>제휴신청</SubMenuText>
            <SubMenuText>자주묻는질문</SubMenuText>

            <SubMenuText></SubMenuText>
            <SubMenuText></SubMenuText>
            <SubMenuText>광고관리 센터</SubMenuText>
            <SubMenuText></SubMenuText>

        </TopMenuGridMenu>
        <SubMenuGridMenu>
            <TopMenuText>
                SNS
            </TopMenuText>
            <TopMenuText>
                문의전화
            </TopMenuText>

            <SubMenuFlexBorder>
                <SnsIcon src={snsF} alt="face"/>
                <SnsIcon src={snsI} alt="insta"/>
                <SnsIcon src={snsB} alt="blog"/>
            </SubMenuFlexBorder>
            <Number>
                070-7799-6299
            </Number>
            <SubMenuText></SubMenuText>
            <SubMenuText style={{lineHeight: "1.5", marginTop: "0.4rem",fontWeight: "400"}}>평일 10:00~18:00<br/>(점심시간 13:00 ~ 14:00) </SubMenuText>
            <SubMenuText></SubMenuText>
            <SubMenuText></SubMenuText>
            <SubMenuText></SubMenuText>
            <SubMenuText></SubMenuText>
            
        </SubMenuGridMenu>
    </TopMenuBorder>
    <BottomMenuBorder>
        <BottomTopMenuBorder>
            <TopMenuText style={{cursor: "pointer"}}>
                이용약관
            </TopMenuText>
            <TopMenuText style={{cursor: "pointer"}}>
                개인정보처리방침
            </TopMenuText>
            <TopMenuText style={{cursor: "pointer"}}>
                사업자정보확인
            </TopMenuText>
        </BottomTopMenuBorder>
        <BottomText>
            미드나잇 테라스는 통신판매 중개자로서 통신판매의 당사자가 아니며,<br/>
            상품, 상품정보, 거래에 대한 법적인 책임은 판매회원에게 있습니다.
        </BottomText>
        <BottomText02>
            Copyrightⓒ MIDNIGHT TERRACE All Rights Reserved.
        </BottomText02>
    </BottomMenuBorder>
</FooterBorder> */}