import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import more from "./../../@images/more-24.svg";
import like from "./../../@images/sns-like-black-40.svg";
import likeActive from './../../@images/like-active.svg';
import comment from "./../../@images/sns-comment-black-40.svg";
import arrow from "./../../@images/arrow-line-24.svg";
import emoji from "./../../@images/chat-emoji.svg";
import lock from "./../../@images/lock-40.svg";
import lockActive from "./../../@images/lock-active-24.svg";
import commentLike from "./../../@images/comment_like_24.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import CommentsInputSubBox from "./comments-input-sub-box";
import CommentsInputSubReplyBox from "./comments-input-sub-reply-box";
import profileIcon1 from "./../../@images/profile_icon1.svg";
import profileIcon2 from "./../../@images/profile_icon2.svg";
import profileIcon3 from "./../../@images/profile_icon3.svg";
import profileIcon4 from "./../../@images/profile_icon4.svg";
import profileIcon5 from "./../../@images/profile_icon5.svg";

// 댓글
let ReplyMainBorder = styled.div`
    width: 100%;
    min-height: 11.2rem;
`;

let ReplyBorder = styled.div`
    width: 100%;
    min-height: 11.2rem;
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 0.8rem;
    box-sizing: border-box;
`;

let ReplyProfileImage = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    background-color: #f2f2f2;
`;

let ReplyContentsBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-contetn: right;
    box-sizing: border-box;
`;

let ReplyNickText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: #171717;
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
`;

let ReplyTimeText = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9e9e9e;
    margin-left: 0.8rem;
`;

let ReplyContentsText = styled.textarea`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #444444;
    width: 100%;
    resize: none;
    outline: none;
    border: none;
    padding: 0;
`;

let ReplyShowMore = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9e9e9e;
    cursor: pointer;
`;

let ReplyToolBorder = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    gap: 0.8rem;
    justify-content: right;
    align-items: flex-start;
`;

let ReplyIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    cursor: pointer;
`;

let ReplyCount = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
    color: #9e9e9e;
    width: 100%;
    text-align: center;
`;

let ReReplyListBorder = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;
    padding-left: 3.2rem;
`;

const ReplySubBox = ({data, lounge_id, isChange}) => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [isClickedReply, setIsClickedReply] = useState(false);
    const [contentHeight, setContentHeight] = useState('auto');
    const clickedReplyHandler = () => {
        setIsClickedReply(!isClickedReply)
    }
    const replyContentsRef = useRef();

    useEffect(() => {
        if (replyContentsRef.current) {
            const scrollHeight = replyContentsRef.current.scrollHeight;
            if (contentHeight === 'auto') {  // Set height only if it's 'auto'
                setContentHeight(scrollHeight + 5 + 'px');
            }
        }
    }, [data?.content]);

    const replyCount = () => {
        axios.put(`https://api.midtrc.com/comment/${data?.id}/like?lounge_id=${lounge_id}`,{},{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{ isChange(); console.log(res.data)}).catch((error)=>{console.log(error)});
    }

    const formatTime = (postTime) => {
        const now = new Date();
        const postDate = new Date(postTime);
    
        const diffInSeconds = Math.floor((now - postDate) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
    
        if (diffInHours < 1) {
            return `${diffInMinutes}분`;
        } else if (diffInHours < 3) {
            return `${diffInHours}시간`;
        } else if (diffInDays < 1) {
            return '오늘';
        } else if (diffInDays < 2) {
            return '어제';
        } else {
            const year = postDate.getFullYear().toString().slice(2); // Get last two digits of the year
            const month = (postDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
            const day = postDate.getDate().toString().padStart(2, '0');
            return `${year}.${month}.${day}`;
        }
    }

    const TimeDisplay = ({ postTime }) => {
        const formattedTime = formatTime(postTime);
        return <div>{formattedTime}</div>;
    };

    // console.log(data?.sub_comment_id);
    const ProfileImageHandler = () => {
        let instProfile = data?.profile_url;
        if(instProfile === '01'){
            return profileIcon1;
        }else if(instProfile === '02'){
            return profileIcon2;
        }else if(instProfile === '03'){
            return profileIcon3;
        }else if(instProfile === '04'){
            return profileIcon4;
        }else if(instProfile === '05'){
            return profileIcon5;
        }else{
            return instProfile
        }
    }

    return(
        <ReplyMainBorder>
            <ReplyBorder>
                {/* 프로필 이미지 */}
                <ReplyProfileImage src={ProfileImageHandler()} alt="re-profile"/>
                {/* 닉네임, 시간, 내용, 대댓글 */}
                <ReplyContentsBorder>
                    <ReplyNickText>
                            {data?.nickname}
                        <ReplyTimeText>
                            <TimeDisplay postTime={data?.updated_at}/>
                        </ReplyTimeText>
                    </ReplyNickText>
                    <ReplyContentsText ref={replyContentsRef} value={data?.content?data?.content:''} readOnly onChange={()=>{}} style={{ height: contentHeight }}/>
                    <ReplyShowMore style={{marginBottom: "1rem"}} onClick={(e)=>{e.stopPropagation(); clickedReplyHandler()}}>
                        {
                            isClickedReply?
                            <div>닫기</div>:<div>댓글</div>
                        }
                    </ReplyShowMore>
                </ReplyContentsBorder>
                <ReplyToolBorder>
                    <div>
                        <ReplyIcon src={commentLike} alt="icon08" onClick={()=>{replyCount()}}/>
                        <ReplyCount>
                            {data?.like_count}
                        </ReplyCount>
                    </div>
                    <ReplyIcon src={more} alt="icon08"/>
                </ReplyToolBorder>
            </ReplyBorder>
            {
                isClickedReply?
                <CommentsInputSubReplyBox data={data} reply_Id={data?.sub_comment_id} lounge_id={lounge_id} isChange={isChange} />
                :
                null
            }

            
        </ReplyMainBorder>
    )
}

export default ReplySubBox;