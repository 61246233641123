import {GoogleLogin} from "@react-oauth/google";
import {GoogleOAuthProvider} from "@react-oauth/google";
import styled from "styled-components";
import google from "./../@images/google.svg";

const SocialButton = styled.img`
    width: 5.6rem;
    height: 5.6rem;
    cursor: pointer;
`;

const GoogleLoginButton = () => {
    const clientId = '1066421655241-cdgkma21142bv0pt3unhhdjuhncodcoq.apps.googleusercontent.com'
    return (
        <>
            <GoogleOAuthProvider  clientId={clientId}>
                <GoogleLogin 
                    onSuccess={(res) => {
                        console.log(res);
                    }}
                    onFailure={(err) => {
                        console.log(err);
                    }}
                />
            </GoogleOAuthProvider>
            <SocialButton src={google} />
        </>
    );
};

export default GoogleLoginButton