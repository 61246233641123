import React, { useState } from "react";
import styled from "styled-components";
import status01 from "./../../@images/status-easy.svg";
import status02 from "./../../@images/status-normal.svg";
import status03 from "./../../@images/status-busy.svg";
import ChatRoom from "./chat-room-mob";

let ChatMainBorder = styled.div`
    width: 100%;
`

let ChatRoomBorder = styled.div`
    width: 100%;
    height: 106.0rem;
    margin-bottom: 0.8rem;
`

let ChatRoomStatusBorder = styled.div`
    width: 100%;
    height: 3.6rem;
    display: flex;
    justify-content: right;
    align-items: flex-start;
    box-sizing: border-box;
    border-width: 0 0 0.1rem 0;
    border-style: solid;
    border-color: #E8E8E8;
    gap: 0.8rem;
`

let ChatStatus = styled.div`
    height:2.4rm;
    width: auto;
    display: flex;
    gap: 0.2rem;
    align-items: center;
`

let StatusImage = styled.img`
    width: 2.4rem;
    height: 2.4rem;
`

let StatusText = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 400;
    color: #6a6a6a;
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
`

let ChatShowMoreBorder = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 400;
    color: #9e9e9e;
    cursor: pointer;
`

const Chat = ({ data, selectedId, changeHandler,filterPin, filterPinNone }) => {

    

    return (
        <ChatMainBorder>
            <ChatRoomBorder>
                <ChatRoomStatusBorder>
                    <ChatStatus>
                        <StatusImage src={status01} alt="status01" />
                        <StatusText>여유</StatusText>
                    </ChatStatus>
                    <ChatStatus>
                        <StatusImage src={status02} alt="status02" />
                        <StatusText>중간</StatusText>
                    </ChatStatus>
                    <ChatStatus>
                        <StatusImage src={status03} alt="status03" />
                        <StatusText>혼잡</StatusText>
                    </ChatStatus>
                </ChatRoomStatusBorder>
                <div>
                    {
                        filterPin?.map((a, i)=>(
                            <ChatRoom changeHandler={changeHandler} key={i} data={a} selectedId={selectedId} />
                        ))
                    }
                    {
                        filterPinNone?.map((a, i)=>(
                            <ChatRoom changeHandler={changeHandler} key={i} data={a} selectedId={selectedId} />
                        ))
                    }
                    
                    {/* <ChatShowMoreBorder>+ 더 보기</ChatShowMoreBorder> */}
                </div>
            </ChatRoomBorder>
        </ChatMainBorder>
    );
}

export default Chat;
