import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Logined, ViewLogined } from './redux/slice/login-slice';
import { useNavigate } from 'react-router-dom';
import naverIcon from "./@images/naver.svg";
import axios from 'axios';

const Button = styled.button`
  padding: 0.5rem 1rem;
  height: 5.6rem;
  width: 7.6rem;
  font-size: 1.6rem;
  color: #fff;
  background-color: ${props => props.isRead? '#dedede' : '#222222'} ;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 1rem;
  margin-top: -1rem;
`;

const SocialButton = styled.img`
  width: 5.6rem;
  height: 5.6rem;
  cursor: pointer;
`;

const MoK_react_index_button_naver = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [isCheckedInst, setIsChecked] = useState(false)
  let [deviceType, setDeviceType] = useState('');
  let [browserType, setBrowserType] = useState('');
  const naverRef = useRef()
  
useEffect(()=>{
    const userAgent = navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setDeviceType(isMobile ? 'Mobile' : 'Desktop');

    let browserTypeInst = '';

    if (userAgent.indexOf("Firefox") > -1) {
        browserTypeInst = "Mozilla Firefox";
    } else if (userAgent.indexOf("Chrome") > -1) {
        browserTypeInst = "Google Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
        browserTypeInst = "Apple Safari";
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
        browserTypeInst = "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
        browserTypeInst = "Microsoft Internet Explorer";
    }

    setBrowserType(browserTypeInst);
  },[])

  useEffect(() => {
    window.result = function(result) {
      try {
        // result 값이 올바른 JSON 형식인지 확인
        const parsedResult = typeof result === "string" ? JSON.parse(result) : result;
  
        // 데이터 구조 확인
        if (parsedResult && parsedResult.data && parsedResult.data.userPhone) {
  
          // NaverLogin 함수 호출
          NaverLogin();
        } else {
          throw new Error("유효하지 않은 데이터 구조");
        }
      } catch (error) {
        console.error("결과 처리 오류: ", error);
        alert('성인인증에 실패했습니다.');
      }
    };
  }, [dispatch]);
  

  /* PC 스크립트 사용시 mok_react_server(5.1 주석해제) */
  const mok_popup = () => {
    window.MOBILEOK.process("https://auth.midtrc.com/mok/mok_std_request", "WB", "result");
  };

  /* 모바일 스크립트 사용시 mok_react_server(5.2 주석해제) */
  // const mok_move = () => {
  //   window.MOBILEOK.process("https://이용기관 본인인증-표준창 요청 (서버 (Node.js))URL/mok/mok_std_request", "WB", "");
  // };

  const NaverLogin = () => {
    const { naver } = window;
    
    const clientId = process.env.REACT_APP_NAVER_CLIENT_ID;
    const callbackUrl = 'https://midtrc.com/login'
    
    const initializeNaverLogin = () => {
      const naverLogin = new naver.LoginWithNaverId({
          clientId,
          callbackUrl,
          isPopup: false,
          loginButton: { color: "green", type: 1, height: "56" },
          callbackHandle: true,
      });
      naverLogin.init();
      
      naverLogin.getLoginStatus(async function (status) {
          if (status) {
              const userid = naverLogin.user.getEmail();
              const username = naverLogin.user.getName();
              const token = naverLogin.accessToken.accessToken; // accessToken 가져오기
              const formattedBirthday = naverLogin.user.birthday.replace("-", ".");
              
            
              axios.get(`https://api.midtrc.com/auth/id-check?login_id=${userid}`).then((res)=>{
                // 회원 가입 후 로그인
                const requestBody = {
                  "signup_type": 'naver',
                  "default_profile": naverLogin.user.profile_image,
                  "client_type": 'user',
                  "sns_id": userid, 
                  "login_id": userid,
                  "nickname": naverLogin.user.nickname,// 닉네임
                  "email": naverLogin.user.email,
                  "pwd": null,
                  "name": naverLogin.user.name,
                  "phone_num": naverLogin.user.mobile,
                  "birthday": naverLogin.user.birthyear+'.'+formattedBirthday,
                  "gender": naverLogin.user.gender === 'M'? 'male':'female',   // 성별
                  "invite_num": null,
                  "accepted_terms1": true,
                  "accepted_terms2": true,
                  "noti_sms": false,
                };
  
                const jsonRequestData = JSON.stringify(requestBody);
                const formData = new FormData();
                formData.append('req_body',jsonRequestData);
                
                axios.post('https://api.midtrc.com/auth/signup',formData).then((res)=>{
                  // 로그인
                  axios.post('https://api.midtrc.com/auth/login',{
                      "signup_type": "naver",
                      "login_id": userid,
                      "pwd": null,
                      "latest_device": deviceType,
                      "latest_browser": browserType
                    }).then((res)=>{console.log(res.data); dispatch(Logined(res.data)); navigate('/')}).catch((error)=>{console.log(error)})


                }).catch((error)=>{console.log(error)})
              }).catch((error)=>{
                // 로그인
                axios.post('https://api.midtrc.com/auth/login',{
                  "signup_type": "naver",
                  "login_id": userid,
                  "pwd": null,
                  "latest_device": deviceType,
                  "latest_browser": browserType
                }).then((res)=>{console.log(res.data); dispatch(Logined(res.data)); navigate('/')}).catch((error)=>{console.log(error)})
              })
          }
      });
   };

    const userAccessToken = () => {
      window.location.href.includes('access_token') && getToken()
    }
      
    const getToken = () => {
      const token = window.location.href.split('=')[1].split('&')[0]
      localStorage.setItem('access_token', token)
      // setGetToken(token)
    }

    useEffect(() => {
      initializeNaverLogin()
      userAccessToken()

      if (isCheckedInst && naverRef.current) {
        naverRef.current.children[0].click();
      }
      // naverRef.current.children[0].click()
    }, [isCheckedInst])
    
  };

  return (
    <main>
      {/* index.html에 설정을 안 할 시 주석해제 */}
      <Helmet>
        {/* 운영 */}
        {/* <script src="https://cert.mobile-ok.com/resources/js/index.js"></script> */}
        {/* 개발 */}
        <script src="https://scert.mobile-ok.com/resources/js/index.js"></script>
      </Helmet>
      <div>
        {/* <Button isRead={isCheckedInst} onClick={mok_popup}>인증</Button> */}
        <SocialButton  onClick={mok_popup} src={naverIcon} alt="Naver" />
        <div ref={naverRef} style={{display: "none"}} id="naverIdLogin"></div>
      </div>
    </main>
  );
};

export default MoK_react_index_button_naver;

