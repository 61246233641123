/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import more from "./../../@images/more-24.svg";
import like from "./../../@images/sns-like-black-40.svg";
import likeActive from './../../@images/like-active.svg';
import comment from "./../../@images/sns-comment-black-40.svg";
import arrow from "./../../@images/arrow-line-24.svg";
import emoji from "./../../@images/chat-emoji.svg";
import lock from "./../../@images/lock-40.svg";
import lockActive from "./../../@images/lock-active-24.svg";
import commentLike from "./../../@images/comment_like_24.svg";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import parse from 'html-react-parser';
import CommentsInputBox from "./comments-input-box";
import ReplyBox from "./reply-box";

let MypageBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
`;

let MyNoticeBoardPostBorder = styled.div`
    margin-top: 0.8rem;
    width: 82.8rem;
    gap: 4rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    background-color: #F7F7F7;
    padding-top: 3rem;
`;

let MyNoticeBoardPostSection = styled.div`
    width: 82.8rem;
    padding-top: 17.8rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`;

let MypageNoticeBoardPostinfo = styled.div`
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 4rem;
    background-color: white;
    border-radius: 16px;
    border: 1px solid #D2D2D2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let MypageTitleBar = styled.div`
    color: #171717;
    width: 74.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center; 
`;

let MypageMyNoticeBoardPostwriter = styled.div`
    color: #171717;
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 600;
`;

let MypageMyNoticeBoardPostcategory = styled.div`
    color: #6a6a6a;
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 600;
    cursor:pointer
`;

let MypageMyNoticeBoardPostdivider = styled.div`
    width:74.8rem;
    border-bottom: 1px solid #E8E8E8;
    margin-top:2rem;
    margin-bottom:2rem;
`;

let NoticeboardpostContainer = styled.div`
    width: 74.8rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

let NoticeboardpostTitle = styled.div`
    width: 74.8rem;
    font-size:2rem;
    margin-bottom: 2rem;
    font-weight:600;
    font-family: 'Pretendard-Regular';
`;

let Noticeboardpostingcontent = styled.div`
    width: 74.8rem;
    font-size:1.8rem;
    gap: 2rem;
    margin-bottom: 2rem;
    font-family: 'Pretendard-Regular';
    line-height: 1.5;
`;

let ContentsBoarder = styled.div`
    width: 74.8rem;
    box-sizing: border-box;
    padding: 3.2rem 3.2rem 1.6rem 3.2rem;
    border-top: solid;
    border-width: 0.1rem;
    border-color: #d2d2d2;
    border-radius: 0.4rem;
    margin-bottom: 1.6rem;
`;

let StatusBorder = styled.div`
    width: 74.8rem;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

let SnsIconBorder = styled.div`
    display: flex;
    gap: 0.4rem;
    align-items: center;
`;

let SnsIcon = styled.img`
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    cursor: pointer;
`;

let SnsIconText = styled.div`
    height: auto;
    width: 3rem;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.8rem;
    color: #171717;
    text-align: left;
`;




// 코멘트
let CommentBorder = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: auto;
    border-radius: 0.8rem;
    border: none;
    background-color: #F7F7F7;
    padding: 1.6rem 2.4rem;
`;

let CommentsDropBorder = styled.div`
    display: flex;
    gap: 0.8rem;
`;

let CommentsDrop = styled.div`
    cursor: pointer;
    width: 14rem;
    height: 4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: white;
    position: relative;
`;

let CommentsNick = styled.input`
    width: 14rem;
    height: 4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: #E8E8E8;
    outline: none;
`

let FilterIconArrow = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
`;

let CommentsInputBorder= styled.div`
    width: 100%;
    min-height: 5.6rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 1.6rem;
    box-sizing: border-box;
    background-color: white;
    position: relative;
`;

let CommentsIconBorder = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 1.6rem;
    top: 0.8rem;
`;

let CommentsIcon = styled.img`
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    cursor: pointer;
`;

let CommentsSubmitButton = styled.button`
    height: 3.6rem;
    background-color: #CD1B1B;
    box-sizing: border-box;
    padding: 0.8rem 1.2rem;
    border: none;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: white;
    cursor: pointer;
`;

let CommentsInput = styled.textarea`
    box-sizing: border-box;
    width: 42.9rem;
    min-height: 5.6rem;
    resize: none;
    border: none;
    outline: none;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    box-sizing: border-box;
    overflow: hidden;
    padding: 1.6rem 0;
    line-height: 1.5;
`;

// 댓글
let ReplyMainBorder = styled.div`
    width: 100%;
    min-height: 11.2rem;
`;

let ReplyBorder = styled.div`
    width: 100%;
    min-height: 11.2rem;
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 0.8rem;
    box-sizing: border-box;
`;

let ReplyProfileImage = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    background-color: black;
`;

let ReplyContentsBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-contetn: right;
    box-sizing: border-box;
`;

let ReplyNickText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: #171717;
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
`;

let ReplyTimeText = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9e9e9e;
    margin-left: 0.8rem;
`;

let ReplyContentsText = styled.textarea`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #444444;
    width: 100%;
    resize: none;
    outline: none;
    border: none;
    padding: 0;
`;

let ReplyShowMore = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9e9e9e;
    cursor: pointer;
`;

let ReplyToolBorder = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    gap: 0.8rem;
    justify-content: right;
    align-items: flex-start;
`;

let ReplyIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    cursor: pointer;
`;

let ReplyCount = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
    color: #9e9e9e;
    width: 100%;
    text-align: center;
`;

let ReReplyListBorder = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;
    padding-left: 3.2rem;
`;

let DropBorder = styled.div`
    width: 14rem;
    box-sizing: border-box;
    padding: 0 2rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #dedede;
    border-width: 0.1rem;
    background-color: white;
    position: absolute;
    top: 4.5rem;
    left: 0;
    z-index: 30;
`

let DropBorderItem = styled.div`
    font-size: 1.4rem;
    text-align: left;
    padding: 1rem 0;
    &:hover{
        color: red;
    }
`

let EmojisBorder = styled.div`
    
    height: 24rem;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #dedede;
    border-width: 0.1rem;
    background-color: white;
    position: absolute;
    top: 4.5rem;
    right: 0;
    z-index: 30;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    overflow-y: auto;
    overflow-x: hidden;
`

let EmojisItems = styled.div`
    box-sizing: border-box;
    padding: 1rem;
    font-size: 1.8rem;
    cursor: pointer;
    &:hover{
        background-color: #f2f2f2;
    }
`

let ShowmoreButton = styled.button`
    width: 100%;
    height: 3rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #a9a9a9;
    background: none;
    border: none;
    cursor: pointer;
`

let PhotoImage = styled.img`
    width: 100%;
    object-fit: cover;
    background-color: #f5f5f5;
    cursor: pointer;
`

const CommunityPost = () => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    const params = useParams();
    let [data, setdata] = useState();
    let [changeData, setChangeData] = useState(false);
    let navigate = useNavigate();

    // 댓글 데이터
    let [replyData, setReplyData] = useState([])

    useEffect(()=>{
        if(params.id){
            const fetchData = async () => {
                try {
                    const response = await axios.get(`https://api.midtrc.com/lounge/${params.id}`,{
                        headers: {
                            'access-token': midMainager?.log_data?.access_token
                        }
                    })
                    setdata(response.data);
                    // 댓글 데이터
                    let instReplyData = response.data.lounge_comments;

                    // 최상위 댓글과 대댓글을 구분하여 구조화
                    let structuredData = instReplyData.reduce((acc, comment) => {
                        if (comment.is_sub === false) {
                            // 최상위 댓글 추가
                            acc.push({ ...comment, replies: [] });
                        } else {
                            // 대댓글인 경우, 해당 상위 댓글에 연결
                            let parentComment = acc.find(c => c.id === comment.sub_comment_id);
                            if (parentComment) {
                                parentComment.replies.push(comment);
                            }
                        }
                        return acc;
                    }, []);

                    // 구조화된 데이터를 상태로 설정
                    setReplyData(structuredData);
                    
                } catch (error) {
                    console.log(error)
                }
            }
            fetchData();
        }

    },[params.id, midMainager?.log_data?.access_token, changeData])



    const isChange = () => {
        setChangeData(!changeData)
    }

    const likeHandler = () => {
        axios.put(`https://api.midtrc.com/lounge/${data?.id}/like`,{},{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{isChange();}).catch((error)=>{console.log(error)});
    }

    const handleEdit = () => {
        if(data?.client_id === midMainager?.log_data?.id){
            navigate(`/community-edit/${data.id}`);
        }
        
    };
    // 데이터가 없을 때는 아무것도 렌더링하지 않음
    if (!data) {
        return null; 
    }

    return (
        <MypageBackground>
            <MyNoticeBoardPostBorder>
                <MyNoticeBoardPostSection>
                    <MypageNoticeBoardPostinfo>
                        <MypageTitleBar>
                            <MypageMyNoticeBoardPostwriter>{data?.nickname}</MypageMyNoticeBoardPostwriter>
                            <MypageMyNoticeBoardPostcategory onClick={()=>{handleEdit()}}>라운지 {data?.client_id === midMainager?.log_data?.id?'| 수정': ''}</MypageMyNoticeBoardPostcategory>
                        </MypageTitleBar>
                        <MypageMyNoticeBoardPostdivider />
                        <NoticeboardpostContainer>
                            <NoticeboardpostTitle>{data?.lounge_title}</NoticeboardpostTitle>
                            <Noticeboardpostingcontent>
                                {parse(data?.data[0]?.content)}
                            </Noticeboardpostingcontent>
                            <div>
                                {
                                    data?.image_urls?.map((a,i) => (
                                        <PhotoImage key={i} alt={`alt${i}`} src={a}/>
                                    ))
                                    
                                }
                            </div>
                            <ContentsBoarder>
                                <StatusBorder style={{marginBottom: "1.6rem"}}>
                                    <SnsIconBorder>
                                        <SnsIcon onClick={()=>{likeHandler()}} src={data?.is_like?likeActive:like} alt="icon01"/>
                                        <SnsIconText style={{marginRight: "0.4rem"}}>
                                            {data?.like_count}
                                        </SnsIconText>
                                        <SnsIcon src={comment} alt="icon01"/>
                                        <SnsIconText>
                                            {data?.comment_count}
                                        </SnsIconText>
                                    </SnsIconBorder>

                                </StatusBorder>
                                {/* 코멘트 인푹 박스 */}
                                <CommentsInputBox data={data} isChange={isChange}/>
                                {
                                    replyData?.map((a, i)=>(
                                        <ReplyBox key={i} data={a} lounge_id={data?.id} isChange={isChange}/>
                                    ))
                                }
                            </ContentsBoarder>
                        </NoticeboardpostContainer>
                    </MypageNoticeBoardPostinfo>
                </MyNoticeBoardPostSection>
            </MyNoticeBoardPostBorder>
        </MypageBackground>
    );
};

export default CommunityPost;
