import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ViewLogined } from './redux/slice/login-slice';
import { useNavigate } from 'react-router-dom';

const Button = styled.button`
  padding: 0.5rem 1rem;
  height: 5.6rem;
  width: 7.6rem;
  font-size: 1.6rem;
  color: #fff;
  background-color: ${props => props.isRead? '#dedede' : '#222222'} ;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 1rem;
  margin-top: -1rem;
`;

const MoK_react_index_button = ({phoneHandler, isChecked, checked}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [isCheckedInst, setIsChecked] = useState(false)
  useEffect(() => {
    window.result = function(result) {
      try {
        const parsedResult = JSON.parse(result);
        // dispatch(ViewLogined(parsedResult));
        // 결과 데이터를 원하는 방식으로 처리
        phoneHandler(parsedResult.data.userPhone)
        
        setIsChecked(true);
        isChecked(true)

      } catch (error) {
        console.error("결과 처리 오류: ", error);
        setIsChecked(false);
        isChecked(false)
      }
    };
  }, [dispatch]);

  /* PC 스크립트 사용시 mok_react_server(5.1 주석해제) */
  const mok_popup = () => {
    window.MOBILEOK.process("https://auth.midtrc.com/mok/mok_std_request", "WB", "result");
  };

  /* 모바일 스크립트 사용시 mok_react_server(5.2 주석해제) */
  // const mok_move = () => {
  //   window.MOBILEOK.process("https://이용기관 본인인증-표준창 요청 (서버 (Node.js))URL/mok/mok_std_request", "WB", "");
  // };

  return (
    <main>
      {/* index.html에 설정을 안 할 시 주석해제 */}
      <Helmet>
        {/* 운영 */}
        {/* <script src="https://cert.mobile-ok.com/resources/js/index.js"></script> */}
        {/* 개발 */}
        <script src="https://scert.mobile-ok.com/resources/js/index.js"></script>
      </Helmet>
      <div>
        <Button isRead={isCheckedInst} onClick={mok_popup}>인증</Button>
      </div>
    </main>
  );
};

export default MoK_react_index_button;

