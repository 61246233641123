/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import bookMark from "./../../@images/bookmark-24.svg";
import bookMarkActive from "./../../@images/bookmark-active.svg";
import bookMarkDefault from "./../../@images/bookmark-default.svg"
import link from "./../../@images/link-shared-24.svg";
import more from "./../../@images/more-24.svg";
import arrow from "./../../@images/arrow-line-24.svg"

import kakao from "./../../@images/kakao-24.svg";
import telegram from "./../../@images/telegram-24.svg";
import instagram from "./../../@images/instagram-24.svg";

// 테스트
import testContent from "./../../@images/test-content.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import JobEditorReader from "../7_job/job-editor-reader";

import profileIcon1 from "./../../@images/profile_icon1.svg";
import profileIcon2 from "./../../@images/profile_icon2.svg";
import profileIcon3 from "./../../@images/profile_icon3.svg";
import profileIcon4 from "./../../@images/profile_icon4.svg";
import profileIcon5 from "./../../@images/profile_icon5.svg";
import Complain from "../../@public-components/complain";
import NoticeBanner from "../../@public-components/notice-banner";


import { NavermapsProvider } from 'react-naver-maps';
import { Container as MapDiv, NaverMap, Marker, useNavermaps } from 'react-naver-maps'


let JobDetailBorder = styled.div`
    display: flex;
    justify-content: space-between;
    width: 125.2rem;
    box-sizing: border-box;
    padding-top: 20rem;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
    position: relative;
`

let JobDetailLeftBorder = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    width: 82.8rem;
    position: relative;
`

let JobDetailLeftNoticeBorder = styled.div`
    width: 100%;
    height: 5.6rem;
    border-radius: 0.4rem;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: white;
`

let JobDetailLeftBannerBorder = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 17rem;
    border-radius: 1.6rem;
    object-fit: cover;
    background-color: #f2f2f2;
`

let JobDetailLeftProgressBorder = styled.div`
    position: absolute;
    display: flex;
    column-gap: 0.8rem;
    bottom: 0.8rem;
    left: 50%;
    height: 0.8rem;
    width: auto;
    transform: translateX(-50%);
`

let JobDetailLeftProgressButton = styled.svg`
    width: 0.8rem;
    height: 0.8rem;
    opacity: ${props => props.isSelected? 1: 0.6};
    border-radius: 1rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #D2D2D2;
    background-color: white;
    cursor: pointer;
`

let JobDetailLeftBanner = styled.img`
    width: 100%;
    height: 17rem;
    border-radius: 1.6rem;
    object-fit: cover;
`

let JobDetailLeftInfoBorder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-contetn: space-between;
    height: 13.6rem;
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    padding: 4rem 0 3.2rem 0;
    border-style: solid;
    border-width: 0 0 0.1rem 0;
    border-color: #E8E8E8;
`

let JobDetailLeftInfoTitleBorder = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2rem;
    color: #171717;
`

let JobDetailLeftInfoButtonBorder = styled.div`
    height: auto;
    display: flex;
    gap: 0.2rem;
`

let JobDetailLeftInfoButton = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    cursor: pointer;
`

let JobDetailLeftInfoSummary = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2.8rem;
    color: #171717;
`

let JobDetailLeftMenuBorder = styled.div`
    height: 7.2rem;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0.8rem;
    gap: 3.2rem;
    position: sticky;
    top: 17.5rem;
    z-index: 50;
    background-color: white;
`

let JobDetailLeftMenuButton = styled.button`
    height: 7.2rem;
    width: auto;
    display: flex;
    justify-content: left;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2.4rem;
    color: ${props => props.isSelected? '#444444':'#9E9E9E'};
    border: none;
    background: none;
    cursor:pointer;
`

let JobDetailContentsBorder = styled.div`
    width: 100%;
    margin-bottom: 3.2rem;
`

let JobDetailLeftTitleBorder = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2.4rem;
    color: #171717;
    margin-bottom: 0.8rem;
    display: flex;
    justify-content: space-between;
`

let JobDetailLeftMapBorder = styled.div`
    width: 100%;
    height: 37.3rem;
    border-radius: 1.6rem;
    border-color: #d2d2d2;
    border-width: 0.1rem;
    border-style: solid;
    margin-bottom: 3.2rem;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
`

let JobDetailLeftMap = styled.div`
    height: 30rem;
    width: 100%;
    background-color: #f5f5f5;

`

let JobDetailLeftMapInfoBorder = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0;
    transfrom: translateX(-50%);
    width: 100%;
    height: 7.2rem;
    display: flex;
    box-sizing: border-box;
    padding-left: 1.6rem;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.6rem;
    color: #444444;
    background: none;
    display: flex;
    align-items: center;
`

let JobDetailLeftPolicyBorder = styled.div`
    width: 100%;
    height: 14.4rem;
    border-radius: 1.6rem;
    border-color: #d2d2d2;
    border-width: 0.1rem;
    border-style: solid;
    box-sizing: border-box;
    padding: 2.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.8rem;
    color: #444444;
    background-color: #F7F7F7;
    line-height: 1.6;
    margin-bottom: 3.2rem;
`

// 오른쪽 컨트롤러 css-style
let JobDetailRightBorder = styled.div`
    position: relative;
    width: 40.4rem;
`

let JobDetailRight = styled.div`
    position: sticky;
    top: 20rem;
    width: 100%;
    height: auto;
    background-color: white;
    overflow: hidden;
    border-color: #D2D2D2;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 1.6rem;
    box-sizing: border-box;
`

let JobDetailRightInfoBorder = styled.div`
    background-color: #F7F7F7;
    height: 11.2rem;
    width: 100%;
    box-sizing: border-box;
    padding: 2.4rem;
    display: flex;
    align-items: center;
    position: relative;
`

let JobDetailRightInfoProfileBorder = styled.div`
    width: 6.4rem;
    height: 6.4rem;
    padding: 0.8rem;
    box-sizing: border-box;
    margin-right: 0.8rem;
`

let JobDetailRightInfoProfile = styled.img`
    min-width:  4.8rem;
    min-height: 4.8rem;
    max-width:  4.8rem;
    max-height: 4.8rem;
    border-radius: 0.8rem;
    object-fit: cover;
    background-color: white;
`

let JobDetailRightInfoTextBorder = styled.div`
    height: 4.8rem;
    width: auto;
`

let JobDetailRightInfoText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #171717;
    height: 2.4rem;
    display: flex;
    align-items: center;
`

let JobDetailRightInfoCompany = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2rem;
    color: #171717;
    height: 2.4rem;
    display: flex;
    align-items: center;
`

let JobDetailDotHamburgerButton = styled.div`
    widht: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    cursor: pointer;
`

let JobDetailRightInfoSummaryBorder = styled.div`
    box-sizing: border-box;
    padding: 1.6rem 2.4rem 2.4rem 2.4rem; 
    width: 100%;
    height: 100%;
`

let JobDetailRightInfoTextBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    margin-bottom: 0.4rem;
`

let JobDetailRightInfoTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #6a6a6a;
`

let JobDetailRightInfoData = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #171717;
    display: flex;
    justify-content: right;
    align-items: center;
`

let JobDetailRightInfoLine = styled.div`
    height: 1.2rem;
    width: 100%;
    border-width: 0 0 0.1rem 0;
    border-style: solid;
    border-color: #D2D2D2;
    margin-bottom: 1.6rem;

`

let SnsBorder = styled.div`
    display: flex;
    align-items: center;
    gap: 0.4rem;

`

let SnsIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
`

let SnsIdText = styled.div`
    color: #171717;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
`

let DmBorder = styled.button`
    width: 100%;
    height: 5.6rem;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    color: white;
    border-radius: 0.4rem;
    border: none;
    background-color: #CD1B1B;
    box-sizing: border-box;
    margin-top: 2.4rem;
    cursor: pointer;
`

let MessageBoxBorder = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

let MessageContentBorder = styled.div`
    width: 50%;
    height: auto;
    border-radius: 2rem;
    background-color: white;
    box-sizing: border-box;
    padding: 4rem;
`

let MessageTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
`

let MessageText = styled.textarea`
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 500;
    border-width: 0.1rem;
    border-radius: 1rem;
    box-sizing: border-box;
    border-style: solid;
    border-color: #dedede;
    resize: none;
    width: 100%;
    height: 100%;
    outline: none;
    min-height: 20rem;
    padding: 2rem;
    line-height: 1.5;
    margin-bottom: 2rem;
`

let MessageButton  = styled.button`
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 500;
    background-color: black;
    border-radius: 0.8rem;
    width: 15rem;
    height: 5.6rem;
    color: white;
    border: none;
    cursor: pointer;
`

let MessageCancel  = styled.button`
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 500;
    background-color: white;
    border-radius: 0.8rem;
    border-style: solid;
    border-color: #dedede;
    border-width: 0.1rem;
    width: 15rem;
    height: 5.6rem;
    color: black;
    cursor: pointer;
`

let DropBorder = styled.div`
    position: absolute;
    width: 10rem;
    height: auto;
    max-height: 30rem;
    right: 0px;
    top: 2em;
    z-index: 30;
    background-color: white;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #E8E8E8;
    border-width: 0.1rem;
    text-align: left;
`

let DropDownItems = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: grey;
    box-sizing: border-box;
    padding: 0 2rem;
    height: 3.4rem;
    &:hover{
        color: black;
    }
`

// function MyMap({ address }) {
//     const navermaps = useNavermaps();
//     const [coordinates, setCoordinates] = useState({
//         lat: 37.3595704, // 기본 위도
//         lng: 127.105399  // 기본 경도
//     });

//     useEffect(() => {
//         if (address) {
//             // 주소를 좌표로 변환
//             naver.maps.Service.geocode({ query: address }, function (status, response) {
//                 if (status === naver.maps.Service.Status.OK && response.v2.addresses.length > 0) {
//                     const result = response.v2.addresses[0];
//                     setCoordinates({
//                         lat: parseFloat(result.y),
//                         lng: parseFloat(result.x)
//                     });
//                 } else {
//                     console.warn('주소를 찾을 수 없습니다. 기본 위치로 설정합니다.');
//                     // 기본 위치를 유지
//                 }
//             });
//         }
//     }, [address]);

//     return (
//         <NaverMap
//             center={new navermaps.LatLng(coordinates.lat, coordinates.lng)}
//             zoom={15}
//         >
//             <Marker
//                 position={new navermaps.LatLng(coordinates.lat, coordinates.lng)}
//             />
//         </NaverMap>
//     );
// }



function MyMap({ address }) {
    const navermaps = useNavermaps();
    const [coordinates, setCoordinates] = useState({
        lat: 37.3595704, // 기본 위도
        lng: 127.105399, // 기본 경도
    });

    // 지도 및 마커 인스턴스를 유지하기 위한 useRef
    const mapInstanceRef = useRef(null);
    const markerInstanceRef = useRef(null);

    useEffect(() => {
        const initializeMap = () => {
            if (!navermaps) {
                console.error('navermaps is not loaded.');
                return; // navermaps가 로드되지 않으면 초기화하지 않음
            }

            // 지도 인스턴스 생성
            mapInstanceRef.current = new navermaps.Map('map', {
                center: new navermaps.LatLng(coordinates.lat, coordinates.lng),
                zoom: 15,
            });

            // 마커 생성 및 지도에 추가
            markerInstanceRef.current = new navermaps.Marker({
                position: new navermaps.LatLng(coordinates.lat, coordinates.lng),
                map: mapInstanceRef.current,
            });
        };

        // 주소로 좌표 변환하는 함수
        const convertAddressToCoordinates = () => {
            if (address) {
                naver.maps.Service.geocode({ query: address }, function (status, response) {
                    if (status === naver.maps.Service.Status.OK && response && response.v2.addresses.length > 0) {
                        const result = response.v2.addresses[0];
                        setCoordinates({
                            lat: parseFloat(result.y),
                            lng: parseFloat(result.x),
                        });
                    } else {
                        console.warn('주소를 찾을 수 없습니다. 기본 위치로 설정합니다.');
                    }
                });
            }
        };

        // 주소가 변환되거나 컴포넌트가 처음 렌더링될 때 지도를 초기화합니다.
        initializeMap();
        convertAddressToCoordinates();

        // 컴포넌트 언마운트 시 지도 및 마커 리소스 정리
        // return () => {
        //     if (markerInstanceRef.current) {
        //         markerInstanceRef.current.setMap(null); // 마커 정리
        //         markerInstanceRef.current = null;
        //     }
        //     if (mapInstanceRef.current) {
        //         mapInstanceRef.current.destroy(); // 지도 리소스 정리
        //         mapInstanceRef.current = null;
        //     }
        // };
    }, [address, coordinates.lat, coordinates.lng, navermaps]);

    // 마커 위치 업데이트 (좌표가 변경될 때)
    useEffect(() => {
        if (markerInstanceRef.current && navermaps) {
            markerInstanceRef.current.setPosition(new navermaps.LatLng(coordinates.lat, coordinates.lng)); // 마커 위치 업데이트
        }
    }, [coordinates, navermaps]);

    return (
        <div id="map" style={{ width: '100%', height: '100%' }}></div>
    );
}





const JobDetail = () => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let params = useParams();
    let [data, setData] = useState();
    let [isChange, setIsChange] = useState(false);
    let [isOpen, setIsOpen] = useState(false);
    let [message, setMessage] = useState('');
    let [index, setIndex] = useState(0);
    let ref01 = useRef();
    let ref02 = useRef();
    let ref03 = useRef();
    let navigate = useNavigate();
    let [companyInfo, setCompanyInfo] = useState(false);

    // 공고 데이터
    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.midtrc.com/recruit/${params.id}`,{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                setData(response.data);
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    },[isChange])

    const ProfileImageHandler = () => {
        let instProfile = data?.profile_url;
        if(instProfile === '01'){
            return profileIcon1;
        }else if(instProfile === '02'){
            return profileIcon2;
        }else if(instProfile === '03'){
            return profileIcon3;
        }else if(instProfile === '04'){
            return profileIcon4;
        }else if(instProfile === '05'){
            return profileIcon5;
        }else{
            return instProfile
        }
    }

    const tcHandler = () => {
        if(data?.tc_pay !== 0 || null){
            return 'T.C'
        }else if(data?.hourly_pay !== 0 || null){
            return '시'
        }else if(data?.daily_pay !== 0 || null){
            return '일'
        }else if(data?.weekly_pay !== 0 || null){
            return '주'
        }else if(data?.monthly_pay !== 0 || null){
            return '월'
        }else if(data?.per_pay !== 0 || null){
            return '건'
        }
    };
    
    const payHandler = () => {
        if(data?.tc_pay !== 0 || null){
            return data?.tc_pay?.toLocaleString()
        }else if(data?.hourly_pay !== 0 || null){
            return data?.hourly_pay?.toLocaleString()
        }else if(data?.daily_pay !== 0 || null){
            return data?.daily_pay?.toLocaleString()
        }else if(data?.weekly_pay !== 0 || null){
            return data?.weekly_pay?.toLocaleString()
        }else if(data?.monthly_pay !== 0 || null){
            return data?.monthly_pay?.toLocaleString()
        }else if(data?.per_pay !== 0 || null){
            return data?.per_pay?.toLocaleString()
        }
    };
    
    const bookMarkHandler = () => {
        if(midMainager?.log_data?.client_type !== 'user'){
            alert('일반 회원으로 로그인하세요')
        }else{
            axios.put(`https://api.midtrc.com/recruit/${data?.id}/favorite`,{},{
                headers: {
                    'access-token': midMainager?.log_data?.access_token
                }
            }).then((res)=>{setIsChange(!isChange)}).catch((error)=>{console.log(error)})
        }
    }

    const handleCopyUrl = () => {
        const currentUrl = window.location.href;
        
        navigator.clipboard.writeText(currentUrl).then(() => {
          alert('URL이 클립보드에 복사되었습니다.');
        }).catch(err => {
          console.error('URL 복사에 실패했습니다: ', err);
        });
    };

    const selectedIndexHandler = (e) => {
        setIndex(e)
        if(e === 0){
            const topOffset = 250; // 원하는 상단 공백 크기 (px 단위)
            const elementPosition = ref01.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - topOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }else if(e === 1){
            const topOffset = 250; // 원하는 상단 공백 크기 (px 단위)
            const elementPosition = ref02.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - topOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }else if(e === 2){
            const topOffset = 250; // 원하는 상단 공백 크기 (px 단위)
            const elementPosition = ref03.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - topOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }   
    }

    const messageTextHandler = (e) => {
        setMessage(e.target.value);
    }

    const sendMessageHandler = () => {
        if(message === '' ){
            alert('메시지 내용이 없습니다.')
        }else if(midMainager?.log_data?.client_type !== 'user'){
            alert('일반 회원 로그인 후 이용하실 수 있습니다.')
        }else{
            let reqeustBody = {
                "contact_client_id": data?.client_id,
                "group_type": "general", //general manage event
                "message_type": "text", //text image file
                "content": message
              }

            axios.post('https://api.midtrc.com/message_group',reqeustBody,{
                headers: {
                    'access-token': midMainager?.log_data?.access_token
                }
            }).then((res)=>{document.body.style.overflow = 'auto'; navigate(`/message`)}).catch((error)=>{console.log(error)});
        }
    }

    const MessageBox = () => {
        
        return(
            <MessageBoxBorder onClick={()=>{messageHandler()}}>
                <MessageContentBorder onClick={(e)=>{e.stopPropagation();}}>
                    <MessageTitle>
                        익명 DM보내기
                    </MessageTitle>
                    <MessageText value={message} onChange={messageTextHandler}/>
                    <div style={{display: "flex", gap: '2rem', justifyContent: "right"}}>
                        <MessageCancel onClick={()=>{messageHandler()}}>
                            취 소
                        </MessageCancel>
                        <MessageButton onClick={()=>{sendMessageHandler()}}>
                            익명 DM보내기
                        </MessageButton>
                    </div>
                </MessageContentBorder>
            </MessageBoxBorder>
        )
    }

    const messageHandler = () => {
        if(isOpen){
            setIsOpen(false)
            document.body.style.overflow = 'auto';
        }else{
            setIsOpen(true)
            document.body.style.overflow = 'hidden';
        }
    }

    let [drop, setDrop] = useState(false);
    let [complainOpen, setComplainOpen] = useState(false);
    let dropRef01 = useRef();
    const dropRef01Handler = (event) => {
        if (dropRef01.current && !dropRef01.current.contains(event.target)) {
            setDrop(false);
        }else{
            setDrop(true);
        }
    }
    // 도 선택 드랍다운 박스 useEffect
    useEffect(() => {
        document.addEventListener('mousedown', dropRef01Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef01Handler);
        };
    }, []);

    const complainHandler = () => {
        if(complainOpen){
            setComplainOpen(false)
            document.body.style.overflow = 'auto';
        }else{
            setComplainOpen(true)
            document.body.style.overflow = 'hidden';
        }
    }




    return(
        <NavermapsProvider ncpClientId='ylt1ojmhya'>

        <JobDetailBorder>
            {
                complainOpen?
                <div>
                    <Complain handler={complainHandler} complain_type={'recruit'} service_id={params.id}/>
                </div>
                :
                null
            }
            {
                isOpen?
                <div>{MessageBox()}</div>
                :
                <div/>
            }
            {/* 왼쪽 콘텐츠 보더 */}
            <JobDetailLeftBorder>
                {/* 공지사항 */}
                {/* <JobDetailLeftNoticeBorder>
                    [공지] 불법 채용공고 관리 안내 및 주의사항
                </JobDetailLeftNoticeBorder> */}
                <NoticeBanner isLounge={false}/>
                {/* 메인 배너 */}
                <JobDetailLeftBannerBorder>
                    {/* 배너 들 */}
                    <JobDetailLeftBanner src={data?.main_image_url} alt="job"/>
                    {/* 프로그래스 바 */}
                    {/* <JobDetailLeftProgressBorder>
                        <JobDetailLeftProgressButton isSelected={true} />
                        <JobDetailLeftProgressButton isSelected={false} />
                        <JobDetailLeftProgressButton isSelected={false} />
                        <JobDetailLeftProgressButton isSelected={false} />
                    </JobDetailLeftProgressBorder> */}
                </JobDetailLeftBannerBorder>
                {/* 공고 요약 정보 */}
                <JobDetailLeftInfoBorder>
                    {/* 상단 타이틀 바 */}
                    <JobDetailLeftInfoTitleBorder>
                        <div>
                            {data?.nickname}
                        </div>
                        <JobDetailLeftInfoButtonBorder>
                            <JobDetailLeftInfoButton onClick={()=>{bookMarkHandler()}} src={data?.is_favorite? bookMarkActive : bookMarkDefault} alt="icon01"/>
                            <JobDetailLeftInfoButton onClick={()=>{handleCopyUrl()}} src={link} alt="icon02"/>
                        </JobDetailLeftInfoButtonBorder>
                    </JobDetailLeftInfoTitleBorder>
                    {/* 중앙 서머리 */}
                    <JobDetailLeftInfoSummary>
                        {data?.recruit_title}
                    </JobDetailLeftInfoSummary>
                </JobDetailLeftInfoBorder>
                {/* 텝 메뉴*/}
                <JobDetailLeftMenuBorder>
                    <JobDetailLeftMenuButton onClick={()=>{selectedIndexHandler(0)}} isSelected={index === 0}>
                        상세정보
                    </JobDetailLeftMenuButton>
                    <JobDetailLeftMenuButton onClick={()=>{selectedIndexHandler(1)}} isSelected={index === 1}>
                        위치
                    </JobDetailLeftMenuButton>
                    <JobDetailLeftMenuButton onClick={()=>{selectedIndexHandler(2)}} isSelected={index === 2}>
                        면책조항
                    </JobDetailLeftMenuButton>
                </JobDetailLeftMenuBorder>
                {/* 상세 정보 (텍스트 에디터에서 작성된 정보) */}
                <JobDetailContentsBorder ref={ref01}>
                    <JobEditorReader data={data?.data[0]?.data}/>
                </JobDetailContentsBorder>
                {/* 위치 */}
                <JobDetailLeftTitleBorder ref={ref02}>
                    위치
                </JobDetailLeftTitleBorder>
                <JobDetailLeftMapBorder>
                    <JobDetailLeftMap>
                        <MapDiv
                        style={{
                        width: '100%',
                        height: '100%',
                        }}
                        >
                        <MyMap address={data?.address} />
                        </MapDiv>
                    </JobDetailLeftMap>
                    <JobDetailLeftMapInfoBorder>
                        {data?.address}
                    </JobDetailLeftMapInfoBorder>
                </JobDetailLeftMapBorder>
                <JobDetailLeftTitleBorder ref={ref03}>
                    면책조항
                </JobDetailLeftTitleBorder>
                <JobDetailLeftPolicyBorder>
                    정보는 여러분이 현재 위치하고 있는 국가의 법에 저촉될 수도 있습니다. 여러분이 위치한 국가의 법 또는원칙에 따른 표현의 자유 보장을 넓은 의미에서 인정하지 않을 수도 있습니다. 혹은 여기에 포함된 어떤 정보를 사용함으로써 생길 수도 있는 법적 위반에 대해 어떠한 책임도 지지 않습니다.
                </JobDetailLeftPolicyBorder>

                <JobDetailLeftTitleBorder onClick={()=>{setCompanyInfo(!companyInfo)}}>
                    <div>
                        기업정보 확인
                    </div>
                    <img style={{width: '3.2rem', cursor: "pointer", transform: companyInfo? 'rotate(180deg)':'rotate(0deg)'}} src={arrow} alt="arrow"/>
                </JobDetailLeftTitleBorder>
                {
                    companyInfo?
                    <JobDetailLeftPolicyBorder style={{margin: "0", display: "grid", gridTemplateColumns: "1.8fr 8.2fr"}}>
                        <div>
                            회사명: 
                        </div>
                        <div>
                            {data?.business_name}
                        </div>
                        <div>
                            회사명 주소: 
                        </div>
                        <div>
                            {data?.business_address}
                        </div>
                        <div>
                            대표자 명: 
                        </div>
                        <div>
                            {data?.business_owner}
                        </div>
                    </JobDetailLeftPolicyBorder>
                    :
                    null
                }
                
            </JobDetailLeftBorder>
            {/* 오른쪽 컨트롤러 */}
            <JobDetailRightBorder>
                <JobDetailRight>
                    {/* 사업자 프로필 정보 */}
                    <JobDetailRightInfoBorder>
                        {/* 프로필 */}
                        <JobDetailRightInfoProfileBorder>
                            <JobDetailRightInfoProfile src={ProfileImageHandler()} alt="profile"/>
                        </JobDetailRightInfoProfileBorder>
                        {/* 사업자 & 사업자 명 */}
                        <JobDetailRightInfoTextBorder>
                            <JobDetailRightInfoText>
                                사업자
                            </JobDetailRightInfoText>
                            <JobDetailRightInfoCompany>
                                {data?.nickname}
                            </JobDetailRightInfoCompany>
                        </JobDetailRightInfoTextBorder>
                        {/* 닷 햄버거, 앱솔루트 */}

                        <JobDetailDotHamburgerButton>
                            <div ref={dropRef01} style={{position: "relative"}}>
                                <img src={more} alt="dot-burger"/>
                                {
                                    drop?
                                    <DropBorder>
                                        <DropDownItems onClick={(e)=>{e.stopPropagation();complainHandler(); setDrop(false)}}>
                                            신고하기
                                        </DropDownItems>
                                    </DropBorder>
                                    :
                                    null
                                }
                                
                            </div>
                        </JobDetailDotHamburgerButton>

                    </JobDetailRightInfoBorder>
                    {/* 사업자 정보 요약 */}
                    <JobDetailRightInfoSummaryBorder>
                        {/* 첫번째 묶음 */}
                        {/* <JobDetailRightInfoTextBox>
                            <JobDetailRightInfoTitle>
                                업종
                            </JobDetailRightInfoTitle>
                            <JobDetailRightInfoData>
                                퍼블릭
                            </JobDetailRightInfoData>
                        </JobDetailRightInfoTextBox> */}
                        <JobDetailRightInfoTextBox>
                            <JobDetailRightInfoTitle>
                                문의 전화번호
                            </JobDetailRightInfoTitle>
                            <JobDetailRightInfoData>
                                {data?.contact_num}
                            </JobDetailRightInfoData>
                        </JobDetailRightInfoTextBox>
                        {
                            data?.safe_num?
                            <JobDetailRightInfoTextBox>
                                <JobDetailRightInfoTitle>
                                    문의 전화번호(안심번호)
                                </JobDetailRightInfoTitle>
                                <JobDetailRightInfoData>
                                    {data?.safe_num}
                                </JobDetailRightInfoData>
                            </JobDetailRightInfoTextBox>
                            :
                            <div style={{display: "none"}} />
                        }
                        <JobDetailRightInfoTextBox>
                            <JobDetailRightInfoTitle>
                                담당자 명
                            </JobDetailRightInfoTitle>
                            <JobDetailRightInfoData>
                                {data?.name}
                            </JobDetailRightInfoData>
                        </JobDetailRightInfoTextBox>
                        <JobDetailRightInfoLine />
                        {/* 두번째 묶음 */}
                        <JobDetailRightInfoTextBox>
                            <JobDetailRightInfoTitle>
                                지역
                            </JobDetailRightInfoTitle>
                            <JobDetailRightInfoData>
                                {data?.address}
                            </JobDetailRightInfoData>
                        </JobDetailRightInfoTextBox>
                        <JobDetailRightInfoTextBox>
                            <JobDetailRightInfoTitle>
                                급여
                            </JobDetailRightInfoTitle>
                            <JobDetailRightInfoData>
                                {tcHandler()+' '+ payHandler()}
                            </JobDetailRightInfoData>
                        </JobDetailRightInfoTextBox>
                        <JobDetailRightInfoLine />
                        {/* 세번째 묶음 */}
                        <JobDetailRightInfoTextBox style={{gridTemplateColumns: "2fr 8fr"}}>
                            <JobDetailRightInfoTitle>
                                메신져 ID
                            </JobDetailRightInfoTitle>
                            <JobDetailRightInfoData style={{gap: "0.8rem"}}>
                                {
                                    data?.kakao_id?
                                    <SnsBorder>
                                        <SnsIcon src={kakao} alt="kakao"/>
                                        <SnsIdText>
                                            {data?.kakao_id}
                                        </SnsIdText>
                                    </SnsBorder>
                                    :
                                    <div style={{display: "none"}}/>
                                }
                                {
                                    data?.telegram_id?
                                    <SnsBorder>
                                        <SnsIcon src={telegram} alt="telegram"/>
                                        <SnsIdText>
                                            {data?.telegram_id}
                                        </SnsIdText>
                                    </SnsBorder>
                                    :
                                    <div style={{display: "none"}}/>
                                }
                                {
                                    data?.instagram_id?
                                    <SnsBorder>
                                        <SnsIcon src={instagram} alt="instagram"/>
                                        <SnsIdText>
                                            {data?.instagram_id}
                                        </SnsIdText>
                                    </SnsBorder>
                                    :
                                    <div style={{display: "none"}}/>
                                }
                            </JobDetailRightInfoData>
                        </JobDetailRightInfoTextBox>
                        <JobDetailRightInfoLine />
                        {/* 한줄 소개 */}
                        <JobDetailRightInfoTitle>
                            한줄 소개
                        </JobDetailRightInfoTitle>
                        <JobDetailRightInfoData style={{justifyContent: 'left', marginTop: "0.4rem"}}>
                            {data?.introduce}
                        </JobDetailRightInfoData>
                        {/* 익명 DM보내기 버튼 */}
                        <DmBorder onClick={()=>{messageHandler()}}>
                            익명 DM보내기
                        </DmBorder>
                    </JobDetailRightInfoSummaryBorder>
                </JobDetailRight>
            </JobDetailRightBorder>
        </JobDetailBorder>
        </NavermapsProvider>
    )
}


export default JobDetail;