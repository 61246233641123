/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import circleArrow from "./../../@images/circle-next-24.svg";
import ResumeCard from "./resume-card-mob";
import { useSelector } from "react-redux";
import axios from "axios";

let ResumeMainBorder = styled.div`
    margin-top:2rem;
    width: 100%;
    height: 43.2rem;
    background-color: #F7F7F7;
    padding: 3.2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left:1rem;
    padding-right:1rem;
    box-sizing: border-box;
`

let ResumeTitleBorder = styled.div`
    width: 100%;
    height: 3.2rem;
    margin-bottom: 1.6rem;
    box-sizing: border-box;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #171717;
`

let ResumeButtonBorder = styled.div`
    height: 3.2rem;
    display: flex;
    align-items: center;
    gap: 0.4rem;
`

let ResumeIcon  = styled.img`
    min-width: 3.2rem;
    min-height: 3.2rem;
    max-width: 3.2rem;
    max-height: 3.2rem;
    object-fit: cover;
    transform: rotate(${props=>props.rotate}deg);
    cursor: pointer;
`

let ResumeListBorder = styled.div`
    width: 100%;
    height: 25.6rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
`

const ResumeFavorite = () => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [resumeData, setResumeData] = useState([]);
    let [isChange, setIsChange] = useState(false);
    let [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 4;

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/resume',{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                // 호출 된 이력서 리스트 데이터
                let instList = response.data;
                let filterData = instList.filter(item => item.popular_yn === true);
                setResumeData(filterData);
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    },[midMainager?.log_data, isChange])

    const changeData = () => {
        setIsChange(!isChange)
    }

    const nextPageHandler = () => {
        setCurrentPage(prev => {
            const totalPages = Math.ceil(resumeData.length / itemsPerPage);
            return (prev + 1) % totalPages;
        });
    }
    
    const prevPageHandler = () => {
        setCurrentPage(prev => {
            const totalPages = Math.ceil(resumeData.length / itemsPerPage);
            return (prev - 1 + totalPages) % totalPages;
        });
    }

    const currentItems = resumeData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    return(
        <ResumeMainBorder>
            <ResumeTitleBorder>
                <div>
                    지금 뜨는 인재정보
                </div>
                <ResumeButtonBorder>
                    <ResumeIcon onClick={prevPageHandler} src={circleArrow} rotate={0} alt="circle-arrow01"/>
                    <ResumeIcon onClick={nextPageHandler} src={circleArrow} rotate={180} alt="circle-arrow02"/>
                </ResumeButtonBorder>
            </ResumeTitleBorder>
            <ResumeListBorder>
                {
                    currentItems?.map((a, i)=>(
                        <ResumeCard key={i} data={a} isChange={changeData}/>
                    ))
                }
            </ResumeListBorder>
        </ResumeMainBorder>
    )
}

export default ResumeFavorite;
