/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CommunityList from "./community-list";
import SnsContentsBoard from "./sns-contents-board";
import NormalContentsBoard from "./normal-contents-board";
import NoticeBanner from "../../@public-components/notice-banner";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

let CommuBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 17.8rem;
    color: black;
    box-sizign: border-box;
    padding-bottom: 7.2rem;
`

let SectionBorder =styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
`

let MenuButtonBorder = styled.div`
    width: 104rem;
    margin: 3.2rem 0 0 0;
    height: 6rem;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    gap: 3.2rem;
`

let MenuButton = styled.div`
    height: 100%;
    width: auto;
    border-style: solid;
    border-color: ${props => props.index? '#171717': 'rgba(0,0,0,0)'};
    border-width: 0 0 0.4rem 0;
    white-space: nowrap;
    box-sizing: border-box;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2.8rem;
    color: ${props => props.index? '#171717': '#9E9E9E'};
    cursor: pointer;
    padding: 0 1.2rem;
`

let NoticeBorder = styled.div`
    width: 104rem;
    height: 5.6rem;
    background-color: #222222;
    margin-top: 3.2rem;
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: white;
    cursor: pointer;
`

let NoticeSpan = styled.span`
    color: #F32E13;
    margin-right: 0.2rem;
`

let NoticeDate = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    margin-left: 1rem;
    color: #9E9E9E;
`

const Community = () => {
    let [currentIndex, setCurrentIndex] = useState(true); 
    let navigate = useNavigate();
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    
    useEffect(()=>{
        if(midMainager?.log_data?.client_type === null || midMainager?.log_data?.client_type === undefined){
            navigate('/login')
        }
    },[]);

    const currentIndexHandler = (e) => {
        setCurrentIndex(e);
    }

    const switchContent = () => {
        switch(currentIndex){
            case true:
                return(
                    <SectionBorder>
                        {/* 공지사항 */}
                        <NoticeBorder>
                            <NoticeBanner isLounge={true}/>
                        </NoticeBorder>
                        {/* 인기 게시판 */}
                        <CommunityList />
                        {/* 콘텐츠 보더 */}
                        <SnsContentsBoard />
                    </SectionBorder>
                )
            
            case false:
                return(
                    <SectionBorder>
                        {/* 공지사항 */}
                        <NoticeBorder>
                            <NoticeBanner isLounge={true}/>
                        </NoticeBorder>
                        {/* 인기 게시판 */}
                        <CommunityList />
                        {/* 일반형 게시글 리스트 보더 */}
                        <NormalContentsBoard />
                    </SectionBorder>
                )

            default :
                return <div />
        }
    }

    return(
        <CommuBorder>
            {/* 메뉴 */}
            <MenuButtonBorder>
                <MenuButton onClick={()=>{currentIndexHandler(true)}} index={currentIndex}>
                    SNS 게시판
                </MenuButton>
                <MenuButton onClick={()=>{currentIndexHandler(false)}} index={!currentIndex}>
                    자유 게시판
                </MenuButton>
            </MenuButtonBorder>
            {switchContent()}
        </CommuBorder>
    );
}

export default Community;