import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

let NoticeBorder = styled.div`
    width: 100%;
    height: 5.6rem;
    border-radius: 0.4rem;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: white;
`

// let NoticeBorderLounge = styled.div`
//     width: 104rem;
//     height: 5.6rem;
//     background-color: #222222;
//     margin-top: 3.2rem;
//     border-radius: 0.4rem;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-family: 'Pretendard-Regular';
//     font-weight: 500;
//     font-size: 1.6rem;
//     color: white;
//     color: white;
//     cursor: pointer;
// `

// let NoticeSpan = styled.span`
//     color: #F32E13;
//     margin-right: 0.2rem;
// `

let NoticeDate = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    margin-left: 1rem;
    color: #9E9E9E;
`
const NoticeBanner = ({isLounge}) => {
    let navigate = useNavigate();
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [contents, setContents] = useState([]);
    // https://api.midtrc.com/notice?notice_type=notice
    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.midtrc.com/notice?notice_type=notice`,{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                setContents(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    },[])

    const FormatDate = (isoString) => {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1을 해줘야 합니다.
        const day = String(date.getDate()).padStart(2, '0');
      
        return `${year}-${month}-${day}`;
      };

    switch(isLounge){
        case true:
            return(
                <NoticeBorder onClick={()=>{navigate(`/noticeboard-post/${contents[0]?.id}`)}}>
                    {contents[0]?.notice_title}
                    
                    <NoticeDate>
                        {
                            FormatDate(contents[0]?.created_at)
                        }
                    </NoticeDate>
                </NoticeBorder>
            )
        case false:
            return(
                <NoticeBorder onClick={()=>{navigate(`/noticeboard-post/${contents[0]?.id}`)}}>
                    {contents[0]?.notice_title}
                </NoticeBorder>
            )
    }
}

export default NoticeBanner;