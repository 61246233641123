import React from "react";
import styled from "styled-components";
import newPage from "./../../@images/newpage-24.svg";
import bookMarkActive from "./../../@images/bookmark-active.svg";

let JobCardBorder = styled.div`
    height: 25.6rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 1px;
    border-radius: 0.8rem;
    cursor: pointer;
`

let JobImages = styled.img`
    min-width: 100%;
    min-height: 11.6rem;
    max-width: 100%;
    max-height: 11.6rem;
    background-color: grey;
    object-fit: cover;
    border-radius: 0.8rem 0.8rem 0 0 ;
`

let JobInfoBorder = styled.div`
    box-sizing: border-box;
    padding: 1.5rem 2rem;
    background-color: white;
    height: 14rem;
    border-radius: 0 0 0.8rem 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`

let JobCompanyName = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #171717;
    box-sizing: border-box;
`

let JobContents = styled.div`
    box-sizing: border-box;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.5rem;
    text-align: left;
    color: #171717;
`

let JobSubInfoBorder= styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    aling-items: center;
    height: 2.4rem;
    position:relative;
`

let JobSubArea = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6a6a6a;
    display: flex;
    align-items: center;
`

let JobSubMoneyType = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.1rem;
`

let JobSubMoney = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.2rem;
`

let JobSubIconBorder = styled.div`
    position:absolute;
    right:0;
    top:-9rem;
    display: flex;
    gap: 0.1rem;
    align-items: center;
`

let JobSubIcon = styled.img`
    height: 2.4rem;
    width: 2.4rem;
    cursor: pointer;
    object-fit: cover;
`

const JobCard = () => {


    return(
        <JobCardBorder>
            <JobImages alt="job-banner"/>
            <JobInfoBorder>
                <JobCompanyName>
                    강남달빛토끼
                </JobCompanyName>
                <JobContents>
                    달빛토끼에서 함께 즐겁게 일하실 여성분(20/30대)모집
                </JobContents>
                <JobSubInfoBorder>
                    <JobSubArea>
                        
                        <JobSubMoneyType>
                            서울 강남구 · 
                        </JobSubMoneyType>
                        &nbsp;
                        <JobSubMoneyType style={{color: "#F32E13"}}>
                            시
                        </JobSubMoneyType>
                        &nbsp;
                        <JobSubMoney>
                            30,000
                        </JobSubMoney>
                        &nbsp;
                        <JobSubMoneyType>
                            원
                        </JobSubMoneyType>
                    </JobSubArea>
                    <JobSubIconBorder>
                        <JobSubIcon src={bookMarkActive} alt="book-mark-active"/>
                        <JobSubIcon src={newPage} alt="new-page"/>
                    </JobSubIconBorder>
                </JobSubInfoBorder>
            </JobInfoBorder>
        </JobCardBorder>
    )
}

export default JobCard;