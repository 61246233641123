import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ViewLogined } from './redux/slice/login-slice';
import { useNavigate } from 'react-router-dom';

const Button = styled.button`
  width: 32.4rem;
  height: 5.6rem;
  padding: 1rem;
  font-size: 1.8rem;
  color: #fff;
  background-color: #CD1B1B;
  border: 1px solid #CD1B1B;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-top: 1rem;
  
  &:hover {
    background-color: #fff;
    color: #CD1B1B;
    border-color: #CD1B1B;
  }
`;

function withNavigate(Component) {
    return function(props) {
      const navigate = useNavigate();
      return <Component {...props} navigate={navigate} />;
    };
  }

/* index.html에 설정을 안 할 시 react-helmet 다운 및 주석해제 */
// import {Helmet} from 'react-helmet';
class moK_react_index extends Component {

    constructor(props) {
        super(props);
        this.mok_popup = this.mok_popup.bind(this);
    }

    componentDidMount() {
        const { dispatch, navigate } = this.props;

        window.result = function(result) {
            try {
                const parsedResult = JSON.parse(result);
                dispatch(ViewLogined(parsedResult))
                // 결과 데이터를 원하는 방식으로 처리
                navigate('/')
            } catch (error) {
                console.error("결과 처리 오류: ", error);
            }
        };
    }

    /* PC | 모바일 스크립트 인식 구분 */
    /* PC 스크립트 사용시 mok_react_server(5.1 주석해제) */
    mok_popup() {
        window.MOBILEOK.process("https://auth.midtrc.com/mok/mok_std_request", "WB", "result");
    }
    /* 모바일 스크립트 사용시 mok_react_server(5.2 주석해제) */
    // mok_move 사용시 mok_react_server(5.2-1 pathname 수정 후 사용)
    // mok_move() {
        // 모바일 전용서비스로 페이지 이동처리 또는 카카오 브라우져 등 새창으로 처리가 어려운 환경 또는 브라우져에서 처리
        // window.MOBILEOK.process("https://이용기관 본인인증-표준창 요청 (서버 (Node.js))URL/mok/mok_std_request", "WB", "");
    // }

    render() {
        return (
            <main>
                {/* index.html에 설정을 안 할 시 주석해제 */}
                <Helmet>
                    {/* 운영 */}
                    {/* <script src="https://cert.mobile-ok.com/resources/js/index.js"></script> */}
                    {/* 개발 */}
                    <script src="https://scert.mobile-ok.com/resources/js/index.js"></script>
                </Helmet> 
                <div>
                    <Button onClick={this.mok_popup}>비회원 인증 입장</Button>
                </div>
            </main>
        )
    }

}

export default connect()(withNavigate(moK_react_index));
