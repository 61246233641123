import React, { useState } from "react";
import styled from "styled-components";
import MyQuestions from "./mypage-1on1questioncontainer-mob";
import Question from "../../@public-components/question";

let MypageBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
`;

let MyQuestion1on1Border = styled.div`
    margin-top: 0.8rem;
    width: 100%;
    gap: 4rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    background-color: #F7F7F7;
    padding-top: 3rem;
`;

let MyQuestion1on1Section = styled.div`
    width: 100%;
    padding-top: 11rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`;

let MypageQuestion1on1info = styled.div`
    padding-top: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 4rem;
    background-color: white;
    border-radius: 16px;
    border: 1px solid #D2D2D2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let MypageTitleBar = styled.div`
    color: #171717;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
`;

let MypageMyQuestion1on1Title = styled.div`
    color: #171717;
    font-family: 'Pretendard-Regular';
    font-size: 2rem;
    font-weight: 600;
`;

let MypageMyQuestion1on1AddQuestion1on1 = styled.div`
    color: white;
    background-color: #CD1B1B;
    font-size: 1.6rem;
    width: 12rem;
    height: 4rem;
    border: 1px solid #CD1B1B;
    border-radius: 4px;
    font-family: 'Pretendard-Regular';
    display: flex; 
    justify-content: center; 
    align-items: center; 
    cursor: pointer;

    &:hover {
        color: #CD1B1B; 
        background-color: white; 
        border-color: #CD1B1B; 
    }
`;

let MypageMyQuestion1on1divider = styled.div`
    width: 100%;
    border-bottom: 1px solid #E8E8E8;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

let MypageNoticeBoardContainer = styled.div`
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

const MyQuestion1on1 = ({ isRequest }) => {
    let [open, setOpen] = useState(false);

    const openHandler = () => {
        if (open) {
            setOpen(false);
            document.body.style.overflow = 'auto';
        } else {
            setOpen(true);
            document.body.style.overflow = 'hidden';
        }
    }

    return (
        <MypageBackground>
            {
                open ?
                    <Question handler={openHandler} />
                    :
                    null
            }
            <MyQuestion1on1Border>
                <MyQuestion1on1Section>
                    <MypageQuestion1on1info>
                        <MypageTitleBar>
                            <MypageMyQuestion1on1Title>{isRequest ? '제휴·입점 문의' : '1:1 문의 게시판'}</MypageMyQuestion1on1Title>
                            <MypageMyQuestion1on1AddQuestion1on1 onClick={openHandler}>문의글 작성</MypageMyQuestion1on1AddQuestion1on1>
                        </MypageTitleBar>
                        <MypageMyQuestion1on1divider />
                        <MypageNoticeBoardContainer>
                            <MyQuestions />
                        </MypageNoticeBoardContainer>
                    </MypageQuestion1on1info>
                </MyQuestion1on1Section>
            </MyQuestion1on1Border>
        </MypageBackground>
    );
};

export default MyQuestion1on1;
