/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import circleArrow from "./../../@images/circle-next-24.svg";
import circleArrowBlack from "./../../@images/circle-next-black-24.svg";
import JobCard from "./job-card-mob";
import { useSelector } from "react-redux";
import axios from "axios";

let FavMainBorder = styled.div`
    width: 100%;
    height: 36.8rem;
    background-color: #F7F7F7;
    padding: 3.2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-left:1rem;
    padding-right:1rem;
`;
let FavTitleBorder = styled.div`
    width: 100%;
    height: 3.2rem;
    margin-bottom: 1.6rem;
    box-sizing: border-box;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #171717;
`;

let FavButtonBorder = styled.div`
    height: 3.2rem;
    display: flex;
    align-items: center;
    gap: 0.4rem;
`;

let FavListBorder = styled.div`
    width: 100%;
    height: 25.6rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
`;

let FavIcon = styled.img`
    min-width: 3.2rem;
    min-height: 3.2rem;
    max-width: 3.2rem;
    max-height: 3.2rem;
    object-fit: cover;
    transform: rotate(${props => props.rotate}deg);
    cursor: pointer;
`;

const JobFavorite = () => {
    let midMainager = useSelector((state) => state.midnightLoginManager);
    let [ResumeData, setResumeData] = useState([]);
    let [isChange, setIsChange] = useState(false);
    let [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 4;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/recruit', {
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                });
                // 호출 된 이력서 리스트 데이터
                let instList = response.data;
                let filterData = instList.filter(item => item.popular_yn === true);
                setResumeData(filterData);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [midMainager?.log_data, isChange]);

    const changeData = () => {
        setIsChange(!isChange);
    };

    const nextPageHandler = () => {
        setCurrentPage(prev => {
            const totalPages = Math.ceil(ResumeData.length / itemsPerPage);
            return (prev + 1) % totalPages;
        });
    };
    
    const prevPageHandler = () => {
        setCurrentPage(prev => {
            const totalPages = Math.ceil(ResumeData.length / itemsPerPage);
            return (prev - 1 + totalPages) % totalPages;
        });
    };

    const currentItems = ResumeData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    
    return (
        <FavMainBorder>
            <FavTitleBorder>
                <div>
                    인기 있는 알바
                </div>
                <FavButtonBorder>
                    <FavIcon onClick={prevPageHandler} src={circleArrow} rotate={0} alt="circle-arrow01"/>
                    <FavIcon onClick={nextPageHandler} src={circleArrow} rotate={180} alt="circle-arrow02"/>
                </FavButtonBorder>
            </FavTitleBorder>
            <FavListBorder>
                {
                    currentItems?.map((a, i) => (
                        <JobCard data={a} key={i} isChange={changeData}/>
                    ))
                }
            </FavListBorder>
        </FavMainBorder>
    );
};

export default JobFavorite;
