/*eslint-disable*/
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import checkboxchecked from "./../../@images/checkbox_checked.svg";
import checkboxunchecked from "./../../@images/checkbox_unchecked.svg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Logined } from "../../redux/slice/login-slice";
import { useNavigate } from "react-router-dom";

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  padding: 2rem;
  font-family: 'Pretendard-Regular';
  padding-left:2rem;
  padding-right:2rem;
  box-sizing: border-box;
`;

const CardContainer = styled.div`
  width: 100%;
  height:54rem;
  background-color: #fff;
  border-radius: 16px;
  // border: 1px solid #D2D2D2;
  border: none;
  padding-top:5rem;
  text-align: center;
  position: relative;
  padding-left:2rem;
  padding-right:2rem;
  box-sizing: border-box;
`;

const Title = styled.h2`
  font-size: 2.4rem;
  color: #000;
  margin-bottom: 5rem;
  text-align:left;
`;

const Input = styled.input`
  width: 100%;
  height:5.6rem;
  padding: 0.8rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  box-sizing: border-box;
  font-family: 'Pretendard-Regular';
`;

const Button = styled.button`
  width: 32.4rem;
  height: 5.6rem;
  padding: 1rem;
  font-size: 1.8rem;
  color: #fff;
  background-color: #CD1B1B;
  border: 1px solid #CD1B1B;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-top: 1rem;
  
  &:hover {
    background-color: #fff;
    color: #CD1B1B;
    border-color: #CD1B1B;
  }
`;

const Footer = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #888;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap:0.5rem;
  font-size:1.4rem;
  color:#9E9E9E;
  margin-top:-1rem;
  margin-bottom:1.15rem;
`;

const Links = styled.div`
  margin-top:-2.1rem;
  display: flex;
  font-size:1.4rem;
  align-items: center;
  a {
    text-decoration: none;
    margin-left: 1rem;
    color:#9E9E9E;
  }
`;

const Linkdivider = styled.span`
  margin-left:1rem;
  font-weight:100;
`;

const BusinessLogin = () => {
  const dispatch = useDispatch();
  const midMainager = useSelector((state) => state.midnightLoginManager);
  const [login_id, setLogin_id] = useState('');
  const [pwd, setPwd] = useState('');
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);

  const [deviceType, setDeviceType] = useState('');
  const [browserType, setBrowserType] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setDeviceType(isMobile ? 'Mobile' : 'Desktop');

    let browserTypeInst = '';

    if (userAgent.indexOf("Firefox") > -1) {
      browserTypeInst = "Mozilla Firefox";
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserTypeInst = "Google Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      browserTypeInst = "Apple Safari";
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
      browserTypeInst = "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
      browserTypeInst = "Microsoft Internet Explorer";
    }

    setBrowserType(browserTypeInst);
  }, [])

  const idHandler = (e) => {
    setLogin_id(e.target.value);
  }

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
  };

  const pwdHandler = (e) => {
    setPwd(e.target.value);
  }

  const emailLoginSubmit = () => {
    const requestBody = {
      "signup_type": "general",
      "login_id": login_id,
      "pwd": pwd,
      "latest_device": deviceType,
      "latest_browser": browserType
    }

    axios.post('https://api.midtrc.com/auth/login', requestBody)
      .then((res) => {
        dispatch(Logined(res.data));
        navigate('/');
      }).catch((error) => { console.log(error) })
  }

  return (
    <PageContainer>
      <CardContainer>
        <Title>기업 회원 로그인</Title>
        <Input value={login_id} onChange={idHandler} type="text" placeholder="아이디를 입력하세요." />
        <Input value={pwd} onChange={pwdHandler} type="password" placeholder="비밀번호를 입력하세요." />
        <Footer>
          <CheckboxContainer onClick={handleCheckboxClick}>
            <img
              src={isChecked ? checkboxchecked : checkboxunchecked}
              alt="Checkbox"
            />
            <label htmlFor="autoLogin">자동 로그인</label>
          </CheckboxContainer>
          <Links>
            <a href="/findidpw">ID/비밀번호 찾기</a><Linkdivider>|</Linkdivider><a href="/business-signup">회원가입</a>
          </Links>
        </Footer>
        <Button onClick={() => { emailLoginSubmit() }}>이메일 로그인</Button>
      </CardContainer>
    </PageContainer>
  );
};

export default BusinessLogin;
