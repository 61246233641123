import React, { useEffect, useState } from "react";
import styled from "styled-components";

import linkWhite from "./../../@images/link-white-24.svg";
import bookMarkwhite from "./../../@images/bookmark-white-24.svg";
import more from "./../../@images/more-24.svg";

import like from "./../../@images/sns-like-40.svg";
import link from "./../../@images/sns-link-40.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

let ColorBorder = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
    padding-top: 20rem;
    box-sizing: border-box;
    padding-bottom: 15rem;
`;

let ResumeDetailBorder = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-left: 2rem;
    padding-right: 2rem;
`;

let ResumeLeftBorder = styled.div`
    width: 100%;
    border-style: solid;
    border-color: #D2D2D2;
    border-width: 0.1rem;
    border-radius: 1.6rem;
    background-color: white;
    overflow: hidden;
`;

let ResumeLeftBannerBorder = styled.div`
    width: 100%;
    height: 18.4rem;
    background-color: black;
    box-sizing: border-box;
    padding: 2.4rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

let ResumeLeftBannerTop = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2rem;
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
`;

let ResumeLeftIconBorder = styled.div`
    display: flex;
    gap: 0.2rem;
`;

let ResumeIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    cursor: pointer;
`;

let ResumeContents = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2.8rem;
    color: white;
`;

let ResumeTagsBorder = styled.div`
    display: flex;
    gap: 0.4rem;
    margin-top: 1.6rem;
`;

let ResumeTag = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1rem;
    color: white;
    box-sizing: border-box;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.4);
    border-radius: 5rem;
    border-width: 0.1rem;
    padding: 0.4rem 0.8rem;
`;

let ResumeLeftContentsBorder = styled.div`
    width: 100%;
    padding: 3.2rem 4rem 0 4rem;
    box-sizing: border-box;
`;

let ResumeLeftContents = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 3.2rem;
    border-style: solid;
    border-color: #D2D2D2;
    border-width: 0 0 0.1rem 0;
`;

let ContentsTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2rem;
    color: #171717;
    margin-bottom: 1.6rem;
`;

let Contents = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.8rem;
    color: #444444;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    line-height: 1.5;
`;

let ResumeContentsTagsBorder = styled.div`
    display: flex;
    gap: 0.8rem;
    margin-top: 1.6rem;
`;

let ResumeContentsTag = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.4rem;
    color: #444444;
    box-sizing: border-box;
    border-style: solid;
    border-color: #E8E8E8;
    border-radius: 5rem;
    border-width: 0.1rem;
    padding: 0.6rem 1.2rem;
`;

let ResumePolicy = styled.div`
    box-sizing: border-box;
    padding: 2.4rem;
    background-color: #f7f7f7;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 2rem;
    color: #444444;
    border-radius: 1.6rem;
    line-height: 1.5;
`;

let ResumeControllerBorder = styled.div`
    position: relative;
    width: 100%;
`;

let ResumeController = styled.div`
    position: sticky;
    width: 100%;
    margin-top: -5rem;
    background-color: white;
    border-radius: 1.6rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 0.1rem;
    overflow: hidden;
    margin-bottom: 3rem;
`;

let ResumeControllerInfoBorder = styled.div`
    width: 100%;
    height: 9.6rem;
    box-sizing: border-box;
    padding: 2.4rem;
    background-color: #f7f7f7;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
`;

let ResumeType = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #171717;
`;

let ResumeName = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2rem;
    color: #171717;
`;

let HamburgerIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    cursor: pointer;
`;

let ResumeControllerContents = styled.div`
    box-sizing: border-box;
    padding: 2.4rem;
`;

let ResumeContollerGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

let GridLeft = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #6a6a6a;
`;

let GridRight = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #171717;
    text-align: right;
`;

let ControllerIconBorder = styled.div`
    display: flex;
    justify-content: right;
`;

let ControllerIcon = styled.img`
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    object-fit: cover;
`;

let ControllerButton = styled.button`
    width: 100%;
    height: 5.6rem;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    color: white;
    border: none;
    border-radius: 0.4rem;
    background-color: #CD1B1B;
    cursor: pointer;
`;

let ControllerButton2 = styled.button`
    width: 100%;
    height: 5.6rem;
    margin-top: 3rem;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    color: white;
    border: none;
    border-radius: 0.4rem;
    background-color: #CD1B1B;
    cursor: pointer;
`;

let MessageBoxBorder = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

let MessageContentBorder = styled.div`
    width: 50%;
    height: auto;
    border-radius: 2rem;
    background-color: white;
    box-sizing: border-box;
    padding: 4rem;
`;

let MessageTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
`;

let MessageText = styled.textarea`
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 500;
    border-width: 0.1rem;
    border-radius: 1rem;
    box-sizing: border-box;
    border-style: solid;
    border-color: #dedede;
    resize: none;
    width: 100%;
    height: 100%;
    outline: none;
    min-height: 20rem;
    padding: 2rem;
    line-height: 1.5;
    margin-bottom: 2rem;
`;

let MessageButton = styled.button`
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 500;
    background-color: black;
    border-radius: 0.8rem;
    width: 15rem;
    height: 5.6rem;
    color: white;
    border: none;
    cursor: pointer;
`;

let MessageCancel = styled.button`
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 500;
    background-color: white;
    border-radius: 0.8rem;
    border-style: solid;
    border-color: #dedede;
    border-width: 0.1rem;
    width: 15rem;
    height: 5.6rem;
    color: black;
    cursor: pointer;
`;

const ResumeDetail = () => {
    let params = useParams();
    let midMainager = useSelector((state) => state.midnightLoginManager);
    let [resumeData, setResumeData] = useState();
    let [isChange, setIsChange] = useState(false);
    let [isOpen, setIsOpen] = useState(false);
    let [message, setMessage] = useState('');
    let navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.midtrc.com/resume/${params.id}`, {
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                });
                let instList = response.data;
                setResumeData(instList); // 호출 된 공고 리스트 데이터
                // console.log(instList)
            } catch (error) {
                console.log(error.response.data.detail);
            }
        };
        fetchData();
    }, [params.id, isChange]);

    const maskName = (name) => {
        if (!name || typeof name !== 'string') return '';
        const length = name.length;

        if (length === 3) {
            return name[0] + '*' + name[2];
        } else if (length === 4) {
            return name[0] + '**' + name[3];
        } else {
            return name; // 3글자 또는 4글자가 아닌 경우 변환하지 않음
        }
    };

    const favoritHandler = () => {
        if (midMainager?.log_data?.client_type !== 'business') {
            alert('사업자 회원으로 로그인 하세요');
        } else {
            axios.put(`https://api.midtrc.com/resume/${resumeData?.id}/favorite`, {}, {
                headers: {
                    'access-token': midMainager?.log_data?.access_token
                }
            }).then((res) => { setIsChange(!isChange); console.log(res.data); }).catch((error) => { console.log(error); });
        }

    };

    const handleCopyUrl = () => {
        const currentUrl = window.location.href;

        navigator.clipboard.writeText(currentUrl).then(() => {
            alert('URL이 클립보드에 복사되었습니다.');
        }).catch(err => {
            console.error('URL 복사에 실패했습니다: ', err);
        });
    };

    const hourly_payHandler = () => {
        if (resumeData?.hourly_pay !== 0 && resumeData?.hourly_pay !== null) {
            return (
                <ResumeContentsTag>
                    {'시 ' + resumeData?.hourly_pay?.toLocaleString() + '원'}
                </ResumeContentsTag>
            );
        } else {
            return <div />;
        }
    };

    const daily_payHandler = () => {
        if (resumeData?.daily_pay !== null) {
            return (
                <ResumeContentsTag>
                    {'일' + resumeData?.daily_pay?.toLocaleString()}
                </ResumeContentsTag>
            );
        } else {
            return <div />;
        }
    };

    const weekly_payHandler = () => {
        if (resumeData?.weekly_pay !== null) {
            return (
                <ResumeContentsTag>
                    {'주' + resumeData?.weekly_pay?.toLocaleString()}
                </ResumeContentsTag>
            );
        } else {
            return <div />;
        }
    };

    const monthly_payHandler = () => {
        if (resumeData?.monthly_pay !== null) {
            return (
                <ResumeContentsTag>
                    {'월' + resumeData?.monthly_pay?.toLocaleString()}
                </ResumeContentsTag>
            );
        } else {
            return <div />;
        }
    };

    const pensionHandler = () => {
        if (resumeData?.pension !== null) {
            return (
                <ResumeContentsTag>
                    {'연' + resumeData?.pension?.toLocaleString()}
                </ResumeContentsTag>
            );
        } else {
            return <div />;
        }
    };

    const per_payHandler = () => {
        if (resumeData?.per_pay !== null) {
            return (
                <ResumeContentsTag>
                    {'건' + resumeData?.per_pay?.toLocaleString()}
                </ResumeContentsTag>
            );
        } else {
            return <div />;
        }
    };

    const messageTextHandler = (e) => {
        setMessage(e.target.value);
    };

    const sendMessageHandler = () => {
        if (message === '') {
            alert('메시지 내용이 없습니다.');
        } else if (midMainager?.log_data?.client_type !== 'business') {
            alert('일반 회원 로그인 후 이용하실 수 있습니다.');
        } else {
            let reqeustBody = {
                "contact_client_id": resumeData?.client_id,
                "group_type": "general", //general manage event
                "message_type": "text", //text image file
                "content": message
            };

            axios.post('https://api.midtrc.com/message_group', reqeustBody, {
                headers: {
                    'access-token': midMainager?.log_data?.access_token
                }
            }).then((res) => { document.body.style.overflow = 'auto'; navigate(`/message`); }).catch((error) => { console.log(error); });
        }
    };

    const MessageBox = () => {

        return (
            <MessageBoxBorder onClick={() => { messageHandler(); }}>
                <MessageContentBorder onClick={(e) => { e.stopPropagation(); }}>
                    <MessageTitle>
                        메시지 보내기
                    </MessageTitle>
                    <MessageText value={message} onChange={messageTextHandler} />
                    <div style={{ display: "flex", gap: '2rem', justifyContent: "right" }}>
                        <MessageCancel onClick={() => { messageHandler(); }}>
                            취 소
                        </MessageCancel>
                        <MessageButton onClick={() => { sendMessageHandler(); }}>
                            메시지 작성
                        </MessageButton>
                    </div>
                </MessageContentBorder>
            </MessageBoxBorder>
        );
    };

    const messageHandler = () => {
        if (isOpen) {
            setIsOpen(false);
            document.body.style.overflow = 'auto';
        } else {
            setIsOpen(true);
            document.body.style.overflow = 'hidden';
        }
    };

    return (
        <ColorBorder>
            {
                isOpen ?
                    <div>{MessageBox()}</div>
                    :
                    <div />
            }

            <ResumeDetailBorder>
                <ResumeControllerBorder>
                    <ResumeController>
                        <ResumeControllerInfoBorder>
                            <ResumeType>
                                일반회원
                            </ResumeType>
                            <ResumeName>
                                {maskName(resumeData?.client_name)}
                            </ResumeName>
                            <HamburgerIcon src={more} alt="hamburger-icon" />
                        </ResumeControllerInfoBorder>
                        <ResumeControllerContents>
                            <ResumeContollerGrid style={{ marginBottom: "0.4rem" }}>
                                <GridLeft>
                                    성별
                                </GridLeft>
                                <GridRight>
                                    {resumeData?.gender === 'male' ? '남' : '여'}
                                </GridRight>
                            </ResumeContollerGrid>
                            <ResumeContollerGrid>
                                <GridLeft>
                                    나이
                                </GridLeft>
                                <GridRight>
                                    {resumeData?.age}
                                </GridRight>
                            </ResumeContollerGrid>
                            <ControllerIconBorder style={{ marginTop: "1.6rem", marginBottom: "2.4rem" }}>
                                <ControllerIcon onClick={() => { favoritHandler(); }} src={resumeData?.is_favorite ? like : like} alt="like" />
                                <ControllerIcon onClick={() => { handleCopyUrl(); }} src={link} alt="link" />
                            </ControllerIconBorder>
                            <ControllerButton onClick={() => { messageHandler(); }}>
                                메세지 보내기
                            </ControllerButton>
                        </ResumeControllerContents>
                    </ResumeController>
                </ResumeControllerBorder>

                <ResumeLeftBorder>
                    <ResumeLeftBannerBorder>
                        <div>
                            <ResumeLeftBannerTop>
                                <div>
                                    {maskName(resumeData?.client_name)}
                                </div>
                                <ResumeLeftIconBorder>
                                    <ResumeIcon src={resumeData?.is_favorite ? like : bookMarkwhite} alt="book-mark-white" />
                                    <ResumeIcon onClick={() => { handleCopyUrl(); }} src={linkWhite} alt="link-white" />
                                </ResumeLeftIconBorder>
                            </ResumeLeftBannerTop>
                            <ResumeContents>
                                {resumeData?.resume_title}
                            </ResumeContents>
                        </div>
                        <ResumeTagsBorder>
                            {
                                resumeData?.keyword?.map((a, i) => (
                                    <ResumeTag key={i}>
                                        {a}
                                    </ResumeTag>
                                ))
                            }
                        </ResumeTagsBorder>
                    </ResumeLeftBannerBorder>
                    <ResumeLeftContentsBorder>
                        <ResumeLeftContents>
                            <ContentsTitle>
                                자기소개
                            </ContentsTitle>
                            <Contents>
                                {resumeData?.introduce}
                            </Contents>
                        </ResumeLeftContents>
                        <ResumeLeftContents style={{ paddingTop: "3.2rem" }}>
                            <ContentsTitle>
                                희망지역
                            </ContentsTitle>
                            <ResumeContentsTagsBorder style={{ marginBottom: "6.8rem" }}>
                                {
                                    resumeData?.address?.map((a, i) => (
                                        <ResumeContentsTag key={i}>
                                            {a}
                                        </ResumeContentsTag>
                                    ))
                                }
                            </ResumeContentsTagsBorder>
                            <ContentsTitle>
                                희망급여
                            </ContentsTitle>
                            <ResumeContentsTagsBorder style={{ marginBottom: "6.8rem" }}>
                                {hourly_payHandler()}
                                {daily_payHandler()}
                                {weekly_payHandler()}
                                {monthly_payHandler()}
                                {pensionHandler()}
                                {per_payHandler()}
                            </ResumeContentsTagsBorder>
                            <ContentsTitle>
                                경력
                            </ContentsTitle>
                            <Contents>
                                {resumeData?.career}
                            </Contents>
                        </ResumeLeftContents>
                        <ResumeLeftContents style={{ paddingTop: "3.2rem" }}>
                            <ContentsTitle>
                                면책조항
                            </ContentsTitle>
                            <ResumePolicy>
                                정보는 여러분이 현재 위치하고 있는 국가의 법에 저촉될 수도 있습니다. 여러분이 위치한 국가의 법 또는원칙에 따른 표현의 자유 보장을 넓은 의미에서 인정하지 않을 수도 있습니다. 혹은 여기에 포함된 어떤 정보를 사용함으로써 생길 수도 있는 법적 위반에 대해 어떠한 책임도 지지 않습니다.
                            </ResumePolicy>
                        </ResumeLeftContents>
                    </ResumeLeftContentsBorder>
                </ResumeLeftBorder>

                <ControllerButton2 onClick={() => { messageHandler(); }}>
                    메세지 보내기
                </ControllerButton2>
            </ResumeDetailBorder>
        </ColorBorder>
    );
};

export default ResumeDetail;
