import React from "react";
import styled from "styled-components";
import snsF from "./../@images/facebook-white-32.svg";
import snsI from "./../@images/insta-white-32.svg";
import snsB from "./../@images/blog-white-32.svg";

let FooterBorder = styled.div`
    width: 100%;
    background-color: black;
    box-sizing: border-box;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
`


let TopMenuText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: #9e9e9e;
`

let SubMenuText = styled.div`
    width:100%;
    padding-left:2rem;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    color: white;
    cursor: pointer;
    height: 2.1rem;
`



let SubMenuFlexBorder = styled.div`
    display: flex;
    justify-content: right;
    gap: 0.8rem;
    height: 2.1rem;
    position:relative;
    right:0;
    width:100%;

`

let SnsIcon = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
    cursor: pointer;
`

let Number = styled.div`
    width:100%;
    padding-left:2rem;
    
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2.8rem;
    color: white;
    height: 2.1rem;
    cursor: pointer;
    text-align:left;
`

let BottomMenuBorder = styled.div`
    width: 100%;
    border-style: solid;
    border-width: 0.1rem 0 0 0;
    border-color: #D2D2D2;
    box-sizing: border-box;
    padding-top: 1rem;
    margin-top: 3rem;
    position: relative;
`

let BottomTopMenuBorder = styled.div`
    display: flex;
    gap: 4.6rem;
    margin-bottom: 1.6rem;
`


let BottomText02 = styled.div`
    font-family: 'Pretendard-Regular';
    width:100%;
    text-align:center;
    font-weight: 400;
    font-size: 1.6rem;
    color: #6A6A6A;
    margin-top:1.5rem;
`

const FooterMob = () => {

    return(
        <FooterBorder>
                <BottomTopMenuBorder>
                    <TopMenuText style={{cursor: "pointer"}}>
                        이용약관
                    </TopMenuText>
                    <TopMenuText style={{cursor: "pointer"}}>
                        개인정보처리방침
                    </TopMenuText>
                    <TopMenuText style={{cursor: "pointer"}}>
                        사업자정보확인
                    </TopMenuText>
                </BottomTopMenuBorder>

                <Number>
                        070-7799-6299
                </Number>
                <SubMenuText></SubMenuText>
                <SubMenuText style={{lineHeight: "1.5", marginTop: "0.4rem",fontWeight: "400"}}>평일 10:00~18:00<br/>(점심시간 13:00 ~ 14:00) </SubMenuText>
                <SubMenuFlexBorder>
                        <SnsIcon src={snsF} alt="face"/>
                        <SnsIcon src={snsI} alt="insta"/>
                        <SnsIcon src={snsB} alt="blog"/>
                </SubMenuFlexBorder>

            <BottomMenuBorder>

                <BottomText02>
                    Copyrightⓒ MIDNIGHT TERRACE All Rights Reserved.
                </BottomText02>
            </BottomMenuBorder>
            
        </FooterBorder>
    )
}

export default FooterMob;