/*eslint-disable*/
import React, { useState } from "react";
import styled from "styled-components";
import newPage from "./../../@images/newpage-24.svg";
import bookMarkActive from "./../../@images/bookmark-active.svg";
import bookMarkDefault from "./../../@images/bookmark-default.svg"
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

let ResumeCardBorder = styled.div`
    height: 25.6rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 1px;
    border-radius: 0.8rem;
    cursor: pointer;
`

let ResumeImages = styled.img`
    min-width: 2.4rem;
    min-height: 2.4rem;
    max-width: 2.4rem;
    max-height: 2.4rem;
    background-color: grey;
    object-fit: cover;
    border-radius: 2rem;
`

let ResumeInfoBorder = styled.div`
    box-sizing: border-box;
    padding: 2.4rem;
    background-color: white;
    height: 15.2rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 1px;
    border-radius: 0.8rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`

let ResumeCompanyNameBorder = styled.div`
    width: 100%;
    font-family: 'Pretendard-Regular';
    font-weight: 800;
    font-size: 1.4rem;
    color: #171717;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

let ResumeCompanyName = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 800;
    font-size: 1.4rem;
    color: #171717;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 0.4rem;
`

let ResumeContents = styled.div`
    box-sizing: border-box;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    text-align: left;
    color: #171717;
`

let ResumeSubInfoBorder= styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    aling-items: center;
    height: 2.4rem;
`

let ResumeSubArea = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6A6A6A;
    display: flex;
    align-items: center;
`

let ResumeSubMoneyType = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
`

let ResumeSubMoney = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.4rem;
    color: #444444;
`

let ResumeSubIconBorder = styled.div`
    display: flex;
    gap: 0.2rem;
    align-items: center;
`

let ResumeSubIcon = styled.img`
    height: 2.4rem;
    width: 2.4rem;
    cursor: pointer;
    object-fit: cover;
`

let ResumeGender = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6A6A6A;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`

const ResumeCard = ({data, isChange}) => {
    let navigate = useNavigate();
    let midMainager = useSelector((state)=>state.midnightLoginManager);

    const tcHandler = () => {
        if(data?.hourly_pay !== 0 && data?.hourly_pay !== null){
            return '시'
        }else if(data?.daily_pay !== 0 && data?.daily_pay !== null){
            return '일'
        }else if(data?.weekly_pay !== 0 && data?.weekly_pay !== null){
            return '주'
        }else if(data?.monthly_pay !== 0 && data?.monthly_pay !== null){
            return '월'
        }else if(data?.pension !== 0 && data?.pension !== null){
            return '연'
        }else if(data?.per_pay !== 0 && data?.per_pay !== null){
            return '건'
        }
    };

    const payHandler = () => {
        if(data?.hourly_pay !== 0 && data?.hourly_pay !== null){
            return data?.hourly_pay?.toLocaleString()
        }else if(data?.daily_pay !== 0 && data?.daily_pay !== null){
            return data?.daily_pay?.toLocaleString()
        }else if(data?.weekly_pay !== 0 && data?.weekly_pay !== null){
            return data?.weekly_pay?.toLocaleString()
        }else if(data?.monthly_pay !== 0 && data?.monthly_pay !== null){
            return data?.monthly_pay?.toLocaleString()
        }else if(data?.pension !== 0 && data?.pension !== null){
            return data?.pension?.toLocaleString()
        }else if(data?.per_pay !== 0 && data?.per_pay !== null){
            return data?.per_pay?.toLocaleString()
        }
    };

    const maskName = (name) => {
        if (!name || typeof name !== 'string') return '';
        const length = name.length;
        
        if (length === 3) {
          return name[0] + '*' + name[2];
        } else if (length === 4) {
          return name[0] + '**' + name[3];
        } else {
          return name; // 3글자 또는 4글자가 아닌 경우 변환하지 않음
        }
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        
        return `${year} . ${month} . ${day}`;
    };
      
    const favoritHandler = () => {
        if(midMainager?.log_data?.client_type !== 'business'){
            alert('사업자 회원으로 로그인 하세요')
        }else{
            axios.put(`https://api.midtrc.com/resume/${data?.id}/favorite`,{},{
                headers: {
                    'access-token': midMainager?.log_data?.access_token
                }
            }).then((res)=>{isChange(!isChange)}).catch((error)=>{console.log(error.response.data.detail)})
        }
        
    }

    const newWindow = () => {
        const newWindow = window.open('', '_blank');
        newWindow.location.href = `/resume-detail/${data?.id}`; 
    }
    
    return(
        <ResumeInfoBorder onClick={()=>{navigate(`/resume-detail/${data?.id}`)}}>
            <ResumeCompanyNameBorder>
                <ResumeCompanyName>
                    <ResumeImages alt="profile"/>
                    <div>{maskName(data?.client_name)}</div>
                    <ResumeGender>({data?.gender === 'male'? '남':'여'}/{data?.age}세)</ResumeGender>
                </ResumeCompanyName>
                
                <ResumeSubIconBorder>
                    <ResumeSubIcon onClick={(e)=>{e.stopPropagation(); favoritHandler()}} src={data?.is_favorite? bookMarkActive : bookMarkDefault} alt="book-mark-active"/>
                    <ResumeSubIcon onClick={(e)=>{e.stopPropagation(); newWindow()}} src={newPage} alt="new-page"/>
                </ResumeSubIconBorder>
            </ResumeCompanyNameBorder>
            <ResumeContents>
                {data?.resume_title}
            </ResumeContents>
            <ResumeSubInfoBorder>
                <ResumeSubArea>
                    <ResumeSubMoneyType>
                        {data?.address[0]} · 
                    </ResumeSubMoneyType>
                    &nbsp;
                    <ResumeSubMoneyType style={{color: "#F32E13"}}>
                        {tcHandler()}
                    </ResumeSubMoneyType>
                    &nbsp;
                    <ResumeSubMoney>
                        {payHandler()}
                    </ResumeSubMoney>
                    &nbsp;
                    <ResumeSubMoneyType>
                        원
                    </ResumeSubMoneyType>
                </ResumeSubArea>
                <ResumeSubArea style={{color: "#9E9E9E"}}>
                    {formatDate(data?.updated_at)}
                </ResumeSubArea>
                
            </ResumeSubInfoBorder>
        </ResumeInfoBorder>
    )
}

export default ResumeCard;