import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MessageRoom from "./message-room-mob";
import MessageWindow from "./message-window-mob";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

// font-family: 'Pretendard-Regular';
// font-weight: 600;
// font-size: 2.8rem;

let ColorBorder = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
    box-sizing: border-box;
    padding-top: 10rem;
    padding-bottom: 6.4rem;
`

let MessageBorder = styled.div`
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left:1rem;
    padding-right:1rem;
`
    
let TextButtonBorder = styled.div`
    display: flex;
    column-gap: 2.4rem;
    height: 2.4rem;
    align-items: center; /* 오타 수정: align-itemc -> align-items */
    margin-bottom: 3.2rem;
    overflow-x: auto; /* 좌우 스크롤 가능하게 설정 */
    white-space: nowrap; /* 요소들이 한 줄에 계속 있도록 설정 */
    padding-left:2rem;
    padding-right:2rem;
    box-sizing: border-box;
`

let TextButton = styled.button`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2rem;
    color: ${props => props.isSelected ? "#171717" : "#9E9E9E"};
    box-sizing: border-box;
    padding: 0px;
    background: none;
    border: none;
    cursor: pointer;
`

let ContentsBorder = styled.div`
`

const MessageBox = () => {
    let midMainager = useSelector((state) => state.midnightLoginManager);
    let [isSelected, setIsSelected] = useState(0);
    let [clickedRoom, setClickedRoom] = useState('');
    let [clickedProfile, setClickedProfile] = useState();
    let [messageData, setMessageData] = useState([]);
    let [isChange, setIsChange] = useState(false);
    const [isRoomSelected, setIsRoomSelected] = useState(false); // 초기값 false로 변경

    let params = useParams();

    // 메시지 그룹 클릭하면 메시지 데이터를 가져오는 함수
    useEffect(() => {
        if (clickedRoom === '') {
            // 선택된 메시지 그룹이 없는 경우
            setMessageData([]);
        } else {
            const fetchData = async () => {
                try {
                    const response = await axios.get(`https://api.midtrc.com/message/${clickedRoom}`, {
                        headers: {
                            'access-token': midMainager?.log_data?.access_token
                        }
                    })
                    let instList = response.data;
                    setMessageData(instList);

                    if (params.id === 'favorite') {
                        setIsSelected(2);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            fetchData();
        }

    }, [clickedRoom, isChange]);

    // 메시지 클릭 시 메시지 콘텍트 아이디 가져오는 함수
    const clickedRoomHandler = (e) => {
        setClickedRoom(e);
        setIsRoomSelected(true);
    }

    const profile = (e) => {
        setClickedProfile(e);
    }

    const changeData = () => {
        setIsChange(!isChange);
    }

    return (
        <ColorBorder>
            <MessageBorder>
                <TextButtonBorder>
                    <TextButton isSelected={true}>
                        전체 메세지
                    </TextButton>
                    <TextButton isSelected={false}>
                        안 읽은 메세지
                    </TextButton>
                    <TextButton isSelected={false}>
                        즐겨찾는 메세지
                    </TextButton>
                    <TextButton isSelected={false}>
                        관리자 메세지
                    </TextButton>
                    <TextButton isSelected={false}>
                        이벤트 메세지
                    </TextButton>
                </TextButtonBorder>
                <ContentsBorder>
                    {isRoomSelected && messageData.length > 0 ? (
                        <MessageWindow changeData={changeData} data={messageData} profile={clickedProfile} />
                    ) : (
                        <MessageRoom selectedFilter={isSelected} clickedRoom={clickedRoomHandler} profile={profile} clickedRoomId={clickedRoom} />
                    )}
                </ContentsBorder>
            </MessageBorder>
        </ColorBorder>
    )
}

export default MessageBox;
