import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";

let MypageBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
`;

let MyNoticeBoardPostBorder = styled.div`
    margin-top: 0.8rem;
    width: 100%;
    gap: 4rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    background-color: #F7F7F7;
    padding-top: 3rem;
`;

let MyNoticeBoardPostSection = styled.div`
    width: 100%;
    padding-top: 11rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`;

let MypageNoticeBoardPostinfo = styled.div`
    padding-top: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 4rem;
    background-color: white;
    border-radius: 16px;
    border: 1px solid #D2D2D2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let MypageTitleBar = styled.div`
    color: #171717;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
`;

let MypageMyNoticeBoardPostwriter = styled.div`
    color: #171717;
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 600;
`;

let MypageMyNoticeBoardPostcategory = styled.div`
    color: #6a6a6a;
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 600;
`;

let MypageMyNoticeBoardPostdivider = styled.div`
    width: 100%;
    border-bottom: 1px solid #E8E8E8;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

let NoticeboardpostContainer = styled.div`
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

let NoticeboardpostTitle = styled.div`
    width: 100%;
    font-size: 2rem;
    margin-bottom: 2rem;
    font-weight: 600;
    font-family: 'Pretendard-Regular';
`;

let Noticeboardpostingcontent = styled.div`
    width: 100%;
    font-size: 1.8rem;
    gap: 2rem;
    margin-bottom: 2rem;
    font-family: 'Pretendard-Regular';
`;

const MyNoticeBoardPost = () => {
    const [commentsValue, setCommentsValue] = useState('');
    const textareaRef = useRef(null);
    let params = useParams();
    let midMainager = useSelector((state) => state.midnightLoginManager);
    let [data, setData] = useState();

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }

        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.midtrc.com/notice/${params.id}`, {
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                setData(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [commentsValue]);

    const handleChange = (event) => {
        setCommentsValue(event.target.value);
    };

    return (
        <MypageBackground>
            <MyNoticeBoardPostBorder>
                <MyNoticeBoardPostSection>
                    <MypageNoticeBoardPostinfo>
                        <MypageTitleBar>
                            <MypageMyNoticeBoardPostwriter>미드나잇테라스 관리자</MypageMyNoticeBoardPostwriter>
                            <MypageMyNoticeBoardPostcategory>이벤트</MypageMyNoticeBoardPostcategory>
                        </MypageTitleBar>
                        <MypageMyNoticeBoardPostdivider />
                        <NoticeboardpostContainer>
                            <NoticeboardpostTitle>{data?.notice_title}</NoticeboardpostTitle>
                            <Noticeboardpostingcontent>
                                {
                                    data?.data[0]?.content
                                }
                            </Noticeboardpostingcontent>
                        </NoticeboardpostContainer>
                    </MypageNoticeBoardPostinfo>
                </MyNoticeBoardPostSection>
            </MyNoticeBoardPostBorder>
        </MypageBackground>
    );
};

export default MyNoticeBoardPost;
