import React from "react";
import styled from "styled-components";

import messageLeft from "./../../@images/message-left.svg";
import messageRight from "./../../@images/messageRight.svg"

import profileIcon1 from "./../../@images/profile_icon1.svg";
import profileIcon2 from "./../../@images/profile_icon2.svg";
import profileIcon3 from "./../../@images/profile_icon3.svg";
import profileIcon4 from "./../../@images/profile_icon4.svg";
import profileIcon5 from "./../../@images/profile_icon5.svg";
import parse from 'html-react-parser';

import ProfileTest from "./../../@images/profile-test.png";

let MessageBorder = styled.div`
    box-sizing: border-box;
    display: flex;
    width: 100%;
`

let MessageProfileBorder = styled.div`
    width: 6.4rem;
    height: 6.4rem;
    padding: 0.4rem;
    box-sizing: border-box;
`

let MessageProfile = styled.img`
    border-radius: 1.2rem;
    min-width: 5.6rem;
    min-height: 5.6rem;
    max-width: 5.6rem;
    max-height: 5.6rem;
`

let MessageLeft = styled.img`
    min-width: 1.6rem;
    max-width: 1.6rem;
    min-height: 3.6rem;
    max-height: 3.6rem;
`

let MessageContentsBorder = styled.div`
    min-height: 6.4rem;
    max-width: 100%;
    min-width: 9rem;
    border-radius: 0.8rem;
    background-color: white;
    margin-left: -0.1rem;
    box-sizing: border-box;
    padding: 1.6rem;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    line-height: 1.5;
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 400;
    text-align: left;
`

let MessageTimeText = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: left;
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 400;
    text-align: left;
    color: #171717;
    margin-left: 10px;
    white-space: nowrap;
`

let RightMessageBorder = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: right;
    width: 100%;
`

let RightMessageContentsBorder = styled.div`
    min-height: 6.4rem;
    max-width: 100%;
    min-width: 9rem;
    border-radius: 0.8rem;
    background-color: #171717;
    margin-left: -0.1rem;
    box-sizing: border-box;
    padding: 1.6rem;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    line-height: 1.5;
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 400;
    text-align: left;
    color: white;
`

let RightMessageTimeText = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: left;
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 400;
    text-align: left;
    color: #171717;
    margin-right: 10px;
    white-space: nowrap;
`

let MessageRight= styled.div`
    min-width: 1.6rem;
    max-width: 1.6rem;
    min-height: 3.6rem;
    max-height: 3.6rem;
    display: flex;
    align-items: flex-end;
    margin-left: -0.1rem;
`

const MessageComment = ({ who, text, profile, date, type }) => {

    const ProfileImageHandler = () => {
        let instProfile = profile;
        if(instProfile === '01'){
            return profileIcon1;
        }else if(instProfile === '02'){
            return profileIcon2;
        }else if(instProfile === '03'){
            return profileIcon3;
        }else if(instProfile === '04'){
            return profileIcon4;
        }else if(instProfile === '05'){
            return profileIcon5;
        }else{
            return instProfile
        }
    }

    const formatTime = (postTime) => {
        const now = new Date();
        const postDate = new Date(postTime);
    
        const diffInSeconds = Math.floor((now - postDate) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
    
        const formatHoursAndMinutes = (date) => {
            const hours = date.getHours();
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const period = hours < 12 ? '오전' : '오후';
            const formattedHours = hours % 12 || 12; // Convert 0 hours to 12 for AM/PM format
            return `${period} ${formattedHours}:${minutes}`;
        };
    
        if (diffInDays < 1) {
            return formatHoursAndMinutes(postDate);
        } else if (diffInDays < 2) {
            return '어제';
        } else {
            const year = postDate.getFullYear().toString().slice(2); // Get last two digits of the year
            const month = (postDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
            const day = postDate.getDate().toString().padStart(2, '0');
            return `${year}.${month}.${day}`;
        }
    }
    
    const TimeDisplay = ({ postTime }) => {
        const formattedTime = formatTime(postTime);
        return <div>{formattedTime}</div>;
    };

    const messageHandler = (e) => {
        const fileDownLoad = (e) => {    
            console.log(e)
        }
        

        switch(e){
            case 'text':
                return text;
            case 'image':
                return <img onClick={()=>{fileDownLoad(text)}} src={text} style={{width: '80%', objectFit: "cover"}}/>;
            case 'file':
                return <div onClick={()=>{fileDownLoad(text)}}>{text}</div>;
            default :
                return null;
        }
    }

    if (!text) {
        return null; 
    }

    switch(who){
        case 'other':
            return(
                <MessageBorder>
                    <MessageProfileBorder>
                        <MessageProfile src={ProfileImageHandler()} alt="message-profile"/>
                    </MessageProfileBorder>
                    <MessageLeft src={messageLeft} alt="message-left"/>
                    <MessageContentsBorder>
                        {messageHandler(type)}
                    </MessageContentsBorder>
                    <MessageTimeText>
                        <TimeDisplay postTime={date}/>
                    </MessageTimeText>
                </MessageBorder>
            )
        case 'own':
            return(
                <RightMessageBorder>
                    <RightMessageTimeText>
                        <TimeDisplay postTime={date}/>
                    </RightMessageTimeText>
                    <RightMessageContentsBorder>
                        {text}
                    </RightMessageContentsBorder>
                    <MessageRight>
                        <img src={messageRight} alt="icon06"/>
                    </MessageRight>
                </RightMessageBorder>
            )
        default:
            return(
                <div/>
            )
    }
}

export default MessageComment;
