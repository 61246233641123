/*eslint-disable*/
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

let ContentsBorder = styled.div`
    width: 74.8rem; 
    height: 4rem;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

let NormalContentsList = styled.div`
    width: 74.8rem;
    height: auto;
    display: grid;
    grid-template-columns: 8fr 1fr 1fr;
    align-items: center;
    box-sizing: border-box;
`;

let ContentTitleBorder = styled.div`
    width: 74.8rem;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    column-gap: 1.6rem;
    box-sizing: border-box;
    font-size: 1.4rem;
`;

let NoticeNum = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #171717;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

let NoticeTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #171717;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

let EtcText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #171717;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NoticeboardContents = ({ content }) => {
    let navigate = useNavigate();

    const formatTime = (postTime) => {
        const now = new Date();
        const postDate = new Date(postTime);
    
        const diffInSeconds = Math.floor((now - postDate) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
    
        if (diffInHours < 1) {
            return `${diffInMinutes}분`;
        } else if (diffInHours < 3) {
            return `${diffInHours}시간`;
        } else if (diffInDays < 1) {
            return '오늘';
        } else if (diffInDays < 2) {
            return '어제';
        } else {
            const year = postDate.getFullYear().toString().slice(2); // Get last two digits of the year
            const month = (postDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
            const day = postDate.getDate().toString().padStart(2, '0');
            return `${year}.${month}.${day}`;
        }
    }

    const TimeDisplay = ({ postTime }) => {
        const formattedTime = formatTime(postTime);
        return <div>{formattedTime}</div>;
    };

    return (
        <ContentsBorder onClick={()=>{navigate(`/noticeboard-post/${content.id}`)}}>
            <NormalContentsList>
                <ContentTitleBorder>
                    <NoticeNum>
                        {content.num}
                    </NoticeNum>
                    <NoticeTitle>
                        {content.notice_title}
                    </NoticeTitle>
                    <EtcText>
                        <TimeDisplay postTime={content.created_at}/>
                    </EtcText>
                </ContentTitleBorder>
            </NormalContentsList>
        </ContentsBorder>
    );
};

export default NoticeboardContents;
