// 한국 지역 데이터 배열 정의
const Policy01 = `제 1장 총칙 

제 1조 목적 

제 2조 약관의 효력 및 변경 

제 3조 약관 외 준칙 

제 2장 서비스 이용계약 

제 4조 계약의 성립 

제 5조 가입의 제한 

제 3장 서비스의 이용 

제 6조 다양한 서비스 제공 

제 7조 서비스의 변경 및 종료 

제 8조 게시물의 관리 

제 9조 권리의 귀속 및 저작물의 이용 

제 10조 유료 서비스의 이용 

제 11조 게시판 이용 상거래 

제 12조 서비스 이용 방법 및 주의점 

제 13조 이용계약 해지 

제 14조 개인정보의 보호 

제 4장 기타 

제 15조 손해배상 

제 16조 청소년 보호 

제 17조 통지 및 공지 

제 18조 분쟁의 해결 

제 1장 총칙 

 

제 1조 목적 및 정의 

 

본 약관은 ㈜블랙스케일(이하 "회사"라 합니다)이 운영하는 웹사이트(이하 “사이트”라 합니다) 및 모바일 애플리케이션(이하 “애플리케이션”이라 하며, 사이트와 애플리케이션을 총칭하여 “사이트 등”이라 합니다)을 통해 서비스를 제공함에 있어 회사와 이용자의 의무 및 책임사항, 서비스의 이용조건 및 절차, 기타 필요한 사항을 규정함을 목적으로 합니다. 

 

제 2 조 (약관의 효력 및 변경) 

 

1. 회사는 이 약관의 내용을 회원이 쉽게 열람할 수 있도록 통합서비스 내 또는 그 연결 화면에 게시하고, 약관에 동의한 회원들에게 모두 효력이 발생합니다. 

 

2. 회사는 『약관의규제에관한법률』(이하 "약관법"), 『정보통신망이용촉진및정보보호등에관한법률(이하 "정보통신망법")』 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. 

 

3. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는 개정약관의 적용일자 30일 전에 공지하거나 서비스내 전자우편, 전자쪽지, 로그인시 동의창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다. 위와 같은 공지 또는 통지에도 불구하고 변경된 약관에 대한 정보를 알지 못해 발생하는 회원의 피해는 회사에서 책임지지 않습니다. 

 

4. 약관이 변경된 이후에 회원이 회사의 서비스를 계속 이용하는 경우에는 개정된 약관에 동의하는 것으로 봅니다. 개정된 약관에 동의하지 않을 경우 회원 탈퇴(해지)를 요청할 수 있습니다. 

 

5. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 『약관의 규제 등에 관한 법률』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 및 관련법령 또는 상관례에 따릅니다. 

 

제 3 조 (약관 외 준칙 및 관련법령과의 관계) 

 

1. 회사는 필요한 경우 통합서비스 내의 개별항목에 대하여 개별약관 또는 운영원칙(이하 "서비스별 안내"라 합니다)를 정할 수 있으며, 이 약관과 서비스별 안내의 내용이 상충되는 경우에는 서비스별 안내의 내용을 우선하여 적용합니다. 

2. 이 약관 또는 통합서비스별 안내에서 정하지 않은 사항은 전기통신사업법, 전자거래기본법, 정보통신망법, 전자상거래 등에서의 소비자보호에 관한 법률, 개인정보보호법 등 관련 법령의 규정과 일반적인 상관례에 의합니다. 

 

 

제 2장 서비스 이용 계약 

 

제 4 조 (이용 계약의 성립) 

 

1. 회원으로 가입하고자 하는 이용자는 회원정보를 기입한 후 본 약관과 개인정보수집 및 이용에 동의한다는 의사표시를 함으로써 회원가입을 신청할 수 있습니다. 

 

제 5조 (서비스 가입의 제한) 

1. 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다. 

 

1)가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우 

 

2)타인의 명의 혹은 전화번호를 이용하거나 이미 가입된 회원과 전화번호나 전자우편주소가 동일한 경우 

 

3)허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우 등 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우 

 

4)부정한 용도 또는 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우 

 

5)기타 관계법령 또는 이 약관에 위배되거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 등 위법 또는 부당한 목적으로 신청한 경우로서 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우 

 

6)가입 신청자가 만 19세 미만인 경우 

 

7)본 서비스와 경쟁관계에 있는 이용자가 신청하는 경우 

 

2. 제1항에 따른 신청에 있어 회사는 회원의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다. 

 

3. 회사는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 또는 기타 회사의 귀책사유로 문제가 있는 경우에는 승낙을 유보할 수 있습니다. 

 

4. 제2항과 제4항에 따라 회원가입 신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를 가입신청자에게 알리도록 합니다. 

 

5. 이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한 시점으로 합니다. 

 

 

제 3장 서비스의 이용 

 

제 6 조 (서비스의 제공) 

 

1. 통합서비스 이용계약이 성립되면 회원들은 개별 서비스에 대한 별도의 이용계약을 체결할 필요는 없습니다. 

 

2. 그러나 개별 서비스 내에서도 일부 세부 하위 서비스의 경우 별도의 이용약관에 동의해야 이용이 가능하며 필요한 추가 정보를 기재하거나, 이메일 주소 승인 또는 문자메시지 인증, 인증서 발급 등 회사가 정한 인증 절차를 완료하여야 서비스 이용이 가능한 경우도 있습니다. 

 

3. 회사는 회원들에게 SNS, 게시판 서비스, 온라인 콘텐츠 제공 서비스등 인터넷과 모바일로 이용 할 수 있는 다양한 서비스를 제공합니다. 회원은 스마트폰의 어플리케이션 스토어 등에서 서비스를 다운받아 설치하거나 직접 PC에 설치 혹은 웹페이지에 접속하여 서비스를 이용할 수 있습니다. 

 

4. 회사는 더 나은 서비스를 위하여 서비스에 필요한 소프트웨어의 업데이트 버전을 제공할 수 있습니다. 소프트웨어의 업데이트에는 중요한 기능의 추가 또는 불필요한 기능의 제거 등이 포함되어 있습니다. 회원들은 서비스를 원활하게 이용할 수 있도록 최신 업데이트를 하는것이 좋습니다. 

 

5. 회사는 더 나은 서비스의 제공을 위하여 회원들에게 서비스의 이용과 관련된 각종 고지, 관리 메시지 및 기타 광고를 비롯한 다양한 정보를 서비스 내에 표시하거나 회원들의 계정 정보에 등록되어 있는 연락처로 직접 발송할 수 있습니다. 단, 광고성 정보 전송의 경우에는 사전에 수신에 동의한 경우에만 전송합니다. 

 

제 7 조 (서비스의 변경 및 종료) 

 

1. 회사의 서비스는 24시간 제공이 원칙입니다. 그러나 아래 각 호의 경우 통합서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 

 

1)통합서비스용 설비의 유지·보수 등을 위한 정기 또는 임시 점검의 경우 

 

2)정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우 

 

3)관계사와의 계약 종료, 정부의 명령/규제, 서비스/회원 정책 변경 등 회사의 제반 사정으로 서비스의 전부 또는 일부를 유지할 수 없는 경우 

 

4)기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우 

 

2. 1항에 의한 서비스 중단의 경우에는 미리 제17조에서 정한 방법으로 회원에게 통지 내지 공지하겠습니다. 이 때 원만한 서비스 및 정책 변경 등을 위하여 서비스 이용 시 재 로그인 또는 추가적인 동의 절차 등이 필요할 수 있습니다. 다만, 회사로서도 예측할 수 없거나 통제할 수 없는 사유(회사의 과실이 없는 디스크 내지 서버 장애, 시스템 다운 등)로 인해 사전 통지 내지 공지가 불가능한 경우에는 게시하지 않습니다. 이러한 경우에도 회사는 상황을 파악하는 즉시 최대한 빠른 시일 내에 서비스를 복구하도록 노력하겠습니다. 

 

제 8 조 (게시물의 관리) 

 

1. 회원의 게시물이 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’)및 저작권법 등 관련 법령에 위반되는 내용을 포함하는 경우, 권리자는 회사에 관련 법령이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련 법령에 따라 조치를 취합니다. 

 

2. 회사는 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사의 정책 및 관련 법령에 위반되는 경우에는 관련 법령에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다. 

 

3. 위와 관련된 세부 절차는 정보통신망법 및 저작권법이 규정한 범위 내에서 회사가 정한 권리침해 신고 절차 에 따릅니다. 

 

제 9 조 (권리의 귀속 및 저작물의 이용) 

 

1. 회원은 사진, 글, 정보, (동)영상, 통합서비스 또는 회사에 대한 의견이나 제안 등 콘텐츠(이하 ‘게시물’)를 통합서비스 내에 게시할 수 있으며, 이러한 게시물에 대한 저작권을 포함한 지적재산권은 권리자가 계속하여 보유합니다. 

 

2.회원은 통합서비스 내에 게시한 게시물에 대한 사용, 저장, 수정, 복제, 공중송신, 전시, 배포 등의 방식으로 이용할 수 있도록 사용을 허락하는 전 세계적인 라이선스를 회사에게 제공하게 됩니다. 본 라이선스에서 회원이 회사에게 부여하는 권리는 서비스를 운영, 개선, 홍보하고 새로운 서비스를 개발하기 위한 범위 내에서 사용되며, 이러한 목적 범위 내에서 회사와 명시적인 업무 계약을 체결한 상대방 또는 다른 이용자에 대한 서브 라이선스 또한 여기에 포함됩니다. 또한, 서비스의 개선 및 연구개발 목적으로 회사 및 회사의 계열사에서 게시물을 사용할 수 있습니다. 일부 개별 서비스에서는 회원이 제공한 콘텐츠에 접근하거나 이를 삭제하는 방법을 제공할 수 있습니다(다만 일부 서비스의 특성 및 콘텐츠의 성질 등에 따라 게시물의 삭제가 불가능할 수도 있습니다). 또한 일부 서비스에서는 제공된 콘텐츠에 대한 회사의 사용 범위를 제한하는 설정이 있습니다. 

 

3. 회원은 회사에 제공한 콘텐츠에 대해 회사에 라이선스를 부여하기 위해 필요한 권리를 보유해야 합니다. 이러한 권리를 보유하지 않아 발생하는 모든 문제에 대해서는 게시자가 책임을 부담하게 됩니다. 또한, 회원은 음란하거나 폭력적이거나 기타 공서양속 및 법령에 위반하는 콘텐츠를 공개 또는 게시할 수 없습니다. 

 

4. 회사는 회원의 콘텐츠가 관련 법령에 위반되거나 음란 또는 청소년에게 유해한 게시물, 차별 갈등을 조장하는 게시물, 도배 · 광고 · 홍보 · 스팸성 게시물, 계정을 양도 또는 거래하는 내용의 게시물, 타인을 사칭하는 게시물 등이라고 판단되는 경우 이를 삭제하거나 게시를 거부할 수 있습니다. 다만 회사가 모든 콘텐츠를 검토할 의무가 있는 것은 아닙니다. 누군가 회원의 권리를 침해하였다면, 고객센터를 통해 게시중단 요청에 대한 도움을 받으실 수 있습니다. 위와 관련된 구체적인 기준 및 이용제한 절차의 내용은 운영정책에서 확인하실 수 있습니다. 

 

5. 서비스에서는 회사가 보유하지 않은 일부 콘텐츠가 표시될 수 있습니다. 그러한 콘텐츠에 대해서는 콘텐츠를 제공한 주체가 단독으로 모든 책임을 부담하게 됩니다. 회원이 서비스를 이용하더라도 다른 이용자의 콘텐츠에 대하여 어떠한 권리를 가지게 되는 것은 아닙니다. 회원이 다른 이용자의 콘텐츠를 사용하기 위해서는 콘텐츠 소유자로부터 별도로 허락을 받아야 합니다. 

 

제 10 조 (유료 서비스의 이용) 

 

1. 통합서비스를 구성하는 개별 서비스의 일부는 무료로 제공하고 있으나, 일부는 유료로 제공될 수 있습니다. 

 

2. 회원이 회사가 제공하는 유료서비스를 이용하는 경우 이용대금을 납부한 후 이용하는 것을 원칙으로 합니다. 회사가 제공하는 유료서비스에 대한 이용요금의 결제 방법은 각 유료서비스마다 결제 방법의 차이가 있을 수 있습니다. 매월 정기적인 결제가 이루어지는 서비스의 경우 회원이 해당 서비스의 이용을 중단하고 정기 결제의 취소를 요청하지 않는 한 매월 결제가 이루어집니다. 

 

3.회사는 결제의 이행을 위하여 반드시 필요한 회원의 개인정보를 추가적으로 요구할 수 있으며, 회원은 회사가 요구하는 개인정보를 정확하게 제공하여야 합니다. 

본 조에서 정하지 않은 내용은 개별 서비스 약관에서 정하며, 본 조의 내용과 각 세부 하위 서비스의 유료서비스 약관의 내용이 충돌하는 경우 세부 하위 서비스의 유료서비스 약관의 규정에 따릅니다. 

 

제 11 조 (게시판 이용 상거래) 

 

1. 회원이 서비스를 이용하여 통신판매 또는 통신판매중개를 업으로 하는 경우 전자상거래 등에서의 소비자보호에 관한 법률(이하 ‘전자상거래법’)에 따른 의무사항을 준수하여야 합니다. 

 

2.회원이 통신판매 또는 통신판매중개를 함에 있어 다른 이용자와 전자상거래 관련 분쟁이 발생하는 경우, 회사는 다른 이용자에게 소비자피해 구제 대행 신청을 할 수 있는 장치를 마련합니다. 

 

3. 회사는 전자상거래법에 따라 신원정보를 입력하는 기능 등을 제공하여 회원의 신원정보를 확인하고, 회원과 다른 이용자 사이에 분쟁이 발생하여 전자상거래법에 따라 소비자피해 분쟁조정기구, 공정거래위원회, 시·도지사 또는 시장·군수·구청장이 신원정보 제공을 요구하는 경우 이에 협조합니다. 

제 12 조 (서비스 이용 방법 및 주의점) 

 

1. 회원은 통합서비스를 자유롭게 이용할 수 있으나, 아래 각 호의 행위는 하여서는 안 됩니다. 

 

1)명의 , 결제 도용 

 

-타인의 개인정보를 이용하여 계정을 생성하는 행위 

-다른 사람의 계정 및 비밀번호를 도용, 부정하게 사용하거나, 다른 사람의 명의를 사용하거나 명의자의 허락 없이 문자메시지(SMS) 인증 등을 수행하는 행위 

-타인의 결제수단을 무단으로 이용하여 서비스 이용요금 등을 결제하는 행위 

 

2)허위 사실 유포 및 기재 

 

-이용 신청 또는 변경 시 허위 사실을 기재 

-서비스 내에 회사나 제3자 등에 대한 허위의 사실을 게시하는 행위 

-타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위 

 

3)불건전 게시물 게시 

 

-회사 또는 제3자의 권리(저작권, 특허 등)를 침해하는 게시물 

-본인/타인의 개인정보(신상정보, 위치, 연락처, 이메일 등)를 수집, 저장, 공개하는 게시물 

-다른 사람에게 성적 수치심을 주거나 혐오감, 불쾌감을 유발하는 게시물 

-다른 사람을 비방하거나 음해하는 목적의 게시물(인신공격, 루머 포함) 

-영리 목적의 광고 게시물(음란사이트, 피라미드 , 폰지 , 금융 서비스, 위법 위조등이 가능한 서비스를 광고하는 행위) 

-재물을 걸고 도박하거나 사행 행위에 대한 내용이 있거나 서비스를 광고하는 게시물 ( 불법 도박 사이트, 사회 통념상 납득되지 않는 서비스 등) 

-악성코드가 포함되어 있거나 시스템 장애를 유도하는 게시물 

-특정 사상/종교적 색채가 짙은 게시물 

-특정 개인 및 회사에 대한 허위 사실을 악의적으로 유포하는 게시물 

-인종, 성, 특정 지역을 비하하거나 차별하는 게시물 

-폭력, 비행, 사행심을 조장하는 게시물 

-약관 위반 행위와 관련된 게시물 

-기타 약관, 관련 법령에서 금지하는 게시물 

 

4)불건전 서비스 이용 행위 

 

-스토킹(stalking), 허위 또는 악의적 신고 남용 등 다른 이용자를 괴롭히는 행위 

-수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상 또는 영상을 계속하여 상대방에게 도달하게 하여 상대방의 일상적 생활을 방해하는 행위 

-게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 행위 

-윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위 

-짧은 시간에 많은 양의 게시물을 등록하거나 동일한 내용을 반복 게시하여 운영을 방해하는 행위 

-회사 또는 제3자의 저작권 등 기타 권리를 침해하는 행위(국내외 제3자의 저작권 등을 침해하는 행위로서 회사가 IP 접속 차단 등 기술적인 조치를 통하여 타인에 대한 권리 침해 방지 조치를 취하였음에도 불구하고 이용자가 회사를 기망하는 수단과 방법 등을 통하여 서비스에 접속 하는 등 제3자의 저작권 등을 침해하는 행위를 포함합니다. 

-자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가하는 등 피해를 입힐 목적으로 허위의 정보를 유통시키는 행위 

-회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를 복사, 수정, 배포, 판매, 양도, 대여, 담보제공하거나 타인에게 그 이용을 허락하는 행위와 소프트웨어를 역설계하거나 소스 코드의 추출을 시도하는 등 서비스를 복제, 분해 또는 모방하거나 기타 변형하는 행위 

-서비스의 운영을 방해하거나 안정적 운영을 방해할 수 있는 정보 및 수신자의 명시적인 수신거부의사에 반하여 또는 수신자의 명시적인 동의 없이 광고성 정보 또는 스팸메일(Spam Mail)을 전송하는 행위 

-회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 행위 

-컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 E-mail, 카카오톡 메시지 등으로 발송하는 행위 

-회사 또는 관계사의 직원이나 운영자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나 E-mail, 카카오톡 메시지 등을 발송하는 행위 

 

5)불건전 닉네임 사용 

 

-회사 직원 및 관리자 또는 제3자를 사칭하는 행위 

-욕설, 비/속어 등을 닉네임에 사용하는 행위 

-다른 이용자에게 불쾌감이나 성적 수치심을 줄 수 있는 명칭을 사용하는 행위 

-기타 약관에서 금지하는 명칭을 사용하는 행위 

 

6)불건전 언어 사용 

 

-욕설, 비/속어 등을 사용하는 행위 

-음란한 단어나 노골적인 성 묘사 등을 통해 성적 수치심을 느끼게 하는 표현이나 행동을 하는 행위 

-다른 이용자에게 불쾌감이나 성적 수치심을 줄 수 있는 명칭을 사용하는 행위 

-현실에 대한 위협이나 상대방에게 공포심을 느끼게 하는 표현이나 행동을 하는 행위 

-기타 약관에서 금지하는 표현이나 통신을 하여 상대방에게 불쾌감이나 혐오감을 주는 행위 

-제3자의 명예, 초상권, 개인정보를 포함한 제반 권리를 침해, 훼손할 목적의 내용이 포함된 대화 등 이에 준하는 행위 

-공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위 

 

7)버그 및 제한 사항 악용 

 

-고의로 서비스 오류를 이용하여 이득을 얻거나, 서비스 내 경제 시스템에 영향을 주는 행위 

-고의로 서비스 오류를 이용하여 다른 이용자에게 피해를 주는 행위 

-고의로 서비스 오류를 이용하여 시스템에 영향을 주는 행위 

 

8)어뷰징 

 

-고의로 리뷰 및 댓글 게시글등을 조작하는 행위 

-회사의 서비스 정책 및 시스템 상 허용되지 않는 방법으로 리뷰 및 게시글 댓글을 조작하는 행위 

-타플랫폼 또는 외부유입경로를 통해 리뷰 또는 게시글 댓글을 조작하는 행위 

-다수의 계정을 이용하여 리뷰 또는 게시글 댓글을 조작하는 행위 

-동일인이 여러개의 계정을 이용하여 리뷰 또는 게시글 댓글을 조작하는 행위 

-불법 언더 마케팅 서비스로 리뷰 또는 게시글 댓글을 조작하는 행위 

-회사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로 리뷰 및 게시글 댓글을 조작하는 행위 

-일반적으로 서비스에 통용되지 아니하는 방법으로 재화를 늘리는 행위 

-비정상적으로 시스템 설계상 제한을 회피하여 이익을 취하는 행위  

(예시. 커뮤니티나 프로필 내 개인 휴대폰 번호, SNS/홈페이지 주소를 게시하여 우회 거래를 유도하는 행위 등) 

-시스템상 허용되지 않는 방법으로 다른 이용자의 평판 이익을 늘리는 행위 

-시스템상 허용되지 않는 방법으로 추천인을 늘리는 행위 

 

9)부정 결제 행위 

-물품 및 용역의 실제 거래 없이 자금을 결제하는 허위 거래 행위 

-결제 수단만을 이용하기 위한 결제 행위 

-결제 수단의 현금화 행위 

-부정한 목적의 무상 혜택 사용 행위 

-결제수단의 명의를 타인에게 빌려주는 행위 

-거래되는 물품 또는 용역의 내용을 정확히 제시하지 않은 거래에 대한 결제 

-위 사항 외 결제 시스템을 이용한 모든 기타 법령위반 행위(특히, 여신전문금융업법, 전자금융거래법 등 금융관련 법령 및 조세 관련 법령을 위반하는 행위를 포함하며, 이에 한정하지 아니합니다). 

-진정한 구매 의사 없이 유료서비스 구매 후 다시 해지하는 행위를 2회 이상 반복하는 등 서비스를 부당하게 악용하는 행위 

 

10)기타 각 호에 포함되지 아니하였지만 관련 법령에서 불법으로 규정되어 있는 행위 

 

2. 회원은 서비스의 이용 권한, 기타 이용계약상 지위를 타인에게 양도·증여할 수 없으며, 담보로 제공할 수 없습니다. 

 

3. 회원의 자격 혹은 나이에 따라 아래 각 호처럼 통합서비스 이용의 전체 혹은 일부가 제한될 수 있습니다. 

1)만19세 미만의 이용자는(단, 만 19세에 도달하는 해의 1월 1일을 맞이한 자는 제외, 이하 본 조에서 동일함) 정보통신망법 및 청소년보호법의 규정에 의하여 청소년유해매체물은 이용할 수 없습니다. 

2)청소년유해매체물을 이용하시기 위해서는 만 19세 이상이어야 하며, 정보통신망법 및 청소년보호법의 규정에 의하여 실명인증을 통해 본인 및 연령 인증을 받으셔야 합니다. 인증을 받지 않으시면, 해당 서비스의 이용이 제한됩니다. 

 

4. 회원이 관련 법령, 회사의 모든 약관 또는 정책을 준수하지 않는다면, 회사는 회원의 위반행위 등을 조사할 수 있고, 해당 게시물 등을 삭제 또는 임시 삭제하거나 회원의 계정 전체 혹은 개별 서비스의 이용을 잠시 또는 계속하여 중단하거나, 서비스 재가입 또는 일부 개별 서비스의 재이용에 제한을 둘 수도 있습니다. 또한 회원이 통합서비스와 관련된 설비의 오작동이나 시스템의 파괴 및 혼란을 유발하는 등 통합서비스 제공에 악영향을 미치거나 안정적 운영을 심각하게 방해한 경우, 회사는 이러한 위험 활동이 확인된 회원의 계정들에 대하여 이용제한을 할 수 있습니다. 다만, 회원은 이용제한과 관련하여 조치 결과가 불만족스러울 경우 고객센터를 통해 이의를 제기할 수 있습니다. 

 

5. 이용 제한은 위반 활동의 누적 정도에 따라 한시적 제한에서 영구적 제한으로 단계적 제한하는 것을 원칙으로 하지만, 음란한 내용의 게시와 유포 및 사행성 도박 홍보 등 관련 법령에서 금지하는 명백한 불법행위나 타인의 권리침해로서 긴급한 위험 또는 피해 차단이 요구되는 사안에 대해서는 위반 활동 횟수의 누적 정도와 관계 없이 즉시 영구적으로 이용이 제한될 수 있습니다. 

 

6. 본 조에서 정한 사항 및 그 밖에 통합서비스의 이용에 관한 자세한 사항은 운영정책 등을 참고해 주시기 바랍니다. 

 

제 13 조 (이용계약 해지) 

 

1. 회원이 계정 탈퇴를 하는 경우 통합서비스 이용계약도 자동으로 해지됩니다. 

 

2. 통합서비스 이용계약 해지를 원하는 경우 회원은 언제든지 서비스 내 제공되는 메뉴를 이용하여 해지 신청을 할 수 있으며,회사는 법령이 정하는 바에 따라 신속히 처리하겠습니다. 

 

3. 통합서비스 이용계약이 해지되면 관련 법령 및 개인정보 처리방침에 따라 회원의 일정 정보를 보유하는 경우를 제외하고는 회원의 정보는 원칙적으로 삭제됩니다 . 다만, 회원이 작성한 게시물은 삭제되지 않고, 제3자에 의하여 스크랩 또는 다른 공유 기능으로 게시되거나, 회원이 제3자의 게시물에 댓글 등 게시물을 추가하는 등의 경우에는 해당 게시물 및 댓글은 삭제되지 않으므로 반드시 해지 신청 전에 삭제하시기 바랍니다. 

 

4. 전항에 따라 회원이 삭제하지 않은 게시물은 다른 이용자의 정상적 서비스 이용을 위하여 필요한 범위 내에서 통합서비스 내에 삭제되지 않고 남아 있게 됩니다. 

 

5. 유료서비스 이용계약의 해지는 회원의 유료서비스 이용계약 해지 신청 및 회사의 승낙에 의해 성립하게 되고, 환불할 금액이 있는 경우 환불도 이루어 지게 됩니다. 다만 각 세부 하위서비스의 유료서비스에서 본 약관과 다른 계약해지 방법 및 효과를 규정하고 있는 경우 각 유료서비스 약관 및 관련 세부지침에서 정한 바에 따릅니다. 

 

6. 통합서비스 이용계약이 해지된 경우라도 회원은 다시 회사에 대하여 이용계약의 체결을 신청할 수 있습니다. 다만, 회원이 관련 법령, 본 약관 및 세부지침을 준수하지 않아 서비스의 이용이 중단된 상태에서 이용계약을 해지한 후 다시 이용계약 체결을 신청하는 경우에는 통합서비스 가입에 일정기간 시간적 제한이 있을 수 있습니다. 또한 통합서비스를 구성하는 일부 개별 서비스의 경우 다시 통합서비스 이용계약을 체결한 후에도 해당 개별 서비스를 바로 이용하는 것에는 내부적인 방침에 따라 일정한 시간적 제한이 따를 수 있습니다. 

 

제 14 조 (개인정보의 보호) 

 

1. 회원의 개인정보는 통합서비스의 원활한 제공을 위하여 회원이 동의한 목적과 범위 내에서만 이용됩니다. 관련 법령에 의하거나 회원이 별도로 동의하지 아니하는 한 회사가 회원의 개인정보를 제3자에게 제공하지 않습니다. 회사가 회원의 개인정보를 안전하게 처리하기 위한 자세한 사항은 개인정보처리방침을 참고해주세요. 

 

제 4장 기타 

 

제 15 조 (손해배상 등) 

 

1. 회사는 관련 법령상 허용되는 한도 내에서 통합서비스와 관련하여 본 약관에 명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지 않습니다. 또한, 회사는 콘텐츠 제공업체가 제공하거나 회원이 작성하는 등의 방법으로 통합서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며, 회사의 과실 없이 발생된 회원의 손해에 대하여는 책임을 부담하지 아니합니다. 

 

2. 회사는 회사의 과실로 인하여 회원이 손해를 입게 될 경우 본 약관 및 관련 법령에 따라 회원 손해를 배상하겠습니다. 다만 회사는 회사의 과실 없이 발생된 아래와 같은 손해에 대해서는 책임을 부담하지 않습니다. 또한 회사는 법률상 허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및 징벌적 손해에 대한 책임을 부담하지 않습니다. 

 

1)천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해 

2)회원의 귀책사유로 통합서비스 이용에 장애가 발생한 경우 

3)통합서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해 

4)제3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해 

5)제3자가 회사 서버에 대한 전송 또는 회사 서버로부터의 전송을 방해함으로써 발생하는 손해 

6)제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해 

7)전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제3자가 서비스를 이용하는 과정에서 발생된 손해 

8)기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해 

 

3. 회사는 회원이 통합서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그 밖에 통합서비스를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다. 

 

4. 회사는 회사의 과실이 없는 한 회원 상호간 또는 회원과  제3자 상호간에 통합서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다. 

 

제 16 조 (청소년 보호) 

 

1. 회사는 유해 정보로부터 청소년을 보호하고 청소년의 안전한 인터넷 사용을 돕기 위해 정보통신망법에서 정한 청소년보호정책을 별도로 시행하고 있으며, 구체적인 내용은 통합서비스를 구성하는 개별 서비스 초기 화면 등에서 확인할 수 있습니다. 

 

제 17 조 (통지 및 공지) 

 

1. 회원은 언제든지 고객센터에 의견을 개진할 수 있습니다. 

 

2. 서비스 이용자 전체에 대한 공지는 7일이상 서비스 공지사항란에 게시함으로써 효력이 발생합니다. 회원에게 중대한 영향을 미치는 사항의 경우에는 계정에 등록된 이메일 주소로 이메일 발송을 하겠습니다. 

 

3.  회사는 서비스 내 전자쪽지 발송, 서비스 내 알림 메시지를 띄우는 등의 별도의 전자적 수단 발송 또는 회원이 등록한 휴대폰번호로 카카오톡 메시지 또는 문자메시지를 발송하는 방법 등으로 회원 개인에게 서비스에 대한 알림 혹은 통지를 할 수 있습니다. 

 

제 18 조 (분쟁의 해결) 

 

1. 본 약관 또는 통합서비스는 대한민국법령에 의하여 규정되고 이행됩니다. 통합서비스 이용과 관련하여 회사와 회원 간에 분쟁이 발생하면 이의 해결을 위해 성실히 협의할 것입니다. 그럼에도 불구하고 해결되지 않으면 민사소송법의 관할법원에 소를 제기할 수 있습니다. 
`
    
  
export default Policy01;