import axios from "axios";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import addFile from "./../@images/add-file.svg";

let ComplainBorder = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

let ComplainContent = styled.div`
    width: 60rem;
    height: auto;
    border-radius: 1.6rem;
    box-sizing: border-box;
    padding: 3.2rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

let ComplainTitle = styled.div`
    font-size: 2rem;
    font-weight: 600;
`

let ComplainLine = styled.div`
    border-width: 0.1rem 0 0 0;
    border-style: solid;
    border-color: #E8E8E8;
    width: 100%;
    margin-top: 3.2rem;
    margin-bottom: 1.6rem;
`

let CompainInput = styled.input`
    width: 100%;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 2rem;
    font-size: 1.8rem;
    font-weight: 500;
    font-family: 'Pretendard-Regular';
    &::placeholder{
        color: #a9a9a9;
    }
`

let ComplainText = styled.textarea`
    width: 100%;
    min-height: 30rem;
    resize: none;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 2rem;
    font-size: 1.8rem;
    font-weight: 500;
    font-family: 'Pretendard-Regular';
    background-color: #F7F7F7;
    border-radius: 1.6rem;
    line-height: 1.5;
    &::placeholder{
        color: #a9a9a9;
    }
`

let CancelButton = styled.button`
    min-width: 10.4rem;
    max-width: 10.4rem;
    height: 5.6rem;
    background-color: #B8B8B8;
    border-radius: 0.4rem;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 500;
    font-family: 'Pretendard-Regular';
`

let SubmitButton = styled.button`
    min-width: 10.4rem;
    max-width: 10.4rem;
    height: 5.6rem;
    background-color: #CD1B1B;
    border-radius: 0.4rem;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 500;
    font-family: 'Pretendard-Regular';
`

let SubmitInputBorder = styled.div`
    width: 100%;
    height: 5.6rem;
    box-sizing: border-box;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 0.4rem;
    border-color: #E8E8E8;
    position: relative;
`

let SubmitInput = styled.input`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;
    padding: 2rem;
    color: #171717;
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 400;
`

let SubmitImage = styled.img`
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
`

const Question = ({handler}) => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [title, setTitle] = useState('');
    let [content, setContent] = useState('');
    let fileRef = useRef(null);
    let [fileName, setFileName] = useState();
    let [myFile, setMyFile] = useState();

    const complainHandler = () => {
        let reqeustBody = {
            "status": "waiting",
            "counsel_type": "one_on_one",
            "content": content,
            "reply": ''
          }

        const jsonRequestData = JSON.stringify(reqeustBody);
        const formData = new FormData();
        formData.append('req_body',jsonRequestData);
        if(myFile){
            formData.append('main_image',myFile);
        }

        axios.post('https://api.midtrc.com/counsel',formData,{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{ handler()}).catch((error)=>{console.log(error)})
    }

    const titleHandler = (e) => {
        setTitle(e.target.value)
    }

    const contentHandler = (e) => {
        setContent(e.target.value)
    }

    const fileHandler = (e) => {
        let file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setFileName(file.name);
              setMyFile(file)
            };
            reader.readAsDataURL(file);
            
          }
          
        fileRef.current.value = null;
    }


    return(
        <ComplainBorder onClick={()=>{handler()}}>
            <ComplainContent onClick={(e)=>{e.stopPropagation();}}>
                <input type="file" onChange={fileHandler} ref={fileRef} style={{display: "none"}}/>
                <ComplainTitle>
                    1:1 문의하기
                </ComplainTitle>
                <ComplainLine />
                {/* <CompainInput value={title} onChange={titleHandler} placeholder="제목을 입력하세요"/> */}
                <ComplainText value={content} onChange={contentHandler} placeholder="내용을 입력하세요"/>
                <ComplainTitle style={{marginTop: "2rem"}}>
                    첨부파일
                </ComplainTitle>
                <SubmitInputBorder onClick={(e)=>{e.stopPropagation(); }} style={{marginTop: "2rem"}}>
                    <SubmitInput onClick={(e)=>{e.stopPropagation(); fileRef.current.click()}} placeholder="첨부파일 업로드" readOnly onChange={()=>{}} value={fileName}/>
                    <SubmitImage alt="icon" src={addFile}/>
                </SubmitInputBorder>
                <div style={{display: "flex", justifyContent: "right", gap: "1.6rem", width: "100%", marginTop: "3.2rem"}}>
                    <CancelButton onClick={(e)=>{e.stopPropagation(); handler()}}>
                        취소하기
                    </CancelButton>
                    <SubmitButton onClick={()=>{complainHandler()}}>
                        작성하기
                    </SubmitButton>
                </div>
            </ComplainContent>
        </ComplainBorder>
    )
}


export default Question;