import { createSlice } from "@reduxjs/toolkit";


const loginSlice = createSlice({
    name: 'midnightLoginManager',
    initialState: {
        is_login: false,
        id: null, 
        pw: null,
        log_data: null // 로그인 데이터
    },
    reducers : {
        Logined(state, info){
            state.log_data = info.payload
        },
        Logout(state, info){
            state.log_data = null
            state.id =  null
            state.pw = null
            state.is_login = false
        },
        IsLogined(state, info){
            state.id = info.payload.id
            state.pw = info.payload.pwd
            state.is_login = info.payload.is_login
        },ViewLogined(state, info){
            state.log_data = {client_type : 'view', gender: info.payload.data.userGender, name: info.payload.data.userName, phone: info.payload.data.userPhone}
        }
    }
})


export let { Logined, Logout, IsLogined, ViewLogined } = loginSlice.actions;

export default loginSlice.reducer;