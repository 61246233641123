/*eslint-disable*/
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import status01 from "./../../@images/status-easy.svg";
import status02 from "./../../@images/status-normal.svg";
import status03 from "./../../@images/status-busy.svg";
import new01 from "./../../@images/new-21x20.svg";
import pin from "./../../@images/pin-24.svg";
import pinActive from "./../../@images/pin-active.svg";
import bookMark from "./../../@images/bookmark-24.svg";
import bookMarkActive from "./../../@images/bookmark-active.svg";
import linkWhite from "./../../@images/link-white-24.svg";
import bookMarkwhite from "./../../@images/bookmark-white-24.svg";
import axios from "axios";
import { useSelector } from "react-redux";

// 테스트용 이미지 임포트
import ProfileTest from "./../../@images/profile-test.png";

import profileIcon1 from "./../../@images/profile_icon1.svg";
import profileIcon2 from "./../../@images/profile_icon2.svg";
import profileIcon3 from "./../../@images/profile_icon3.svg";
import profileIcon4 from "./../../@images/profile_icon4.svg";
import profileIcon5 from "./../../@images/profile_icon5.svg";

let MessageBoxRoomBorder = styled.div`
    width: 100%;
    height: 10.4rem;
    padding: 0.8rem 1.6rem 0.8rem 0.8rem;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    cursor: pointer;
`

let ClickedMessageBoxRoomBorder = styled.div`
    width: 100%;
    height: 10.4rem;
    padding: 0.8rem 1.6rem 0.8rem 0.8rem;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    cursor: pointer;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 0.4rem;
    border-color: #d2d2d2;
    background-color: #f2f2f2;
`

let MessageBoxProfileBorder = styled.div`
    padding: 0.8rem;
    width: 8.8rem;
    height: 8.8rem;
    box-sizing: border-box;
`

let MessageBoxProfileImage = styled.img`
    min-width: 7.2rem;
    min-height: 7.2rem;
    max-width: 7.2rem;
    max-height: 7.2rem;
    background-color: black;
    border-radius: 1.6rem;
    overflow: hidden;
    object-fit: cover;
`

let MessageBoxInfoBorder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.2rem;
    width: 100%;
    margin-top: 1.2rem;
`

let MessageBoxInfoFirst = styled.div`
    display: flex;
    justify-content: space-between;
    align-itmes: flex-start;
    width: 100%;
`

let MessageBoxInfoFirstLeft = styled.div`
    display: flex;
    height: 2.4rem;
    align-items: center;
`

let MessageBoxInfoFirstTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 500;
    color: #171717;
`

let MessageBoxInfoFirstNew = styled.img`
    width: 2.1rem;
    height:2.0rem;
    margin-left: 0.8rem;
`

let MessageBoxInfoFirstRight = styled.div`
    display: flex;
    height: 2.4rem;
    align-items: center;
`

let MessageBoxInfoFirstRightIconBorder = styled.div`
    display: flex;
    justify-content: right; 
    gap: 0.2rem;
`

let MessageBoxInfoFirstRightIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
`

let MessageBoxInfoSecondBorder = styled.div`
    box-sizing: border-box;
    padding: 0;
    height: 2.4rem;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 400;
    color: #444444;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    line-height: 1.5;
`

let MessageBoxInfoThirdBorder = styled.div`
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    position: relative;
`

let MessageBoxInfoThirdText = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 400;
    color: #6A6A6A;
    position: absolute;
    right: 0;
`

const MessageBoxRoom = ({ isSelected, data, isChange, onClick }) => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [Status, setStatus] = useState(status01);
    let [isFavorite, setIsFavorite] = useState(false);
    let [isPin, setIsPin] = useState(false);

    useEffect(()=>{
        setIsFavorite(data?.is_favorite);
        setIsPin(data?.pin_yn);
    },[data])

    // 새 메시지 텍스트 턴케이트
    const truncateHandler = () => {
        let instCount = 35;
        let instText = data?.newest_message?.content;
        if (instText.length > instCount) {
            return instText.slice(0, instCount) + '...';
          }
        return instText
    }

    // 고정하기 상태 변경
    const pinHandler = () => {
        let requestBost = {
            "pin_yn": !isPin,
            "is_favorite": isFavorite
        }
        axios.put(`https://api.midtrc.com/message_group/${data?.id}`, requestBost, {
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{isChange()}).catch((error)=>{console.log(error)})
    }

    // 즐겨찾기 상태 변경
    const favoriteHandler = () => {
        let requestBost = {
            "pin_yn": isPin,
            "is_favorite": !isFavorite
        }
        axios.put(`https://api.midtrc.com/message_group/${data?.id}`, requestBost, {
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{isChange()}).catch((error)=>{console.log(error)})
    }

    const formatTime = (postTime) => {
        const now = new Date();
        const postDate = new Date(postTime);
    
        const diffInSeconds = Math.floor((now - postDate) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
    
        if (diffInHours < 1) {
            return `${diffInMinutes}분`;
        } else if (diffInHours < 3) {
            return `${diffInHours}시간`;
        } else if (diffInDays < 1) {
            return '오늘';
        } else if (diffInDays < 2) {
            return '어제';
        } else {
            const year = postDate.getFullYear().toString().slice(2); // Get last two digits of the year
            const month = (postDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
            const day = postDate.getDate().toString().padStart(2, '0');
            return `${year}.${month}.${day}`;
        }
    }

    const TimeDisplay = ({ postTime }) => {
        const formattedTime = formatTime(postTime);
        return <div>{formattedTime}</div>;
    };

    const ProfileImageHandler = () => {
        let instProfile = data?.contact_profile_url;
        if(instProfile === '01'){
            return profileIcon1;
        }else if(instProfile === '02'){
            return profileIcon2;
        }else if(instProfile === '03'){
            return profileIcon3;
        }else if(instProfile === '04'){
            return profileIcon4;
        }else if(instProfile === '05'){
            return profileIcon5;
        }else{
            return instProfile
        }
    }

    return (
        <div onClick={onClick}>
            {isSelected ? (
                <ClickedMessageBoxRoomBorder>
                    <MessageBoxProfileBorder>
                        <MessageBoxProfileImage src={ProfileImageHandler()} alt="profile"/>
                    </MessageBoxProfileBorder>
                    <MessageBoxInfoBorder>
                        {/* 첫째 줄 */}
                        <MessageBoxInfoFirst>
                            <MessageBoxInfoFirstLeft>
                                <MessageBoxInfoFirstTitle>
                                    {data?.contact_nickname}
                                </MessageBoxInfoFirstTitle>
                                {
                                    data?.unread_count === 0?
                                    null
                                    :
                                    <MessageBoxInfoFirstNew src={new01} alt="new"/>
                                }
                            </MessageBoxInfoFirstLeft>
                            <MessageBoxInfoFirstRight>
                                <MessageBoxInfoFirstRightIconBorder>
                                    <MessageBoxInfoFirstRightIcon onClick={(e)=>{e.stopPropagation(); pinHandler()}} src={data?.pin_yn? pinActive:pin} alt="pin-icon"/>
                                    <MessageBoxInfoFirstRightIcon onClick={(e)=>{e.stopPropagation(); favoriteHandler()}} src={data?.is_favorite? bookMarkActive:bookMark} alt="bookmark-icon"/>
                                </MessageBoxInfoFirstRightIconBorder>
                            </MessageBoxInfoFirstRight>
                        </MessageBoxInfoFirst>
                        {/* 둘째 줄 */}
                        <MessageBoxInfoSecondBorder>
                            {truncateHandler()}
                        </MessageBoxInfoSecondBorder>
                        {/* 셋째 줄 */}
                        <MessageBoxInfoThirdBorder>
                            <MessageBoxInfoThirdText>
                                <TimeDisplay postTime={data?.newest_message.created_at}/>
                            </MessageBoxInfoThirdText>
                        </MessageBoxInfoThirdBorder>
                    </MessageBoxInfoBorder>
                </ClickedMessageBoxRoomBorder>
            ) : (
                <MessageBoxRoomBorder>
                    <MessageBoxProfileBorder>
                        <MessageBoxProfileImage src={ProfileImageHandler()} alt="profile"/>
                    </MessageBoxProfileBorder>
                    <MessageBoxInfoBorder>
                        {/* 첫째 줄 */}
                        <MessageBoxInfoFirst>
                            <MessageBoxInfoFirstLeft>
                                <MessageBoxInfoFirstTitle>
                                    {data?.contact_nickname}
                                </MessageBoxInfoFirstTitle>
                                {
                                    data?.unread_count === 0?
                                    null
                                    :
                                    <MessageBoxInfoFirstNew src={new01} alt="new"/>
                                }
                            </MessageBoxInfoFirstLeft>
                            <MessageBoxInfoFirstRight>
                                <MessageBoxInfoFirstRightIconBorder>
                                    <MessageBoxInfoFirstRightIcon onClick={(e)=>{e.stopPropagation(); pinHandler()}} src={data?.pin_yn? pinActive:pin} alt="pin-icon"/>
                                    <MessageBoxInfoFirstRightIcon onClick={(e)=>{e.stopPropagation(); favoriteHandler()}} src={data?.is_favorite? bookMarkActive:bookMark} alt="bookmark-icon"/>
                                </MessageBoxInfoFirstRightIconBorder>
                            </MessageBoxInfoFirstRight>
                        </MessageBoxInfoFirst>
                        {/* 둘째 줄 */}
                        <MessageBoxInfoSecondBorder>
                            {truncateHandler()}
                        </MessageBoxInfoSecondBorder>
                        {/* 셋째 줄 */}
                        <MessageBoxInfoThirdBorder>
                            <MessageBoxInfoThirdText>
                                <TimeDisplay postTime={data?.newest_message.created_at}/>
                            </MessageBoxInfoThirdText>
                        </MessageBoxInfoThirdBorder>
                    </MessageBoxInfoBorder>
                </MessageBoxRoomBorder>
            )}
        </div>
    )
}

export default MessageBoxRoom;
