import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import JobEditor from "../7_job_mob/job-editor-mob";
import JobEditorReader from "../7_job_mob/job-editor-reader-mob";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import arrow from "./../../@images/arrow-line-24.svg";
import AddPhoto from "./../../@images/add-photo.svg";
import checked from "./../../@images/check-circle-24.svg";
import unChecked from "./../../@images/check-circle-active-24.svg";
import korea from "../../@public-components/kroea";
import businessCategory from "../../@public-components/business-category";
import profileIcon1 from "./../../@images/profile_icon1.svg";
import profileIcon2 from "./../../@images/profile_icon2.svg";
import profileIcon3 from "./../../@images/profile_icon3.svg";
import profileIcon4 from "./../../@images/profile_icon4.svg";
import profileIcon5 from "./../../@images/profile_icon5.svg";

let ColorBorder = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
    padding-top: 20rem;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`;

let AddDetailBorder = styled.div`
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 10rem;
    width: 100%;
    box-sizing: border-box;
`;

let AddDetailLeftBorder = styled.div`
    margin-top: -5rem;
    flex-direction: column;
    display: flex;
    gap: 0.8rem;
    width: 100%;
    box-sizing: border-box;
`;

let AddDetailTitleBorder = styled.div`
    height: 2.4rem;
    display: flex;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2.4rem;
    margin-bottom: 1.6rem;
`;

let AddDetailSubmitBorder = styled.div`
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    padding: 3.2rem;
    text-align: left;
    border-color: #D2D2D2;
    border-radius: 0.4rem;
    border-width: 0.1rem;
    border-style: solid;
`;

let Title = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
`;

let BannerInput = styled.div`
    width: 100%;
    height: 12rem;
    border-radius: 0.4rem;
    border-width: 0.1rem;
    border-color: #d2d2d2;
    border-style: solid;
    position: relative;
    overflow: hidden;
    cursor: pointer;
`;

let BannerImage = styled.img`
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    background-color: white;
    cursor: pointer;
`;

let AddImage = styled.img`
    width: 4.8rem;
    height: 4.8rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
`;

let SubmitInput = styled.input`
    width: 100%;
    height: 5.6rem;
    border-style: solid;
    border-color: #D2D2D2;
    border-width: 0.1rem;
    border-radius: 0.4rem;
    outline: none;
    box-sizing: border-box;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    padding: 1.6rem;
    &::placeholder {
        color: #d2d2d2;
    }
`;

let PhoneAlarmText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6A6A6A;
    margin-bottom: 0.8rem;
`;

let PhoneButton = styled.button`
    width: 30.5rem;
    height: 5.6rem;
    border-radius: 0.4rem;
    border: none;
    background-color: #222222;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: white;
`;

let PhoneInput = styled.input`
    width: 100%;
    height: 5.6rem;
    border-style: solid;
    border-color: #D2D2D2;
    border-width: 0.1rem;
    outline: none;
    box-sizing: border-box;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    padding: 1.6rem;
    background-color: #F7F7F7;
    border-radius: 0.4rem;
    &::placeholder {
        color: #d2d2d2;
    }
`;

let AddFilterDropBorder = styled.div`
    display: flex;
    gap: 0.8rem;
`;

let AddFilterDrop = styled.div`
    position:relative;
    cursor: pointer;
    width: 16rem;
    height: 5.6rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #D2D2D2;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    display: flex;
    justify-content: space-between;;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
`;

let FilterIconArrow = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
`;

let TitleBorder = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

let TextButton = styled.button`
    padding: 0.4rem 0.8rem;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.2rem;
    color: #6A6A6A;
    border: none;
    background-color: #E8E8E8;
    border-radius: 0.4rem;
    cursor: pointer;
`;

let CostBorder = styled.div`
    display: flex;
    align-items: center;
    gap: 0.8rem;
    justify-content: space-between;
    width: 100%;
    position: relative;
`;

let CostIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
`;

let UnitText = styled.div`
    position: absolute;
    z-index: 10;
    right: 1.6rem;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #9E9E9E;
`;

let TextEditorBorder = styled.div`
    width: 100%;
    height: 28rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #D2d2d2;
    border-width: 0.1rem;
    background-color: #f5f5f5;
    margin-bottom: 3.2rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
`;

let AddDetailRightBorder = styled.div`
    margin-top: -5rem;
    width: 100%;
    box-sizing: border-box;
    padding-top: 4.8rem;
    position: relative;
`;

let ContrllerStickyBorder = styled.div`
    position: sticky;
    top: 20rem;
`;

let AddControllerBorder = styled.div`
    border-color: #D2D2D2;
    border-radius: 0.4rem;
    border-width: 0.1rem;
    border-style: solid;
    width: 100%;
    background-color: white;
    overflow: hidden;
    margin-bottom: 1.6rem;
`;

let AddControllerProfileBorder = styled.div`
    background-color: #f7f7f7;
    box-sizing: border-box;
    padding: 2.4rem;
    display: flex;
    width: 100%;
    height: 10.4rem;
    align-items: center;
    gap: 1.2rem;
`;

let ProfileImage = styled.img`
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 0.8rem;
    background-color: black;
`;

let ProfileInfoCompany = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #171717;
`;

let ProfileText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2rem;
    color: #171717;
`;

let ControllerBorder = styled.div`
    box-sizing: border-box;
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
`;

let ControllerInfoBorder = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

let ControllerText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.6rem;
    color: #6A6A6A;
    height: 100%;
    width: 100%;
    display: flex;
    jusify-content: left;
    align-items: center;
`;

let ControllerButton = styled.button`
    margin-top: 3rem;
    width: 100%;
    height: 5.6rem;
    border-radius: 0.4rem;
    border: none;
    background-color: #CD1B1B;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    color: white;
    cursor: pointer;
`;

let PayTableBorder = styled.div`
    position: absolute;
    min-width: 35rem;
    height: 38rem;
    right: 0px;
    top: 2.8rem;
    z-index: 30;
    background-color: white;
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #E8E8E8;
    border-width: 0.1rem;
    overflow: auto;
    text-align: left;
    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background: #CBCED5;
        border-radius: 6px;
    }
`;

let PayTableImg = styled.img`
    min-width: 100%;
    min-height: 45rem;
    object-fit: cover;
`;

let DropBorder = styled.div`
    position: absolute;
    min-width: 16rem;
    height: auto;
    max-height: 30rem;
    left: 0px;
    top: 5.8rem;
    z-index: 30;
    background-color: white;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #E8E8E8;
    border-width: 0.1rem;
    overflow: auto;
    text-align: left;
    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background: #CBCED5;
        border-radius: 6px;
    }
`;

let DropDownItems = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: grey;
    box-sizing: border-box;
    padding: 0 2rem;
    height: 3.4rem;
    &:hover {
        color: black;
    }
`;

const JobEdit = () => {
    let mainRef = useRef(null);
    let addRef = useRef(null);
    let navigate = useNavigate();
    let midMainager = useSelector((state) => state.midnightLoginManager);

    let [main_image, setmain_image] = useState(null);
    let [instMainImage, setInstMainImage] = useState();
    let [sub_image, setsub_image] = useState(null);
    let [instSubImage, setInstSubImage] = useState();

    let [workerName, setWorkerName] = useState('');
    let [contact_num, setcontact_num] = useState('');
    let [safe_num, setsafe_num] = useState('');

    let [addressFirst, setaddressFirst] = useState('시/도 선택');
    let [isaddressFirst, setisaddressFirst] = useState(false);

    let [addressSecond, setaddressSecond] = useState('구 선택');
    let [addressSecondArray, setaddressSecondArray] = useState([]);
    let [isaddressSecond, setisaddressSecond] = useState(false);

    let [addressThird, setaddressThird] = useState('');

    let [addressDetail, setaddressDetail] = useState('');

    let [address, setaddress] = useState('');
    let [industry, setindustry] = useState('업종 선택');
    let [isindustry, setisindustry] = useState(false);

    let [recruit_type, setrecruit_type] = useState('');
    let [isrecruit_type, setisrecruit_type] = useState(false);

    let [payTable, setPayTable] = useState();
    let [isPayTable, setIsPayTable] = useState(false);

    let [tc_pay, settc_pay] = useState(0);
    let [tc_format, settc_format] = useState(0);
    let [is_tc_pay, setis_tc_pay] = useState(false);

    let [hourly_pay, sethourly_pay] = useState(0);
    let [hourly_format, sethourly_format] = useState(0);
    let [is_hourly_pay, setis_hourly_pay] = useState(false);

    let [daily_pay, setdaily_pay] = useState(0);
    let [daily_format, setdaily_format] = useState(0);
    let [is_daily_pay, setis_daily_pay] = useState(false);

    let [weekly_pay, setweekly_pay] = useState(0);
    let [weekly_format, setweekly_format] = useState(0);
    let [is_weekly_pay, setis_weekly_pay] = useState(false);

    let [monthly_pay, setmonthly_pay] = useState(0);
    let [monthly_format, setmonthly_format] = useState(0);
    let [is_monthly_pay, setis_monthly_pay] = useState(false);

    let [per_pay, setper_pay] = useState(0);
    let [per_format, setper_format] = useState(0);
    let [is_per_pay, setis_per_pay] = useState(false);

    let [recruit_title, setrecruit_title] = useState('');
    let [data, setData] = useState('');
    let [introduce, setintroduce] = useState('');

    let [kakao_id, setkakao_id] = useState('');
    let [telegram_id, settelegram_id] = useState('');
    let [instagram_id, setinstagram_id] = useState('');
    let [client_type, setclient_type] = useState('');
    let [nick, setNick] = useState('');

    let params = useParams();
    let [readOnly, setReadOlny] = useState(true);

    useEffect(() => {
        let fetchData = async () => {
            try {
                const response = await axios.get(`https://api.midtrc.com/recruit/${params.id}`, {
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                });

                let instData = response.data;

                setInstMainImage(instData.main_image_url);
                setInstSubImage(instData.sub_image_url);
                setWorkerName(instData.name);
                setcontact_num(instData.contact_num);
                setsafe_num(instData.safe_num);

                const parts = instData.address.split(' ');
                if (parts.length > 1) {
                    setaddressThird(parts[0] + ' ' + parts[1]);
                    setaddressDetail(parts.slice(2).join(' '));
                } else {
                    setaddressThird(instData.address);
                    setaddressDetail('');
                }

                setindustry(instData.industry[0]);
                setrecruit_type(instData.recruit_type === 'short_term' ? "단기 알바" : "장기 알바");

                setis_tc_pay(instData.tc_pay ? true : false);
                setis_hourly_pay(instData.hourly_pay ? true : false);
                setis_daily_pay(instData.daily_pay ? true : false);
                setis_weekly_pay(instData.weekly_pay ? true : false);
                setis_monthly_pay(instData.monthly_pay ? true : false);
                setis_per_pay(instData.per_pay ? true : false);

                const formattedPay1 = new Intl.NumberFormat().format(instData.tc_pay ? instData.tc_pay : 0);
                const formattedPay2 = new Intl.NumberFormat().format(instData.hourly_pay ? instData.hourly_pay : 0);
                const formattedPay3 = new Intl.NumberFormat().format(instData.daily_pay ? instData.daily_pay : 0);
                const formattedPay4 = new Intl.NumberFormat().format(instData.weekly_pay ? instData.weekly_pay : 0);
                const formattedPay5 = new Intl.NumberFormat().format(instData.monthly_pay ? instData.monthly_pay : 0);
                const formattedPay6 = new Intl.NumberFormat().format(instData.per_pay ? instData.per_pay : 0);

                settc_format(formattedPay1);
                sethourly_format(formattedPay2);
                setdaily_format(formattedPay3);
                setweekly_format(formattedPay4);
                setmonthly_format(formattedPay5);
                setper_format(formattedPay6);

                settc_pay(instData.tc_pay ? instData.tc_pay : 0);
                sethourly_pay(instData.hourly_pay ? instData.hourly_pay : 0);
                setdaily_pay(instData.daily_pay ? instData.daily_pay : 0);
                setweekly_pay(instData.weekly_pay ? instData.weekly_pay : 0);
                setmonthly_pay(instData.monthly_pay ? instData.monthly_pay : 0);
                setper_pay(instData.per_pay ? instData.per_pay : 0);

                setrecruit_title(instData.recruit_title);
                setData(instData.data[0].data);
                setintroduce(instData.introduce);

                setkakao_id(instData.kakao_id);
                settelegram_id(instData.telegram_id);
                setinstagram_id(instData.instagram_id);

            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const ProfileImageHandler = () => {
        let instProfile = midMainager?.log_data?.profile_url;
        if (instProfile === '01') {
            return profileIcon1;
        } else if (instProfile === '02') {
            return profileIcon2;
        } else if (instProfile === '03') {
            return profileIcon3;
        } else if (instProfile === '04') {
            return profileIcon4;
        } else if (instProfile === '05') {
            return profileIcon5;
        } else {
            return instProfile;
        }
    };

    const mainImageHandler = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setInstMainImage(reader.result);
            };
            reader.readAsDataURL(file);
            setmain_image(file);
        }
    };

    const subImageHandler = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setInstSubImage(reader.result);
            };
            reader.readAsDataURL(file);
            setsub_image(file);
        }
    };

    const workerNameHandler = (e) => {
        setWorkerName(e.target.value);
    };

    const ContactNumHandler = (e) => {
        const input = e.target.value;
        const onlyNums = input.replace(/[^0-9]/g, '');
        setcontact_num(onlyNums);
    };

    const safeNumHandler = (e) => {
        const input = e.target.value;
        const onlyNums = input.replace(/[^0-9]/g, '');
        setsafe_num(onlyNums);
    };

    const addressFirstHandler = (e) => {
        setaddressFirst(e);
        setisaddressFirst(false);
        setaddressSecond('구 선택');
        addressThirdHandler(e);
        setaddressDetail('');
        citisHandler(e);
    };

    const citisHandler = (e) => {
        let instprovince = korea.filter((obj) => obj.province === e);
        setaddressSecondArray(instprovince[0]?.cities[0].districts);
    };

    const addressSecondHandler = (e) => {
        setaddressSecond(e);
        setisaddressSecond(false);
        addressThirdHandler(addressFirst + ' ' + e);
    };

    const addressThirdHandler = (e) => {
        setaddressThird(e);
    };

    const addressDetailHandler = (e) => {
        setaddressDetail(e.target.value);
    };

    const industryHandler = (e) => {
        setindustry(e);
        setisindustry(false);
    };

    const recruitTypeHandler = (e) => {
        setrecruit_type(e);
        setisrecruit_type(false);
    };

    const tc_payHandler = (e) => {
        const input = e.target.value;
        const onlyNums = input.replace(/[^0-9]/g, '');
        settc_pay(onlyNums);

        const formattedPay = new Intl.NumberFormat().format(onlyNums);
        settc_format(formattedPay);
    };

    const hourly_payHandler = (e) => {
        const input = e.target.value;
        const onlyNums = input.replace(/[^0-9]/g, '');
        sethourly_pay(onlyNums);

        const formattedPay = new Intl.NumberFormat().format(onlyNums);
        sethourly_format(formattedPay);
    };

    const daily_payHandler = (e) => {
        const input = e.target.value;
        const onlyNums = input.replace(/[^0-9]/g, '');
        setdaily_pay(onlyNums);

        const formattedPay = new Intl.NumberFormat().format(onlyNums);
        setdaily_format(formattedPay);
    };

    const weekly_payHandler = (e) => {
        const input = e.target.value;
        const onlyNums = input.replace(/[^0-9]/g, '');
        setweekly_pay(onlyNums);

        const formattedPay = new Intl.NumberFormat().format(onlyNums);
        setweekly_format(formattedPay);
    };

    const monthly_payHandler = (e) => {
        const input = e.target.value;
        const onlyNums = input.replace(/[^0-9]/g, '');
        setmonthly_pay(onlyNums);

        const formattedPay = new Intl.NumberFormat().format(onlyNums);
        setmonthly_format(formattedPay);
    };

    const per_payHandler = (e) => {
        const input = e.target.value;
        const onlyNums = input.replace(/[^0-9]/g, '');
        setper_pay(onlyNums);

        const formattedPay = new Intl.NumberFormat().format(onlyNums);
        setper_format(formattedPay);
    };

    const recruit_titleHandler = (e) => {
        setrecruit_title(e.target.value);
    };

    const dataHandler = (e) => {
        setData(e);
    };

    const introduceHandler = (e) => {
        setintroduce(e.target.value);
    };

    const kakao_idHandler = (e) => {
        setkakao_id(e.target.value);
    };

    const telegram_idHandler = (e) => {
        settelegram_id(e.target.value);
    };

    const instagram_idHandler = (e) => {
        setinstagram_id(e.target.value);
    };

    let payTableRef = useRef();
    const payTableHandler = (event) => {
        if (payTableRef.current && !payTableRef.current.contains(event.target)) {
            setIsPayTable(false);
        } else {
            setIsPayTable(true);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', payTableHandler);
        return () => {
            document.removeEventListener('mousedown', payTableHandler);
        };
    }, []);

    let dropRef01 = useRef();
    const dropRef01Handler = (event) => {
        if (dropRef01.current && !dropRef01.current.contains(event.target)) {
            setisaddressFirst(false);
        } else {
            setisaddressFirst(true);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', dropRef01Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef01Handler);
        };
    }, []);

    let dropRef02 = useRef();
    const dropRef02Handler = (event) => {
        if (dropRef02.current && !dropRef02.current.contains(event.target)) {
            setisaddressSecond(false);
        } else {
            setisaddressSecond(true);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', dropRef02Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef02Handler);
        };
    }, []);

    let dropRef03 = useRef();
    const dropRef03Handler = (event) => {
        if (dropRef03.current && !dropRef03.current.contains(event.target)) {
            setisindustry(false);
        } else {
            setisindustry(true);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', dropRef03Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef03Handler);
        };
    }, []);

    let dropRef04 = useRef();
    const dropRef04Handler = (event) => {
        if (dropRef04.current && !dropRef04.current.contains(event.target)) {
            setisrecruit_type(false);
        } else {
            setisrecruit_type(true);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', dropRef04Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef04Handler);
        };
    }, []);

    const recriutSubmitHandler = () => {
        const requestBody = {
            "client_id": midMainager?.log_data?.id,
            "status": "active",
            "kakao_id": kakao_id,
            "telegram_id": telegram_id,
            "instagram_id": instagram_id,
            "popular_yn": false,
            "midnight_yn": false,
            "recruit_type": recruit_type === '단기 알바' ? "short_term" : "always",
            "name": workerName,
            "contact_num": contact_num,
            "safe_num": safe_num,
            "address": addressFirst + " " + addressSecond + " " + addressDetail,
            "industry": [
                industry
            ],
            "tc_pay": is_tc_pay ? tc_pay : 0,
            "hourly_pay": is_hourly_pay ? hourly_pay : 0,
            "daily_pay": is_daily_pay ? daily_pay : 0,
            "weekly_pay": is_weekly_pay ? weekly_pay : 0,
            "monthly_pay": is_monthly_pay ? monthly_pay : 0,
            "per_pay": is_per_pay ? per_pay : 0,
            "recruit_title": recruit_title,
            "data": [
                { data }
            ],
            "introduce": introduce
        };

        const jsonRequestData = JSON.stringify(requestBody);
        const formData = new FormData();
        formData.append('req_body', jsonRequestData);
        if (main_image) {
            formData.append('main_image', main_image);
        }
        if (sub_image) {
            formData.append('sub_image', sub_image);
        }

        axios.put(`https://api.midtrc.com/recruit/${params.id}`, formData, {
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res) => { setReadOlny(true) }).catch((error) => { console.log(error) });
    };

    return (
        <ColorBorder>
            <input ref={mainRef} onChange={mainImageHandler} type="file" style={{ display: "none" }} />
            <input ref={addRef} onChange={subImageHandler} type="file" style={{ display: "none" }} />
            <AddDetailBorder>
                <AddDetailLeftBorder>
                    <AddDetailTitleBorder>
                        나의 공고
                    </AddDetailTitleBorder>
                    <AddDetailRightBorder>
                        <ContrllerStickyBorder>
                            <AddControllerBorder>
                                <AddControllerProfileBorder>
                                    <ProfileImage src={ProfileImageHandler()} alt="profile" />
                                    <div>
                                        <ProfileInfoCompany>
                                            사업자
                                        </ProfileInfoCompany>
                                        <ProfileText>
                                            {midMainager?.log_data?.nickname}
                                        </ProfileText>
                                    </div>
                                </AddControllerProfileBorder>
                                <ControllerBorder>
                                    <ControllerInfoBorder>
                                        <ControllerText>
                                            카톡 ID
                                        </ControllerText>
                                        <SubmitInput value={kakao_id} onChange={kakao_idHandler} style={{ height: "4rem", width: "100%" }} />
                                    </ControllerInfoBorder>
                                    <ControllerInfoBorder>
                                        <ControllerText>
                                            텔레그램 ID
                                        </ControllerText>
                                        <SubmitInput value={telegram_id} onChange={telegram_idHandler} style={{ height: "4rem", width: "100%" }} />
                                    </ControllerInfoBorder>
                                    <ControllerInfoBorder>
                                        <ControllerText>
                                            인스타그램 ID
                                        </ControllerText>
                                        <SubmitInput value={instagram_id} onChange={instagram_idHandler} style={{ height: "4rem", width: "100%" }} />
                                    </ControllerInfoBorder>
                                </ControllerBorder>
                            </AddControllerBorder>
                        </ContrllerStickyBorder>
                    </AddDetailRightBorder>
                    <AddDetailSubmitBorder>
                        <Title>
                            대표 이미지
                        </Title>
                        <BannerInput style={{ marginBottom: "3.2rem" }} onClick={() => { mainRef.current.click() }}>
                            {
                                instMainImage ?
                                    <AddImage style={{ width: "100%", height: "100%", zIndex: "10", objectFit: "cover" }} src={instMainImage} alt="banner01" />
                                    :
                                    <AddImage src={AddPhoto} alt="add-photo01" />
                            }
                        </BannerInput>
                        <Title>
                            추가 이미지
                        </Title>
                        <BannerInput onClick={() => { addRef.current.click() }}>
                            {
                                instSubImage ?
                                    <AddImage style={{ width: "100%", height: "100%", zIndex: "10", objectFit: "cover" }} src={instSubImage} alt="add-photo02" />
                                    :
                                    <AddImage src={AddPhoto} alt="banner02" />
                            }
                        </BannerInput>
                    </AddDetailSubmitBorder>
                    <AddDetailSubmitBorder>
                        <div style={{ marginBottom: "3.2rem" }}>
                            <div>
                                <Title>
                                    담당자 명
                                </Title>
                                <SubmitInput readOnly={readOnly} onChange={workerNameHandler} value={workerName} placeholder="담당자 명을 입력하세요" />
                            </div>
                            <div style={{ marginTop: "3rem" }}>
                                <Title>
                                    문의 전화번호
                                </Title>
                                <SubmitInput readOnly={readOnly} onChange={ContactNumHandler} value={contact_num} placeholder="문의 전화번호를 입력하세요" />
                            </div>
                        </div>
                        <Title>
                            안심번호
                        </Title>
                        <PhoneAlarmText>
                            안심번호를 같이 사용하려면 아래 버튼을 눌러 발급받아주세요.
                        </PhoneAlarmText>
                        <div style={{ display: "flex", gap: "0.8rem" }}>
                            {
                                readOnly ?
                                    null
                                    :
                                    <PhoneButton>
                                        안심번호 받기
                                    </PhoneButton>
                            }
                            <PhoneInput readOnly value={safe_num} onChange={() => { }} />
                        </div>
                    </AddDetailSubmitBorder>
                    <AddDetailSubmitBorder>
                        <Title>
                            근무지역
                        </Title>
                        <AddFilterDropBorder style={{ marginBottom: "0.8rem" }}>
                            <AddFilterDrop ref={dropRef01} onClick={dropRef01Handler}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span>{addressFirst}</span>
                                </div>
                                <FilterIconArrow src={arrow} style={{ transform: isaddressFirst && !readOnly ? 'rotate(180deg)' : 'rotate(0deg)' }} alt="arrow" />
                                {
                                    isaddressFirst && !readOnly ?
                                        <DropBorder>
                                            {
                                                korea.map((a, i) => (
                                                    <DropDownItems onClick={() => { addressFirstHandler(a.province); }} key={i}>
                                                        {a.province}
                                                    </DropDownItems>
                                                ))
                                            }

                                        </DropBorder>
                                        :
                                        <div style={{ display: "none" }} />
                                }
                            </AddFilterDrop>
                            <AddFilterDrop ref={dropRef02} onClick={dropRef02Handler}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span>{addressSecond}</span>
                                </div>
                                <FilterIconArrow src={arrow} style={{ transform: isaddressSecond && !readOnly ? 'rotate(180deg)' : 'rotate(0deg)' }} alt="arrow" />
                                {
                                    isaddressSecond && !readOnly ?
                                        <DropBorder>
                                            {
                                                addressSecondArray?.map((a, i) => (
                                                    <DropDownItems onClick={() => { addressSecondHandler(a); }} key={i}>
                                                        {a}
                                                    </DropDownItems>
                                                ))
                                            }
                                        </DropBorder>
                                        :
                                        <div style={{ display: "none" }} />
                                }
                            </AddFilterDrop>
                        </AddFilterDropBorder>
                        <div style={{ marginBottom: "3.2rem" }}>
                            <SubmitInput readOnly value={addressThird} onChange={() => { }} />
                            <SubmitInput readOnly={readOnly} value={addressDetail} onChange={addressDetailHandler} style={{ marginTop: "0.8rem" }} placeholder="세부 주소를 입력해주세요" />
                        </div>
                        <Title>
                            업종
                        </Title>
                        <AddFilterDropBorder>
                            <AddFilterDrop ref={dropRef03} onChange={dropRef03Handler}>
                                <div style={{ display: "flex", alignItems: "center", fontSize: "1.5rem", width: "6rem" }}>
                                    <span>업종 선택</span>
                                </div>
                                <FilterIconArrow src={arrow} style={{ transform: isindustry && !readOnly ? 'rotate(180deg)' : 'rotate(0deg)' }} alt="arrow" />
                                {
                                    isindustry && !readOnly ?
                                        <DropBorder>
                                            {
                                                businessCategory.map((a, i) => (
                                                    <DropDownItems onClick={() => { industryHandler(a) }} key={i}>
                                                        {a}
                                                    </DropDownItems>
                                                ))
                                            }
                                        </DropBorder>
                                        :
                                        <div style={{ display: "none" }} />
                                }
                            </AddFilterDrop>
                            <SubmitInput readOnly value={industry} onChange={() => { }} />
                        </AddFilterDropBorder>


                        <Title style={{marginTop: "3rem"}}>
                            공고 분류
                        </Title>
                        <AddFilterDropBorder>
                            <AddFilterDrop ref={dropRef04} onChange={dropRef04Handler}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <span>공고 분류 선택</span>
                                </div>
                                <FilterIconArrow src={arrow} style={{transform: isrecruit_type && !readOnly?'rotate(180deg)':'rotate(0deg)'}} alt="arrow"/>
                                {
                                    isrecruit_type && !readOnly?
                                    <DropBorder>
                                        <DropDownItems onClick={()=> {recruitTypeHandler('단기 알바')}}>
                                            단기 알바
                                        </DropDownItems>
                                        <DropDownItems onClick={()=> {recruitTypeHandler('장기 알바')}}>
                                            장기 알바
                                        </DropDownItems>
                                    </DropBorder>
                                    :
                                    <div style={{display: "none"}} />
                                }
                            </AddFilterDrop>
                            <SubmitInput readOnly value={recruit_type === 'always'? '장기 알바':'단기 알바'} onChange={()=>{}} style={{width: "45%"}}/>
                        </AddFilterDropBorder>

                    </AddDetailSubmitBorder>
                    <AddDetailSubmitBorder>
                        <TitleBorder>
                            <Title>
                                급여
                            </Title>
                            <TextButton ref={payTableRef} onClick={payTableHandler}>
                                <div>급여기준표</div>
                                {
                                    isPayTable ?
                                        <PayTableBorder>
                                            <Title style={{ fontSize: "1.6rem", fontWeight: "600" }}>
                                                급여기준표
                                            </Title>
                                            <PayTableImg src={payTable} alt="table" />
                                        </PayTableBorder>
                                        :
                                        <div />
                                }
                            </TextButton>
                        </TitleBorder>
                        {
                            readOnly ?
                                <CostBorder style={{ marginBottom: "0.8rem" }}>
                                    <CostIcon onClick={() => { }} src={is_tc_pay ? checked : unChecked} alt="active-option" />
                                    <SubmitInput readOnly value={'T.C'} onChange={() => { }} style={{ width: "13.2rem" }} />
                                    <SubmitInput readOnly={true} value={tc_format} onChange={tc_payHandler} style={{ width: "49rem" }} />
                                    <UnitText>
                                        원
                                    </UnitText>
                                </CostBorder>
                                :
                                <CostBorder style={{ marginBottom: "0.8rem" }}>
                                    <CostIcon onClick={() => { setis_tc_pay(!is_tc_pay) }} src={is_tc_pay ? checked : unChecked} alt="active-option" />
                                    <SubmitInput readOnly value={'T.C'} onChange={() => { }} style={{ width: "13.2rem" }} />
                                    <SubmitInput readOnly={!is_tc_pay} value={tc_format} onChange={tc_payHandler} style={{ width: "49rem" }} />
                                    <UnitText>
                                        원
                                    </UnitText>
                                </CostBorder>
                        }
                        {
                            readOnly ?
                                <CostBorder style={{ marginBottom: "0.8rem" }}>
                                    <CostIcon onClick={() => { }} src={is_hourly_pay ? checked : unChecked} alt="active-option" />
                                    <SubmitInput readOnly value={'시급'} onChange={() => { }} style={{ width: "13.2rem" }} />
                                    <SubmitInput readOnly={true} value={hourly_format} onChange={hourly_payHandler} style={{ width: "49rem" }} />
                                    <UnitText>
                                        원
                                    </UnitText>
                                </CostBorder>
                                :
                                <CostBorder style={{ marginBottom: "0.8rem" }}>
                                    <CostIcon onClick={() => { setis_hourly_pay(!is_hourly_pay) }} src={is_hourly_pay ? checked : unChecked} alt="active-option" />
                                    <SubmitInput readOnly value={'시급'} onChange={() => { }} style={{ width: "13.2rem" }} />
                                    <SubmitInput readOnly={!is_hourly_pay} value={hourly_format} onChange={hourly_payHandler} style={{ width: "49rem" }} />
                                    <UnitText>
                                        원
                                    </UnitText>
                                </CostBorder>
                        }
                        {
                            readOnly ?
                                <CostBorder style={{ marginBottom: "0.8rem" }}>
                                    <CostIcon onClick={() => { }} src={is_daily_pay ? checked : unChecked} alt="active-option" />
                                    <SubmitInput readOnly value={'일급'} onChange={() => { }} style={{ width: "13.2rem" }} />
                                    <SubmitInput readOnly={true} value={daily_format} onChange={daily_payHandler} style={{ width: "49rem" }} />
                                    <UnitText>
                                        원
                                    </UnitText>
                                </CostBorder>
                                :
                                <CostBorder style={{ marginBottom: "0.8rem" }}>
                                    <CostIcon onClick={() => { setis_daily_pay(!is_daily_pay); }} src={is_daily_pay ? checked : unChecked} alt="active-option" />
                                    <SubmitInput readOnly value={'일급'} onChange={() => { }} style={{ width: "13.2rem" }} />
                                    <SubmitInput readOnly={!is_daily_pay} value={daily_format} onChange={daily_payHandler} style={{ width: "49rem" }} />
                                    <UnitText>
                                        원
                                    </UnitText>
                                </CostBorder>
                        }
                        {
                            readOnly ?
                                <CostBorder style={{ marginBottom: "0.8rem" }}>
                                    <CostIcon onClick={() => { }} src={is_weekly_pay ? checked : unChecked} alt="active-option" />
                                    <SubmitInput readOnly value={'주급'} onChange={() => { }} style={{ width: "13.2rem" }} />
                                    <SubmitInput readOnly={true} value={weekly_format} onChange={weekly_payHandler} style={{ width: "49rem" }} />
                                    <UnitText>
                                        원
                                    </UnitText>
                                </CostBorder>
                                :
                                <CostBorder style={{ marginBottom: "0.8rem" }}>
                                    <CostIcon onClick={() => { setis_weekly_pay(!is_weekly_pay) }} src={is_weekly_pay ? checked : unChecked} alt="active-option" />
                                    <SubmitInput readOnly value={'주급'} onChange={() => { }} style={{ width: "13.2rem" }} />
                                    <SubmitInput readOnly={!is_weekly_pay} value={weekly_format} onChange={weekly_payHandler} style={{ width: "49rem" }} />
                                    <UnitText>
                                        원
                                    </UnitText>
                                </CostBorder>
                        }
                        {
                            readOnly ?
                                <CostBorder style={{ marginBottom: "0.8rem" }}>
                                    <CostIcon onClick={() => { }} src={is_monthly_pay ? checked : unChecked} alt="active-option" />
                                    <SubmitInput readOnly value={'월급'} onChange={() => { }} style={{ width: "13.2rem" }} />
                                    <SubmitInput readOnly={true} value={monthly_format} onChange={monthly_payHandler} style={{ width: "49rem" }} />
                                    <UnitText>
                                        원
                                    </UnitText>
                                </CostBorder>
                                :
                                <CostBorder style={{ marginBottom: "0.8rem" }}>
                                    <CostIcon onClick={() => { setis_monthly_pay(!is_monthly_pay) }} src={is_monthly_pay ? checked : unChecked} alt="active-option" />
                                    <SubmitInput readOnly value={'월급'} onChange={() => { }} style={{ width: "13.2rem" }} />
                                    <SubmitInput readOnly={!is_monthly_pay} value={monthly_format} onChange={monthly_payHandler} style={{ width: "49rem" }} />
                                    <UnitText>
                                        원
                                    </UnitText>
                                </CostBorder>
                        }
                        {
                            readOnly ?
                                <CostBorder >
                                    <CostIcon onClick={() => { }} src={is_per_pay ? checked : unChecked} alt="active-option" />
                                    <SubmitInput readOnly value={'건당'} onChange={() => { }} style={{ width: "13.2rem" }} />
                                    <SubmitInput readOnly={true} value={per_format} onChange={per_payHandler} style={{ width: "49rem" }} />
                                    <UnitText>
                                        원
                                    </UnitText>
                                </CostBorder>
                                :
                                <CostBorder >
                                    <CostIcon onClick={() => { setis_per_pay(!is_per_pay) }} src={is_per_pay ? checked : unChecked} alt="active-option" />
                                    <SubmitInput readOnly value={'건당'} onChange={() => { }} style={{ width: "13.2rem" }} />
                                    <SubmitInput readOnly={!is_per_pay} value={per_format} onChange={per_payHandler} style={{ width: "49rem" }} />
                                    <UnitText>
                                        원
                                    </UnitText>
                                </CostBorder>
                        }

                    </AddDetailSubmitBorder>
                    <AddDetailSubmitBorder>
                        <Title>
                            공고 제목
                        </Title>
                        <SubmitInput readOnly={readOnly} value={recruit_title} onChange={recruit_titleHandler} style={{ marginBottom: "3.2rem", width: "100%" }} placeholder="공고 제목을 입력해주세요" />
                        <Title>
                            상세페이지 작성
                        </Title>
                        {
                            readOnly ?
                                <JobEditorReader data={data} />
                                :
                                <JobEditor data={dataHandler} />
                        }
                        <div style={{ marginBottom: '3.2rem', width: '100%' }} />
                        <Title>
                            한 줄 소개
                        </Title>
                        <SubmitInput readOnly={readOnly} value={introduce} onChange={introduceHandler} style={{ width: "100%" }} placeholder="공고 제목을 입력해주세요" />
                    </AddDetailSubmitBorder>
                </AddDetailLeftBorder>
                <ControllerButton onClick={() => { recriutSubmitHandler() }}>
                    공고 등록하기
                </ControllerButton>

            </AddDetailBorder>
        </ColorBorder>
    );
};

export default JobEdit;
