import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MyComuPostSNSContent from "./mypage-mycomupost-sns-contents-mob";
import { useSelector } from "react-redux";
import axios from "axios";
import NormalContents from "../6_community_mob/normal-contents-mob";  
import SnsContents from "../6_community_mob/sns-contents-mob";

let MypageBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
`;

let MyfavoritecomupostpostBorder = styled.div`
    margin-top: 0.8rem;
    width: 100%;
    gap: 4rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    background-color: #F7F7F7;
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
`;

let MyfavoritecomupostpostSection = styled.div`
    width: 100%;
    padding-top: 11rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`;

let MypageResumeinfo = styled.div`
    padding-top: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 4rem;
    background-color: white;
    border-radius: 16px;
    border: 1px solid #D2D2D2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let MypageTitleBar = styled.div`
    color: #171717;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
`;

let MypageMyfavoritecomupostpostTitle = styled.div`
    color: #171717;
    font-family: 'Pretendard-Regular';
    font-size: 2rem;
    font-weight: 600;
`;

let MypageMyfavoritecomupostpostdivider = styled.div`
    width: 100%;
    border-bottom: 1px solid #E8E8E8;
    margin-top:2rem;
    margin-bottom:2rem;
`;

let MypageMyfavoritecomupostpostListContainer = styled.div`
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

let MypageCategory = styled.div`
    color: #a9a9a9;
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
`;

const Myfavoritecomupostpost = () => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [isSns, setIsSns] = useState('sns');
    let [data, setData] = useState([]);
    let [changeData, setChangeData] = useState(false);

    const isSnsHandler = () => {
        if(isSns === 'sns'){
            setIsSns('general')
        }else{
            setIsSns('sns')
        }
    }

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/lounge',{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instList = response.data;
                let filterData = instList.filter(obj=>obj.is_favorite === true && obj.lounge_type === isSns);
                setData(filterData);
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    },[isSns])

    const isChangeData = () => {
        setChangeData(!changeData);
    }

    const listHandler = () => {
        switch(isSns){
            case 'sns':
                return(
                    <MypageMyfavoritecomupostpostListContainer>
                        {
                            data?.map((a, i)=>(
                                <SnsContents key={i} data={a} isChange={isChangeData} />
                            ))
                        }
                    </MypageMyfavoritecomupostpostListContainer>
                )

            case 'general':
                return(
                    <MypageMyfavoritecomupostpostListContainer>
                        {
                            data?.map((a, i)=>(
                                <NormalContents data={a} key={i} isChange={isChangeData} />
                            ))
                        }
                    </MypageMyfavoritecomupostpostListContainer>
                )

            default: 
                return null
        }
    }

    return (
        <MypageBackground>
            <MyfavoritecomupostpostBorder>
                <MyfavoritecomupostpostSection>
                    <MypageResumeinfo>
                        <MypageTitleBar>
                            <MypageMyfavoritecomupostpostTitle>내가 찜한 게시글</MypageMyfavoritecomupostpostTitle>
                            <MypageCategory onClick={isSnsHandler}>
                                {isSns === 'sns' ? '자유 게시글 보기' : 'SNS 게시글 보기'}
                            </MypageCategory>
                        </MypageTitleBar>
                        <MypageMyfavoritecomupostpostdivider/>
                        {listHandler()}
                    </MypageResumeinfo>
                </MyfavoritecomupostpostSection>
            </MyfavoritecomupostpostBorder>
        </MypageBackground>
    );
};

export default Myfavoritecomupostpost;
