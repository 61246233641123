/*eslint-disable*/
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import questionspreadarrow from "./../../@images/arrow.svg";

let ContentsBorder = styled.div`
    width: 74.8rem; 
    height: 4rem;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

let NormalContentsList = styled.div`
    width: 74.8rem;
    height: auto;
    align-items: center;
    box-sizing: border-box;
`;

let ContentTitleBorder = styled.div`
    width: 74.8rem;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 6fr 2fr 1fr;
    column-gap: 1.6rem;
    box-sizing: border-box;
    font-size: 1.4rem;
`;

let NoticeNum = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #171717;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

let NoticeTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #171717;
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

let QuestionStatus = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #171717;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

let EtcText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #171717;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

let Questionarrow = styled.img`
    margin-top: -0.5rem;
    width: 2.4rem;
    height: 2.4rem;
    transition: transform 0.3s ease;
    transform: ${props => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

let ContentQNABorder = styled.div`
    width: 57rem;
    margin-left: 8.5rem;
    margin-right: 5rem;
    max-height: ${props => (props.expanded ? `${props.contentHeight}px` : '0')};
    overflow: hidden;
    box-sizing: border-box;
    font-size: 1.4rem;
    transition: max-height 0.3s ease;
`;

let ContentQuestion = styled.div`
    width: 57rem;
    height: 100%;
    box-sizing: border-box;
    font-size: 1.4rem;
    color: #6A6A6A;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: 'Pretendard-Regular';
`;

let ContentAnswer = styled.div`
    width: 57rem;
    height: 100%;
    background-color: #E8E8E8;
    padding-left: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    padding-right: 1rem;
    box-sizing: border-box;
    font-size: 1.4rem;
    color: #171717;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: 'Pretendard-Regular';
`;

let ContentImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;

`

const MypageMyquestionContents = ({ content }) => {
    const [expanded, setExpanded] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);
    // console.log(content)
    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, []);

    const turnCate = () => {
        let inst = content.content;
        let instCount = 20;
        if (inst.length > instCount) {
          return inst.slice(0, instCount) + '...';
        }
        return inst;
    }

    const myStatus = () => {
        if(content.status === 'waiting'){
            return '대기중'
        }else if(content.status === 'complete'){
            return '답변 완료'
        }
    }

    const formatTime = (postTime) => {
        const now = new Date();
        const postDate = new Date(postTime);
    
        const diffInSeconds = Math.floor((now - postDate) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
    
        if (diffInHours < 1) {
            return `${diffInMinutes}분`;
        } else if (diffInHours < 3) {
            return `${diffInHours}시간`;
        } else if (diffInDays < 1) {
            return '오늘';
        } else if (diffInDays < 2) {
            return '어제';
        } else {
            const year = postDate.getFullYear().toString().slice(2); // Get last two digits of the year
            const month = (postDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
            const day = postDate.getDate().toString().padStart(2, '0');
            return `${year}.${month}.${day}`;
        }
    }

    const TimeDisplay = ({ postTime }) => {
        const formattedTime = formatTime(postTime);
        return <div>{formattedTime}</div>;
    };

    return (
        <div>
            <ContentsBorder onClick={toggleExpand}>
                <NormalContentsList>
                    <ContentTitleBorder>
                        <NoticeNum>
                            {content.num}
                        </NoticeNum>
                        <NoticeTitle>
                            <div>{turnCate()}</div>
                            <Questionarrow src={questionspreadarrow} alt="question fold icon" expanded={expanded} />
                        </NoticeTitle>
                        <QuestionStatus>
                            {myStatus()}
                        </QuestionStatus>
                        <EtcText>
                            <TimeDisplay postTime={content.created_at}/>
                        </EtcText>
                    </ContentTitleBorder>
                </NormalContentsList>
            </ContentsBorder>

            <ContentQNABorder ref={contentRef} expanded={expanded} contentHeight={expanded?'auto':0}>
                <div>
                    <ContentQuestion>
                        {content.content}
                    </ContentQuestion>
                    {
                        content.main_file_url?
                        <ContentImage src={content.main_file_url} alt="content"/>
                        :
                        null
                    }
                    {
                        content.reply?
                        <ContentAnswer>
                            {content.reply}
                        </ContentAnswer>
                        :
                        null
                    }
                    
                </div>
            </ContentQNABorder>
        </div>
    );
};

export default MypageMyquestionContents;
