/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import status01 from "./../../@images/status-easy.svg";
import status02 from "./../../@images/status-normal.svg";
import status03 from "./../../@images/status-busy.svg";
import new01 from "./../../@images/new-21x20.svg";
import pin from  "./../../@images/pin-24.svg";
import pinActive from "./../../@images/pin-active.svg";
import bookMark from "./../../@images/bookmark-24.svg";
import bookMarkActive from "./../../@images/bookmark-active.svg";
import linkWhite from "./../../@images/link-white-24.svg";
import bookMarkwhite from "./../../@images/bookmark-white-24.svg";

// 테스트용 이미지 임포트
import ProfileTest from "./../../@images/profile-test.png";
import axios from "axios";
import { useSelector } from "react-redux";

let ChatRoomBorder = styled.div`
    width: 51rem;
    height: 12rem;
    padding: 1.6rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
`

let ChatProfileBorder = styled.div`
    padding: 0.8rem;
    width: 8.8rem;
    height: 8.8rem;
    box-sizing: border-box;
`

let ChatProfileImage = styled.img`
    min-width: 7.2rem;
    min-height: 7.2rem;
    max-width: 7.2rem;
    max-height: 7.2rem;
    background-color: #f1f1f1;
    border-radius: 1.6rem;
    overflow: hidden;
    object-fit: cover;
`

let ChatInfoBorder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.2rem;
    width: 100%;
`

let ChatInfoFirst = styled.div`
    display: flex;
    justify-content: space-between;
    align-itmes: center;
    width: 100%;
`

let ChatInfoFirstLeft = styled.div`
    display: flex;
    height: 2.4rem;
    align-items: center;
`

let ChatInfoFirstStatus = styled.img`
    width: 2.4rem;
    height: 2.4rem;
`

let ChatInfoFirstTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 1.8rem;
    font-weight: 600;
    color: #171717;
`

let ChatInfoFirstNew = styled.img`
    width: 2.1rem;
    height:2.0rem;
    margin-left: 0.8rem;
`

let ChatInfoFirstRight = styled.div`
    display: flex;
    height: 2.4rem;
    align-items: center;
`

let ChatInfoFirstRightIconBorder = styled.div`
    display: flex;
    justify-content: right: 
    gap: 0.2rem;
`

let ChatInfoFirstRightIcon = styled.img`
    width: 2.4rem;
    height: 2.4rme;
    cursor: pointer;
`

let ChatInfoSecondBorder = styled.div`
    box-sizing: border-box;
    padding: 0 0 0 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 400;
    color: #444444;
`

let ChatInfoThirdBorder = styled.div`
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 0 0 2.4rem;
    width: 100%;
`

let ChatInfoThirdText = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 400;
    color: #6A6A6A;
`

let ChatRoomclickedBorder = styled.div`
    width: 51rem;
    height: 24rem;
    box-sizing: border-box;
    border-style: solid;
    border-color: #D2D2D2;
    border-width: 0.1rem;
    border-radius: 1.6rem;
    background-color: #f7f7f7;
    over-flow: hidden;
`

let ChatRoomclickedImageBorder = styled.div`
    position: relative;
    width: 51rem;
    height: 12rem;
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    over-flow: hidden;
`

let ChatRoomClickedImage = styled.img`
    position: absolute;
    top: 0;
    left: 0%;
    transform: traslateX(-50%);
    object-fit: cover;
    min-width: 100%;
    min-height: 12rem;
    max-width: 100%;
    max-height: 12rem;
    background-color: white;
    border-radius: 1.6rem 1.6rem 0 0;
    z-index: 0;
`

let ChatRoomClickedCover = styled.div`
    position: absolute;
    top: 0;
    left: 0%;
    transform: traslateX(-50%);
    object-fit: cover;
    min-width: 100%;
    min-height: 12rem;
    max-width: 100%;
    max-height: 12rem;
    background-color: rgba(0,0,0,0.4);
    border-radius: 1.6rem 1.6rem 0 0;
    z-index: 10;
`

let ChatRoomClickedFirst = styled.div`
    display: flex;
    width: 100%;
    justify-content: right;
    align-items: center;
    z-index: 20;
    margin-bottom: 0.6rem;
`

let ChatRoomClickedIconBorder = styled.div`
    display: flex;
    gap: 0.2rem;
`

let ChatRoomClickedIcon = styled.img`
    width: 2.4rem;
    height: 2.4rme;
    object-fit: cover;
    cursor: pointer;
`

let ChatRoomClickedTitleBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: right;
    z-index: 10;
    gap: 0.6rem;
`

let ChatRoomClickedTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 1.8rem;
    font-weight: 600;
    color: white;
`

let ChatRoomClickedSubTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 400;
    color: white;
`

const ChatRoom = ({isSelected, selectedId, changeHandler, data, isfirst}) => {
    let midManager = useSelector((state)=>state.midnightLoginManager);
    // let [Status, setStatus] = useState(status01);
    let [pinIcon, setPinIcon] = useState(pin);

    const [Id, setId] = useState('');
    const [name, setName] = useState('');
    const [region, setRegion] = useState('');
    const [choiceTitle, setChoiceTitle] = useState('');
    const [Status, setStatus] = useState(status01);
    const [pinYn, setPinYn] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const [waiting, setWaiting] = useState(0);
    const [updatedAt, setUpdatedAt] = useState('');

    useEffect(() => {
        if (data) {
            setId(data?.id || '')
            setName(data?.name || ''); // 초이스톡 채팅방 이름
            setRegion(data?.region || '');
            setChoiceTitle(data?.choice_title || ''); // 초이스톡 서브 타이틀
            // setStatus(data?.status === 'active' || data?.status === 'plenty' ? status01 : data?.status === 'neither' ? status02 : status03);
            setStatus(data?.status);
            setPinYn(data?.pin_yn || false);
            setIsFavorite(data?.is_favorite || false);
            setWaiting(data?.waiting || 0); // 대기인원
            setUpdatedAt(data?.talk_created_at || '');
           
        }
    }, [data]);


    const favoriteHandler = () => {
        axios.put(`https://api.midtrc.com/choice/${Id}/favorite`,{},{
            headers: {
                'access-token': midManager?.log_data?.access_token
            }
        }).then((res)=>{changeHandler()}).catch((error)=>{console.log(error)})

    }

    const selectedHandler = (e) => {
        isSelected(e);
        changeHandler();
    }

    const pinHandler = () => {
        axios.put(`https://api.midtrc.com/choice/${Id}/pin`,{},{
            headers: {
                'access-token': midManager?.log_data?.access_token
            }
        }).then((res)=>{changeHandler()}).catch((error)=>{console.log(error)})
    }

    // data?.status active paused plenty(월활) neither(중간) crowded(혼잡)
    const statusHandler = () => {
        switch(Status){
            case 'active':
                return <ChatInfoFirstStatus src={status01} alt="status-image"/>;
            case 'plenty':
                return <ChatInfoFirstStatus src={status01} alt="status-image"/>;
            case 'neither':
                return <ChatInfoFirstStatus src={status02} alt="status-image"/>;
            case 'crowded':
                return <ChatInfoFirstStatus src={status03} alt="status-image"/>;
            default :
                return <ChatInfoFirstStatus src={status01} alt="status-image"/>;
        }
    }

    const formatTime = (postTime) => {
        const now = new Date();
        
        // 서버 시간이 UTC라면 9시간을 더해 KST로 변환
        const postDate = new Date(new Date(postTime).getTime() + 9 * 60 * 60 * 1000);
    
        const diffInSeconds = Math.floor((now - postDate) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        
        if (diffInSeconds < 60) {
            return '지금';
        } else if (diffInMinutes < 60) {
            return `${diffInMinutes}분 전`;
        } else if (diffInHours < 24) {
            return `${diffInHours}시간 전`;
        } else if (diffInDays === 1) {
            return '어제';
        } else {
            const year = postDate.getFullYear().toString().slice(2); // 연도의 마지막 두 자리를 가져옵니다.
            const month = (postDate.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 +1
            const day = postDate.getDate().toString().padStart(2, '0');
            return `${year}.${month}.${day}`;
        }
    }

    const TimeDisplay = ({ postTime }) => {
        const formattedTime = formatTime(postTime);
        if(updatedAt){
            return <div>{formattedTime}</div>;
        }
        return ''
    };

    const truncateHandler = () => {
        let instCount = 30;
        let instText = choiceTitle;
        if (instText?.length > instCount) {
            return instText.slice(0, instCount) + '...';
          }
        return instText
    }

    // useState(()=>{
    //     if(isfirst){
    //         selectedHandler(data.id)
    //     }
    // },[])


    switch(Id === selectedId){
        case true :
            return(
                <ChatRoomclickedBorder onClick={()=>{isSelected(data.id)}}>
                    <ChatRoomclickedImageBorder>
                        <ChatRoomClickedImage src={data?.profile_url} alt="clicked-border"/>
                        <ChatRoomClickedCover />
                        {/* 첫째 줄 */}
                        <ChatRoomClickedFirst>
                            <ChatRoomClickedIconBorder>
                                <ChatRoomClickedIcon src={linkWhite} alt="link-white"/>
                                <ChatRoomClickedIcon onClick={(e)=>{e.stopPropagation(); favoriteHandler()}} src={isFavorite?bookMarkActive: bookMark} alt="bm-white"/>
                            </ChatRoomClickedIconBorder>
                        </ChatRoomClickedFirst>
                        {/* 둘째 줄 - 타이틀 및 지역구 */}
                        <ChatRoomClickedTitleBorder>
                            <ChatRoomClickedTitle>
                                {name}
                            </ChatRoomClickedTitle>
                            <ChatRoomClickedSubTitle>
                                {region}
                            </ChatRoomClickedSubTitle>
                        </ChatRoomClickedTitleBorder>
                    </ChatRoomclickedImageBorder>
                    <ChatRoomBorder>
                        <ChatProfileBorder>
                            <ChatProfileImage src={data?.profile_url} alt="profile"/>
                        </ChatProfileBorder>
                        <ChatInfoBorder>
                            {/* 첫째 줄 */}
                            <ChatInfoFirst>
                                <ChatInfoFirstLeft>
                                    {/* <ChatInfoFirstStatus src={Status} alt="status-image"/> */}
                                    {statusHandler()}
                                    <ChatInfoFirstTitle>
                                        {name}
                                    </ChatInfoFirstTitle>
                                    {
                                        data?.is_read?
                                        null
                                        :
                                        <ChatInfoFirstNew src={new01} alt="new"/>
                                    }
                                </ChatInfoFirstLeft>
                                <ChatInfoFirstRight>
                                    <ChatInfoFirstRightIconBorder>
                                        <ChatInfoFirstRightIcon onClick={(e)=>{e.stopPropagation(); pinHandler()}} src={pinYn?pinActive:pin} alt="pin-icon"/>
                                        {/* <ChatInfoFirstRightIcon src={bookMarkIcon} alt="bookmark-icon"/> */}
                                    </ChatInfoFirstRightIconBorder>
                                </ChatInfoFirstRight>
                            </ChatInfoFirst>
                            {/* 둘째 줄 */}
                            <ChatInfoSecondBorder>
                                {truncateHandler()}
                            </ChatInfoSecondBorder>
                            {/* 셋째 줄 */}
                            <ChatInfoThirdBorder>
                                <ChatInfoThirdText>
                                    {waiting}
                                </ChatInfoThirdText>
                                <ChatInfoThirdText>
                                    <TimeDisplay postTime={updatedAt}/>
                                </ChatInfoThirdText>
                            </ChatInfoThirdBorder>
                        </ChatInfoBorder>
                    </ChatRoomBorder>
                </ChatRoomclickedBorder>
            )
        
        case false :
            return(
                <ChatRoomBorder onClick={()=>{selectedHandler(data.id)}}>
                    <ChatProfileBorder>
                        <ChatProfileImage src={data?.profile_url} alt="profile"/>
                    </ChatProfileBorder>
                    <ChatInfoBorder>
                        {/* 첫째 줄 */}
                        <ChatInfoFirst>
                            <ChatInfoFirstLeft>
                                {/* <ChatInfoFirstStatus src={Status} alt="status-image"/> */}
                                {statusHandler()}
                                <ChatInfoFirstTitle>
                                    {name}
                                </ChatInfoFirstTitle>
                                {
                                    data?.is_read?
                                    null
                                    :
                                    <ChatInfoFirstNew src={new01} alt="new"/>
                                }
                            </ChatInfoFirstLeft>
                            <ChatInfoFirstRight>
                                <ChatInfoFirstRightIconBorder>
                                    <ChatInfoFirstRightIcon onClick={(e)=>{e.stopPropagation(); pinHandler()}} src={pinYn?pinActive:pin} alt="pin-icon"/>
                                    <ChatInfoFirstRightIcon onClick={(e)=>{e.stopPropagation(); favoriteHandler()}} src={isFavorite?bookMarkActive: bookMark} alt="bookmark-icon"/>
                                </ChatInfoFirstRightIconBorder>
                            </ChatInfoFirstRight>
                        </ChatInfoFirst>
                        {/* 둘째 줄 */}
                        <ChatInfoSecondBorder>
                            {truncateHandler()}
                        </ChatInfoSecondBorder>
                        {/* 셋째 줄 */}
                        <ChatInfoThirdBorder>
                            <ChatInfoThirdText>
                                {waiting}
                            </ChatInfoThirdText>
                            <ChatInfoThirdText>
                                <TimeDisplay postTime={updatedAt}/>
                            </ChatInfoThirdText>
                        </ChatInfoThirdBorder>
                    </ChatInfoBorder>
                </ChatRoomBorder>
            )

        default:
            return <div/>
    }
    
}


export default ChatRoom;