/*eslint-disable*/
import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

import testBanner from "./../../@images/banner-L.png"
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

let BannerBorder = styled.div`
    width: 100%;
    height: 48.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

let BannerContentsBorder = styled.div`
    width: 100%;
    height: 46rem;
    display: flex;
    position: relative;
`

let BannerContents = styled.img`
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${props => props.isCurrent? 1:0};
    z-index: ${props => props.isCurrent? 30:0};
    transition: opacity 0.5s ease-in-out;
`

let PagiNationBorder = styled.div`
    width: auto;
    height: 1.2rem;
    display: flex;
    gap: 1rem;
`

let PagiNationButton = styled.svg`
    background-color: ${[props => props.isCurrent? 'black':'#d9d9d9']};
    min-width: 1.2rem;
    min-height: 1.2rem;
    max-width: 1.2rem;
    max-height: 1.2rem;
    border-radius: 1rem;
    border: none;
    cursor: pointer;
`

const MainBanner = () => {
    let navigate = useNavigate();
    let [currentPage, setCurrentPage] = useState(0);
    let midMainager = useSelector((state)=>state.midnightLoginManager);

    let testArray = [0,1,2,3,4,5]
    let [banner, setBanner] = useState([]);

    useEffect(()=>{
        const fetchData = async () => {
            try {
                let response = await axios.get('https://api.midtrc.com/banner',{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })

                let instData = response.data;
                let filterData = instData.filter(obj=>obj.banner_type === 'banner');

                setBanner(filterData)
            } catch (error) {
                console.log(error);
            }
        }
        fetchData()
    },[])

    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentPage(prevPage => (prevPage + 1) % banner?.length);
        }, 2000);
    
        return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 제거
      }, [banner]);


    const pageHandler = (e) => {
        setCurrentPage(e)
    }

    const linkHandler = (e) => {
        let url = e.click_url;

        if (!/^https?:\/\//i.test(url)) {
            url = 'https://' + url;
        }
        window.open(url, '_blank');
    }

    return(
        <BannerBorder>
            <BannerContentsBorder>
                {
                    banner?.map((a, i)=>(
                        <BannerContents onClick={()=>{linkHandler(a)}} key={i} isCurrent={i === currentPage} src={a.img_url} alt='contents' />
                    ))
                }
                
            </BannerContentsBorder>
            <PagiNationBorder>
                {
                    banner?.map((a, i)=>(
                        <PagiNationButton onClick={()=>{pageHandler(i)}} key={i} isCurrent={i === currentPage}/>
                    ))
                }
            </PagiNationBorder>
        </BannerBorder>
    )
}

export default MainBanner;