/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import arrow from "./../../@images/arrow-line-24.svg";
import search from "./../../@images/search.svg";
import checked from "./../../@images/check-circle-24.svg";
import MessageBoxRoom from "./mesage-box";
import axios from "axios";
import { useSelector } from "react-redux";

let RoomBorder = styled.div`
    width: 37.2rem;
`

let RoomFilterBorder = styled.div`
    box-sizing: border-box;
    padding: 0 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5.6rem;
    position: relative;
    background-color: white;
    border-radius: 0.4rem;
    border-width: 0.1rem;
    border-style: solid;
    border-color: #D2D2D2;
    margin-bottom: 2.4rem;
`

let FilterInput = styled.input`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 1.6rem 0;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #171717;
    border: none;
    background: none;
    outline: none;
    &::placeholder{
        color: #6a6a6a
    }
`

let FilterIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    cursor: pointer;
`

let CheckedBoxBorder = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    box-sizing: border-box;
    padding-bottom: 0.8rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 0 0 0.1rem 0;
    margin-bottom: 0.4rem;
`

let CheckedText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
    color: #9E9E9E;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: 0.1rem;
`

let ShowMoreBorder = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-size: 1.4rem;
    font-weight: 400;
    color: #9e9e9e;
    cursor: pointer;
    background-color: white;
`

let GroupsBorder = styled.div`
    position: relative;
    width: 100%;
    max-height: 64rem;
    overflow-y: auto;
    overflow-x: hidden;
     &::-webkit-scrollbar {
        width: 0; /* 너비 설정 */
      }
`


const MessageRoom = ({selectedFilter, clickedRoom, clickedRoomId, profile, roomIndex}) => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let [data, setData] = useState([]);
    let [fixedData, setFixedData] = useState([]);
    let [isChageData, setIsChageData] = useState(false);

    const filterHandler = (e) => {
        if(e === 3){
            return 'https://api.midtrc.com/message_group?group_type=manage';
        }else if(e === 4){
            return 'https://api.midtrc.com/message_group?group_type=event';
        }else if(e === 0){
            return 'https://api.midtrc.com/message_group?group_type=general';
        }else if(e === 1){
            return 'https://api.midtrc.com/message_group/unread';
        }else if(e === 2){
            return 'https://api.midtrc.com/message_group/favorite';
        }else{
            return 'https://api.midtrc.com/message_group?group_type=general';
        }
    }

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get(filterHandler(selectedFilter),{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                // 전체 데이터
                let instList = response.data;
                // 고정 된 데이터 리스트
                let fixedList = instList.filter(obj => obj.pin_yn === true);
                setFixedData(fixedList);
                let unfixedList = instList.filter(obj => obj.pin_yn === false);
                // 고정 안된 데이터 리스트
                setData(unfixedList); 

                // 최초 메시지 페이지 이동 시 첫 메시지 룸 선택
                clickedRoom(fixedList[0]? fixedList[0]?.contact_id:unfixedList[0]?.contact_id);
                roomIndex(fixedList[0]? fixedList[0]?.id:unfixedList[0]?.id)
                profile(fixedList[0]?fixedList[0]?.contact_profile_url:unfixedList[0]?.contact_profile_url)
                // console.log(fixedList[0]?fixedList[0]?.contact_id:unfixedList[0]?.contact_id)
            } catch (error) {
                console.log(error)
                setData([]);
                setFixedData([]);
                clickedRoom('');
                profile('');
            }
        }
        fetchData();
    },[selectedFilter, isChageData])

    const isChage = () => {
        setIsChageData(!isChageData)
    }




    return(
        <RoomBorder>
            <RoomFilterBorder>
                <FilterIcon src={search} alt="icon01"/>
                <FilterInput placeholder="검색어를 입력하세요"/>
                <FilterIcon src={arrow} alt="icon02"/>
            </RoomFilterBorder>
            <CheckedBoxBorder>
                <FilterIcon src={checked} alt="icon03"/>
                <CheckedText>
                    목록 편집
                </CheckedText>
            </CheckedBoxBorder>
            {/* 고정 메시지들 */}

            {/* 메시지들 */}
            
            <GroupsBorder>
                {
                    fixedData?.map((a, i)=>(
                        <MessageBoxRoom roomIndex={roomIndex} isChange={isChage} clickedRoom={clickedRoom} key={i} data={a} isSelected={a.contact_id === clickedRoomId}/>
                    ))
                }
                {
                    data?.map((a, i)=>(
                        <MessageBoxRoom roomIndex={roomIndex} isChange={isChage} clickedRoom={clickedRoom} key={i} data={a} isSelected={a.contact_id === clickedRoomId}/>
                    ))
                }
                
            </GroupsBorder>
            {
                data?.length>5?
                <ShowMoreBorder>
                    + 더 보기
                </ShowMoreBorder>
                :
                null
            }
            
        </RoomBorder>
    )
}

export default MessageRoom;