/*eslint-disable*/
import React from "react";
import styled from "styled-components";
import newPage from "./../../@images/newpage-24.svg";
import bookMarkActive from "./../../@images/bookmark-active.svg";

let MyJobCardBorder = styled.div`
    height: 25.6rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 1px;
    border-radius: 0.8rem;
    cursor: pointer;
`

let MyJobImages = styled.img`
    min-width: 100%;
    min-height: 11.6rem;
    max-width: 100%;
    max-height: 11.6rem;
    background-color: grey;
    object-fit: cover;
    border-radius: 0.8rem 0.8rem 0 0 ;
`

let MyJobInfoBorder = styled.div`
    box-sizing: border-box;
    padding: 1.5rem 2.4rem;
    background-color: white;
    height: 14rem;
    border-radius: 0 0 0.8rem 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`

let MyJobCompanyName = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #171717;
    box-sizing: border-box;
`

let MyJobContents = styled.div`
    box-sizing: border-box;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    text-align: left;
    color: #171717;
`

let MyJobSubInfoBorder= styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    aling-items: center;
    height: 2.4rem;
`

let MyJobSubArea = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6a6a6a;
    display: flex;
    align-items: center;
`

let MyJobSubMoneyType = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
`

let MyJobSubMoney = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.4rem;
`

let MyJobSubIconBorder = styled.div`
    display: flex;
    gap: 0.2rem;
    align-items: center;
`

let MyJobSubIcon = styled.img`
    height: 2.4rem;
    width: 2.4rem;
    cursor: pointer;
    object-fit: cover;
`

const MyJobCard = () => {


    return(
        <MyJobCardBorder>
            <MyJobImages alt="MyJob-banner"/>
            <MyJobInfoBorder>
                <MyJobCompanyName>
                    강남달빛토끼
                </MyJobCompanyName>
                <MyJobContents>
                    달빛토끼에서 함께 즐겁게 일하실 여성분(20/30대)모집
                </MyJobContents>
                <MyJobSubInfoBorder>
                    <MyJobSubArea>
                        
                        <MyJobSubMoneyType>
                            서울 강남구 · 
                        </MyJobSubMoneyType>
                        &nbsp;
                        <MyJobSubMoneyType style={{color: "#F32E13"}}>
                            시
                        </MyJobSubMoneyType>
                        &nbsp;
                        <MyJobSubMoney>
                            30,000
                        </MyJobSubMoney>
                        &nbsp;
                        <MyJobSubMoneyType>
                            원
                        </MyJobSubMoneyType>
                    </MyJobSubArea>
                    <MyJobSubIconBorder>
                        <MyJobSubIcon src={bookMarkActive} alt="book-mark-active"/>
                        <MyJobSubIcon src={newPage} alt="new-page"/>
                    </MyJobSubIconBorder>
                </MyJobSubInfoBorder>
            </MyJobInfoBorder>
        </MyJobCardBorder>
    )
}

export default MyJobCard;