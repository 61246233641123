/*eslint-disable*/
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import arrow from "./../../@images/arrow.svg";
import checkboxchecked from "./../../@images/checkbox_checked.svg";
import checkboxunchecked from "./../../@images/checkbox_unchecked.svg";

const CardContainer = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 2rem;
  padding-left:3rem;
  padding-right:3rem;
  text-align: left;
  box-sizing: border-box;
  margin: 0 auto;
  border: 1px solid #D2D2D2;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: ${props => props.border ? '1px solid #E0E0E0' : 'none'};
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;
  margin-top:2rem;
`;

const TotalCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;
  margin-bottom:0.5rem;
`;

const CheckboxImage = styled.img`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;

`;

const Label = styled.label`
  flex: 1;
  font-weight: ${props => props.required ? 'bold' : 'normal'};
`;

const CheckLabel = styled.span`
  font-weight: bold;
  color: #171717;
  font-size:1.6rem;
`;


const Required = styled.span`
  color: #E53935;
  font-size:1.6rem;
`;

const Text = styled.span`
  color: #171717;
  font-size:1.6rem;
`;

const TotalText = styled.span`
  color: #171717;
  font-size:1.8rem;
  font-weight:600;
`;

const Arrow = styled.img`
  width: 3rem;
  height: 3rem;
  position:relative;
  top:1rem;
  cursor: pointer;
  transform: ${props => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`;

const Details = styled.div`
  overflow: hidden;
  transition: height 0.3s ease;
  height: ${props => (props.expanded ? `${props.height}px` : '0')};
`;

const Button = styled.button`
  width: 100%;
  height:5.6rem;
  padding: 1rem;
  font-size: 1.8rem;
  color: #fff;
  background-color: #CD1B1B;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-top: 2rem;
  margin-bottom:1rem;

  &:hover {
    color: #CD1B1B;
    background-color: #fff;
    border: 1px solid #CD1B1B;
  }
`;

const Signup1Step = ({ goToNextStep, saveCheckedStatus }) => {
  const [expandedSection, setExpandedSection] = useState(null);
  const [allChecked, setAllChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState({
    terms1: false,
    terms2: false,
    terms3: false,
  });

  const refs = {
    terms1: useRef(null),
    terms2: useRef(null),
  };

  useEffect(() => {
    if (termsChecked.terms1 && termsChecked.terms2 && termsChecked.terms3) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [termsChecked]);

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const handleAllChecked = () => {
    const newCheckedStatus = !allChecked;
    setAllChecked(newCheckedStatus);
    setTermsChecked({
      terms1: newCheckedStatus,
      terms2: newCheckedStatus,
      terms3: newCheckedStatus,
    });
    // saveCheckedStatus(termsChecked)
  };

  const handleIndividualChecked = (term) => {
    setTermsChecked((prevState) => ({
      ...prevState,
      [term]: !prevState[term],
    }));
    // saveCheckedStatus(termsChecked)
  };

  const handleNextStep = () => {
    if (!termsChecked.terms1 || !termsChecked.terms2) {
      alert('필수 항목을 체크해주세요');
      return;
    }
    goToNextStep();
  };

  return (
    <CardContainer>
      <Section border>
        <TotalCheckboxContainer onClick={handleAllChecked}>
          <CheckboxImage
            src={allChecked ? checkboxchecked : checkboxunchecked}
            alt="Checkbox"
          />
          <Label htmlFor="allAgree">
            <TotalText>전체 약관 동의</TotalText>
          </Label>
        </TotalCheckboxContainer>
      </Section>
      <Section>
        <CheckboxContainer onClick={() => handleIndividualChecked('terms1')}>
          <CheckboxImage
            src={termsChecked.terms1 ? checkboxchecked : checkboxunchecked}
            alt="Checkbox"
          />
          <Label htmlFor="terms1" required>
            <Required>[필수]</Required> <Text>미드나잇 테라스 이용 약관</Text>
          </Label>
        </CheckboxContainer>
        <Arrow
          src={arrow}
          alt="arrow"
          expanded={expandedSection === 'terms1'}
          onClick={() => toggleSection('terms1')}
        />
      </Section>
      <Details
        ref={refs.terms1}
        expanded={expandedSection === 'terms1'}
        height={refs.terms1.current ? refs.terms1.current.scrollHeight : 'auto'}
      >
        <div ref={refs.terms1}>
          <Text>미드나잇테라스 이용약관 내용이 표시됩니다</Text>
        </div>
      </Details>
      <Section>
        <CheckboxContainer onClick={() => handleIndividualChecked('terms2')}>
          <CheckboxImage
            src={termsChecked.terms2 ? checkboxchecked : checkboxunchecked}
            alt="Checkbox"
          />
          <Label htmlFor="terms2" required>
            <Required>[필수]</Required> <Text>개인정보 보호정책</Text>
          </Label>
        </CheckboxContainer>
        <Arrow
          src={arrow}
          alt="arrow"
          expanded={expandedSection === 'terms2'}
          onClick={() => toggleSection('terms2')}
        />
      </Section>
      <Details
        ref={refs.terms2}
        expanded={expandedSection === 'terms2'}
        height={refs.terms2.current ? refs.terms2.current.scrollHeight : 'auto'}
      >
        <div ref={refs.terms2}>
          <Text>개인정보 보호정책 내용이 표시됩니다</Text>
        </div>
      </Details>
      <Section>
        <CheckboxContainer onClick={() => {handleIndividualChecked('terms3'); } }>
          <CheckboxImage
            src={termsChecked.terms3 ? checkboxchecked : checkboxunchecked}
            alt="Checkbox"
          />
          <Label htmlFor="terms3">
            <CheckLabel>[선택] SNS 수신동의</CheckLabel>
          </Label>
        </CheckboxContainer>
      </Section>
      <Button onClick={()=>{handleNextStep(); saveCheckedStatus(termsChecked.terms3 ? true:false)}}>다음</Button>
    </CardContainer>
  );
};

export default Signup1Step;
