/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import hamburger from "./../../@images/more-24.svg";
import chataddPhoto from "./../../@images/chat-add-photo.svg";
import chataddFile from "./../../@images/chat-add-file.svg";
import emoji from "./../../@images/chat-emoji.svg";
import send from "./../../@images/send-message.svg";
import waterMark from "./../../@images/wordmark.svg";
import MessageComment from "./message-comment";
import { useSelector } from "react-redux";
import axios from "axios";
import Complain from "../../@public-components/complain";



let MainBorder = styled.div`
    width: 57.2rem;
    min-height: 77.4rem;
    max-height: 100.4rem;
    border-radius: 1.6rem;
    border: none;
    background-color: #E6E8EC;
    box-sizing: border-box;
    padding: 1.6rem 0 0 0 ;
`

let MainTopBorder= styled.div`
    width: 100%;
    height: 2.4rem;
    position: relative;
`

let ShowMoreIcon = styled.div`
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
    position: absolute;
    right: 0.8rem;
    top: 0px;
`

let MainMiddleBorder = styled.div`
    box-sizing: border-box;
    padding-right: 0.8rem;
    position: relative;
`

let ChatScrollBorder = styled.div`
    width: 100%;
    height: 56.4rem;
    position: relative;
    overflow: auto;
    box-sizing: border-box;
    &::-webkit-scrollbar {
        width: 12px; /* 너비 설정 */
      }
    &::-webkit-scrollbar-thumb {
        background: #CBCED5;
        border-radius: 6px;
    }
`

let ChatBorder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: right;
    widht: 100px;
    box-sizing: border-box;
    padding-top: 0.8rem;
    padding-bottom: 2.4rem;
    margin-right: 0.8rem;
    margin-left: 1.6rem;
    gap: 2.4rem;
`

let MainBottomBorder = styled.div`
    width: 100%;
    min-height: 17rem;
    background-color: #DADEE5;
    padding: 0 1.6rem 1.6rem 1.6rem;
    box-sizing: border-box;
    border-radius: 0 0 1.6rem 1.6rem;
`

let MainBottmTextBorder = styled.div`
    height: 3.6rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 400;
    color: #444444;
`

let MainBottomChatBorder = styled.div`
    background-color: white;
    border-radius: 1.6rem;
    min-height: 11.8rem;
    width: 100%;
    box-sizing: border-box;
    padding: 2.4rem 2.4rem 1.6rem 2.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

let MainBottomChatBorderInput = styled.textarea`
    border-radius: 0.8rem;
    background-color: #f7f7f7;
    width: 100%;
    height: 4rem;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 0.8rem 1.6rem;
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    font-weight: 400;
    color: #444444;
    resize: none;
    overflow: hidden;
`

let MainBottomToolBorder = styled.div`
    height: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

let ButtonIcon = styled.img`
    width: 4rem;
    height: 4rem;
    objec-fit: cover;
    cursor: pointer;
`

let ButtonBorder = styled.div`
    display: flex;
    gap: 0.2rem;
`

let ChatwindowWaterMark = styled.img`
    position: absolute;
    width: 100%;
    top: 0rem;
    pointer-events: none;
    z-index: 20;
`

let EmojisBorder = styled.div`
    
    height: 24rem;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #dedede;
    border-width: 0.1rem;
    background-color: white;
    position: absolute;
    top: 4.5rem;
    left: 0;
    z-index: 30;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    overflow-y: auto;
    overflow-x: hidden;
`

let EmojisItems = styled.div`
    box-sizing: border-box;
    padding: 1rem;
    font-size: 1.8rem;
    cursor: pointer;
    &:hover{
        background-color: #f2f2f2;
    }
`

let DropBorder = styled.div`
    position: absolute;
    width: 10rem;
    height: auto;
    max-height: 30rem;
    right: 0px;
    top: 2em;
    z-index: 30;
    background-color: white;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #E8E8E8;
    border-width: 0.1rem;
    text-align: left;
`

let DropDownItems = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: grey;
    box-sizing: border-box;
    padding: 0 2rem;
    height: 3.4rem;
    &:hover{
        color: black;
    }
`

const MessageWindow = ({data, profile, changeData, roomIndex}) => {
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    let messageRef = useRef();
    let [messageContent, setMessageContent] = useState('');
    let [isCommentsDrop, setIsCommentsDrop] = useState(false);
    let [fileComment, setFileComment] = useState('');
    let [imageComment, setImageComment] = useState('');
    let emojis= [
        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
        '✅', '❎', '💯',
    ]

    const handleInput = (e) => {
        const textarea = e.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    };

    const messageHandler = (e) => {
        setMessageContent(e.target.value)
    }

    let imageRef = useRef();
    const imageSubmitHandler = (e) => {
        let file = e.target.files[0]

        // 이미지 업로드
        let requestBody = {
            "message_type": "image",
            "content": messageContent
        }

        const jsonRequestData = JSON.stringify(requestBody);
        const formData = new FormData();
        formData.append('req_body',jsonRequestData);
        if(file){
            formData.append('files', file);
        }

        axios.post(`https://api.midtrc.com/message/${data[0]?.contact_id}`,formData,{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{setMessageContent(''); changeData(); }).catch((error)=>{console.log(error)})
        imageRef.current.value = null
    }

    let fileRef = useRef();
    const fileSubmitHandler = (e) => {
        let file = e.target.files[0]

        // 파일
        let requestBody = {
            "message_type": "file",
            "content": messageContent
        }

        const jsonRequestData = JSON.stringify(requestBody);
        const formData = new FormData();
        formData.append('req_body',jsonRequestData);
        if(file){
            formData.append('files', file);
        }

        axios.post(`https://api.midtrc.com/message/${data[0]?.contact_id}`,formData,{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{setMessageContent(''); changeData(); }).catch((error)=>{console.log(error)})
        fileRef.current.value = null
    }

    const messageSubmitHandler = () => {
        let requestBody = {
            "message_type": "text",
            "content": messageContent
        }

        const jsonRequestData = JSON.stringify(requestBody);
        const formData = new FormData();
        formData.append('req_body',jsonRequestData);
        // if(main_image){
        //     formData.append('files',main_image);
        // }

        axios.post(`https://api.midtrc.com/message/${data[0]?.contact_id}`,formData,{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{setMessageContent(''); changeData()}).catch((error)=>{console.log(error)})

    }

    let commentsEmojisHandler = (e) => {
        setMessageContent(prevComments => prevComments + e);
    }

    let dropRef02 = useRef();
    const dropRef02Handler = (event) => {
        if (dropRef02.current && !dropRef02.current.contains(event.target)) {
            setIsCommentsDrop(false);
        }else{
            setIsCommentsDrop(true);
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', dropRef02Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef02Handler);
        };
    }, []);

    // 스크롤 최하단으로 이동
    const chatScrollRef = useRef();
    useEffect(()=>{
        if (chatScrollRef.current) {
            chatScrollRef.current.scrollTop = chatScrollRef.current.scrollHeight;
          }
    },[data])

    let [drop, setDrop] = useState(false);
    let [complainOpen, setComplainOpen] = useState(false);
    let dropRef01 = useRef();
    const dropRef01Handler = (event) => {
        if (dropRef01.current && !dropRef01.current.contains(event.target)) {
            setDrop(false);
        }else{
            setDrop(true);
        }
    }
    // 도 선택 드랍다운 박스 useEffect
    useEffect(() => {
        document.addEventListener('mousedown', dropRef01Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef01Handler);
        };
    }, []);

    const complainHandler = () => {
        if(complainOpen){
            setComplainOpen(false)
            document.body.style.overflow = 'auto';
        }else{
            setComplainOpen(true)
            document.body.style.overflow = 'hidden';
        }
    }

    return(
        <MainBorder>
            {
                complainOpen?
                <div>
                    <Complain handler={complainHandler} complain_type={'message_group'} service_id={roomIndex}/>
                </div>
                :
                null
            }
            <input ref={fileRef} onChange={fileSubmitHandler} alt="fileuploader" type="file" style={{display: "none"}}/>
            <input ref={imageRef} onChange={imageSubmitHandler} alt="thumbuploader" type="file" style={{display: "none"}}/>
            {/* 쇼 모어 버튼 */}
            <MainTopBorder>
                {/* <ShowMoreIcon src={hamburger} alt="icon01"/> */}
                <ShowMoreIcon>
                    <div ref={dropRef01} style={{position: "relative"}}>
                        <img src={hamburger} alt="dot-burger"/>
                        {
                            drop?
                            <DropBorder>
                                <DropDownItems onClick={(e)=>{e.stopPropagation();complainHandler(); setDrop(false)}}>
                                    신고하기
                                </DropDownItems>
                            </DropBorder>
                            :
                            null
                        }
                        
                    </div>
                </ShowMoreIcon>
            </MainTopBorder>
            <MainMiddleBorder>
                {/* 백그라운드 */}
                <ChatwindowWaterMark src={waterMark} alt="water-mark"/>
                <ChatScrollBorder  ref={chatScrollRef}>
                    {/* 채팅창 */}
                    <ChatBorder>
                        {
                            data?.map((a, i)=>(
                                <MessageComment 
                                key={i} 
                                who={a?.client_id === midMainager?.log_data?.id?'own':'other'} 
                                text={a?.content} 
                                profile={profile} 
                                date={a.created_at}
                                type={a.message_type}
                                />
                            ))  
                        }
                    </ChatBorder>
                </ChatScrollBorder>
            </MainMiddleBorder>
            <MainBottomBorder>
                <MainBottmTextBorder>
                    *타인에게 계좌 정보/전화번호 등을 노출하여 문제가 발생할 경우 미드나잇 테라스는 책임지지 않습니다.
                </MainBottmTextBorder>
                <MainBottomChatBorder>
                    <MainBottomChatBorderInput ref={messageRef} value={messageContent} onChange={messageHandler} onInput={handleInput} placeholder="익명으로 메시지 보내기"/>
                    <MainBottomToolBorder>
                        <ButtonBorder>
                            <ButtonIcon onClick={(e)=>{e.stopPropagation(); imageRef.current.click();}} src={chataddPhoto} alt="icon04"/>
                            <ButtonIcon onClick={(e)=>{e.stopPropagation(); fileRef.current.click();}} src={chataddFile} alt="icon05"/>
                            <div style={{display: "flex", alignItems: "center", position: "relative"}} ref={dropRef02} onClick={dropRef02Handler}>
                                <ButtonIcon src={emoji} alt="icon06"/>
                                {
                                    isCommentsDrop?
                                    <EmojisBorder >
                                        {
                                            emojis.map((a, i)=>(
                                                <EmojisItems onClick={(e)=>{e.stopPropagation(); commentsEmojisHandler(a); setIsCommentsDrop(false)}} key={i}>
                                                    {a}
                                                </EmojisItems>
                                            ))
                                        }
                                    </EmojisBorder>
                                    :
                                    <div style={{display: "none"}}/>
                                }
                            </div>
                            
                        </ButtonBorder>
                        <ButtonIcon onClick={()=>{messageSubmitHandler()}} src={send} style={{width: '3.2rem', height: '3.2rem'}} alt="icon07"/>
                    </MainBottomToolBorder>
                </MainBottomChatBorder>
            </MainBottomBorder>
        </MainBorder>
    )
}

export default MessageWindow;