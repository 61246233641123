/*eslint-disable*/
import React, { useState } from "react";
import styled from "styled-components";
import newPage from "./../../@images/newpage-24.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import bookMarkActive from "./../../@images/bookmark-active.svg";
import bookMarkDefault from "./../../@images/bookmark-default.svg";
import kebabmenuicon from "./../../@images/kebabmenuicon.svg";

let MyResumeCardBorder = styled.div`
    height: 25.6rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 1px;
    border-radius: 0.8rem;
    cursor: pointer;
`;

let MyResumeImages = styled.img`
    min-width: 2.4rem;
    min-height: 2.4rem;
    max-width: 2.4rem;
    max-height: 2.4rem;
    background-color: grey;
    object-fit: cover;
    border-radius: 2rem;
`;

let MyResumeInfoBorder = styled.div`
    box-sizing: border-box;
    padding: 2.4rem;
    background-color: white;
    height: 15.2rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 1px;
    border-radius: 0.8rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
`;

let MyResumeCompanyNameBorder = styled.div`
    width: 100%;
    font-family: 'Pretendard-Regular';
    font-weight: 800;
    font-size: 1.4rem;
    color: #171717;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

let MyResumeCompanyName = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 800;
    font-size: 1.4rem;
    color: #171717;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 0.4rem;
`;

let MyResumeContents = styled.div`
    box-sizing: border-box;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    text-align: left;
    color: #171717;
`;

let MyResumeSubInfoBorder= styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    height: 2.4rem;
`;

let MyResumeSubArea = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6A6A6A;
    display: flex;
    align-items: center;
`;

let MyResumeSubMoneyType = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
`;

let MyResumeSubMoney = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.4rem;
    color: #444444;
`;

let MyResumeSubIconBorder = styled.div`
    display: flex;
    gap: 0.2rem;
    align-items: center;
`;

let MyResumeSubIcon = styled.img`
    height: 2.4rem;
    width: 2.4rem;
    cursor: pointer;
    object-fit: cover;
`;

let MyResumeGender = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6A6A6A;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`;

let MyResumeKekbabMenuList = styled.div`
    position: absolute;
    box-sizing: border-box;
    z-index: 3;
    border: 1px solid #D2D2D2;
    background-color: white;
    width: 10.8rem;
    height: 15.6rem;
    right: -9rem;
    border-radius: 2px;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    display: ${props => (props.show ? "flex" : "none")};
    flex-direction: column;
`;

let MyResumeKekbabMenu = styled.div`
    font-family: 'Pretendard-Regular';
    margin-top: 1.2rem;
    font-weight: 400;
    font-size: 1.2rem;
    color: #444444;
    cursor: pointer;
`;

const MyResumeCard = ({data, isChange}) => {
    const [showMenu, setShowMenu] = useState(false);
    let navigate = useNavigate();
    let midMainager = useSelector((state)=>state.midnightLoginManager);


    const toggleMenu = () => {
        setShowMenu(prevShowMenu => !prevShowMenu);
    };

    const tcHandler = () => {
        if(data?.hourly_pay !== 0 && data?.hourly_pay !== null){
            return '시'
        }else if(data?.daily_pay !== 0 && data?.daily_pay !== null){
            return '일'
        }else if(data?.weekly_pay !== 0 && data?.weekly_pay !== null){
            return '주'
        }else if(data?.monthly_pay !== 0 && data?.monthly_pay !== null){
            return '월'
        }else if(data?.pension !== 0 && data?.pension !== null){
            return '연'
        }else if(data?.per_pay !== 0 && data?.per_pay !== null){
            return '건'
        }
    };

    const payHandler = () => {
        if(data?.hourly_pay !== 0 && data?.hourly_pay !== null){
            return data?.hourly_pay?.toLocaleString()
        }else if(data?.daily_pay !== 0 && data?.daily_pay !== null){
            return data?.daily_pay?.toLocaleString()
        }else if(data?.weekly_pay !== 0 && data?.weekly_pay !== null){
            return data?.weekly_pay?.toLocaleString()
        }else if(data?.monthly_pay !== 0 && data?.monthly_pay !== null){
            return data?.monthly_pay?.toLocaleString()
        }else if(data?.pension !== 0 && data?.pension !== null){
            return data?.pension?.toLocaleString()
        }else if(data?.per_pay !== 0 && data?.per_pay !== null){
            return data?.per_pay?.toLocaleString()
        }
    };

    const maskName = (name) => {
        if (!name || typeof name !== 'string') return '';
        const length = name.length;
        
        if (length === 3) {
          return name[0] + '*' + name[2];
        } else if (length === 4) {
          return name[0] + '**' + name[3];
        } else {
          return name; // 3글자 또는 4글자가 아닌 경우 변환하지 않음
        }
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        
        return `${year} . ${month} . ${day}`;
    };

    const statusHandler = (e) => {
        let reqeustBody = {
            "before": data.status,
            "after": e
            }

        axios.put(`https://api.midtrc.com/resume/${data.id}/status`,reqeustBody,{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{console.log(res.data); isChange(); setShowMenu(false)}).catch((error)=>{console.log(error)})

    }
    
    const delHandler = (e) => {

        axios.delete(`https://api.midtrc.com/resume/${data.id}`,{},{
            headers: {
                'access-token': midMainager?.log_data?.access_token
            }
        }).then((res)=>{console.log(res.data); isChange(); setShowMenu(false)}).catch((error)=>{console.log(error)})

    }
    return (
        <MyResumeInfoBorder onClick={()=>{navigate(`/myresume-edit/${data.id}`)}}>
            <MyResumeCompanyNameBorder>
                <MyResumeCompanyName>
                    <MyResumeImages alt="profile"/>
                    <div>{maskName(data?.client_name)}</div>
                    <MyResumeGender>({data?.gender === 'male'? '남':'여'}/{data?.age}세)</MyResumeGender>
                </MyResumeCompanyName>
                
                <MyResumeSubIconBorder>
                    <MyResumeSubIcon onClick={(e)=>{e.stopPropagation(); toggleMenu()}} src={kebabmenuicon} alt="book-mark-active"/>
                    {/* <MyResumeSubIcon onClick={(e)=>{e.stopPropagation(); newWindow()}} src={newPage} alt="new-page"/> */}
                </MyResumeSubIconBorder>
            </MyResumeCompanyNameBorder>
            <MyResumeContents>
                {data?.resume_title}
            </MyResumeContents>
            <MyResumeSubInfoBorder>
                <MyResumeSubArea>
                    <MyResumeSubMoneyType>
                        {data?.address[0]} · 
                    </MyResumeSubMoneyType>
                    &nbsp;
                    <MyResumeSubMoneyType style={{color: "#F32E13"}}>
                        {tcHandler()}
                    </MyResumeSubMoneyType>
                    &nbsp;
                    <MyResumeSubMoney>
                        {payHandler()}
                    </MyResumeSubMoney>
                    &nbsp;
                    <MyResumeSubMoneyType>
                        원
                    </MyResumeSubMoneyType>
                </MyResumeSubArea>
                <MyResumeSubArea style={{color: "#9E9E9E"}}>
                    {formatDate(data?.updated_at)}
                </MyResumeSubArea>
                
            </MyResumeSubInfoBorder>
            <MyResumeKekbabMenuList show={showMenu}>
                <MyResumeKekbabMenu onClick={()=>{delHandler()}}>수정/삭제</MyResumeKekbabMenu>
                <MyResumeKekbabMenu onClick={()=>{statusHandler('represent')}}>대표이력서 등록</MyResumeKekbabMenu>
                <MyResumeKekbabMenu onClick={()=>{statusHandler('writing')}}>작성 중</MyResumeKekbabMenu>
                <MyResumeKekbabMenu onClick={()=>{statusHandler('active')}}>구직 중</MyResumeKekbabMenu>
                <MyResumeKekbabMenu onClick={()=>{statusHandler('paused')}}>구직 마감</MyResumeKekbabMenu>
            </MyResumeKekbabMenuList>
        </MyResumeInfoBorder>
    );
};

export default MyResumeCard;
