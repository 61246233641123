import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import search from "./../../@images/search.svg";
import SnsContents from "./sns-contents-mob";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

let SnsContentsBorder = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.2rem;
`

let SnsFilterBorder = styled.div`
    width: 100%;
    height: 5.6rem;
    display: flex;
    justify-content: left;
    align-items: center;
`

let SnsFilter = styled.div`
    width: 50rem;
    height: 5.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

let SnsSearchBorder = styled.div`
    width: 18rem;
    height: 5.6rem;
    border-radius: 3.2rem;
    border-color: #d2d2d2;
    border-style: solid;
    border-width: 0.1rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: left;
    box-sizing: border-box;
    padding: 1.6rem;
`

let SnsSearch = styled.input`
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6A6A6A;
    &::placeholder{
        color: #d2d2d2;
    }
`

let SearchIcon = styled.img`
    width: 3.2rem;
    height: 3.2rem;
`

let SnsButtonFilter = styled.div`
    width: 20rem;
    height: 5.6rem;
    border-radius: 3.2rem;
    border-color: #d2d2d2;
    border-style: solid;
    border-width: 0.1rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1.6rem;
`

let SnsButton = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: ${props => props.isSelected ? '#171717' : '#9E9E9E'};
    cursor: pointer;
`

let SnsSlideer = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 100;
    font-size: 1.2rem;
    color: #9e9e9e;
`

let SnsContentsListBorder = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

let SnsContentsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    width: 100%;
    box-sizing: border-box;
    margin-top: 1.6rem;
`

let SubmitBorder = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-top: -2rem;
    margin-bottom: 2rem;
    position: relative;
`

let SubmitButton = styled.button`
    background-color: #CD1B1B;
    border-radius: 0.4rem;
    border: none;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    color: white;
    position: sticky;
    top: 20rem;
    width: 100%;
    height: 5.6rem;
    cursor: pointer;
`

const SnsContentsBoardMob = () => {
    let navigate = useNavigate();
    let midMainager = useSelector((state) => state.midnightLoginManager);
    let [data, setData] = useState([]);
    let [displayData, setDisplayData] = useState([]);
    let [changeData, setChangeData] = useState(false);
    let [searchKeyword, setSearchKeyword] = useState('');
    let [sortOption, setSortOption] = useState('latest');
    const postRefs = useRef([]);
    const params = useParams();
    const ITEMS_PER_PAGE = 5;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/lounge', {
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instList = response.data;
                let filterData = instList.filter(obj => obj.lounge_type === 'sns');
                setData(filterData);
                setDisplayData(filterData.slice(0, ITEMS_PER_PAGE));
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [changeData, midMainager]);

    const loadMoreItems = useCallback(() => {
        setDisplayData(prev => {
            const nextPageItems = data.slice(prev.length, prev.length + ITEMS_PER_PAGE);
            return [...prev, ...nextPageItems];
        });
    }, [data]);

    const handleScroll = useCallback(() => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            loadMoreItems();
        }
    }, [loadMoreItems]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    const isChangeData = () => {
        setChangeData(!changeData);
    }

    // 콘텐츠 주소로 이동 시 스크롤 이동 코드
    useEffect(() => {
        if (params.id) {
            const postElement = document.getElementById(`post-${params.id}`);
            if (postElement) {
                postElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [params.id]);

    const handleSearchChange = (event) => {
        setSearchKeyword(event.target.value);
        setChangeData(!changeData);
    };

    const handleSortChange = (sortOption) => {
        setSortOption(sortOption);
        setChangeData(!changeData);
    };

    const filterAndSortData = () => {
        let filteredData = data.filter(item =>
            item.lounge_title.toLowerCase().includes(searchKeyword.toLowerCase())
        );

        switch (sortOption) {
            case 'latest':
                filteredData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                break;
            case 'recommendation':
                filteredData.sort((a, b) => b.like_count - a.like_count);
                break;
            case 'comments':
                filteredData.sort((a, b) => b.comment_count - a.comment_count);
                break;
            default:
                break;
        }

        return filteredData;
    };

    useEffect(() => {
        setDisplayData(filterAndSortData().slice(0, ITEMS_PER_PAGE));
    }, [data, searchKeyword, sortOption]);

    return (
        <SnsContentsBorder>
            {/* 오른쪽 리스트 */}

            <SubmitBorder>
                <SubmitButton onClick={() => { navigate('/community-add/sns') }}>
                    게시글 작성
                </SubmitButton>
            </SubmitBorder>
            <SnsFilterBorder>
                <SnsFilter>
                    <SnsSearchBorder>
                        <SearchIcon src={search} alt="search-icon" />
                        <SnsSearch
                            placeholder="검색어를 입력하세요"
                            value={searchKeyword}
                            onChange={handleSearchChange}
                        />
                    </SnsSearchBorder>
                    <SnsButtonFilter>
                        <SnsButton
                            isSelected={sortOption === 'latest'}
                            onClick={() => handleSortChange('latest')}
                        >
                            최신순
                        </SnsButton>
                        <SnsSlideer>|</SnsSlideer>
                        <SnsButton
                            isSelected={sortOption === 'recommendation'}
                            onClick={() => handleSortChange('recommendation')}
                        >
                            추천순
                        </SnsButton>
                        <SnsSlideer>|</SnsSlideer>
                        <SnsButton
                            isSelected={sortOption === 'comments'}
                            onClick={() => handleSortChange('comments')}
                        >
                            댓글 많은 순
                        </SnsButton>
                    </SnsButtonFilter>
                </SnsFilter>
            </SnsFilterBorder>
            <SnsContentsListBorder>
                {/* 왼쪽 */}
                <SnsContentsList>
                    {
                        displayData?.map((a, i) => (
                            <SnsContents key={i} data={a} reff={(el) => (postRefs.current[i] = el)} isChange={isChangeData} />
                        ))
                    }
                </SnsContentsList>
                {/* 오른쪽 */}
            </SnsContentsListBorder>
        </SnsContentsBorder>
    )
}

export default SnsContentsBoardMob;
