/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import snslike from "./../../@images/sns-like-40.svg";
import comment from "./../../@images/comment-24.svg";
import circleArrow from "./../../@images/circle-next-24.svg";
import circleArrowBlack from "./../../@images/circle-next-black-24.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

let ListMainBorder = styled.div`
    margin-top: 0.8rem;
    width: 104rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-color: #d2d2d2;
    border-width: 0 0 0.1rem 0;
    border-style: solid;
    column-gap: 2rem;
    row-gap: 0.4rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
`

let ListBorder = styled.div`
    width: 100%;
    height: 4rem;
    box-sizing: border-box;
    padding: 0 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`

let ListNumberBrder = styled.div`
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: ${props => props.isRed? '#F32E13': '#E8E8E8'};
    font-family: 'Pretendard-Regular';
    font-weight: 700;
    font-size: 1rem;
    box-sizing: border-box;
    padding-top: 0.2rem;
    margin-right: 0.8rem;
`

let ListContentsText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    color: #171717;
    box-sizing: border-box;
    padding-top: 0.2rem;
`

let ListButtonBorder = styled.div`
    display: flex;
    justify-content: right;
    align-items: center;
`

let ListIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
`
let ListCount = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.2rem;
    color: #9E9E9E;
    box-sizing: border-box;
    padding-top: 0.3rem;
    width: 2rem;
    text-align: center;
`

let FavTitleBorder = styled.div`
    width: 104rem;
    height: 4.8rem;
    margin-top: 3.2rem;
    box-sizing: border-box;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #171717;
    padding: 0.8rem;
`

let FavButtonBorder = styled.div`
    height: 3.2rem;
    display: flex;
    align-items: center;
    gap: 0.4rem;

`


let FavIcon  = styled.img`
    min-width: 3.2rem;
    min-height: 3.2rem;
    max-width: 3.2rem;
    max-height: 3.2rem;
    object-fit: cover;
    transform: rotate(${props=>props.rotate}deg);
    cursor: pointer;
`


const CommunityList = () => {
    let [myData, setMyData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    let midMainager = useSelector((state)=>state.midnightLoginManager);
    const itemsPerPage = 6;
    const totalPages = Math.ceil(myData?.length / itemsPerPage);
    let navigate = useNavigate();

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/lounge',{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instList = response.data;
                let filterData = instList.filter(obj=>obj.hot_issue === true);
                setMyData(filterData);
                
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    },[])

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        } else {
            setCurrentPage(1);
        }
    };

    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else {
            setCurrentPage(totalPages);
        }
    };

    const ListComponent = ({ number, isRed, like, message, data }) => {
        const displayText = () => {
            let instCount = 25;
            if (data?.lounge_title?.length > instCount) {
            return data?.lounge_title.slice(0, instCount) + '...';
            }
            return data?.lounge_title;
        }
        
        const clickedHandler = () => {
            if(data?.lounge_type === 'sns'){
                navigate(`/community/${data?.id}`);
                window.location.reload()
            }else{
                navigate(`/community-post/${data?.id}`);
            }
        }

        return (
            <ListBorder onClick={()=>{clickedHandler()}}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <ListNumberBrder isRed={isRed}>
                        {number}
                    </ListNumberBrder>
                    <ListContentsText>
                        {displayText()}
                    </ListContentsText>
                </div>
                <ListButtonBorder>
                    <ListIcon src={snslike} alt="like" />
                    <ListCount style={{ marginRight: "0.4rem" }}>
                        {like}
                    </ListCount>
                    <ListIcon src={comment} alt="like" />
                    <ListCount>
                        {message}
                    </ListCount>
                </ListButtonBorder>
            </ListBorder>
        );
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = myData.slice(startIndex, endIndex);

    return (
        <div>
            <FavTitleBorder>
                <div>
                    HOT ISSUE
                </div>
                <FavButtonBorder>
                    <FavIcon src={circleArrow} rotate={0} alt="circle-arrow01" onClick={handlePrev} />
                    <FavIcon src={circleArrow} rotate={180} alt="circle-arrow02" onClick={handleNext} />
                </FavButtonBorder>
            </FavTitleBorder>

            <ListMainBorder>
                {
                    currentItems.map((a, i) => (
                        <ListComponent
                            key={a.id}
                            number={startIndex + i + 1}
                            isRed={(startIndex + i) % 2 === 0}
                            like={a.like_count}
                            message={a.comment_count}
                            data={a}
                        />
                    ))
                }
            </ListMainBorder>
        </div>
        );
    };

export default CommunityList;