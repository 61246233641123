import React, { useState } from "react";
import styled from "styled-components";
import newPage from "./../../@images/newpage-24.svg";
import bookMarkActive from "./../../@images/bookmark-active.svg";
import kebabmenubtn from "./../../@images/kebabmenuicon.svg";

let MyResumeCardBorder = styled.div`
    height: 25.6rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 1px;
    border-radius: 0.8rem;
    cursor: pointer;
`;

let MyResumeImages = styled.img`
    min-width: 2.4rem;
    min-height: 2.4rem;
    max-width: 2.4rem;
    max-height: 2.4rem;
    background-color: grey;
    object-fit: cover;
    border-radius: 2rem;
`;

let MyResumeInfoBorder = styled.div`
    box-sizing: border-box;
    padding: 2.4rem;
    background-color: white;
    height: 15.2rem;
    border-style: solid;
    border-color: #d2d2d2;
    border-width: 1px;
    border-radius: 0.8rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
`;

let MyResumeCompanyNameBorder = styled.div`
    width: 100%;
    font-family: 'Pretendard-Regular';
    font-weight: 800;
    font-size: 1.4rem;
    color: #171717;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

let MyResumeCompanyName = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 800;
    font-size: 1.4rem;
    color: #171717;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 0.4rem;
`;

let MyResumeContents = styled.div`
    box-sizing: border-box;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
    text-align: left;
    color: #171717;
`;

let MyResumeSubInfoBorder= styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    height: 2.4rem;
`;

let MyResumeSubArea = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6A6A6A;
    display: flex;
    align-items: center;
`;

let MyResumeSubMoneyType = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
`;

let MyResumeSubMoney = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.4rem;
    color: #444444;
`;

let MyResumeSubIconBorder = styled.div`
    display: flex;
    gap: 0.2rem;
    align-items: center;
`;

let MyResumeSubIcon = styled.img`
    height: 2.4rem;
    width: 2.4rem;
    cursor: pointer;
    object-fit: cover;
`;

let MyResumeGender = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #6A6A6A;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`;

let MyResumeKekbabMenuList = styled.div`
    position: absolute;
    box-sizing: border-box;
    z-index: 3;
    border: 1px solid #D2D2D2;
    background-color: white;
    width: 10.8rem;
    height: 15.6rem;
    right: 3rem;
    border-radius: 2px;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    display: ${props => (props.show ? "flex" : "none")};
    flex-direction: column;
`;

let MyResumeKekbabMenu = styled.div`
    font-family: 'Pretendard-Regular';
    margin-top: 1.2rem;
    font-weight: 400;
    font-size: 1.2rem;
    color: #444444;
    cursor: pointer;
`;

const MyResumeCard = () => {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(prevShowMenu => !prevShowMenu);
    };

    return (
        <MyResumeInfoBorder style={{marginBottom: "1.5rem"}}>
            <MyResumeCompanyNameBorder>
                <MyResumeCompanyName>
                    <MyResumeImages alt="profile"/>
                    <div>강남달빛토끼</div>
                    <MyResumeGender>(여/24세)</MyResumeGender>
                </MyResumeCompanyName>
                <MyResumeSubIconBorder>
                    <MyResumeSubIcon src={bookMarkActive} alt="book-mark-active"/>
                    <MyResumeSubIcon src={newPage} alt="new-page"/>
                    <MyResumeSubIcon src={kebabmenubtn} alt="kebabmenu" onClick={toggleMenu}/>
                </MyResumeSubIconBorder>
            </MyResumeCompanyNameBorder>
            <MyResumeContents>
                단기로 일할 곳 찾습니다.
            </MyResumeContents>
            <MyResumeSubInfoBorder>
                <MyResumeSubArea>
                    <MyResumeSubMoneyType>
                        서울 강남구 · 
                    </MyResumeSubMoneyType>
                    &nbsp;
                    <MyResumeSubMoneyType style={{color: "#F32E13"}}>
                        시
                    </MyResumeSubMoneyType>
                    &nbsp;
                    <MyResumeSubMoney>
                        30,000
                    </MyResumeSubMoney>
                    &nbsp;
                    <MyResumeSubMoneyType>
                        원
                    </MyResumeSubMoneyType>
                </MyResumeSubArea>
                <MyResumeSubArea style={{color: "#9E9E9E"}}>
                    2024.4.6
                </MyResumeSubArea>
            </MyResumeSubInfoBorder>
            <MyResumeKekbabMenuList show={showMenu}>
                <MyResumeKekbabMenu>수정/삭제</MyResumeKekbabMenu>
                <MyResumeKekbabMenu>대표이력서 등록</MyResumeKekbabMenu>
                <MyResumeKekbabMenu>작성 중</MyResumeKekbabMenu>
                <MyResumeKekbabMenu>구직 중</MyResumeKekbabMenu>
                <MyResumeKekbabMenu>구직 마감</MyResumeKekbabMenu>
            </MyResumeKekbabMenuList>
        </MyResumeInfoBorder>
    );
};

export default MyResumeCard;
