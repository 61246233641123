import React, { useState } from "react";
import styled from "styled-components";
import MyJobCard from "./business-mypage-myjob-card-mob";

let MypageBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
`;

let MyResumeBorder = styled.div`
    margin-top: 0.8rem;
    width: 100%;
    gap: 4rem;
    padding-left:2rem;
    padding-right:2rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    background-color: #F7F7F7;
    padding-top: 3rem;
`;

let MyResumeSection = styled.div`
    width: 100%;
    padding-top: 11rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 7.2rem;
`;

let MypageResumeinfo = styled.div`
    padding-top: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 4rem;
    background-color: white;
    border-radius: 16px;
    border: 1px solid #D2D2D2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let MypageTitleBar = styled.div`
    color: #171717;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
`;

let MypageMyResumeTitle = styled.div`
    color: #171717;
    font-family: 'Pretendard-Regular';
    font-size: 2rem;
    font-weight: 600;
`;

let MypageMyResumeAddResume = styled.div`
    color: white;
    background-color: #222222;
    font-size: 1.6rem;
    width: 12rem;
    height: 4rem;
    border: 1px solid #222222;
    border-radius: 4px;
    font-family: 'Pretendard-Regular';
    display: flex; 
    justify-content: center; 
    align-items: center; 
    cursor: pointer;

    &:hover {
        color: #222222; 
        background-color: white; 
        border-color: #222222; 
    }
`;

let MypageMyResumedivider = styled.div`
    width: 100%;
    border-bottom: 1px solid #E8E8E8;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

let MypageMyCoreResumeContainer = styled.div`
    width: 100%;
    margin-top: 1rem;
`;

let MypageMyCoreResumSubtitle = styled.div`
    color: #444444;
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
`;

let MypageMyCoreResumCardContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

let MypageMyResumeTabContainer = styled.div`
    width: 100%;
    margin-top: 1rem;
    display: flex;
    gap: 1.5rem;
`;

let MypageMyResumeTabtitle = styled.div`
    color: ${({ active }) => (active ? '#444444' : '#9E9E9E')};
    font-family: 'Pretendard-Regular';
    font-size: 1.6rem;
    cursor: pointer;
`;

let MypageMyResumeContainerWriting = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

let MypageMyResumeContainerGetWork = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

let MypageMyResumeContainerGetWorked = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

const Businessmyjob = () => {
    const [activeTab, setActiveTab] = useState("writing");

    return (
        <MypageBackground>
            <MyResumeBorder>
                <MyResumeSection>
                    <MypageResumeinfo>
                        <MypageTitleBar>
                            <MypageMyResumeTitle>내 공고 관리</MypageMyResumeTitle>
                            <MypageMyResumeAddResume>+ 공고 작성</MypageMyResumeAddResume>
                        </MypageTitleBar>
                        <MypageMyResumedivider />
                        <MypageMyCoreResumeContainer>
                            <MypageMyCoreResumSubtitle>
                                대표 공고
                            </MypageMyCoreResumSubtitle>
                        </MypageMyCoreResumeContainer>
                        <MypageMyCoreResumCardContainer>
                            <MyJobCard />
                        </MypageMyCoreResumCardContainer>

                        <MypageMyResumeTabContainer>
                            <MypageMyResumeTabtitle
                                active={activeTab === "writing"}
                                onClick={() => setActiveTab("writing")}
                            >
                                작성 중
                            </MypageMyResumeTabtitle>
                            <MypageMyResumeTabtitle
                                active={activeTab === "gettingWork"}
                                onClick={() => setActiveTab("gettingWork")}
                            >
                                구인 중
                            </MypageMyResumeTabtitle>
                            <MypageMyResumeTabtitle
                                active={activeTab === "gotWork"}
                                onClick={() => setActiveTab("gotWork")}
                            >
                                구인 마감
                            </MypageMyResumeTabtitle>
                        </MypageMyResumeTabContainer>

                        {activeTab === "writing" && (
                            <MypageMyResumeContainerWriting>
                                <MyJobCard />
                            </MypageMyResumeContainerWriting>
                        )}
                        {activeTab === "gettingWork" && (
                            <MypageMyResumeContainerGetWork>
                               <MyJobCard />
                               <MyJobCard />
                            </MypageMyResumeContainerGetWork>
                        )}
                        {activeTab === "gotWork" && (
                            <MypageMyResumeContainerGetWorked>
                                <MyJobCard />
                                <MyJobCard />
                                <MyJobCard />
                            </MypageMyResumeContainerGetWorked>
                        )}
                    </MypageResumeinfo>
                </MyResumeSection>
            </MyResumeBorder>
        </MypageBackground>
    );
};

export default Businessmyjob;
