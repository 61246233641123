import React, { useEffect, useState } from "react";
import styled from "styled-components";
import like from "./../../@images/like-24.svg";
import comment from "./../../@images/comment-24.svg";
import beforebasic1 from "./../../@images/main-before-basic.svg";
import beforehover1 from "./../../@images/main-before-hover.svg";
import nextbasic1 from "./../../@images/main-next-basic.svg";
import nexthover1 from "./../../@images/main-next-hover.svg";
import roungelike from "./../../@images/main-rounge-like.svg";
import roungecomment from "./../../@images/main-rounge-comment.svg";
import { useNavigate } from "react-router-dom";

import subBanner from "./../../@images/sub-banner.png";
import { useSelector } from "react-redux";
import axios from "axios";

const TitleBorder = styled.div`
    width: 100%;
    padding-left:2rem;
    padding-right:2rem;
    box-sizing: border-box;
    margin-bottom:3rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position:relative;
`

const TitleBordertotalshow = styled.div`
    font-size:1.6rem;
    color:#9e9e9e;
    position:absolute;
    text-align:right;
    right:2rem;
    bottom:-2rem;
    cursor:pointer;
`

const Titlecontent = styled.div`
    text-align:left;
`
const Titlesmall = styled.span`
    font-size:3.2rem;
`
const Titlebig = styled.span`
    font-size:4rem;
    font-weight:600;
`
const BestPickArrowContainer = styled.div`
    margin-top:0rem;
    display: flex;
    height: 5.6rem;
    width: 10rem;
    justify-content: space-between;
`

const ArrowButton = styled.div`
    width: 4.8rem;
    height: 4.8rem;
    background-image: url(${props => props.default});
    background-size: cover;
    cursor: pointer;
    &:hover {
        background-image: url(${props => props.hover});
    }
`

const RoungeBorder = styled.div`
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    padding: 4rem 0 7.2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const RoungeContentBorder = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 0 2rem 0 2rem;
`

const RoungeThumbBorder = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
`

const RoungeThumb = styled.img`
    width: 100%;
    position: absolute;
    top: 0;
    object-fit: cover;
    background-color: #f4f5f5f5;
    border-radius: 0.8rem;
    border: none;
`

const RoungeListBorder = styled.div`
    margin-top:1rem;
    margin-bottom:1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`

const RoungeListItemBorder = styled.div`
    margin-bottom:1rem;
    border-radius: 0.8rem;
    border-color: #d2d2d2;
    border-style: solid;
    border-width: 1px;
    width: 100%;
    box-sizing: border-box;
    padding-top:2.5rem;
    padding-bottom:2.5rem;
    padding-right:2rem;
    padding-left:2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2.4rem;
    cursor: pointer;
`

const RoungeListThumb = styled.img`
    min-width: 7.4rem;
    min-height: 7.4rem;
    max-width: 7.4rem;
    max-height: 7.4rem;
    border-radius: 0.8rem;
    border: none;
    background-color: #f5f5f5;
`

const RoungeThumbTextBorder = styled.div`
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    bottom: 0rem;
    width: 100%;
    border-radius: 0 0 0.8rem 0.8rem;
    box-sizing: border-box;
    padding-top:2.5rem;
    padding-bottom:2.5rem;
    padding-right:2rem;
    padding-left:2rem;
`

const RoungeThumbTextContainer = styled.div`
    width:100%;
    height:100%;
`
const RoungeThumbTextBorderTitle = styled.div`
    font-size:2rem;
    color:#ffffff;
`
const RoungeThumbTextBorderInfoContainer = styled.div`
    display: flex;
    margin-top:1rem;
    justify-content: space-between;
    align-items: flex-start;
    color:#9E9E9E;
    width:100%;
`
const RoungeThumbTextBorderInfoWriter = styled.div`
    color:#9E9E9E;
    font-size:2rem;
`
const RoungeThumbTextBorderInfoDataContainer = styled.div`
    display:flex;
    gap:1rem;
    margin-top:0.2rem;
`
const RoungeThumbTextBorderInfoDatasubgroup = styled.div`
    display:flex;
    gap:0.5rem;
    align-items: center;
`

const RoungeThumbTextBorderInfoImage = styled.img`
    cursor: pointer;
`

const RoungeThumbTextBorderInfoDatalike = styled.div`
    font-size:1.6rem;
    cursor:pointer;
`
const RoungeThumbTextBorderInfoDatacomment = styled.div`
    font-size:1.6rem;
    cursor:pointer;
`

const RoungeListInfoTitle = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 2rem;
`

const RoungeListInfoNick = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.8rem;
`

const RoungeListInfoBorder = styled.div`
    width: 11.7rem;
    height: 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const RoungeListInfoButton = styled.img`
    width: 2.4rem;
    height: 2.4rem;
`

const RoungeListInfoText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #9E9E9E;
`

const MainRounge = () => {
    let midMainager = useSelector((state) => state.midnightLoginManager);
    let navigate = useNavigate();
    const [imageContent, setImageContent] = useState([]);
    const [data, setData] = useState([]);
    const [currentImageNum, setCurrentImageNum] = useState(0);
    const [currentNum, setCurrentNum] = useState(0);

    const itemsPerPage = 1;
    const itemsDataPerPage = 4;
    const totalImagePages = Math.ceil(imageContent.length / itemsPerPage);
    const totaldataPages = Math.ceil(data.length / itemsDataPerPage);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/lounge',{
                    headers: {
                        'access-token': midMainager?.log_data?.access_token
                    }
                })
                let instList = response.data;
                let filterData = instList.filter(obj=>obj.status === 'best_pick_photo');
                setImageContent(filterData);
                setData(instList);
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [])

    const navigateRounge = (e) => {
        if(e.lounge_type === 'sns'){
            navigate(`/community/${e.id}`);
        }else if(e.lounge_type === 'general'){
            navigate(`/community-post/${e.id}`);
        }
        
    }

    const BorderList = ({data}) => {

        const truncatedContent = data?.lounge_title?.length > 10 ? data?.lounge_title?.substring(0, 10) + '...' : data?.lounge_title;



        return (
            <RoungeListItemBorder onClick={()=>{navigateRounge(data)}}>
                {
                    data?.image_urls?.length !==0?
                    <RoungeListThumb src={data?.image_urls[0]} alt="list-thumb" />
                    :
                    null
                }
                
                <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "6rem" }}>
                    <RoungeListInfoTitle>
                        {truncatedContent}
                    </RoungeListInfoTitle>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <RoungeListInfoNick>
                            {data?.nickname}
                        </RoungeListInfoNick>
                        <RoungeListInfoBorder>
                            <div style={{ display: "flex", gap: "0.2rem", alignItems: "center" }}>
                                <RoungeListInfoButton alt="like" src={like} />
                                <RoungeListInfoText>
                                    {data?.like_count}
                                </RoungeListInfoText>
                            </div>
                            <div style={{ display: "flex", gap: "0.2rem", alignItems: "center" }}>
                                <RoungeListInfoButton alt="comment" src={comment} />
                                <RoungeListInfoText>
                                    {data?.comment_count}
                                </RoungeListInfoText>
                            </div>
                        </RoungeListInfoBorder>
                    </div>
                </div>
            </RoungeListItemBorder>
        )
    }

    const handleNext = () => {
        // 이미지 페이지 넘김
        if (currentImageNum < totalImagePages - 1) {
            setCurrentImageNum(currentImageNum + 1);
        } else {
            setCurrentImageNum(0);
        }

        // 데이터 페이지 넘김
        if (currentNum < totaldataPages - 1) {
            setCurrentNum(currentNum + 1);
        } else {
            setCurrentNum(0);
        }
    };


    
    const handlePrev = () => {
        // 이미지 페이지 넘김
        if (currentImageNum > 0) {
            setCurrentImageNum(currentImageNum - 1);
        } else {
            setCurrentImageNum(totalImagePages - 1);
        }

        // 데이터 페이지 넘김
        if (currentNum > 0) {
            setCurrentNum(currentNum - 1);
        } else {
            setCurrentNum(totaldataPages - 1);
        }
    };

    const startIndex = currentNum * itemsDataPerPage;
    const endIndex = startIndex + itemsDataPerPage;
    const currentItems = data.slice(startIndex, endIndex);

    const startIndex1 = currentImageNum * itemsPerPage;
    const endIndex1 = startIndex1 + itemsPerPage;
    const currentItems1 = imageContent.slice(startIndex1, endIndex1);

    // const BorderList = () => {
    //     return (
    //         <RoungeListItemBorder onClick={() => navigate('/community')}>
    //             <RoungeListThumb alt="list-thumb" />
    //             <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "6rem" }}>
    //                 <RoungeListInfoTitle>
    //                     미드나잇 테라스 꿀알바 팁
    //                 </RoungeListInfoTitle>
    //                 <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
    //                     <RoungeListInfoNick>
    //                         달빛토끼
    //                     </RoungeListInfoNick>
    //                     <RoungeListInfoBorder>
    //                         <div style={{ display: "flex", gap: "0.2rem", alignItems: "center" }}>
    //                             <RoungeListInfoButton alt="like" src={like} />
    //                             <RoungeListInfoText>
    //                                 418
    //                             </RoungeListInfoText>
    //                         </div>
    //                         <div style={{ display: "flex", gap: "0.2rem", alignItems: "center" }}>
    //                             <RoungeListInfoButton alt="comment" src={comment} />
    //                             <RoungeListInfoText>
    //                                 392
    //                             </RoungeListInfoText>
    //                         </div>
    //                     </RoungeListInfoBorder>
    //                 </div>
    //             </div>
    //         </RoungeListItemBorder>
    //     )
    // }

    return (
        <RoungeBorder>
            {/* 타이틀 영역 */}
            <TitleBorder>
                <Titlecontent>
                    <Titlesmall>TERRACE ROUNGE</Titlesmall><br></br>
                    <Titlebig>BEST PICK</Titlebig>
                </Titlecontent>
                <BestPickArrowContainer>
                    <ArrowButton default={beforebasic1} hover={beforehover1} onClick={handlePrev}/>
                    <ArrowButton default={nextbasic1} hover={nexthover1} onClick={handleNext}/>
                </BestPickArrowContainer>
                <TitleBordertotalshow onClick={() => navigate('/community')}>전체보기</TitleBordertotalshow>
            </TitleBorder>
            {/* 콘텐츠 리스트 및 배너 영역 */}
            <RoungeContentBorder>
                {
                    currentItems1?.map((a, i)=>(
                        <RoungeThumbBorder style={{cursor: 'pointer'}} onClick={()=>{navigateRounge(a)}} key={i}>
                            {/* 썸네일 */}
                            <RoungeThumb src={a.image_urls[0]} alt="thumb" />
                            {/* 텍스트 영역 */}
                            <RoungeThumbTextBorder>
                                <RoungeThumbTextContainer>
                                    <RoungeThumbTextBorderTitle>
                                        {a.lounge_title}
                                    </RoungeThumbTextBorderTitle>
                                    <RoungeThumbTextBorderInfoContainer>
                                        <RoungeThumbTextBorderInfoWriter>
                                            {a.nickname}
                                        </RoungeThumbTextBorderInfoWriter>
                                        <RoungeThumbTextBorderInfoDataContainer>
                                            <RoungeThumbTextBorderInfoDatasubgroup>
                                                <RoungeThumbTextBorderInfoImage src={roungelike} alt="like" />
                                                <RoungeThumbTextBorderInfoDatalike>{a.like_count}</RoungeThumbTextBorderInfoDatalike>
                                            </RoungeThumbTextBorderInfoDatasubgroup>
                                            <RoungeThumbTextBorderInfoDatasubgroup>
                                                <RoungeThumbTextBorderInfoImage src={roungecomment} alt="comment" />
                                                <RoungeThumbTextBorderInfoDatacomment>{a.comment_count}</RoungeThumbTextBorderInfoDatacomment>
                                            </RoungeThumbTextBorderInfoDatasubgroup>
                                        </RoungeThumbTextBorderInfoDataContainer>
                                    </RoungeThumbTextBorderInfoContainer>
                                </RoungeThumbTextContainer>
                            </RoungeThumbTextBorder>
                        </RoungeThumbBorder>
                    ))
                }
                <RoungeListBorder>
                    {
                        currentItems?.map((a, i)=>(
                            <BorderList data={a} key={i}/>
                        ))
                    }
                </RoungeListBorder>
            </RoungeContentBorder>
        </RoungeBorder>
    )
}

export default MainRounge;